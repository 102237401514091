// Routes

// App Routes
export const MathTopicsRoute = "/app/math"
export const VRTopicsRoute = "/app/verbal-reasoning"
export const EnglishTopicsRoute = "/app/english"
export const DashboardRoute = "/app/dashboard"
export const AnalyticsPage = "/app/analytics"

// Admin Routes
export const AdminRoute = "/app/admin-whizz"
export const AdminMockExamDashboardRoute = "/app/admin-whizz/mock-exams"
export const AdminMockExamPapersRoute = "/app/admin-whizz/mock-exam-papers"
export const AdminMockExamSectionsRoute = "/app/admin-whizz/mock-exam-sections"
export const AdminMockExamSectionQuestionsRoute = "/app/admin-whizz/mock-exam-section-questions"
export const AdminAddEnglishQuestion = "/app/admin-whizz/add-english-question"
export const AdminAddGrammarQuestion = "/app/admin-whizz/add-grammar-question"
export const AdminAddComprehensionQuestion = "/app/admin-whizz/add-comprehension-question"
export const AdminMockExamSessionsView = "/app/admin-whizz/mock-exam-sessions"
export const AdminViewAllUsers = "/app/admin-whizz/view-all-users"
export const AdminViewMockExamSessionUsers = "/app/admin-whizz/view-session-users"

// Entry Support Routes
export const supportCurrentExamSetBeingEntered = "app/admin-whizz/mock-exam-papers/3"

// Teacher Routes
export const HeadTeacherDashboard = "/teacher/headteacher"
export const ClassesView = "/teacher/my-classes"
export const CreateNewClass = "/teacher/create-new-class"
export const ViewClass = "/teacher/view-class"
export const ViewStudent = "/teacher/view-student"
export const AddAssignmentView = "/teacher/add-assignment-view"

// Mock Exam Routes
export const MockExamLogin = "/auth/11plus-mock-exam-login"
export const MockExamBase = "/mock-exams"
export const MockExamDashboard = "/mock-exams/dashboard"
export const MockExamPastExams = "/mock-exams/past-exams"
export const MockExamUpcomingExams = "/mock-exams/upcoming-exams"
export const MockExamStartPage = "/mock-exams/start-exam-session"
export const MockExamPage = "/mock-exams/exam"
export const MockExamBreakPage = "/mock-exams/break"
export const MockExamSessionCompletePage = "/mock-exams/exam-session-complete"
export const MockExamPractisePage = "/mock-exams/practice-page"
export const MockExamDemoTest = "/mock-exams/demo-test"
export const MockExamDemoCompletePage = "/mock-exams/demo-complete"
export const ReviewMockExamPaper = "/mock-exams/review-mock-paper"


// Auth Routes
export const IndividualLogin = "/auth/individual-login"
export const StandardUserSignUpStart = "/auth/register/1"
export const ForgotPassword = "/auth/forgot-password"

// Blog Routes (also auth)
export const MainBlog = "/auth/11plus-preparation"
export const CEMBlog = "/auth/preparing-for-the-cem-11plus-exams"
export const GLBlog = "/auth/preparing-for-the-GL-11plus-exams"
export const TheImportanceOf11PlusMockExams = "/auth/importance-of-mock-exams"
export const ParentsElevenPlusPlaybook = "/auth/parents-eleven-plus-playbook"
export const ChoosingYourPerfectElevenPlusSchool = "/auth/your-perfect-eleven-plus-school"

// Blog Title 
export const MainBlogTitle = "11plus-preparation"
export const CEMBlogTitle = "Preparing-for-the-cem-11plus-exams"
export const GLBlogTitle = "Preparing-for-the-GL-11plus-exams"
export const TheImportanceOf11PlusMockExamsTitle = "The-importance-of-mock-exams"
export const ParentsElevenPlusPlaybookTitle = "Parents-eleven-plus-playbook"
export const ChoosingYourPerfectElevenPlusSchoolTitle = "Your-perfect-eleven-plus-school"

// Question Instructions for 11plus English 

export const SpellingQuestionInstructions = "In the sentence below, select the letter corresponding to the group of words that has a spelling mistake. If there are none then mark E."
export const SynonymsQuestionInstructions = "Select the word from the options that is most similar in meaning to the given word."
export const AntonymsQuestionInstructions = "Choose which option is most opposite in meaning to the word provided or can be added on as a prefix to make the word opposite in meaning."
export const MissingWordsQuestionInstructions = "Select the missing word or words from the ones given that best completes the sentence."
export const PunctuationQuestionInstructions = "Select the group of words that have a mistake. If there are none then mark E."
export const JumbledSentenceQuestionInstructions = "In this question, two of the words in the sentence have been swapped. Find the swapped pair and then click the corresponding option."
export const ShuffledSentencesQuestionInstructions = "In the jumbled up sentence below, there is one word that shouldn't be there. Find this word and click the correct answer option."
export const ComprehensionQuestionInstructions = "Read the following passage and then answer the questions."

// Conversion Page Routes 

export const MockExamsConversionPageRoute = "/11plus-mock-exams"
export const UnlimitedLearningConversionPageRoute = "/free-11plus-resources"

// TLH Admin Routes

export const TLHAdminAddObjects = "/tlh/admin-dashboard" //For adding assessments or new staff

// TLH Page Routes

export const TLHStaffLogin = "/tlh/staff-login"
export const TLHStaffDashboard = "/tlh/staff-dashboard"
export const TakeNewEnquiry = "/tlh/create-new-enquiry"
export const ViewOpenEnquiries = "/tlh/view-open-enquiries"
export const ViewBookedAssessments = "/tlh/view-booked-assessments"
export const ViewTLHClasses = "/tlh/view-classes"
export const ViewPrintedInventory = "/tlh/view-printed-inventory"
export const TimeSheets = "/tlh/staff-timesheets"
export const ViewTLHClass = "/tlh/view-tlh-class"
export const TLHParentEvenings = "/tlh/parents-evenings"



// TLH Enums

export const TLHEnquiryStatus = [ 
    "CONVERTED",
    "ASSESSMENT_BOOKED",
    "ASSESSMENT_MISSED",
    "ASSESSMENT_MEETING_NEEDS_SCHEDULING",
    "ASSESSMENT_MEETING_SCHEDULED",
    "ASSESSMENT_MEETING_MISSED",
    "ASSESSMENT_MEETING_ATTENDED",
    "INTRODUCTORY_MEETING_SCHEDULED",
    "INTRODUCTORY_MEETING_MISSED",
    "OPEN",
    "REQUIRES_FOLLOW_UP",
    "DEAD",
]

export const TLHSubjects = [ 
    "MATHS",
    "ENGLISH",
    "SCIENCE",
    "VR_NVR",
    "COMPUTING",
    "PHYSICS",
    "BIOLOGY",
    "CHEMISTRY"
]
export const TLHYearGroups = [ 
    "YEAR_1",
    "YEAR_2",
    "YEAR_3",
    "YEAR_4",
    "YEAR_5",
    "YEAR_6",
    "YEAR_7_AND_8",
    "YEAR_8_AND_9",
    "KS3_A",
    "KS3_B",
    "KS3_C",
    "GCSE_1",
    "GCSE_2"
]

export const TLHRooms = [ 
    "ROOM_1",
    "ROOM_2",
    "ROOM_3",
    "ROOM_4",
    "ROOM_5",
    "ROOM_6_ICT",
    "ONLINE"
]

export const TLHClassAttendanceOptions = [
    "PENDING",
    "HOLIDAY",
    "PRESENT",
    "ABSENT",
    "SICK",
    "LATE",
    "OTHER",
    "LEFT_EARLY"
]

// General Enums

export const DaysOfTheWeek = [ 
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday"
]

