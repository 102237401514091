import React, { useState, useEffect } from "react";

function CountdownTimer(props) {
  // subtract the 60*60*1000 due to gmt time diff
  // console.log(props)
  // console.log(props.targetTime)

  const [timeLeft, setTimeLeft] = useState(props.targetTime);


  // useEffect(() => {

  //   var time = new Date();
  //   console.log("seeing what we get")
  //   console.log(time)
  //   console.log(new Date())
  //   console.log(props.targetTime)
  //   time.setHours(props.targetTime.hours)
  //   time.setMinutes(props.targetTime.minutes)
  //   time.setSeconds(props.targetTime.seconds)
 
  //   setTimeLeft(time)
 
     
  //  let timer;
   
  //  if (timeLeft > 0) {
  //    timer = setTimeout(() => {
  //      setTimeLeft(timeLeft - 1000);
  //    }, 1000);
  //  } else {
  //    props.handleTimeExpired()
  //  }
 
  //    return () => clearTimeout(timer);
  //  }, [timeLeft, props]);

  useEffect(() => {
    
  let timer;

  // const totalTime = 

  
  if (timeLeft > 0) {
    timer = setTimeout(() => {
      setTimeLeft(timeLeft - 1000);
    }, 1000);
  } else {
    console.log("Time expired at: ")
    console.log(props.targetTime)
    console.log("Giving: ")
    console.log(props.targetTime - new Date())
    props.handleTimeExpired()
  }

    return () => clearTimeout(timer);
  }, [timeLeft, props]);

  const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
  const hours = Math.floor((timeLeft / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((timeLeft / (1000 * 60)) % 60);
  const seconds = Math.floor((timeLeft / 1000) % 60);

  // const hours = timeLeft.hours
  // const minutes = timeLeft.minutes
  // const seconds = timeLeft.seconds

  return (
    <div>
      {timeLeft < 0 ? "Time Up." : hours + " hours, " + minutes + " minutes and " + seconds + " seconds remaining."
      } 
    </div>
  );
}

export default CountdownTimer;
