import React, { useState, useEffect } from 'react';
import Title from 'components/Title';
import Grid from '@material-ui/core/Grid';

import MockExamQuestionTopicTile from './MockExamQuestionTopicTile';

import * as CONSTANTS from "../../StringConstants"


  export default function MockExamQuestionTile({question, section, submitAndGoToNextQuestion, currentUserAnswer}) {
    // first element is the question number - second element is the selected choice
    const [selectedChoice, setSelectedChoice] = useState([question.mockExamQuestionNumber, currentUserAnswer])
    var currentChoice = ""
    const [hasCheckedForBoldValue, setHasCheckedForBoldValue] = useState(false)
    const [questionNumberIndex, setQuestionNumberIndex] = useState(question.mockExamQuestionNumber)
    const [imageData, setImageData] = useState({});
    const [imageMultipleChoiceOptionsData, setImageMultipleChoiceOptionsData] = useState({});
    const [sectionImageData, setSectionImageData] = useState({})
    const [isButtonDisabled, setIsButtonDisabled] = useState(true)
    // const [startTimeOnQuestion, setStartTimeOnQuestion] = useState(new Date());


    console.log("In question tile with question info:")
    console.log(question)
    console.log("And section info")
    console.log(section)

    var questionNumber = question.mockExamQuestionNumber
    var multipleChoiceOptions = question.textMultipleChoiceOptions
    var grammarQuestionType = section.sectionName.toUpperCase()
    var comprehensionDescription = null

    useEffect(() => {
      console.log("In use effect - current user answer")
      console.log(currentUserAnswer)
      console.log("Current question number: " + question.mockExamQuestionNumber)
      console.log("Current selected choice: " + selectedChoice)
      if (question.mockExamQuestionNumber !== selectedChoice[0]) {
        setSelectedChoice([question.mockExamQuestionNumber, currentUserAnswer])
      }
      setImageData(question.mockExamQuestionImage)
      setImageMultipleChoiceOptionsData(question.imageMultipleChoiceOptions)
      setSectionImageData(section.sectionImage)
    });

    


    function disableButtonAndSubmitAnswer(mockExamQuestionNumber, selectedChoice) {
      setIsButtonDisabled(true)
      submitAndGoToNextQuestion(mockExamQuestionNumber, selectedChoice)
    }

    function checkAnswerIsInMultipleChoiceOption(answer) {
      for (var i = 0; i < multipleChoiceOptions.length; i++) {
        // console.log(i)
        // console.log("Checking: " + answer  + " and " + multipleChoiceOptions[i])
        if (multipleChoiceOptions[i] == answer) {
          // console.log("Returning true")
          return true
        }
      }
      // console.log("Returning false")
      return false
    }

    // if the selected choice isn't in the answer options 
    // check if current user answer is in answer options
    // if it is, selected = currentUserChoice - checked for bold value = true
    // otherwise nothing gets highlighted - checked for bold value = true
    // if selected choice in answer options, found our bold value - - checked for bold value = true

    // useEffect(() => {
    //   setSelectedChoice(currentUserAnswer)
    // });

    function shouldBeBold(answerOption) {
      // For each answer option, check if it equals the current selected choice
      // if not check if it equals the current user answer 
      // otherwise its not bold
  

      if (grammarQuestionType === "SPELLING" || grammarQuestionType === "PUNCTUATION") {
        // Could keep track at this level - have a list - each index is question number - 1
        // update the list on next and previous and 
        // return currentChoice == "" ? currentUserAnswer === answerOption : selectedChoice === answerOption
        if (selectedChoice[0] === questionNumber) {
          return selectedChoice[1] === answerOption
        } else {
          console.log()
          return currentUserAnswer === answerOption
        }


      } else {
        if (answerOption === selectedChoice[1]) {
          console.log("Same as selected choice")
          return true
        } 
        if (!checkAnswerIsInMultipleChoiceOption(selectedChoice[1])) {
          // console.log("Selected choice not in multiple choice options")
          if (answerOption === currentUserAnswer && answerOption !== selectedChoice[1]){
            // console.log("Same as current user answer and not selected choice")
            return true
          } else {
            // console.log("not the same")
            return false
          }
        }
      }
    }
  

    function onOptionClick(selection) {
      console.log("selection: " + selection)
      currentChoice = selection
      console.log("Current choice: " + currentChoice)
      console.log("Updating user answer with: " + selection)
      // updateUserAnswer(questionNumber, selection)
      setSelectedChoice([questionNumber, selection])
      setIsButtonDisabled(false)
    }

   
    function getMultipleChoiceOptions() {
      var multipleChoiceOptions = question.textMultipleChoiceOptions.split("||")
      console.log("Multiple choice options are: ")
      console.log(multipleChoiceOptions)
      console.log("Current selected choice is: " + selectedChoice)
      console.log("Current user answer is: " + currentUserAnswer)

      var tiles = [];

      for (var i = 0; i < multipleChoiceOptions.length; i++) {
        // console.log(i)

        var multipleChoiceAnswerOption = multipleChoiceOptions[i]
        console.log("Subject")
        console.log(section.subject)
        if (section.subject == "ENGLISH") {
          multipleChoiceAnswerOption = multipleChoiceAnswerOption.charAt(0).toUpperCase() + multipleChoiceAnswerOption.slice(1);
        }

        let isBold = shouldBeBold(multipleChoiceAnswerOption)
        console.log("isBold: " + isBold)
        tiles.push(
          <Grid item xs={12}>
            <MockExamQuestionTopicTile 
              answerOption={multipleChoiceAnswerOption} 
              optionNumber={i}
              onOptionClick={onOptionClick}
              currentAnswerSelectedByUser={selectedChoice}
              shouldBeBoldOption={isBold}
            />
          </Grid>
        )}
        return tiles
    }
    
    function formatSpellingOrPunctuationQuestion() {
      console.log("Grammar Question type is: " + grammarQuestionType)

      const questionSegments = question.mockExamQuestionText.split("||")
      console.log(questionSegments)
      var underLinedSegments = []


        underLinedSegments.push(
          // <Grid container spacing={2}>
          <Grid item style={{textAlign: "center", fontSize: "20px", display: "contents", justifyContent: "space-evenly"}}>
              {/* <u className='m-4'>{questionSegments[0]}</u>
              <u className='m-4'>{questionSegments[1]}</u>
              <u className='m-4'>{questionSegments[2]}</u>
              <u className='m-4'>{questionSegments[3]}</u> */}
              {questionSegments[0] + " " + questionSegments[1] + " " + questionSegments[2] + " " + questionSegments[3]}
          </Grid>
        )
    

         return <Grid container spacing={3} >
              <Grid item xs={12}>
                <Title style={{justifyContent: "space-evenly"}}>
                  {/* <div style={{display: "flex"}}> */}
                  {questionNumber}{") "} {underLinedSegments}
                  {/* </div>   */}
                </Title>
              </Grid>
                {getSpellingAndPunctuationMultipleChoiceOptions(questionSegments)}
            </Grid>
    }


    function getSpellingAndPunctuationMultipleChoiceOptions(questionSegments) {

      multipleChoiceOptions = question.textMultipleChoiceOptions.split("||")

      return <Grid item xs={12} style={{textAlign: "center", fontSize: "20px", display: "flex", justifyContent: "space-evenly"}}>
        <div>
          
          <MockExamQuestionTopicTile 
            answerOption={multipleChoiceOptions[0]} 
            optionNumber={""}
            onOptionClick={onOptionClick}
            currentAnswerSelectedByUser={selectedChoice}
            shouldBeBoldOption={shouldBeBold(multipleChoiceOptions[0])}
            style={{ display: 'block' }}
            questionSegmentText={questionSegments[0]}
          />
        </div>
        <div>
          {/* <u className='m-4' style={{ display: 'block' }}>{questionSegments[1]}</u> */}
          <MockExamQuestionTopicTile 
            answerOption={multipleChoiceOptions[1]} 
            optionNumber={""}
            onOptionClick={onOptionClick}
            currentAnswerSelectedByUser={selectedChoice}
            shouldBeBoldOption={shouldBeBold(multipleChoiceOptions[1])}
            style={{ display: 'block' }}
            questionSegmentText={questionSegments[1]}
          />
        </div>
        <div>
          {/* <u className='m-4' style={{ display: 'block' }}>{questionSegments[2]}</u> */}
          <MockExamQuestionTopicTile 
            answerOption={multipleChoiceOptions[2]} 
            optionNumber={""}
            onOptionClick={onOptionClick}
            currentAnswerSelectedByUser={selectedChoice}
            shouldBeBoldOption={shouldBeBold(multipleChoiceOptions[2])}
            questionSegmentText={questionSegments[2]}
          />
         </div> 
         <div>
          {/* <u className='m-4' style={{ display: 'block' }}>{questionSegments[3]}</u> */}
          <MockExamQuestionTopicTile 
            answerOption={multipleChoiceOptions[3]} 
            optionNumber={""}
            onOptionClick={onOptionClick}
            currentAnswerSelectedByUser={selectedChoice}
            shouldBeBoldOption={shouldBeBold(multipleChoiceOptions[3])}
            questionSegmentText={questionSegments[3]}
          />
          </div>
          <div>
            {/* <b className='m-4' style={{ display: 'block' }}>{"."}</b> */}
            <MockExamQuestionTopicTile 
              answerOption={multipleChoiceOptions[4]} 
              optionNumber={""}
              onOptionClick={onOptionClick}
              currentAnswerSelectedByUser={selectedChoice}
              shouldBeBoldOption={shouldBeBold(multipleChoiceOptions[4])}
              questionSegmentText={questionSegments[4]}
            />
          </div>
          </Grid>
    }


    function returnRelevantQuestionInstructions() {
      console.log("Grammar Question Type")
      console.log(grammarQuestionType)
      if (grammarQuestionType == "JUMBLED SENTENCES") {
        return CONSTANTS.JumbledSentenceQuestionInstructions
      } else if (grammarQuestionType == "SPELLING") {
        return CONSTANTS.SpellingQuestionInstructions
      } else if (grammarQuestionType == "PUNCTUATION") {
        return CONSTANTS.PunctuationQuestionInstructions
      } else if (grammarQuestionType == "MISSING WORDS") {
        return CONSTANTS.MissingWordsQuestionInstructions
      } else if (grammarQuestionType == "SYNONYMS") {
        return CONSTANTS.SynonymsQuestionInstructions
      } else if (grammarQuestionType == "ANTONYMS") {
        return CONSTANTS.AntonymsQuestionInstructions
      } else if (grammarQuestionType == "SHUFFLED SENTENCES") {
        return CONSTANTS.ShuffledSentencesQuestionInstructions
      } else if (grammarQuestionType == "COMPREHENSION") {
        return <b>{CONSTANTS.ComprehensionQuestionInstructions}</b>
      }
      return null
    }

    function formatSectionTableOrComprehensionData() {
      // Works with formatting from when comprehensions are inputted - || means new line and ||p|| is new paragraph
      // if ()
      
      var comprehensionPassage = section.tableData
      var comprehensionParagraphs = comprehensionPassage.split("||")
      var formattedParaphraphs = [] 
      for (var i = 0; i < comprehensionParagraphs.length; i++) {
        if (comprehensionParagraphs[i] == "p") {
          formattedParaphraphs.push(<br></br>)
        } else {
          formattedParaphraphs.push(<div>{comprehensionParagraphs[i]}</div>)
        }
      }
      formattedParaphraphs.push(<br></br>)
      return formattedParaphraphs
    }


    // Handles checks for any subscripts or fractions - ASSUMES NO QUESTION HAS BOTH
    function renderMockExamQuestion() {

      var questionText = question.mockExamQuestionText
      var questionSegments = []

      console.log("Checking if question needs super script rendering for metres squared etc..")

      // Checking for subscripts
      if (questionText.includes("**")) {
        console.log("Looks like question needs it")
        var answerOptionParts = questionText.split("**")
        // subscripts will be the odd indices
        for (var i = 0; i < answerOptionParts.length; i ++) {
          if (i % 2 == 0) {
            questionSegments.push(<b>{answerOptionParts[i]}</b>)
          } else {
            questionSegments.push(
            <b><sup>
              {answerOptionParts[i]}
            </sup></b>
          )}
        }
        return questionSegments
      }


      // Checking for fractions 
      if (questionText.includes("@@")) {
        var fractionParts = questionText.split("@@")
        // Fractions will be the odd indexes within the array 
        for (var i = 0; i < fractionParts.length; i ++) {
          if (i % 2 == 0) {
            questionSegments.push(<b>{fractionParts[i]}</b>)
          } else {
            var numeratorAndDenominator = fractionParts[i].split("/")
            var numerator = numeratorAndDenominator[0]
            var denominator = numeratorAndDenominator[1]
            questionSegments.push(<b>{renderFraction(numerator, denominator)}</b>)
          }
        }
        return questionSegments
      }

      return <b>{question.mockExamQuestionText}</b>
    }

    function renderFraction(num, den) {
      if (num === 0) {
        return "0";
      } else if (den === 0) {
        return "undefined";
      } else {
        let whole = Math.floor(num / den);
        let remainder = num % den;
        if (whole === 0) {
          return <><sup>{num}</sup>/<sub>{den}</sub></>;
        } else if (remainder === 0) {
          return <>{whole}</>;
        } else {
          return (
            <span>
              {whole} <sup>{remainder}</sup>/<sub>{den}</sub>
            </span>
          );
        }
      }
    }


    function renderSectionExampleText(sectionExampleText) {

      if (!sectionExampleText) {
        return 
      }

      let lines = sectionExampleText.split("\\n");
      let formattedLines = [];
    
      for (let i = 0; i < lines.length; i++) {
        let line = lines[i];
    
        if (line.includes("•")) {
          let bulletPoints = line.split("•");
          formattedLines.push(<div style={{ marginBottom: '10px' }}>{(bulletPoints[0])}</div>);
    
          for (let j = 1; j < bulletPoints.length; j++) {
            var lineToPush = bulletPoints[j].trim()
            formattedLines.push(<li style={{ marginBottom: '10px' }}>{(lineToPush)}</li>);
          }
        } else {
          let bulletPointIndex = line.indexOf("•");
          while (bulletPointIndex !== -1) {
            var lineToPush = line.substring(0, bulletPointIndex).trim()
            formattedLines.push(<div style={{ marginBottom: '10px' }}>{(lineToPush)}</div>);
            line = "• " + line.substring(bulletPointIndex + 1).trim();
            bulletPointIndex = line.indexOf("•");
          }
          formattedLines.push(<div style={{ marginBottom: '10px' }}>{(line)}</div>);
        }
      }
      return <div style={{paddingLeft: "10px"}}>{formattedLines.map((line, index) => <p key={index}>{line}</p>)}</div>;

    }




    return (
        <div style={{ marginLeft: "80px", marginTop: "80px"}}>
          
{/* Section info */}
          <div style={{fontSize: "20px", marginBottom: "20px"}}>
            {returnRelevantQuestionInstructions()}
          </div>
          <div style={{fontSize: "18px", marginBottom: "5px"}}>
            <b>{(section.sectionInstructions)}</b>
          </div>
          {sectionImageData? 
          <div style={{textAlign: "center", width: "100%"}}>
            <img src={`data:image/png;base64,${sectionImageData}`} alt="question-image-loading..." style={{ display: 'inline-block' }} />
          </div>
          :
          <div></div>
          }
          {/* Section example image */}

          {/* This would need to be edited for comprehensions  */}
          <div style={{padding: "5px", textAlign: "center", fontSize: "20px"}}>
          <b>{renderSectionExampleText(section.exampleText)}</b>
          </div>
          {section.exampleImage}
          { grammarQuestionType == "COMPREHENSION" ? 
          (<div style={{fontSize: "18px"}}>
            {formatSectionTableOrComprehensionData()}
          </div>) : <div style={{padding: "5px"}}><b >{section.tableData}</b></div>}
          {/* Section eg text */}
          {/* section image */}
          {/* Section instructions */}
          {/* section subject */}
          {/* section data (table or comprehension) */}


{/* Question info */}

        {grammarQuestionType == "SPELLING" || grammarQuestionType == "PUNCTUATION" ? (<></>) : 
          (<div style={{marginTop: "0px", marginBottom: "20px", paddingBlock: "5px", fontSize: "23px"}}>
            <b>{question.mockExamQuestionNumber + ") "}</b> {renderMockExamQuestion()}
          </div>)
        }
          {question.mockExamQuestionImage ? 
          <div style={{textAlign: "center", width: "100%"}}>
            <img src={`data:image/png;base64,${imageData}`} alt="question-image-loading..." style={{ display: 'inline-block' }} />
          </div>
          :
          <div></div>
          }

{/* Below not used for maths and english */}
          {question.imageMultipleChoiceOptions ? 
          <div>
            <div style={{marginTop: "0px", marginBottom: "20px", paddingBlock: "5px", fontSize: "23px"}}>
               Click a letter from the answers that matches to one of the options below:
            </div>
            <div style={{textAlign: "center", width: "100%"}}>
              <img src={`data:image/png;base64,${imageMultipleChoiceOptionsData}`} alt="question-image-loading..." style={{ display: 'inline-block' }} />
            </div>
          </div>
          :
          <div></div>
          }

          {/* <div style={{ marginTop: "10px", fontSize: "23px"}}>
            {getMultipleChoiceOptions()}
          </div> */}

          <div style={{fontSize: "20px"}}>
            {grammarQuestionType == "SPELLING" || grammarQuestionType == "PUNCTUATION" ?
                formatSpellingOrPunctuationQuestion()
              :
              <Grid container spacing={3} >
              {/* <Grid item xs={12}>
                <Title>{questionNumber + 1}) {question}</Title>
              </Grid> */}
                {getMultipleChoiceOptions()}
            </Grid>
            }
          </div>
          <div style={{textAlign: "center", paddingBlock: "20px", fontSize: "20px", marginTop: "40px"}}>
            <button
              className={isButtonDisabled ? 'submit-question-button-disabled' : 'submit-question-button'}
              onClick={() => disableButtonAndSubmitAnswer(question.mockExamQuestionNumber, selectedChoice)}
              disabled={isButtonDisabled}
            >
                Submit Answer
            </button>
          </div>
          {/* <div style={{textAlign: "center", fontSize: "15px"}}>
            Answer Submitted
          </div> */}


        </div>
    )
        }