// import { Typography } from '@material-ui/core';
import React from 'react';
// import "../../assets/styles/index.css";
// import { Link } from "react-router-dom";

// Material UI imports

// Component imports 
import Dashboard from 'views/Dashboard';



export default function AddAssignmentView() {



  return (
   <>
   

   <Dashboard className="p-4"/>
   
   
   </>
  );
}