import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useHistory } from "react-router-dom";


import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import QuestionsAndUserDataService from 'services/QuestionsAndUserDataService';



export default function CreateAdminUserForm() {

  // let history = useHistory();
  const [title, setTitle] = React.useState("");   
  const [selectedDates, setSelectedDates] = useState([])
  
  const handleChange = (event) => {
    setTitle(event.target.value);
  };

  const [isLoadingMockExamSessionsData, setIsLoadingMockExamSessionsData] = useState(true)
  const [mockExamSessionsData, setMockExamSessionsData] = useState([])


  useEffect(() => {
    if (isLoadingMockExamSessionsData) {
        getUpcomingMockExamSessions()
    }
  });

    // Handle the change in selected dates
    const handleDateChange = (event) => {
      const { value } = event.target;
      const currentDates = selectedDates;
      const index = currentDates.indexOf(value)
      // console.log(typeof selectedDates)
      if (index > -1) {
        // console.log("Current date: " + value + " - already included, removing now")
        // currentDates.splice(index, 1)
        setSelectedDates(selectedDates.filter((d) => d !== value));


      } else {
        // console.log("Current date: " + value + " - not included, including now")
        // currentDates.push(value)
        // setSelectedDates(currentDates)
        setSelectedDates([...selectedDates, value]);

      }
      // setSelectedDates(currentDates);
      console.log(selectedDates)
    };

  function getUpcomingMockExamSessions(){
    QuestionsAndUserDataService.getAllUpcomingMockExams().then(sessionsData => {
      setMockExamSessionsData(sessionsData.data)
      setIsLoadingMockExamSessionsData(false)
      console.log(sessionsData)
    })
  }

  const validationSchema = yup.object({
    email: yup
      .string('Enter your email')
      .email('Enter a valid email')
      .required('Email is required'),
    password: yup
      .string('Enter your password')
      .required('Password is required')
  });


  // const WithMaterialUI = () => {
    const formik = useFormik({
      initialValues: {
        email: '',
        password: '',
        customCheckLogin: false,
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {

        console.log("formik values")
        console.log(formik.values)

        QuestionsAndUserDataService.createAdminUserFromDashboard(formik.values.email, formik.values.password)
      },
    });


  return (
    
    <div>
     <form
        onSubmit={formik.handleSubmit}
        >
         <label> New Admin User Details</label>

        <div className="relative w-full mb-3">
        <TextField
          fullWidth
          id="email"
          name="email"
          label="Email*"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        </div>

        <div className="relative w-full mb-3">
        <TextField
          fullWidth
          id="password"
          name="password"
          label="Password*"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        />
        </div>


        <div className="text-center mt-6">
          <Button
            colour="primary"
            className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
            type="submit"
          >
            Add Admin User
          </Button>
        </div>
      </form>
    </div>
  );
}
