import React, { useState } from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Title from './Title';
import QuestionsAndUserDataService from '../../services/QuestionsAndUserDataService'
import { useHistory } from "react-router";
import * as CONSTANTS from "../../StringConstants"

export default function CorrectlyAnsweredQuestionsTile({numCorrectlyAnsweredQuestions, lastWorksheetId, switchToReviewMode}) {

  const [hasLoadedLastWorksheet, setHasLoadedLastWorksheet] = useState(false)

  let history = useHistory();

  function loadLastWorksheet() {
    if (lastWorksheetId !== -1){ 
      QuestionsAndUserDataService.getWorksheetQuestions(lastWorksheetId).then(questions => {
        if (questions.data.comprehensionDescription === "") {
          let sortedQuestions = questions.data.worksheetQuestions.sort((a,b) => a.questionNumber > b.questionNumber ? 1 : -1)
          switchToReviewMode(sortedQuestions, 0)
        } else {
          switchToReviewMode(questions.data, 0)
        }
        setHasLoadedLastWorksheet(true)

        history.push(CONSTANTS.MathTopicsRoute)
      });
    }
  }


  return (
    <React.Fragment>
      <Title>Correctly Answered Questions</Title>
      <Typography component="p" variant="h4">
        {numCorrectlyAnsweredQuestions}
      </Typography>
      <Typography color="var(--whizzdom-purple)" sx={{ flex: 1 }}>
      </Typography>
      <div>
        <Link style={{color: "var(--whizzdom-purple)"}} onClick={() => loadLastWorksheet()}>
          {(lastWorksheetId !== -1 ? "View last worksheet" : "")}
        </Link>
      </div>
    </React.Fragment>
  );
}

// CorrectlyAnsweredQuestionTile --> Analytics --> Dashboard
// Answer Tile --> Answer Page --> Dashboard 
// Need to pass up question object with:
//  - questions 
//  - user answers 
//  - correct answers
//  - is answer correct 
//  - Question to go to 