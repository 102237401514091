import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useHistory } from "react-router-dom";


import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';

import QuestionsAndUserDataService from '../../services/QuestionsAndUserDataService'


export default function CreateMockExamSessionForm() {

  // let history = useHistory();
  const [title, setTitle] = React.useState("");   
  const [selectedDates, setSelectedDates] = useState([])
  
  const handleChange = (event) => {
    setTitle(event.target.value);
  };

  const [isLoadingMockExamSessionsData, setIsLoadingMockExamSessionsData] = useState(true)
  const [mockExamSessionsData, setMockExamSessionsData] = useState([])


  useEffect(() => {
    if (isLoadingMockExamSessionsData) {
        getUpcomingMockExamSessions()
    }
  });

    // Handle the change in selected dates
    const handleDateChange = (event) => {
      const { value } = event.target;
      const currentDates = selectedDates;
      const index = currentDates.indexOf(value)
      // console.log(typeof selectedDates)
      if (index > -1) {
        // console.log("Current date: " + value + " - already included, removing now")
        // currentDates.splice(index, 1)
        setSelectedDates(selectedDates.filter((d) => d !== value));


      } else {
        // console.log("Current date: " + value + " - not included, including now")
        // currentDates.push(value)
        // setSelectedDates(currentDates)
        setSelectedDates([...selectedDates, value]);

      }
      // setSelectedDates(currentDates);
      console.log(selectedDates)
    };

  function getUpcomingMockExamSessions(){
    QuestionsAndUserDataService.getAllUpcomingMockExams().then(sessionsData => {
      setMockExamSessionsData(sessionsData.data)
      setIsLoadingMockExamSessionsData(false)
      console.log(sessionsData)
    })
  }

  const validationSchema = yup.object({
    firstName: yup
      .string('Enter your name'),
    lastName: yup
      .string('Enter your name'),
    email: yup
      .string('Enter your email')
      .email('Enter a valid email')
      .required('Email is required'),
    childName: yup
      .string("Enter child's name")
      .required("Child's name is required"),
    password: yup
      .string('Enter your password')
      .required('Password is required')
  });


  // const WithMaterialUI = () => {
    const formik = useFormik({
      initialValues: {
        firstName: '',
        lastName: '',
        email: '',
        childName: '',
        password: '',
        customCheckLogin: false,
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {

        console.log("formik values")
        console.log(formik.values.firstName)
        console.log(formik.values.lastName)
        console.log(formik.values.email)
        console.log(formik.values.childName)
        console.log(formik.values.password)
        console.log(selectedDates)


        QuestionsAndUserDataService.createMockExamUserFromAdminBoard(formik.values.firstName, formik.values.lastName, formik.values.email, formik.values.childName, formik.values.password, selectedDates)
        // QuestionsAndUserDataService.createTeacher(formik.values.firstName, formik.values.lastName, formik.values.email, title, isHeadTeacher, schoolId).then(history.push((isHeadTeacher ? "/app/admin-whizz" : "/teacher/headteacher")))
        // updateUserDetails(formik.values.firstName, formik.values.lastName, formik.values.email).then(onStepChange(2))

      },
    });

    function currentlySelectedDatesContainMockExamSession(mockExamDate) {
      console.log("CurrentDate: " + mockExamDate)
      console.log("Selected Dates: " + selectedDates)
      console.log("Returning: " + selectedDates.includes(mockExamDate))
      return selectedDates.includes(mockExamDate)
    }

  return (
    
    <div>
     <form
        onSubmit={formik.handleSubmit}
        >
         <label> New Mock Exam User Details</label>
        <div className="relative w-full mb-3">

        <TextField
            fullWidth
            id="firstName"
            name="firstName"
            label="Parent First Name"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
          />

        </div>

        <div className="relative w-full mb-3">

          <TextField
              fullWidth
              id="lastName"
              name="lastName"
              label="Parent Last Name"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
            />

          </div>


        <div className="relative w-full mb-3">
        <TextField
          fullWidth
          id="email"
          name="email"
          label="Email*"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        </div>

        <div className="relative w-full mb-3">
        <TextField
            fullWidth
            id="childName"
            name="childName"
            label="Child's Name"
            value={formik.values.childName}
            onChange={formik.handleChange}
            error={formik.touched.childName && Boolean(formik.errors.childName)}
            helperText={formik.touched.childName && formik.errors.childName}
          />
        </div>

        <div className="relative w-full mb-3">
        <TextField
          fullWidth
          id="password"
          name="password"
          label="Password*"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        />
        </div>

        {isLoadingMockExamSessionsData ? <>Loading upcoming mock exam sessions</> :
      

        <Box style={{textAlign:"center"}}>
           <InputLabel id="dates">Select Dates</InputLabel>
            <FormGroup>
              <FormControlLabel
                  key={"all"}
                  control={
                    <Checkbox
                      checked={currentlySelectedDatesContainMockExamSession("all")}
                      onChange={handleDateChange}
                      value={"all"}
                    />
                  }
                  label={"Make Subscriber"}
                />
              {mockExamSessionsData.map((mockExamSession) => (
                <FormControlLabel
                  key={mockExamSession.mockexamSessionId}
                  control={
                    <Checkbox
                      checked={currentlySelectedDatesContainMockExamSession(mockExamSession.mockExamDate)}
                      onChange={handleDateChange}
                      value={mockExamSession.mockExamDate}
                    />
                  }
                  label={mockExamSession.mockExamDate}
                />
              ))}
            </FormGroup>
        </Box>
        }


        <div className="text-center mt-6">
          <Button
            colour="primary"
            className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
            type="submit"
          >
            Add Mock Exam User
          </Button>
        </div>
      </form>
    </div>
  );
}
