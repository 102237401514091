import React, { useEffect, useState } from 'react';
import * as CONSTANTS from "../../StringConstants";
import Link from '@material-ui/core/Link';
import { useHistory } from "react-router";
// import { fdatasync } from 'fs';

export default function ReviewPreviousExamSessionsTable({data}) {

  const [expandedRow, setExpandedRow] = useState(null);
  const [completedMockExamSessions, setCompletedMockExamSessions] = useState([])
  let history = useHistory();

  console.log("Table data")

  console.log(data)
  console.log(data[0])
  // console.log(data[0].ranking)
  // // console.log(data[0].ranking[5])
  // var keysList = Object.keys(data[0])
  // // var rank
  // console.log(keysList)
  // console.log("Ranking: " + data[keysList[0]])

  useEffect(() => {
    console.log("Completed Mock Exam Sessions")
    console.log(completedMockExamSessions) 
    data.sort((a,b) => new Date(a.mockExamDate) - new Date(b.mockExamDate))
    setCompletedMockExamSessions(data)
    
    console.log(completedMockExamSessions) 
    // console.log("Completed Mock Exam Sessions")
    // console.log(completedMockExamSessions)
    // setCompletedMockExamSessions(data.mockExamSessionsCompletedByUser)
    // console.log(completedMockExamSessions)
    // completedMockExamSessions.sort(compareDates)
    // console.log(completedMockExamSessions)
    // setCompletedMockExamSessions(completedMockExamSessions)
    // console.log(completedMockExamSessions)
  });

  const compareDates = (a, b) => {
    const dateA = new Date(a.mockExamDate);
    const dateB = new Date(b.mockExamDate);
    return dateB - dateA;
  };




  const handleRowClick = (rowIndex) => {
    if (rowIndex === expandedRow) {
      setExpandedRow(null);
    } else {
      setExpandedRow(rowIndex); 
    }
  };

  function formatDate(date) {
    console.log(date)
    var dateComponents = date.split("-")
    console.log(dateComponents)
    var dateString = dateComponents[2]
    console.log(dateString)
    var wordingForNumber = ""
    if(dateString[1] === "1") {
      wordingForNumber = "st"
    } else if (dateString[1] === "2") {
      wordingForNumber = "nd"
    } else if (dateString[1] === "3") {
      wordingForNumber = "rd"
    } else {
      wordingForNumber = "th"
    }

    if (dateString[0] === "0") {
      dateString = dateString[1]
    }

    dateString += wordingForNumber
    console.log(dateString)

    var month = convertMonthNumberToCorrespondingWords(dateComponents[1])
    var year = dateComponents[0]

    return dateString + " " + month + " " + year
  }

  function formatTime(time) {
    var timeComponents = time.split(":")
    console.log(timeComponents)
    return timeComponents[0] + ":" + timeComponents[1] + " " + (timeComponents[0] < 12 ? "am" : "pm")

  }

  function convertMonthNumberToCorrespondingWords(monthNumber) {
    switch(monthNumber) {
      case "01":
        return "January"
      case "02":
        return "February"
      case "03":
        return "March"
      case "04":
        return "April"
      case "05":
        return "May"
      case "06":
        return "June"
      case "07":
        return "July"
      case "08":
        return "August"
      case "09":
        return "September"
      case "10":
        return "October"
      case "11":
        return "November"
      case "12":
        return "December"
      default:
        return ""
    }
  }

  function getLinkTarget(blogType) {
    if (blogType === "GL") {
      return "https://whizzdom.co.uk/auth/preparing-for-the-GL-11plus-exams"
    } else {
      return "https://whizzdom.co.uk/auth/preparing-for-the-cem-11plus-exams"
    }
  }

  function getExamScore(mockExamId) {
    console.log("Getting score for: " + mockExamId)
    var score = data.userScores[mockExamId]
    console.log("Score: " + score)
    if (score === null) {
      return "Incomplete Test"
    }
    return score + "%"
  }

  function Accordion({ data }) {
    console.log("Accordion data: ")
    console.log(data)
    return (
      <div className="accordion">
        {data.map((exam) => (
          <div key={exam.examId} className="accordion-item">
            <td>
            <div className="accordion-header"> <Link onClick={() => history.push(CONSTANTS.ReviewMockExamPaper + "/" + `${exam.mockExamId}`)}>{exam.examName}</Link></div>
            </td>
            <td><Link onClick={() => history.push(CONSTANTS.ReviewMockExamPaper + "/" + `${exam.mockExamId}`)}><u>Click here to review</u></Link></td>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="table-container">
      <table className="table">
        <thead className="table-header">
          <tr>
            <th>Date</th>
            <th>View Exams</th>
            <th className="type-header">Type</th>
          </tr>
        </thead>
        <tbody>
        {completedMockExamSessions.map((row, rowIndex) => (
          <React.Fragment key={row.mockExamSessionId}>
            <tr className="table-row" onClick={() => handleRowClick(rowIndex)}>
              <td>{formatDate(row.mockExamDate)}</td>
              <td><u>View</u></td>
              <td className="type-column">{row.mockExamType}</td>
            </tr>
            {expandedRow === rowIndex && (
              <tr className="accordion-row">
                <td>
                  <Accordion data={row.mockExamSet.mockExams} />
                </td>
                <td></td>
                <td></td>
              </tr>
            )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};



