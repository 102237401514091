import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { format } from 'date-fns';
import Checkbox from '@mui/material/Checkbox';

import QuestionsAndUserDataService from '../../../services/QuestionsAndUserDataService'

export default function CreateMockExamSessionForm() {

  const [examType, setExamType] = useState("");   
  const [timeValue, setTimeValue] = useState();
  const [isCatchUpSession, setIsCatchUpSession] = useState(false);
  const [isFreeSession, setIsFreeSession] = useState(false);

  const handleTimeChange = (newValue) => {
    console.log(newValue)
    setTimeValue(newValue);
  };

  const handleIsCatchUpSessionChange = () => {
    setIsCatchUpSession(!isCatchUpSession);
  };

  const handleIsFreeSessionChange = () => {
    setIsFreeSession(!isFreeSession);
  };



  const handleChange = (event) => {
    setExamType(event.target.value);
  };

  const validationSchema = yup.object({
    examDate: yup
      .string('Enter your name')
      .required('A date is required for the exam set'),
    time: yup
      .string('Enter your time')
      // .time('Enter a valid time')
      .required('time is required'),
  });

  const label = { inputProps: { 'aria-label': 'is catch up session checkbox' } };



  // const WithMaterialUI = () => {
    const formik = useFormik({
      initialValues: {
        examDate: new Date(),
        time: new Date(),
        customCheckLogin: false,
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {

        console.log("Submitting mock exam set form")
        console.log("formik values")
        console.log(formik.values.examDate)
        console.log(timeValue)
        console.log(examType)
        console.log(isCatchUpSession)
        console.log(isFreeSession)

        const formattedTime = format(timeValue, "HH:mm:ss");
        console.log("Formatted time")
        console.log(formattedTime)

        QuestionsAndUserDataService.addMockExamSession(formik.values.examDate, formattedTime, isCatchUpSession, isFreeSession).then(result => {
          console.log(result)
        })
        // updateUserDetails(formik.values.name, formik.values.examDate, formik.values.time).then(onStepChange(2))

      },
    });

  return (
    
    <div>
     <form
        onSubmit={formik.handleSubmit}
        >
         <label> Exam Session Details</label>

        <div className="relative w-full mb-3">
          <TextField
            fullWidth
            id="examDate"
            name="examDate"
            label="Exam Date"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            value={formik.values.examDate}
            onChange={formik.handleChange}
            error={formik.touched.examDate && Boolean(formik.errors.examDate)}
            helperText={formik.touched.examDate && formik.errors.examDate}
          />
        </div>


        <div className="relative w-full mb-3">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
           <TimePicker
              label="Time"
              id="time"
              name="time"
              value={timeValue}
              onChange={handleTimeChange}
              renderInput={(params) => <TextField {...params} />}
            />
            </LocalizationProvider>
        </div>

      <></>
        <div>Is Catch Up Session:</div>
        <Checkbox 
          value = {isCatchUpSession}
          onChange = {handleIsCatchUpSessionChange}
        />

      <div>Is Free Session:</div>
        <Checkbox 
          value = {isFreeSession}
          onChange = {handleIsFreeSessionChange}
        />



        <div className="text-center mt-6">
          <Button
            colour="primary"
            className="bg-blueGray-800 active:bg-blueGray-600 uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
            type="submit"
          >
            Add Exam Session
          </Button>
        </div>
      </form>
    </div>
  );
}
