import React from 'react';
import Title from '../Title';
import { Grid } from '@material-ui/core';
import Link from '@mui/material/Link';
import { useHistory } from "react-router-dom";
import * as CONSTANTS from "../../StringConstants.js"


  // Component is currently updating state of parent component on every single key stroke input
export default function AnswerTile({questionNumber, userAnswer, correctAnswer, isCorrect, viewInReviewMode, currentSubject}) {

    // const theme = useTheme();
    let textColor = isCorrect ? "#2bbb17" : "red"
    let history = useHistory();


    function ensureWeStayInReviewMode(questionNumber) {
      console.log("Current subject is: " + currentSubject)
      history.push(currentSubject === "Maths" ? CONSTANTS.MathTopicsRoute : CONSTANTS.EnglishTopicsRoute)
      viewInReviewMode(questionNumber)
    }

    return (
        <React.Fragment>
            {/* This should be a parameter for the tile */}
            {/* <ResponsiveContainer> */}
            <Grid container >
              <Grid item xs={3} >
                <Title>{questionNumber})</Title>
              </Grid>
              <Grid item xs={3} style={{ color: textColor }}>
                {userAnswer}
              </Grid>
               
              <Grid item xs={3} style={{ color: textColor }} >
              {isCorrect ? ""  :  "Correct Answer: " + correctAnswer  }
              </Grid>

              <Grid item xs={3}>
                <Link onClick={() => ensureWeStayInReviewMode(questionNumber)}>
                  Go to question
                </Link>
              </Grid>
              
            </Grid>
            {/* </ResponsiveContainer> */}
        </React.Fragment>
    )
}  