import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router";

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Chart from './AnalyticsComponents/Chart';
import CorrectlyAnsweredQuestionsTile from './AnalyticsComponents/CorrectlyAnsweredQuestionsTile';
import PastWorksheets from './AnalyticsComponents/PastWorksheets';
import QuestionsAndUserDataService from '../services/QuestionsAndUserDataService'

import * as CONSTANTS from "../StringConstants"
import { Typography } from '@mui/material';
 
export default function Analytics({switchToReviewMode, toggleReviewWorksheetMode}) {
    let history = useHistory();

    const [totalNumberCorrectlyAnsweredQuestions, setTotalNumberCorrectlyAnsweredQuestions] = useState(0)
    const [isTotalNumberCorrectQuestionsLoading, setIsTotalNumberCorrectQuestionsLoading] = useState(true)
    const [isWorksheetDataLoading, setIsWorksheetDataLoading] = useState(true)
    const [worksheetData, setWorksheetData] = useState([])
    const [idOfLastWorksheet, setIdOfLastWorksheet] = useState(-1)
    const [chartData, setChartData] = useState([])
    const [isNoContent, setIsNoContent] = useState(false)

    useEffect(() => {
        if (isTotalNumberCorrectQuestionsLoading || isWorksheetDataLoading) {
            getUserCorrectNumberQuestionsAnswered()
            getUserWorksheetData()
        }
      });

    function getUserCorrectNumberQuestionsAnswered() {
        QuestionsAndUserDataService.getUserAnalyticsData().then(result => {
            console.log("Completing promise to get number of correct questions answered")
            // console.log(result)
            setIsTotalNumberCorrectQuestionsLoading(false)
            setTotalNumberCorrectlyAnsweredQuestions(result.data)
            // setTotalNumberCorrectlyAnsweredQuestions(result)
          });
        
    }

    // The function and variable below are currently unused but can be called if dummy data is needed on the front end
    function createDummyData(date, numberOfQuestionsInTheWorksheet, numberQuestionsCorrectlyAnswered, questionTypes, subject, topic, user, worksheetId, worksheetQuestions) {
        return {date, numberOfQuestionsInTheWorksheet, numberQuestionsCorrectlyAnswered, questionTypes, subject, topic, user, worksheetId, worksheetQuestions}
    }

    let dummyData = [
        createDummyData("2022-05-21", 10, 6, "Subtraction", "Math", "Fractions - Standard", null, 1, null), // 1
        createDummyData("2022-05-21", 10, 4, "Multiplication", "Math", "Decimals", null, 2, null), // 1
        createDummyData("2022-05-23", 15, 11, "Area Questions", "Math", "Volume and Area Problems", null, 3, null), // 2
        createDummyData("2022-05-23", 10, 9, "Multiplication", "Math", "Capacity Problems", null, 4, null), // 3
        createDummyData("2022-05-24", 12, 9, "Addition", "Math", "Length Problems", null, 5, null), // 4
        createDummyData("2022-05-25", 20, 19, "Multiplication", "Math", "Fractions - Standard", null, 6, null), // 5
    ]


    function getUserWorksheetData() {
        QuestionsAndUserDataService.getUserWorksheets().then(worksheets => {
            console.log("Completing promise to get worksheets")
            console.log(worksheets)
            console.log("Worksheet info: ")
            console.log(worksheets.data.length)
            if (worksheets.status !== 204) {
                console.log("in 204")
            const sortedWorksheetData = worksheets.data.sort((a,b) => a.worksheetId < b.worksheetId ? 1 : -1)
            // console.log("Last worksheet id: " + sortedWorksheetData[0].worksheetId)
                
                convertToChartData(sortedWorksheetData)
                setIdOfLastWorksheet(sortedWorksheetData[0].worksheetId)
                setWorksheetData(sortedWorksheetData)
                console.log("Sorted worksheet data")
                console.log(sortedWorksheetData.length)
                setIsWorksheetDataLoading(false)
            } else {
                console.log("No worksheet content")
                setIsNoContent(true)
                setIsWorksheetDataLoading(false)

            }
        })
    }

    function convertToChartData(allWorkSheetData) {
        let chartData =[]
        for (let i = 0; i < allWorkSheetData.length; i++) {
            let percentageScore = Math.round((allWorkSheetData[i].numberQuestionsCorrectlyAnswered / allWorkSheetData[i].numberOfQuestionsInTheWorksheet)*100)
            // Since data is ordered with latest first we need to flip this to show the score progression correctly 
            let flippedIndex = allWorkSheetData.length - i - 1
            chartData[flippedIndex] = createData(allWorkSheetData[i].date, percentageScore)
        }
        setChartData(chartData)
    }

    function createData(time, amount) {
        return { time, amount };
    }

    function backToTopicsSelection() {
        toggleReviewWorksheetMode(false)
        history.push(CONSTANTS.MathTopicsRoute)
    }


  return (        
            <div>
                <Typography
                   component="h2" 
                   variant="h4"
                    // onClick={backToTopicsSelection}
                >
                    Dashboard
                </Typography>

                <Grid container spacing={3}>
                {/* Chart */}
                <Grid item xs={12} md={8} lg={9}>
                    <Paper
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        height: 240,
                    }}
                    >
                    <Chart chartData={chartData}/>
                    </Paper>
                </Grid>

                <Grid item xs={12} md={4} lg={3}>
                    <Paper
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        height: 240,
                    }}
                    >
                        {isTotalNumberCorrectQuestionsLoading ?
                            <div>Loading data ... </div> : 
                            <CorrectlyAnsweredQuestionsTile 
                                numCorrectlyAnsweredQuestions={totalNumberCorrectlyAnsweredQuestions}
                                lastWorksheetId={idOfLastWorksheet}
                                switchToReviewMode={switchToReviewMode}
                            />
                        }
                    </Paper>
                </Grid>
                {/* Recent PastWorksheets */}
                <Grid item xs={12}>
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                        {/* Add check and mention no past worksheets if required */}
                        { isWorksheetDataLoading ?
                            <div>Loading data ... </div> : 
                            ((isNoContent) ? 
                                <div>No worksheets completed yet </div> :
                                <PastWorksheets 
                                    data={worksheetData}
                                    switchToReviewMode={switchToReviewMode}
                                />)
                        }
                    </Paper>
                </Grid>
                </Grid>
            </div>
  );
}
