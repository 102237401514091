import React, { useState, useEffect } from 'react';

import FooterAdmin from "components/Footers/FooterAdmin.js";
import whiteLogo from "./../../assets/logoVariations/Primarylogomark_purple_black_white-03.png"

import Grid from '@mui/material/Grid';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Paper from '@mui/material/Paper';
import Modal from '@mui/material/Modal';

import QuestionsAndUserDataService from 'services/QuestionsAndUserDataService';
import Chart from '../AnalyticsComponents/Chart';
import PastWorksheets from 'views/AnalyticsComponents/PastWorksheets';
import SideDrawer from './SideDrawer';
import UpcomingExamSessionsTable from './UpcomingExamHelperComponents/UpcomingExamSessionsTable';
import MockExamAccountSettingsButton from './MockExamAccountSettingsButton';
import PricingCard from 'components/Cards/CardPricing';
import * as PRICING_DETAILS from "../../views/PricingDetails.js";

const drawerWidth = 260;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  // boxShadow: 24,
  // p: 4,
};


export default function MockExamUserUpcomingExams() {
  const [open, setOpen] = React.useState(true);
  const [chartData, setChartData] = useState([])
  const [isLoadingDummyData, setIsLoadingDummyData] = useState(true)
  const [worksheetData, setWorksheetData] = useState([])
  const [isSubscriberModalOpen, setIsSubscriberModalOpen] = useState(false)
  const [isLoadingMockExamSessionsData, setIsLoadingMockExamSessionsData] = useState(true)
  const [sessionsData, setSessionsData] = useState([])


  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 250,
    bgcolor: 'background.paper',
    border: '2px solid "#e49609"',
    textAlign: "center", 
    fontWeight: "bold", 
    fontSize: "25px"
    // boxShadow: 24,
    // p: 4,
  };

  // let dummyData = [
  //   createDummyData("2022-05-21", 0.6), // 1
  //   createDummyData("2022-05-21", 0.4), // 1
  //   createDummyData("2022-05-23", 0.7), // 2
  //   createDummyData("2022-05-23", 0.83), // 3
  //   createDummyData("2022-05-24", 0.73), // 4
  //   createDummyData("2022-05-25", 0.91), // 5
  // ]

  useEffect(() => {
    if (isLoadingMockExamSessionsData) {
      getUpcomingMockExamSessionsForUser()
    }
  });

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };



  function getUpcomingMockExamSessionsForUser() {
    console.log("Getting upcoming mock exam sessions data")
    QuestionsAndUserDataService.getUpcomingMockExamSessionsForUser().then(result => {
      console.log(result)
      setIsLoadingMockExamSessionsData(false)
      setSessionsData(result.data)
    });
  }

  function checkToSeeIfSubscriptionAdvertNeeded() {
    console.log("Checking if we show banner")
    for (var i = 0; i < sessionsData.length; i++) {
      if (sessionsData[i].isSubscriber !== "true") {
        return true
      }
    }
    return false
  }



  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} style={{backgroundColor: "purple"}}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          {/* <Typography variant="h6" noWrap component="div">
            Mini variant drawer
          </Typography> */}
            <img alt="11plus-Whizzdom-logo" style={{height: '60px'}} src={whiteLogo}></img>
            <div className="w-full mx-autp flex justify-end md:flex-nowrap flex-wrap md:px-10 px-4" >
              <div>
               <MockExamAccountSettingsButton/>
              </div>
            </div>
        </Toolbar>
      </AppBar>

      <SideDrawer drawerStatus={open} handleDrawerClose={handleDrawerClose}/>

      <Grid container spacing={3} className='p-8 mx-8 m-8'>
        <Grid item xs={12}>
          <Typography variant="h4" sx={{paddingTop: "70px"}}>Your Upcoming Mock Exams</Typography>

          { isLoadingMockExamSessionsData ? 
          <Typography variant="h5" style={{textAlign: "center", margin: "50px"}}>Loading your mock exam sessions...</Typography> 
          :
          <UpcomingExamSessionsTable data={sessionsData}/>
          }

          {checkToSeeIfSubscriptionAdvertNeeded() ?

          <Typography variant="body1" style={{fontWeight: "0"}}>
            <div style={{border: "solid 2px #C27F00", padding: "5px", textAlign: "center", marginBottom: "5px", marginTop: "-20px"}}> 
              Register your child for all of our upcoming exam sessions as well as gaining unlimited access to our learning 
              platform for only £38.98 per month.
              <br/>
              <button style={{marginBlock: "10px"}} onClick={() => QuestionsAndUserDataService.createCheckoutSessionFromDashboard("2")}>
                <u><b>Click here to subscribe monthly</b></u>
              </button>
            </div>
            <br/>
            </Typography>

            :
            <></>
          }

            <Typography variant="body1" style={{fontWeight: "0"}}>
              This table will be updated monthly as new 
              mock exam sessions are added. Subscribed users will automatically be 
              registered for new exams each month. 
              
              <br/><br/><b>Exams taking place today</b> will not
              show up on this table, select "Instructions Dashboard" from the menu
              on the left and then scroll down to click "Start Mock Exam".
          </Typography>

        </Grid>
      </Grid>

      <Modal
          open={isSubscriberModalOpen}
          onClose={() => setIsSubscriberModalOpen(false)}
          className="landing-page-upcoming-mock-exams-modal"
        >
          <Box sx={style}>
          {/* <PricingCard 
            className="pricing-button-pro" 
            title="Professional 11plus Mock Exams (Premium)" 
            price="£19.49 per set" 
            features={PRICING_DETAILS.mockExamDetails}
            callToActionButtonText="Get Exam Day Ready"
            productId="2"
            isBestDeal={true}
          /> */}
          test
          </Box>

          {/* <div className="pricing-card-header"> */}
            {/* <CardHeader
                title={title}
            /> */}
        {/* </div> */}

        </Modal>

        

      {/* <Box sx={{width: "100%", maxWidth: 500}}>
        <Typography variant="h4" style={{textAlign: "center", justifyContent: "center", display: "flex", alignItems: "center", height: "100%", position: "fixed", left: "35%", width: "30%"}}>
          Complete your first set of mock exams to review them here.
        </Typography>
      </Box> */}

      
      {/* <Grid container spacing={3} className='p-4 mt-16'>
        <Grid item xs={12}>
          <Paper
            sx={{
                p: 6,
                // display: 'flex',
                flexDirection: 'column',
                height: 340,
            }}
          >
            <Chart className="p-8" chartData={chartData}/>
          </Paper>
        </Grid> */}

       {/* Past Mock Exams */}
        {/* <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}> */}
                {/* Add check and mention no past worksheets if required */}
                {/* { isWorksheetDataLoading ?
                    <div>Loading data ... </div> : 
                    ((isNoContent) ? 
                        <div>No worksheets completed yet </div> : */}
                        {/* <PastWorksheets 
                            data={worksheetData}
                            switchToReviewMode={false}
                        /> */}
                        {/* )
                } */}
            {/* </Paper>
        </Grid>
      </Grid> */}


      <Box component="main" sx={{ flexGrow: 1, left: "50%", position: "fixed", bottom: 0}}>
        {/* <DrawerHeader /> */}
        {/* <Typography paragraph>
            
        </Typography> */}
        <FooterAdmin/>
      </Box>
    </Box>
  );
}