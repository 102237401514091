import React from "react";

export default function FooterAdmin() {
  return (
    <>
      {/* <div className="footer"> */}
      <div>
          <div>
          <hr className="mt-4 mb-4 border-b-1 border-blueGray-200" />
            Copyright © {new Date().getFullYear()}{" "}
            Whizzdom
          </div>
      </div>
    </>
  );
}
