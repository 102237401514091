import React, { useCallback, useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Box from '@mui/material/Box';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Modal from '@mui/material/Modal';
import { Typography } from '@material-ui/core';

import IndividualChildAssessment from 'theLearningHub/layouts/HelperComponents/IndividualChildAssessment';
import TLHEndpoints from 'services/TLHEndpoints';

export default function NewEnquiryAssessment({isEnquiryNotFromToday, enquiryInfo}) {

  console.log("Logging enquiry info")
  console.log(enquiryInfo)

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  
  const [numberOfStudents, setNumberOfStudents] = useState(enquiryInfo?.childrenBooked?.length || 1);
  const [childrenAssessments, setChildrenAssessments] = useState(enquiryInfo?.childrenBooked || []);
  const [openSuccessfulSubmissionModal, setOpenSuccessfulSubmissionModal] = useState(false);

  useEffect(() => {
    console.log("Using the effect")
    console.log(enquiryInfo)
    if (enquiryInfo && enquiryInfo.childrenBooked) {
      setChildrenAssessments(enquiryInfo.childrenBooked);
    }
  }, [enquiryInfo]);

  function handleCloseSuccessfulSubmissionModal() {
    setOpenSuccessfulSubmissionModal(false);
    setChildrenAssessments([]);
    formik.resetForm();
  }

  const handleChildDataChange = useCallback((index, updatedChildData) => {
    const updatedChildrenAssessments = [...childrenAssessments];
    updatedChildrenAssessments[index] = updatedChildData;
    setChildrenAssessments(updatedChildrenAssessments);
  }, [childrenAssessments]);

  const validationSchema = yup.object({
    parentLastName: yup.string('Enter the parent last name'),
    parentFirstName: yup.string('Enter the parent first name'),
    enquiryDate: yup.date('When did the enquiry come in'),
  });

  function renderIndividualStudentInfoTiles() {
    return childrenAssessments.map((child, index) => (
      <IndividualChildAssessment
        key={index}
        index={index}
        updateChildData={handleChildDataChange}
        childData={Object.keys(child).length == 0 ? null : child}
      />
    ));
  }

  const formatPhoneNumber = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, ''); // Remove all non-digit characters
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 5) return phoneNumber;
    if (phoneNumberLength < 9) {
      return `${phoneNumber.slice(0, 4)} ${phoneNumber.slice(4)}`;
    }
    return `${phoneNumber.slice(0, 4)} ${phoneNumber.slice(4, 7)} ${phoneNumber.slice(7, 11)}`;
  };

  const handlePhoneNumberChange = (event) => {
    const formattedPhoneNumber = formatPhoneNumber(event.target.value);
    formik.setFieldValue('phoneNumber', formattedPhoneNumber);
  };

  const handleAltPhoneNumberChange = (event) => {
    const formattedPhoneNumber = formatPhoneNumber(event.target.value);
    formik.setFieldValue('altPhoneNumber', formattedPhoneNumber);
  };

  const formik = useFormik({
    initialValues: {
      parentFirstName: enquiryInfo?.parentFirstName || '',
      parentLastName: enquiryInfo?.parentLastname || '',
      phoneNumber: enquiryInfo?.phoneNumber || '',
      altPhoneNumber: enquiryInfo?.altPhoneNumber || '',
      email: enquiryInfo?.email || '',
      addressLine1: enquiryInfo?.addressLine1 || '',
      addressLine2: enquiryInfo?.addressLine2 || '',
      postcode: enquiryInfo?.postcode || '',
      enquirySource: enquiryInfo?.enquirySource || '',
      assessmentMeetingDate: enquiryInfo?.assessmentMeetingDate || '',
      assessmentMeetingTime: enquiryInfo?.assessmentMeetingTime ? new Date(`1970-01-01T${enquiryInfo.assessmentMeetingTime}`) : null,
      enquiryDate: enquiryInfo?.enquiryDate ? enquiryInfo.enquiryDate.split('T')[0] : '',
      notes: '',
      followUpDate: enquiryInfo?.followUpDate ? enquiryInfo.followUpDate.split('T')[0] : '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log("Adding Assessment");
      console.log("Parent values");
      console.log(formik.values);
      console.log("Children Values");
      console.log(childrenAssessments);
      if (enquiryInfo?.enquiryAndAssessmentBookingId) {
        console.log("Can update the enquiry here")
        TLHEndpoints.updateDetailsForNewAssessmentEnquiry(formik.values, childrenAssessments, enquiryInfo.enquiryAndAssessmentBookingId)
      } else {
        console.log("Can save the new enquiry here")
        TLHEndpoints.saveNewAssessmentEnquiry(formik.values, childrenAssessments);
      }
      setOpenSuccessfulSubmissionModal(true);
    },
    
  });

  return (
    <div>
      <form
        onSubmit={formik.handleSubmit}
        style={{padding: '20px'}}
      >
        <Typography variant="h4" align='center' style={{padding: "15px"}}>
          New Enquiry Details
        </Typography>

        {isEnquiryNotFromToday ? 
          <div style={{ marginBottom: '20px' }}>
            <TextField
              id="enquiryDate"
              name="enquiryDate"
              label="Enquiry Date"
              type="date"
              style={{paddingRight: '200px'}}
              InputLabelProps={{
                shrink: true,
              }}
              value={formik.values.enquiryDate}
              onChange={formik.handleChange}
            />
          </div>
        : null}

        <Typography
          variant="h5"
          style={{paddingBottom: "20px"}}
        >
          Parent Details
        </Typography>

        <div className="relative w-full mb-3" style={{ display: 'flex', justifyContent: 'space-between'}}>
          <div style={{ flexBasis: '45%' }}>
            <TextField
              id="parentFirstName"
              name="parentFirstName"
              label="Parent First Name"
              multiline
              maxRows={30}
              value={formik.values.parentFirstName}
              onChange={formik.handleChange}
              error={formik.touched.parentFirstName && Boolean(formik.errors.parentFirstName)}
              helperText={formik.touched.parentFirstName && formik.errors.parentFirstName}
            />
          </div>

          <div style={{ flexBasis: '45%' }}>
            <TextField
              id="parentLastName"
              name="parentLastName"
              label="Parent Last Name"
              multiline
              maxRows={30}
              value={formik.values.parentLastName}
              onChange={formik.handleChange}
              error={formik.touched.parentLastName && Boolean(formik.errors.parentLastName)}
              helperText={formik.touched.parentLastName && formik.errors.parentLastName}
            />
          </div>
        </div>

        <div className="relative w-full mb-3" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ flexBasis: '45%' }}>
            <TextField
              id="phoneNumber"
              name="phoneNumber"
              label="Phone Number"
              multiline
              maxRows={30}
              value={formik.values.phoneNumber}
              onChange={handlePhoneNumberChange}
              error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
              helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
            />
          </div>

          <div style={{ flexBasis: '45%' }}>
            <TextField
              id="altPhoneNumber"
              name="altPhoneNumber"
              label="Alt. Phone Number"
              multiline
              maxRows={30}
              value={formik.values.altPhoneNumber}
              onChange={handleAltPhoneNumberChange}
              error={formik.touched.altPhoneNumber && Boolean(formik.errors.altPhoneNumber)}
              helperText={formik.touched.altPhoneNumber && formik.errors.altPhoneNumber}
            />
          </div>
        </div>

        <div>
          <TextField
            id="email"
            name="email"
            label="Email"
            multiline
            maxRows={30}
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            style={{ width: '50%' }}
          />
        </div>

        <br/>

        <div>
          <TextField
            id="addressLine1"
            name="addressLine1"
            label="Address Line 1"
            multiline
            maxRows={30}
            value={formik.values.addressLine1}
            onChange={formik.handleChange}
            error={formik.touched.addressLine1 && Boolean(formik.errors.addressLine1)}
            helperText={formik.touched.addressLine1 && formik.errors.addressLine1}
            style={{ width: '50%' }}
          />
        </div>

        <div>
          <TextField
            id="addressLine2"
            name="addressLine2"
            label="Address Line 2"
            multiline
            maxRows={30}
            value={formik.values.addressLine2}
            onChange={formik.handleChange}
            error={formik.touched.addressLine2 && Boolean(formik.errors.addressLine2)}
            helperText={formik.touched.addressLine2 && formik.errors.addressLine2}
            style={{ width: '50%' }}
          />
        </div>

        <div>
          <TextField
            id="postcode"
            name="postcode"
            label="Postcode"
            multiline
            maxRows={30}
            value={formik.values.postcode}
            onChange={formik.handleChange}
            error={formik.touched.postcode && Boolean(formik.errors.postcode)}
            helperText={formik.touched.postcode && formik.errors.postcode}
            style={{ width: '50%' }}
          />
        </div>

        <FormControl fullWidth style={{ maxWidth: '250px', padding: '20px' }}>
          <InputLabel id="enquiry-source-label" style={{paddingLeft: '50px', paddingTop: '10px'}}>Enquiry Source</InputLabel>
          <Select
            labelId="enquirySourceLabel"
            id="enquirySource"
            name="enquirySource"  
            value={formik.values.enquirySource}
            label="Enquiry Source"
            onChange={formik.handleChange}
            style={{textAlign: 'center'}}
            MenuProps={{
              PaperProps: {
                style: {
                  maxWidth: '200px',
                },
              },
            }}
          >
            <MenuItem style={{ width: '100%'}} value="currentParent">Current Parent</MenuItem>
            <MenuItem style={{ width: '100%'}} value="newParents">New Parent</MenuItem>
            <MenuItem style={{ width: '100%'}} value="siblingAttending">Sibling Attending Already</MenuItem>
            <MenuItem style={{ width: '100%'}} value="website">Website</MenuItem>
            <MenuItem style={{ width: '100%'}} value="newParentsEvening">New Parents Evening</MenuItem>
            <MenuItem style={{ width: '100%'}} value="leaflets">Leaflets</MenuItem>
            <MenuItem style={{ width: '100%'}} value="banner">TLH Banner/Poster</MenuItem>
            <MenuItem style={{ width: '100%'}} value="other">Other</MenuItem>
          </Select>
        </FormControl>

        {renderIndividualStudentInfoTiles()}

        <br></br>
        <div style={{justifyContent: "center", textAlign: "center"}}>
          <button 
            type="button"
            onClick={() => {
              setNumberOfStudents(numberOfStudents + 1);
              setChildrenAssessments([...childrenAssessments, {}]);
            }}
            className="px-6 py-3 rounded shadow-lg hover:shadow-lg focus:outline"  
          >Add Extra Child Details</button>
        </div>
        <br></br>
        <br></br>

        <div className="relative w-full" style={{ display: 'flex', justifyContent: 'space-between', paddingTop: "16px"}}>
          <div style={{ flexBasis: '45%' }}>
            <TextField
              id="assessmentMeetingDate"
              name="assessmentMeetingDate"
              label="Assessment Meeting Date"
              type="date"
              style={{paddingRight: '200px'}}
              InputLabelProps={{
                shrink: true,
              }}
              value={formik.values.assessmentMeetingDate}
              onChange={formik.handleChange}
            />
          </div>

          <div style={{ flexBasis: '45%'}}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TimePicker
                label="Assessment Meeting Time"
                id="assessmentMeetingTime"
                name="assessmentMeetingTime"
                value={formik.values.assessmentMeetingTime}
                onChange={(value) => formik.setFieldValue('assessmentMeetingTime', value)}
                renderInput={(params) => <TextField style={{width: '225px'}} {...params} />}
              />
            </LocalizationProvider>
          </div>
        </div>

        <div>
          <TextField
            id="notes"
            name="notes"
            label="Extra Notes"
            multiline
            maxRows={30}
            value={formik.values.notes}
            onChange={formik.handleChange}
            style={{ width: '100%' }}
          />
        </div>

        <div style={{paddingTop: '20px'}}>
          <TextField
            id="followUpDate"
            name="followUpDate"
            label="Follow Up Date (set if parent needs to be contacted again later on)"
            type="date"
            style={{paddingRight: '500px'}}
            InputLabelProps={{
              shrink: true,
            }}
            value={formik.values.followUpDate}
            onChange={formik.handleChange}
          />
        </div>

        <button
          className="uppercase px-6 py-3 rounded shadow-lg hover:shadow-lg outline-none focus:outline mr-1 mb-1 w-full"
          type="submit"
        >Click to {enquiryInfo ? "Update" : "Submit"} Enquiry</button>
      </form>

      <Modal
        open={openSuccessfulSubmissionModal}
        onClose={handleCloseSuccessfulSubmissionModal}
      >
        <Box sx={style}>
          <Typography variant="h5" align='center' style={{padding: "15px"}}>
            Enquiry Successfully Submitted
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
