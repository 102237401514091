import React, { useState } from 'react';
import Title from '../Title';
import TextField from '@material-ui/core/TextField';
import { Grid } from '@material-ui/core';


// const useStyles = makeStyles({
//     root: {
//       minWidth: 100,
//       maxWidth: 100,
//       height: 100,
//       textAlign: 'center',
//       padding: 10
//     },
//     bullet: {
//       display: 'inline-block',
//       margin: '0 2px',
//       transform: 'scale(0.8)',
//     },
//     title: {
//       fontSize: 14,
//     },
//     pos: {

//     },
//   });

  // Component is currently updating state of parent component on every single key stroke input
export default function QuestionQuantityTile({title, onValueEntered, questionTypes, key}) {

    const [numberOfQuestions, setNumberOfQuestions] = useState(0)

    // const theme = useTheme();

    // Define useEffect function - will allow us to get list of questions choices for this title.

    // const title = useState(title);

    function updateNumberOfQuestions(value){
        const re = /^[0-9]\d*$/;

        

        onValueEntered(title, value)

        if (value === '' || re.test(value)) {
          console.log("Setting value: " + value)
          setNumberOfQuestions(value)
       }

        // let val = parseInt(value, 10);
        //     if (isNaN(val)) {
        //       setNumberOfQuestions("")

        //     } else {
        //       // is A Number
        //       val = val >= 0 ? val : 0;
        //       setNumberOfQuestions(value)
        //     }

        // console.log("Got value: " + value)
        // if (value >= 0) {
        //   console.log("Changing value")
        //   setNumberOfQuestions(value)
        // } else {
        //   console.log("Setting to null")
        //   setNumberOfQuestions(null)
        // }
        // console.log("Requested number: " + numberOfQuestions)
    }

    return (
        <React.Fragment>
            {/* This should be a parameter for the tile */}
            {/* <ResponsiveContainer> */}
            <Grid container>
              <Grid item xs={3}>
                <Title>{title}</Title>
              </Grid>
              <Grid item xs={3}>
                <TextField                
                  // defaultValue={numberOfQuestions}
                  value = {numberOfQuestions}
                  type="number"
                  min="0"
                  step="1"
                  onChange={e => updateNumberOfQuestions(e.target.value)}
                /> 
              </Grid>
            </Grid>
            {/* </ResponsiveContainer> */}
        </React.Fragment>
    )
}  