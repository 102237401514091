import React, { useState, useEffect } from 'react';
import { createTheme } from '@mui/material/styles';
import {useParams} from "react-router-dom";
import { useHistory } from "react-router";

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Chart from '../AnalyticsComponents/Chart';
import Box from '@mui/material/Box';
import Link from '@material-ui/core/Link';
import { Typography } from '@material-ui/core';
import Card from '@mui/material/Card';

// import CorrectlyAnsweredQuestionsTile from '../AnalyticsComponents/CorrectlyAnsweredQuestionsTile';
import PastWorksheets from '../AnalyticsComponents/PastWorksheets';
import QuestionsAndUserDataService from '../../services/QuestionsAndUserDataService'
import AddNewAssignment from './TeacherComponents/AddNewAssignment';

// Routes
import * as CONSTANTS from "../../StringConstants.js"




export default function ViewClass() {
    const [totalNumberCorrectlyAnsweredQuestions, setTotalNumberCorrectlyAnsweredQuestions] = useState(0)
    const [worksheetData, setWorksheetData] = useState([])
    const [idOfLastWorksheet, setIdOfLastWorksheet] = useState(-1)
    const [chartData, setChartData] = useState([])
    const [isNoContent, setIsNoContent] = useState(false)
    const [teacherName, setTeacherName] = useState("")
    const [classData, setClassData] = useState([])

    const [isTotalNumberCorrectQuestionsLoading, setIsTotalNumberCorrectQuestionsLoading] = useState(true)
    const [isWorksheetDataLoading, setIsWorksheetDataLoading] = useState(true)
    const [isLoadingClassData, setIsLoadingClassData] = useState(true)
    const [isLoadingTeacherName, setIsLoadingTeacherName] = useState(true)

    const { classId } = useParams();
    let history = useHistory();

    const blockHeight = 150
    const paddingUsed = 20

    // useEffect(() => {
    //     console.log("Arrived at the page")
    //     if (isTotalNumberCorrectQuestionsLoading) {
    //         setIsTotalNumberCorrectQuestionsLoading(false)
    //         // getUserCorrectNumberQuestionsAnswered()
    //     }
    //     if (isWorksheetDataLoading) {
    //         setIsWorksheetDataLoading(false)
    //         // getUserWorksheetData()
    //     }
    //     if (isLoadingClassData) {
    //         getClassData(classId)
    //     }
    //     if (isLoadingTeacherName) {
    //         getTeacherName()
    //     }
    //   });

      

    function getClassData(classId) {
        console.log("Need to implement get class data")
        QuestionsAndUserDataService.getClassData(classId).then(classData => {
            console.log("Class Data")
            console.log(classData.data.studentsInClass)
            setClassData(classData.data.studentsInClass)
            setIsLoadingClassData(false)
        })
    }

    function getTeacherName() {
        console.log("Need to implement get TeacherName")
        QuestionsAndUserDataService.getTeacherName().then(name => {
            // console.log(name)
            setTeacherName(name.data)
            setIsLoadingTeacherName(false)
        })
    }

    function getUserCorrectNumberQuestionsAnswered() {
        QuestionsAndUserDataService.getUserAnalyticsData().then(result => {
            console.log("Completing promise to get number of correct questions answered")
            // console.log(result)
            setIsTotalNumberCorrectQuestionsLoading(false)
            setTotalNumberCorrectlyAnsweredQuestions(result.data)
            // setTotalNumberCorrectlyAnsweredQuestions(result)
          });
        
    }

    function getUserWorksheetData() {
        QuestionsAndUserDataService.getUserWorksheets().then(worksheets => {
            console.log("Completing promise to get worksheets")
            console.log(worksheets)
            console.log("Worksheet info: ")
            console.log(worksheets.data.length)
            if (worksheets.status != 204) {
                console.log("in 204")
            const sortedWorksheetData = worksheets.data.sort((a,b) => a.worksheetId < b.worksheetId ? 1 : -1)
            // console.log("Last worksheet id: " + sortedWorksheetData[0].worksheetId)
                
                convertToChartData(sortedWorksheetData)
                setIdOfLastWorksheet(sortedWorksheetData[0].worksheetId)
                setWorksheetData(sortedWorksheetData)
                console.log("Sorted worksheet data")
                console.log(sortedWorksheetData.length)
                setIsWorksheetDataLoading(false)
            } else {
                console.log("No worksheet content")
                setIsNoContent(true)
                setIsWorksheetDataLoading(false)

            }
        }).finally(() => {
            setIsNoContent(worksheetData.length == 0)
            setIsWorksheetDataLoading(false)
            console.log("Worksheet data in finally")
            console.log(worksheetData)
        })
    }

    const columns = [
        {
          field: 'fullName',
          headerName: 'Student',
          description: 'Student Name',
          sortable: false,
          width: 170,
          valueGetter: (params) =>
            `${params.row.firstName || ''} ${params.row.surname || ''}`,
        },
        {
            field: 'viewDetails',
            headerName: 'View Student Details',
            type: 'actions',
            width: 170,
            description: 'Select to view more details about the students',
            sortable: false,
            getActions: (params) => [
                <Link onClick={() => goToStudentPage(params.row.id)}>
                  View Details
                </Link>
              ],
            // renderCell: (params) => (
            //     <Link style={{textAlign: 'center'}}
            //       onClick={goToStudentPage(params.row.getRowId)}
            //     > View Details</Link>
            //   )
          },
      ];
    
      function goToStudentPage(studentId) {
          console.log("Going to student page")
          let studId = studentId //need to figure out how to get this
          console.log(studentId)
          history.push(CONSTANTS.ViewStudent + "/" + `${studentId}`)
      }

    // The function and variable below are currently unused but can be called if dummy data is needed on the front end
    function createDummyData(date, numberOfQuestionsInTheWorksheet, numberQuestionsCorrectlyAnswered, questionTypes, subject, topic, user, worksheetId, worksheetQuestions) {
        return {date, numberOfQuestionsInTheWorksheet, numberQuestionsCorrectlyAnswered, questionTypes, subject, topic, user, worksheetId, worksheetQuestions}
    }

    let dummyData = [
        createDummyData("2022-05-21", 10, 6, "Subtraction", "Math", "Fractions - Standard", null, 1, null), // 1
        createDummyData("2022-05-21", 10, 4, "Multiplication", "Math", "Decimals", null, 2, null), // 1
        createDummyData("2022-05-23", 15, 11, "Area Questions", "Math", "Volume and Area Problems", null, 3, null), // 2
        createDummyData("2022-05-23", 10, 9, "Multiplication", "Math", "Capacity Problems", null, 4, null), // 3
        createDummyData("2022-05-24", 12, 9, "Addition", "Math", "Length Problems", null, 5, null), // 4
        createDummyData("2022-05-25", 20, 19, "Multiplication", "Math", "Fractions - Standard", null, 6, null), // 5
    ]


    function convertToChartData(allWorkSheetData) {
        let chartData =[]
        for (let i = 0; i < allWorkSheetData.length; i++) {
            let percentageScore = Math.round((allWorkSheetData[i].numberQuestionsCorrectlyAnswered / allWorkSheetData[i].numberOfQuestionsInTheWorksheet)*100)
            // Since data is ordered with latest first we need to flip this to show the score progression correctly 
            let flippedIndex = allWorkSheetData.length - i - 1
            chartData[flippedIndex] = createData(allWorkSheetData[i].date, percentageScore)
        }
        setChartData(chartData)
    }

    function createData(time, amount) {
        return { time, amount };
    }


  return (        
            <div className='p-4'>

                <Grid container spacing={3}>
               

                <Grid item xs={4}>
                    <div>
                        <div style={{paddingRight: paddingUsed+"px", paddingBottom: paddingUsed + "px", width: '50%', float: 'left'}}>
                            <Paper
                                // sx={{
                                //     p: 2,
                                //     display: 'flex',
                                //     flexDirection: 'column',
                                //     height: blockHeight,
                                // }}
                                > 
                                <AddNewAssignment/>
                            </Paper>
                        </div>
                    </div>
                    <div style={{width: '50%', float: 'left', paddingBottom: paddingUsed + "px",}}>
                        <Card
                            sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            height: blockHeight,
                        }}>
                            <Typography
                                variant="h6" 
                                component="div"
                            >
                                <b>Welcome back {teacherName}</b>
                            </Typography>
                        </Card>
                    </div>
                    
                    <div>
                        <Box sx={{ height: 2*blockHeight, width: '100%' }}>
                            {/* <DataGrid
                                rows={classData}
                                columns={columns}
                                autoPageSize
                                disableSelectionOnClick
                                disableColumnFilter
                                disableColumnMenu
                                disableColumnSelector
                                disableExtendRowFullWidth
                                loading={false}
                                density="compact"
                                showColumnRightBorder={false}
                                showCellRightBorder={false}
                                getRowId={(row) => row.id}
                            /> */}
                        </Box>    
                    </div>
                </Grid>

                {/* <Grid item xs={3}>
                   
                </Grid> */}

                 {/* Chart */}
                 <Grid item xs={8}>
                    <Paper
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        height: blockHeight*3 + paddingUsed,
                    }}
                    >
                    <Chart chartData={chartData}/>
                    </Paper>
                </Grid>
                
                {/* Recent PastWorksheets */}
                <Grid item xs={12}>
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                        {/* Add check and mention no past worksheets if required */}
                        { isWorksheetDataLoading ?
                            <div>Loading data ... </div> : 
                            ((isNoContent) ? 
                                <div>No worksheets assigned yet </div> :
                                <PastWorksheets 
                                    data={worksheetData}
                                />)
                        }
                    </Paper>
                </Grid>
                </Grid>
            </div>
  );
}
