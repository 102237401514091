import React, { useState } from 'react';
import { Typography, TextField, Button } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker, TimePicker } from '@mui/x-date-pickers';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TLHEndpoints from 'services/TLHEndpoints';

const validationSchema = yup.object({
  schoolName: yup.string().required('School name is required'),
  schoolVisitDate: yup.date().required('School visit date required'),
  numberLeafletsGivenOut: yup.number().min(0, 'Must be a positive number').required('Number of leaflets given out is required'),
  parentDetails: yup.array().of(
    yup.object().shape({
      // firstName: yup.string().required('First name is required'),
      // lastName: yup.string().required('Last name is required'),
      // email: yup.string().email('Enter a valid email').required('Email is required'),
      // phoneNumber: yup.string().required('Phone number is required'),
      // alternatePhoneNumber: yup.string(),
      // notes: yup.string(),
      // schoolYears: yup.array().of(yup.string().required('School year is required'))
    })
  )
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const formatPhoneNumber = (value) => {
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, ''); // Remove all non-digit characters
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 5) return phoneNumber;
  if (phoneNumberLength < 9) {
    return `${phoneNumber.slice(0, 4)} ${phoneNumber.slice(4)}`;
  }
  return `${phoneNumber.slice(0, 4)} ${phoneNumber.slice(4, 7)} ${phoneNumber.slice(7, 11)}`;
};

export default function AddNewProspectiveParentsDataForm({ parentsEveningId }) {
  const [parentDetails, setParentDetails] = useState([]);
  const [openSuccessfulSubmissionModal, setOpenSuccessfulSubmissionModal] = useState(false);
  const handleCloseSuccessfulSubmissionModal = () => setOpenSuccessfulSubmissionModal(false);

  const formik = useFormik({
    initialValues: {
      schoolName: '',
      schoolVisitDate: null,
      schoolClosingTime: null,
      numberLeafletsGivenOut: 0,
      parentDetails: [],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log("Submitting formik values");
      console.log("Formik values", values);
      console.log("Parents Evening Id: " + parentsEveningId);
      TLHEndpoints.AddNewProspectiveParentsDataFormData(values, parentsEveningId).then(
        setOpenSuccessfulSubmissionModal(true)
      )
    },
  });

  const handleAddParent = () => {
    setParentDetails([...parentDetails, {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      alternatePhoneNumber: '',
      notes: '',
      schoolYears: []
    }]);
  };

  const handleRemoveParent = (index) => {
    const updatedParents = [...parentDetails];
    updatedParents.splice(index, 1);
    setParentDetails(updatedParents);
    formik.setFieldValue('parentDetails', updatedParents);
  };

  const handleParentChange = (index, event) => {
    const { name, value } = event.target;
    const updatedParents = [...parentDetails];
    if (name === "phoneNumber" || name === "alternatePhoneNumber") {
      updatedParents[index][name] = formatPhoneNumber(value);
    } else {
      updatedParents[index][name] = value;
    }
    setParentDetails(updatedParents);
    formik.setFieldValue('parentDetails', updatedParents);
  };

  const handleSchoolYearChange = (index, event) => {
    const updatedParents = [...parentDetails];
    updatedParents[index].schoolYears = event.target.value.split(',');
    setParentDetails(updatedParents);
    formik.setFieldValue('parentDetails', updatedParents);
  };

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Typography variant="h6" style={{ marginBottom: "20px" }}>
          Add School Visit and Parent Details
        </Typography>

        <div style={{ paddingBottom: '20px', display: 'flex', flexDirection: 'column', gap: '20px' }}>
          <TextField
            label="School Name"
            id="schoolName"
            name="schoolName"
            fullWidth
            value={formik.values.schoolName}
            onChange={formik.handleChange}
            error={formik.touched.schoolName && Boolean(formik.errors.schoolName)}
            helperText={formik.touched.schoolName && formik.errors.schoolName}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="School Visit Date"
              value={formik.values.schoolVisitDate}
              onChange={(newValue) => formik.setFieldValue('schoolVisitDate', newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="schoolVisitDate"
                  name="schoolVisitDate"
                  fullWidth
                  error={formik.touched.schoolVisitDate && Boolean(formik.errors.schoolVisitDate)}
                  helperText={formik.touched.schoolVisitDate && formik.errors.schoolVisitDate}
                />
              )}
            />
            <TimePicker
              label="School Closing Time"
              value={formik.values.schoolClosingTime}
              onChange={(newValue) => formik.setFieldValue('schoolClosingTime', newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="schoolClosingTime"
                  name="schoolClosingTime"
                  fullWidth
                />
              )}
            />
          </LocalizationProvider>
          <TextField
            label="Number of Leaflets Given Out"
            id="numberLeafletsGivenOut"
            name="numberLeafletsGivenOut"
            type="number"
            fullWidth
            value={formik.values.numberLeafletsGivenOut}
            onChange={formik.handleChange}
            error={formik.touched.numberLeafletsGivenOut && Boolean(formik.errors.numberLeafletsGivenOut)}
            helperText={formik.touched.numberLeafletsGivenOut && formik.errors.numberLeafletsGivenOut}
          />
        </div>

        <Typography variant="h6" style={{ marginBottom: "20px" }}>
          Parent Details
        </Typography>

        {parentDetails.map((parent, index) => (
          <div key={index} style={{ marginBottom: '20px', border: '1px solid #ccc', padding: '10px', borderRadius: '5px' }}>
            <TextField
              label="First Name"
              name="firstName"
              fullWidth
              value={parent.firstName}
              onChange={(e) => handleParentChange(index, e)}
              style={{ marginBottom: '10px' }}
            />
            <TextField
              label="Last Name"
              name="lastName"
              fullWidth
              value={parent.lastName}
              onChange={(e) => handleParentChange(index, e)}
              style={{ marginBottom: '10px' }}
            />
            <TextField
              label="Email"
              name="email"
              fullWidth
              value={parent.email}
              onChange={(e) => handleParentChange(index, e)}
              style={{ marginBottom: '10px' }}
            />
            <TextField
              label="Phone Number"
              name="phoneNumber"
              fullWidth
              value={parent.phoneNumber}
              onChange={(e) => handleParentChange(index, e)}
              style={{ marginBottom: '10px' }}
            />
            <TextField
              label="Alternate Phone Number"
              name="alternatePhoneNumber"
              fullWidth
              value={parent.alternatePhoneNumber}
              onChange={(e) => handleParentChange(index, e)}
              style={{ marginBottom: '10px' }}
            />
            <TextField
              label="Notes"
              name="notes"
              fullWidth
              value={parent.notes}
              onChange={(e) => handleParentChange(index, e)}
              style={{ marginBottom: '10px' }}
            />
            <TextField
              label="School Years (comma separated)"
              name="schoolYears"
              fullWidth
              value={parent.schoolYears.join(',')}
              onChange={(e) => handleSchoolYearChange(index, e)}
              style={{ marginBottom: '10px' }}
            />
            <Button variant="contained" color="secondary" onClick={() => handleRemoveParent(index)} style={{ marginTop: '10px' }}>
              Remove Parent
            </Button>
          </div>
        ))}

        <Button variant="contained" color="primary" onClick={handleAddParent} style={{ marginBottom: '20px' }}>
          Add Parent
        </Button>

        <div style={{ textAlign: "center", paddingTop: "25px" }}>
          <button
            style={{
              padding: '10px 20px',
              fontSize: '18px',
              color: 'white',
              backgroundColor: '#0056b3',
              border: 'none',
              borderRadius: '5px',
              boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
              cursor: 'pointer',
              transition: 'all 0.3s ease',
            }}
            onMouseOver={(e) => {
              e.target.style.backgroundColor = '#004085';
              e.target.style.boxShadow = '0 8px 16px rgba(0,0,0,0.2)';
            }}
            onMouseOut={(e) => {
              e.target.style.backgroundColor = '#0056b3';
              e.target.style.boxShadow = '0 4px 8px rgba(0,0,0,0.1)';
            }}
            type="submit"
          >
            Add Parent Details
          </button>
        </div>
      </form>

      <Modal
        open={openSuccessfulSubmissionModal}
        onClose={handleCloseSuccessfulSubmissionModal}
      >
        <Box sx={style}>
          <Typography variant="h5" align='center' style={{ padding: "15px" }}>
            Parent Details Successfully Submitted
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
