import React from 'react';
// import "../assets/styles/index.css";
import "../../assets/styles/index.css"




export default function EnglishQuestionTopicTile({answerOption, onOptionClick, optionNumber, currentAnswerSelectedByUser, shouldBeBoldOption}) {

  function updateUserChoice(selection) {
    console.log("selection: " + selection)
    onOptionClick(selection)
    // setSelectedChoice(selection)
  }


  function intToChar(key) {
    // console.log("Returning letter for: " + key)
    if (key === "") {
      return ""
    }
    const code = 'A'.charCodeAt(0);
    const newLetter = String.fromCharCode(code + key);
    // console.log("Got: " + newLetter)
    return newLetter + ") "
  }

    return (
      <button 
        className='english-question-answer-option'
        onClick={() => updateUserChoice(answerOption)} 
        style={{
          borderStyle: "outset",
          fontWeight: shouldBeBoldOption ? "bold" : "normal"
        }}
      >
        {intToChar(optionNumber) + answerOption}
      </button>
    )
}  