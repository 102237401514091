import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';
import { Grid } from '@material-ui/core';

import QuestionsAndUserDataService  from 'services/QuestionsAndUserDataService';
import * as CONSTANTS from "../../StringConstants"
import whiteLogo from "./../../assets/logoVariations/Primarylogomark_purple_black_white-03.png"
import CountdownTimer from './CountdownTimer';

const drawerWidth = 260;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));




// questionNumber, question, answer, updateUserAnswer, currentUserAnswer, multipleChoiceOptions, grammarQuestionType, comprehensionDescription, comprehensionPassage

  export default function MockExamBreakPage() {
    const [open, setOpen] = useState(false);
    const [isLoadingMockExamSessionTracker, setIsLoadingMockExamSessionTracker] = useState(true)
    const [mockExamSessionTracker, setMockExamSessionTracker] = useState(null)
    const [timerExpired, setTimerExpired] = useState(false);
    const [endTime, setEndTime] = useState(null)

    let history = useHistory()

    useEffect(() => {
      console.log("In use effect for break page")
      console.log("Is loading mock exam session tracker: " + isLoadingMockExamSessionTracker)
      if (isLoadingMockExamSessionTracker) {
        QuestionsAndUserDataService.startBreakSession().then(result => {
          console.log("Checking if currently break time")
          console.log(result.data)
    
          if (result.data == "") {
            console.log("No data receieved from backend, returning to dashboard")
            history.push(CONSTANTS.MockExamDashboard)
            return
          }
            // Store mock exam session tracker
          setMockExamSessionTracker(result.data)
    
          const currentStage = result.data.currentMockExamStage
    
          if (currentStage === "STAGE_EXAM_IN_PROGRESS") {
            history.push(CONSTANTS.MockExamPage)
            return
          }
          if (currentStage === "STAGE_EXAM_SESSION_COMPLETE") {
            history.push(CONSTANTS.MockExamSessionCompletePage)
            return
          }
          if (currentStage === "STAGE_SESSION_NOT_STARTED") {
            history.push(CONSTANTS.MockExamDashboard)
            return
          }

          console.log("Break time, time calculations:")
    
          setMockExamSessionTracker(result.data)
          
          var backEndCurrentTime = result.data.currentBackEndTime
          var frontEndCurrentTime = new Date()
    
          var backEndFrontEndTimeDiff = calculateBackEndFrontEndTimeDiffInMinutes(backEndCurrentTime, frontEndCurrentTime)
          console.log("Back end current time: " + backEndCurrentTime)
          console.log("Front end current time: " + frontEndCurrentTime)
          console.log("Difference between them: " + backEndFrontEndTimeDiff)
    
          console.log("Back end start time: " + result.data.currentExamStartTime)
          console.log("Back end end time: " + result.data.currentExamEndTime)
    
          // console.log("Current break start time")
          // var startTime = result.data.currentExamStartTime
          var endTime = result.data.currentExamEndTime
    
          // var difference = timeDiff(startTime, endTime)
          var timeLeftInCurrentSection = timeDiff(backEndCurrentTime, endTime)
          var valueInMilliseconds = timeLeftInCurrentSection.hours * 1000 * 60 * 60 + timeLeftInCurrentSection.minutes * 1000 * 60 + timeLeftInCurrentSection.seconds * 1000
          console.log("Time left in current section")
          console.log(timeLeftInCurrentSection)
          // console.log("Time Difference")
          // console.log(difference)
    
          // Timing is slightly more complicated. Should send the current time from the backend and compare
          // it with the time on the front end, take that offset and use for the countdown 
    
          var startTime = result.data.currentExamStartTime.split(":")
          // var endTime = result.data.currentExamEndTime.split(":")
      
          var newStartTime = new Date();
          // var newEndTime = new Date();
    
          newStartTime.setHours(startTime[0])
          newStartTime.setMinutes(startTime[1])
          newStartTime.setSeconds(startTime[2])
    
          // newEndTime.setHours(endTime[0])
          // newEndTime.setMinutes(endTime[1])
          // newEndTime.setSeconds(endTime[2])
    
          var breakLength = 10
    
          var endTime = addMinutes(newStartTime, breakLength + backEndFrontEndTimeDiff)
    
          console.log("Current break start time: " + startTime)
          console.log("Current break end time (after adding minutes): " + endTime)
          console.log("Time being added to the counter: " + endTime - new Date())
          setEndTime(valueInMilliseconds)
          setIsLoadingMockExamSessionTracker(false)
        })
      }
    });

  

  // function getMockExamSessionTracker() {
    
  // }

  function calculateBackEndFrontEndTimeDiffInMinutes(backEndCurrentTime, frontEndTime) {
    const [hours, minutes, seconds] = backEndCurrentTime.split(":");
    // const date = new Date(backendTime);
    const frontEndTimeHours = frontEndTime.getHours();
    const frontEndTimeMinutes = frontEndTime.getMinutes();
    const frontEndTimeSeconds = frontEndTime.getSeconds();
  
    const timeInMinutes = parseInt(hours) * 60 + parseInt(minutes) + parseInt(seconds) / 60;
    const frontEndTimeInMinutes = frontEndTimeHours * 60 + frontEndTimeMinutes + frontEndTimeSeconds / 60;
  
    const differenceInMinutes = timeInMinutes - frontEndTimeInMinutes;

  
    return Math.trunc(differenceInMinutes);
  }

  function timeDiff(start, end) {
    const [startHours, startMinutes, startSeconds] = start.split(':').map(Number);
    const [endHours, endMinutes, endSeconds] = end.split(':').map(Number);
  
    let diffHours = endHours - startHours;
    let diffMinutes = endMinutes - startMinutes;
    let diffSeconds = endSeconds - startSeconds;
  
    if (diffSeconds < 0) {
      diffSeconds += 60;
      diffMinutes--;
    }
  
    if (diffMinutes < 0) {
      diffMinutes += 60;
      diffHours--;
    }
  
    return { hours: diffHours, minutes: diffMinutes, seconds: diffSeconds };
  }

  
  function addMinutes(time, minutes) {

    console.log("In add minutes")
    console.log("Adding " + minutes + " to " + time)

    var mins = time.getMinutes() + minutes
    var hrs = time.getHours()

    while (mins > 60) {
      mins = mins - 60
      hrs = hrs + 1
    }

    time.setMinutes(mins)
    time.setHours(hrs)
  
    // Return the new time
    console.log("New total time: " + time)
    return time;
  }



  function handleTimeExpired() {
    console.log("Time expire event triggered")
    console.log(mockExamSessionTracker)
    if (isLoadingMockExamSessionTracker) {
      console.log("Still loading mock exam tracker data")
      return
    }
    QuestionsAndUserDataService.endBreakSession(mockExamSessionTracker.mockExamSessionTrackerCompositeId).then(result => {
      console.log("Just called end break sesson. Tracker data is as follows:")
      console.log(result.data)

      if (result.data == "") {
        history.push(CONSTANTS.MockExamDashboard)
        return
      } else {
        // Get mock exam 
          const currentStage = result.data.currentMockExamStage
          if (currentStage === "STAGE_EXAM_IN_PROGRESS") {
            history.push(CONSTANTS.MockExamPage)
            return
          }
          if (currentStage === "STAGE_EXAM_SESSION_COMPLETE") {
            history.push(CONSTANTS.MockExamSessionCompletePage)
            return
          }
          if (currentStage === "STAGE_SESSION_NOT_STARTED") {
            history.push(CONSTANTS.MockExamDashboard)
            return
          }
        }
      })
      setTimerExpired(true);
    }
     


    return (
      <>
        <AppBar position="fixed" open={open} style={{backgroundColor: "purple"}}>
          <Toolbar>
         
              <img alt="11plus-Whizzdom-logo" style={{height: '60px'}} src={whiteLogo}></img>
             

          {/* <div className="w-full mx-autp flex justify-end md:flex-nowrap flex-wrap md:px-10 px-4" >
            <div>
              <div>
                {!timerExpired && !isLoadingMockExam ? (
                  <CountdownTimer targetTime={endTime} />
                ) : (
                  <div>Loading ...</div>
                )}
              </div>
            </div>
          </div> */}
          </Toolbar>
        </AppBar>

        <Grid container>
          <Grid item xs={3}></Grid>

          <Grid item xs={6}>

          <Box sx={{width: "100%", maxWidth: 700, display: "flex", flexDirection: "column",  marginTop: "30%", alignItems: "center", height: "100vh"}}>
            <Typography 
                variant="h4" 
                style={{textAlign: "center", justifyContent: "center", display: "flex", alignItems: "center"}}>

              You will now have a short break before your next exam. All previous answers have been saved.
              <br></br>
              <br></br>
              The next exam will start automatically when the timer below reaches 0. Do not close this page.
              <br></br>
              <br></br>
            </Typography>

            {(!isLoadingMockExamSessionTracker && !timerExpired) ? 

            (<Typography 
                variant="h4" 
                style={{textAlign: "center", justifyContent: "center", display: "flex", alignItems: "center"}}>
            <CountdownTimer 
                targetTime={endTime} 
                handleTimeExpired={handleTimeExpired}
              />
            </Typography>) : (<div>Loading ...</div>)
          
            }

          </Box>
          </Grid>
            <Grid item xs={3}></Grid>
        </Grid>
      
      </>
    )
  }