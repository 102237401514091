import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { format } from 'date-fns';

import QuestionsAndUserDataService from '../../../services/QuestionsAndUserDataService'

export default function CreateMockExamSetForm() {

  const [examType, setExamType] = useState("");   
  const [timeValue, setTimeValue] = useState();

  const handleTimeChange = (newValue) => {
    console.log(newValue)
    setTimeValue(newValue);
  };

  const handleChange = (event) => {
    setExamType(event.target.value);
  };

  const validationSchema = yup.object({
    name: yup
      .string('Enter your name')
      .required('A name is required for the exam set'),
    examDate: yup
      .string('Enter your name')
      .required('A date is required for the exam set'),
    time: yup
      .string('Enter your time')
      // .time('Enter a valid time')
      .required('time is required'),
  });


  // const WithMaterialUI = () => {
    const formik = useFormik({
      initialValues: {
        name: '',
        examDate: new Date(),
        time: new Date(),
        customCheckLogin: false,
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {

        console.log("Submitting mock exam set form")
        console.log("formik values")
        console.log(formik.values.name)
        console.log(formik.values.examDate)
        console.log(timeValue)
        console.log(examType)

        const formattedTime = format(timeValue, "HH:mm:ss");
        console.log("Formatted time")
        console.log(formattedTime)

        QuestionsAndUserDataService.addMockExamSet(formik.values.name, formik.values.examDate, formattedTime, examType).then(result => {
          console.log(result)
        })
        // updateUserDetails(formik.values.name, formik.values.examDate, formik.values.time).then(onStepChange(2))

      },
    });

  return (
    
    <div>
     <form
        onSubmit={formik.handleSubmit}
        >
         <label> Exam Set Details</label>
        <div className="relative w-full mb-3">

        <TextField
            fullWidth
            id="name"
            name="name"
            label="Exam set name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />

        </div>

        <div className="relative w-full mb-3">
          <TextField
            fullWidth
            id="examDate"
            name="examDate"
            label="Exam Date"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            value={formik.values.examDate}
            onChange={formik.handleChange}
            error={formik.touched.examDate && Boolean(formik.errors.examDate)}
            helperText={formik.touched.examDate && formik.errors.examDate}
          />
        </div>


        <div className="relative w-full mb-3">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
           <TimePicker
              label="Time"
              id="time"
              name="time"
              value={timeValue}
              onChange={handleTimeChange}
              renderInput={(params) => <TextField {...params} />}
            />
            </LocalizationProvider>
        </div>

      
        <Box style={{textAlign:"center"}}>

          <InputLabel id="examType">examType</InputLabel>
          <Select
            labelId="examType"
            id="examType"
            value={examType}
            autoWidth
            label="examType"
            onChange={handleChange}
            className="px-2"
          >
              <MenuItem value="CEM">CEM</MenuItem>
              <br></br>
              <MenuItem value="GL">GL</MenuItem>
          </Select>

          </Box>



        <div className="text-center mt-6">
          <Button
            colour="primary"
            className="bg-blueGray-800 active:bg-blueGray-600 uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
            type="submit"
          >
            Add Exam Set
          </Button>
        </div>
      </form>
    </div>
  );
}
