import { useHistory } from "react-router";
import "../../assets/styles/index.css";
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Grid } from '@material-ui/core';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Settings from '@mui/icons-material/Settings';
import QuestionsAndUserDataService from '../../services/QuestionsAndUserDataService'
import * as CONSTANTS from "../../StringConstants"
import React, { useState, useEffect } from "react";

// import Logout from '@mui/icons-material/Logout';


// const ColorButton = styled(Button)(({ theme }) => ({
//   color: '#FFFFFF',
//   backgroundColor: '#FFFFFF',
//   textTransform: 'none',
//   fontSize: 16,
//   padding: '6px 12px',
//   border: '1px solid',

//   fontFamily: [
//     '-apple-system',
//     'BlinkMacSystemFont',
//     '"Segoe UI"',
//     'Roboto',
//     '"Helvetica Neue"',
//     'Arial',
//     'sans-serif',
//     '"Apple Color Emoji"',
//     '"Segoe UI Emoji"',
//     '"Segoe UI Symbol"',
//   ].join(','),
//   '&:hover': {
//     color: '#FFFFFF',
//   },
// }));

// const BootstrapButton = styled(Button)({
//   boxShadow: 'none',
//   textTransform: 'none',
//   fontSize: 16,
//   padding: '6px 12px',
//   border: '1px solid',
//   lineHeight: 1.5,
//   backgroundColor: '#0063cc',
//   color: '#000000',
//   borderColor: '#0063cc',
//   fontFamily: [
//     '-apple-system',
//     'BlinkMacSystemFont',
//     '"Segoe UI"',
//     'Roboto',
//     '"Helvetica Neue"',
//     'Arial',
//     'sans-serif',
//     '"Apple Color Emoji"',
//     '"Segoe UI Emoji"',
//     '"Segoe UI Symbol"',
//   ].join(','),
//   '&:hover': {
//     backgroundColor: '#0069d9',
//     borderColor: '#0062cc',
//     boxShadow: 'none',
//   },
//   '&:active': {
//     boxShadow: 'none',
//     backgroundColor: '#0062cc',
//     borderColor: '#005cbf',
//   },
//   '&:focus': {
//     boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
//   },
// });

export default function Navbar() {
  let history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);
  const [expiryInfo, setExpiryInfo] = useState()
  const open = Boolean(anchorEl);


  useEffect( () => {

    const user = localStorage.getItem("user")
    const userData = JSON.parse(user);
    const userRoles = userData.roles;
    if (userRoles && userRoles.includes("ROLE_FREE_USER")) {
      console.log("User has the ROLE_FREE_USER role");
      // Get the relevant expiry info 
      QuestionsAndUserDataService.getTimeLeftOnFreemiumTrial()
      .then((response) => {
        console.log("Response: ")
        console.log(response);
        if (response !== null) {
          let daysLeftOnTrial = response.data 
          let navBarMessage = []
          if (daysLeftOnTrial > 0 && daysLeftOnTrial <= 7) {
            navBarMessage.push(<b>{"You have " + daysLeftOnTrial + " days left on your trial. "}</b>)
          }
          if (daysLeftOnTrial == 0) {
            navBarMessage.push(<b>{"Your trial expires at midnight. "}</b>)
          }
          if (daysLeftOnTrial < 0) {
            navBarMessage.push(<b>{"Your trial has expired. "}</b>)
          }
          navBarMessage.push(<button onClick={() => QuestionsAndUserDataService.createCheckoutSessionFromDashboard("1")}
          ><b><u>Upgrade now for unlimited access!</u></b></button>)
          setExpiryInfo(navBarMessage)
        }
      })
  } 
  }, [])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function userLogout() {
    history.push(CONSTANTS.IndividualLogin)
    localStorage.removeItem("user")
  }

  function switchToAnalyticsDashboard() {
    console.log(localStorage)
    history.push("/app/analytics")
  }

  function createBillingPortal() {
    QuestionsAndUserDataService.createCustomerPortal() 
  }

  return (
    <>
      {/* Navbar */}
      <nav className="absolute top-0 left-0 w-full z-10 bg-transparent md:flex-row md:flex-nowrap md:justify-start flex items-space-between p-4">
        <Grid container>
          <Grid item style={{color: "white", alignSelf: "center"}} xs={10} md={10} sm={10}>
            {expiryInfo}
          </Grid>


          <Grid item style={{width: "10%"}} xs={2} md={2} sm={2}>

          <div className="flex justify-end" >

              <Box>
                <Tooltip title="Account settings">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 0 }}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                  >
                    {/* <ListItemIcon> */}
                      <Settings sx={{ color: "white" }} fontSize="large" />
                    {/* </ListItemIcon>  */}
                  </IconButton>
              </Tooltip>
              </Box>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
              
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    padding: 1,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 2,
                      right: 8,
                      width: 20,
                      height: 20,
                      padding: 1,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
          {/* <MenuItem>
            <Avatar /> Student Profile
          </MenuItem> */}
                <MenuItem
                  onClick={createBillingPortal}>
                  My Subscription
                </MenuItem>
                <Divider />

                <MenuItem
                  onClick={() => switchToAnalyticsDashboard(true)}>
                  Review Area
                </MenuItem>
                <Divider />

                <MenuItem
                  onClick={userLogout}>
                  <ListItemIcon>
                    {/* <Logout fontSize="small" /> */}
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
            </div>

            {/* </div> */}
            {/* <ul className="flex-col md:flex-row list-none items-left hidden md:flex">
              <UserDropdown />
            </ul> */}

          </Grid>

        </Grid>
      </nav>
      {/* End Navbar */}
    </>
  );
}
