import React, { useState, useEffect } from 'react';
import {useParams} from "react-router-dom";

import { useFormik } from 'formik';
import TextField from '@material-ui/core/TextField';
import { Typography } from '@material-ui/core';

import TLHEndpoints from 'services/TLHEndpoints';

import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Link } from '@mui/material';
import AddNewProspectiveParentsDataForm from 'theLearningHub/layouts/HelperComponents/AddNewProspectiveParentsDataForm';



export default function TLHIndividualParentsEvening() {

  const buttonStyle = {
    padding: '10px 20px',
    fontSize: '16px',
    color: 'white',
    backgroundColor: '#0056b3',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    display: 'inline-block',
    margin: '10px 0',
    textDecoration: 'none',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    transition: 'all 0.3s ease',
  };

  const handleMouseOver = (e) => {
    e.target.style.backgroundColor = '#004085';
    e.target.style.boxShadow = '0 8px 16px rgba(0, 0, 0, 0.2)';
  };

  const handleMouseOut = (e) => {
    e.target.style.backgroundColor = '#0056b3';
    e.target.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.1)';
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%', // Adjust the width as needed
    maxHeight: '90vh', // Max height of the modal
    overflowY: 'auto', // Enable vertical scrolling
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const [isLoadingProspectiveParentsEveningInfo, setIsLoadingProspectiveParentsEveningInfo] = useState(true)
  const [prospectiveParentsEveningData, setProspectiveParentsEveningData] = useState()

  const [openAddParentsDataModel, setOpenAddParentsDataModel] = useState(false);

  const { tlhParentsEveningId } = useParams();

  const handleOpenAddParentsDataModel = () => setOpenAddParentsDataModel(true);
  const handleCloseAddParentsDataModel = () => setOpenAddParentsDataModel(false);

  useEffect(() => {
    if (isLoadingProspectiveParentsEveningInfo) {
      getIndividualParentsEveningInfo()
    }
  }, [prospectiveParentsEveningData]);

  function getIndividualParentsEveningInfo() {
    TLHEndpoints.getProspectiveParentsEveningInfo(tlhParentsEveningId).then(result => {
      console.log(result.data)
      // const sortedProspectiveParentsEveningsByDate = sortProspectiveParentsEveningsByDate(result.data)
      // console.log(sortedEnquiriesByCreationDate)
      setProspectiveParentsEveningData(result.data)
      setIsLoadingProspectiveParentsEveningInfo(false)
    })
  }

  function sortProspectiveParentsEveningsByDate(parentsEvenings) {
    return parentsEvenings.sort((a, b) => new Date(b.prospectiveParentsEveningDate) - new Date(a.prospectiveParentsEveningDate));
  }

  function formatFriendlyDate(dateString) {

    if (dateString === "" || dateString === null) {
      return ""
    }

    const date = new Date(dateString);
    
    // Check if the date is valid
    if (isNaN(date)) {
        return "Invalid date";
    }

    // Create a formatter for a friendly date format
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Intl.DateTimeFormat('en-US', options).format(date);
}

function removeNullStringFromName(clientName) {
  if (clientName == null || clientName == undefined) {
    return ""
  }
  return clientName
}

function renderChildNames(studentData) {
  var studentNames = ""
  for (var i = 0; i < studentData.length; i ++) {
    var firstName = removeNullStringFromName(studentData[i].studentFirstName)
    var surname = removeNullStringFromName(studentData[i].studentSurname)
    if (studentNames === "") {
      studentNames = firstName + " " + surname
    } else {
      studentNames = studentNames + ", " + firstName + " " + surname
    }
  }
  return studentNames
}

function formatStringFromEnumForm(status) {
  let formatConversionStatus = status.toLowerCase();
  formatConversionStatus = formatConversionStatus.replace(/_/g, " ");
  formatConversionStatus = formatConversionStatus.charAt(0).toUpperCase() + formatConversionStatus.slice(1);
  return formatConversionStatus;
}

function formatYearGroups(yearGroups) {
  return yearGroups
      .map(yearGroup => {
          // Split the string by underscore, capitalize the first letter of each word, and join them with a space
          return yearGroup.split('_').map(word => word.charAt(0) + word.slice(1).toLowerCase()).join(' ');
      })
      .join(', ');
}




  return (
    
    <div>

      <Typography variant="h4" align='center' style={{padding: "15px"}}>
        Prospective Parents' Evening Date
      </Typography>



      {isLoadingProspectiveParentsEveningInfo ?
        <div className="table-container" style={{textAlign: "center", fontWeight: "bold", fontSize: "25px"}}>
          Loading Prospective Parents Evening Data...
        </div>
        :
      <div>
        <div>
          <Typography variant="h6" align='left' style={{padding: "15px"}}>
            Schools Visited
          </Typography>
        </div>

        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>School</TableCell>
                        <TableCell>Date Visited</TableCell>
                        <TableCell>Number of leaflets distributed</TableCell>
                        <TableCell>Number Converted</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {prospectiveParentsEveningData.schoolsVisited.map(item => (
                        <TableRow key={item.id}>
                            <TableCell>{item.schoolName}</TableCell>
                            <TableCell>{formatFriendlyDate(item.visitDate)}</TableCell>
                            <TableCell>{item.numberOfLeafletsGivenOut}</TableCell>
                            <TableCell>{item.numberOfParentsConverted}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>

        <div>
          <Typography variant="h6" align='left' style={{padding: "15px"}}>
            Parent Details Collected
          </Typography>
        </div>

        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>School</TableCell>
                        <TableCell>Parent name</TableCell>
                        <TableCell>Phone Number</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Student Year(s)</TableCell>
                        <TableCell>Confirmed Attendance</TableCell>
                        <TableCell>Attended</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <>
                    {prospectiveParentsEveningData.schoolsVisited.map(school => (
                      <>
                        {school.collectedProspectiveParentDetails.map(parent => (
                          <TableRow key={parent.prospectiveParentsEveningParentsId}>
                            <TableCell>{school.schoolName}</TableCell>
                            <TableCell>{parent.parentFirstName + " " + parent.parentLastName}</TableCell>
                            <TableCell>{parent.parentPhoneNumber + "/" + parent.alternatePhoneNumber}</TableCell>
                            <TableCell>{parent.parentEmailAddress}</TableCell>
                            <TableCell>{formatYearGroups(parent.studentAges)}</TableCell>
                            <TableCell>No</TableCell>
                            <TableCell>No</TableCell>
                          </TableRow>
                        ))}
                      </>
                    ))}
                    </>
                </TableBody>
            </Table>
        </TableContainer>

        {/* <div>
          <Typography variant="h6" align='left' style={{padding: "15px"}}>
            Add New Parents Data
          </Typography>
        </div> */}


        <div style={{textAlign: 'center', padding: '10px'}}>
          <button
            className="uppercase px-6 py-3 rounded shadow-lg hover:shadow-lg focus:outline mr-1 mb-1"
            onClick={handleOpenAddParentsDataModel}
          >Click to Add New Parents Data</button>
        </div>

      </div>}


      <Modal
        open={openAddParentsDataModel}
        onClose={handleCloseAddParentsDataModel}
      >
        <Box sx={style}>
          <AddNewProspectiveParentsDataForm
            parentsEveningId={tlhParentsEveningId}
          />
        </Box>
      </Modal>
      
    </div>
  );
}

