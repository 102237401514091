import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router";

import * as CONSTANTS from "../../../StringConstants.js"

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Typography } from '@mui/material';


export default function TLHClassesForEachDay({dayOfTheWeek, classesData}) {

  let history = useHistory();

  // console.log(dayOfTheWeek)
  // console.log(classesData)

  const [subjectMatrix, setSubjectMatrix] = useState([])
  const [isCollatingSubjects, setIsCollatingSubjects] = useState(true)

  useEffect(() => {
    if (isCollatingSubjects) {
      // collateSubjects()
      createSubjectStudentMatrix(classesData)
    }
   
  });

  // function collateSubjects() {
  //   var subjectsData = []
  //   console.log(classesData)
  //   for (var i = 0, l = classesData.length; i < l; i++) {
  //     console.log("Adding " + classesData[i].subject)
  //     subjectsData.push(classesData[i].subject)
  //   }
  //   setSubjects(subjectsData)
  //   console.log(subjectsData)
  //   setIsCollatingSubjects(false)
  // }

  function createSubjectStudentMatrix(classList) {
    // Extract unique subjects
    const uniqueSubjects = [...new Set(classList.map(cls => cls.subject))];
    
    // Initialize the result matrix with the header row
    const result = [];
    result.push(uniqueSubjects);
    console.log(result[0])
    // Iterate over each class to build the rows
    classList.forEach(cls => {
      // Initialize the row with the classId
      const row = new Array(uniqueSubjects.length).fill("-");
      
      // Find the index of the class's subject in the uniqueSubjects array
      const subjectIndex = uniqueSubjects.indexOf(cls.subject);
  
      // Set the class size at the correct subject index
      if (subjectIndex !== -1) {
        row[subjectIndex] = cls.classSize;
      }
  
      // Add the row to the result matrix
      result.push(row);
    });
    setIsCollatingSubjects(false)
    setSubjectMatrix(result)
    console.log("Subjects Matrix")
    console.log(result)
  }


  function viewTLHClassDetails(tlhClassId) {
    history.push(CONSTANTS.ViewTLHClass + "/" + `${tlhClassId}`)
  }

  return (
    
    <div>
      {isCollatingSubjects ? <></> :
      <div style={{ overflowX: 'auto', width: '100%'}}>

       <Typography style={{padding: "15px"}}>
          {dayOfTheWeek}
        </Typography>

     
        <Table size="small" style={{tableLayout: 'auto'}}>
          <TableHead >
            <TableRow>
              <TableCell>Class</TableCell>
              <TableCell>Timing</TableCell>
              <TableCell>Room</TableCell>
              <TableCell>Teacher</TableCell>
              {subjectMatrix[0].map((subject) => (
                <TableCell>{subject}</TableCell>
            ))}
            </TableRow>
          </TableHead>
          <TableBody style={{textAlign: "center"}}>
          {classesData.map((classData, classIndex) => (
              <TableRow key={classData.classId}>
                <TableCell>
                  <button 
                    onClick={() => viewTLHClassDetails(classData.classId)}
                  > 
                    <u>{classData.className}</u>
                  </button>
                </TableCell>
                <TableCell>{classData.startTime}</TableCell>
                <TableCell>{classData.roomNumber}</TableCell>
                <TableCell>{classData.teachers}</TableCell>
                {subjectMatrix[classIndex + 1].map((studentCount, cellIndex) => (
                  <TableCell key={cellIndex}>{studentCount}</TableCell>
               ))}
              </TableRow>
            ))} 
          </TableBody>
          
        </Table>
      </div>
    }
    </div>
  );
}

