import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router";
import {useParams} from "react-router-dom";

import "../assets/styles/index.css";

// components

// import CardLineChart from "components/Cards/CardLineChart.js";
// import CardBarChart from "components/Cards/CardBarChart.js";
// import CardPageVisits from "components/Cards/CardPageVisits.js";
// import CardSocialTraffic from "components/Cards/CardSocialTraffic.js";

import * as SUBJECTS from '../components/EducationTiles/TileNames';
import TopicTile from '../components/EducationTiles/TopicTile';
import SubTopicTile from '../components/EducationTiles/SubTopicTile';
import QuestionTile from '../components/EducationTiles/QuestionTile';
import QuestionTileEnglish from '../components/EducationTiles/QuestionTileEnglish';
import QuestionQuantityTile from '../components/EducationTiles/QuestionQuantityTile';
import QuestionsAndUserDataService from '../services/QuestionsAndUserDataService'
import AnswerPage from 'components/EducationTiles/AnswerPage';
import UpgradeToStandardModal from './UpgradeToStandardModal';
import WhizzdomLogo from "../assets/logoVariations/AlternativeLogomark_purple_black_white-01.png"

// Material UI imports

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@mui/material/FormHelperText';
import WorksheetReview from 'components/EducationTiles/WorksheetReview';
import { TextField } from 'formik-material-ui';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TopicTileList from 'components/EducationTiles/TopicTileLists';


// Need to shift values to relative as opposed to hard coded
const useStyles = makeStyles((theme) => ({
  grid: {
    marginTop: 130,
  },

  breadCrumbs: {
    paddingBottom: 20,
  },

  root: {
    display: 'flex',
  },


  title: {
    flexGrow: 1,
  },

  TopicTiles: {
      padding: '100px',
  },

  QuestionNavButtons: {
    padding: '10px',
  }

}));

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  width: '480px',  // <-- Set a fixed width
  maxWidth: '90%', // <-- Ensure it doesn't exceed the viewport width on small screens
};

export default function SubjectTiles({isReviewingWorksheet, toggleIsReviewingWorksheet, updateQuestionInfoForGoingToReview, completedQuestionsInfo, questionToViewFirstInReviewMode, currentSubject}) {
  const classes = useStyles();
  const { stateView } = useParams()
  console.log("Param view being shown:")
  console.log(stateView)
  console.log(questionToViewFirstInReviewMode)
  isReviewingWorksheet = stateView === "questions" ? false : isReviewingWorksheet

  // For the freemium upgrade modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // To render the correct level
  const [level, setLevel] = useState("topics")
  // To pass the correct number of requested questions
  const [questionRequests, setQuestionRequests] = useState([])
  // To track if amount of questions requested is valid
  const [isValidInputForQuestionAmounts, setIsValidInputForQuestionAmounts] = useState(false)
  // Current question number being shown
  const [questionNumber, setQuestionNumber] = useState(0)
  // Total number of questions
  // const [numOfQuestionsInWorksheet, setNumOfQuestionsInWorksheet] = useState(0)

  // Used for showing tiles on each level
  const [currentParentTile, setCurrentParentTile] = useState("")
  const [previousParentTile, setPreviousParentTile] = useState(null)

  // Used for breadcrumb navigation
  const [currentTopic, setCurrentTopic] = useState("topic")
  const [currentSubTopic, setCurrentSubTopic] = useState("sub-topic")
  const [shownLevels, setShownLevels] = useState(['subjects'])

  // To store questions
  const [currentQuestions, setCurrentQuestions] = useState(null)
  // To store user answers
  const [currentUserAnswers, setCurrentUserAnswers] = useState([])
  // Are Analytics being viewed
  // const [isAnalytics, setIsAnalytics] = useState(false)
  const [showWhatWouldYouLikeToPractice, setShowWhatWouldYouLikeToPractice] = useState(true)

  // Bad practise - state variables being used just for comprehension
  const [comprehensionDescription, setComprehensionDescription] = useState("")
  const [comprehensionPassage, setComprehensionPassage] = useState("")

  // Each tile coloured by difficulty
  const [tileColourCodes, setTileColourCode] = useState([])


  let history = useHistory()
  var allRequestedQuantities = []
  var allRequestedQuestionTypes = []
  const [finalTopicsList, setFinalTopicsList] = useState([])
  const [finalTopicsQuantitiesList, setFinalTopicsQuantitiesList] = useState([])

  useEffect( () => {
    //passing getData method to the lifecycle method
    console.log("Checking if we need the colour codes")
    console.log(tileColourCodes.length == 0)
    if (tileColourCodes.length == 0) {
      console.log("Getting the colour codes")
      getColourCodesForTiles()
    }

  }, [])


  function getColourCodesForTiles() {
    QuestionsAndUserDataService.getColourCodesForTiles().then((colourCodes) =>{
      console.log("Retrieved colour codes!")
      console.log(colourCodes.data)
      setTileColourCode(colourCodes.data)
    })
  }


  function populateRelevantTiles() {
    // Reset current user answers if leaving worksheet
    // setCurrentUserAnswers([])
    

    if (level === 'topics') {
      return populateTopicsTiles();
    }
    if (level === 'sub-topics') {
      return populateSubTopicTiles();
    }
    if (level === 'question-choices') {
      return getQuestionTypes();
    }
    if (level === 'questions') {
      console.log("In the generate questions part")
      // setStateUpdate(stateUpdate + 1)
      return generateQuestions();
    }
    if (level === 'answers') {
      // show answers
      console.log("On the answers level")
      return showAnswersPage();
    }
  }

  function getPractiseTestQuestions() {
    setQuestionNumber(0)
    let parent = "Practice Test"
    QuestionsAndUserDataService.getQuestions(currentSubject, parent, "not needed", ["not needed"], [5,3])
        .then(question => {
          console.log("Completing promise to get questions")
          console.log(question.data)
          setCurrentQuestions(question.data)
          setLevel('questions')
          setShowWhatWouldYouLikeToPractice(false)
        });
    setQuestionRequests([])
  }

  function getEnglishComprehension() {
    setQuestionNumber(0)

    QuestionsAndUserDataService.getEnglishComprehension()
      .then(comprehension => {
        if (typeof(comprehension.data) == "number"){
          console.log("We're dealing with numbers")
          console.log(comprehension)
          setCurrentQuestions(comprehension.data)
          setLevel('topics')
          // Regardless we want to show upgrade modal with the only difference being the message - either used up all questions or can only request 'x' more 
          handleOpen()
          return 
        }
        const comprehensionQuestions = comprehension.data.comprehensionQuestions
        const sortedComprehensionQuestions = comprehensionQuestions.sort((a,b) => a.questionNumber > b.questionNumber ? 1 : -1)

        console.log("Got comprehension")
        console.log(comprehension.data)
        setComprehensionDescription(comprehension.data.passageDescription)
        setComprehensionPassage(comprehension.data.passageText)
        setCurrentTopic("Comprehension")
        setCurrentQuestions(sortedComprehensionQuestions)
        setLevel('questions')
        setShowWhatWouldYouLikeToPractice(false)
      })
      setQuestionRequests([])
  }

  function changeToReviewMode(questionsData, questionToGoTo) {
    console.log("Reaching dashboard")
    console.log(questionsData)
    console.log("Question number selected in dashboard page: " + questionToGoTo)

    // toggleAnalyticsDashboard(true)

    // setQuestionsData(questionsData)
    // setQuestionNumber(questionToGoTo)
    // toggleIsReviewingWorksheet(true)
    updateQuestionInfoForGoingToReview(questionsData, questionToGoTo)
  }

  function changeTileLevel(newLevel, currentTitle) {
    // reset user answers when leaving worksheet
    setCurrentUserAnswers([])
    console.log(newLevel)
    console.log(currentTitle)
    console.log("Current level: " + level)

    if (currentTitle === "Practice Test") {
      console.log("Practice test requested")
      getPractiseTestQuestions()
    } else if (currentTitle === "Comprehension") {
      console.log("Comprehension requested")
      getEnglishComprehension()
    } else {
      console.log("Level being changed !!!!!!!!!!!!!!!!!")
      if(newLevel === 'topics') {
        console.log("In topics change")
        setShownLevels(['subjects'])
        setPreviousParentTile(null)
        setQuestionRequests([])
        setLevel(newLevel)
        // if (currentTitle === "Maths" || currentTitle == "Verbal Reasoning") {
        //   changeSubject(currentTitle)
        // }
        console.log("States updated")

      }
      if (newLevel === 'sub-topics') {
        setShownLevels(['subjects','topics'])
        setCurrentTopic(currentTitle)
        setCurrentSubTopic(currentTitle)
        setPreviousParentTile(null)
        setQuestionRequests([])
      }
      if (newLevel === 'question-choices') {
        setShownLevels(['subjects','topics','sub-topics'])
        setCurrentSubTopic(currentTitle)
        setPreviousParentTile(currentParentTile)
      }
      console.log("Setting new level to: " + newLevel)
      setLevel(newLevel)
      setCurrentParentTile(currentTitle)
    }
  }

  
  function populateTopicsTiles() {

    var topics = [];
    if (currentSubject === "Maths") {
      topics = Object.keys(SUBJECTS.MathTopics);
    } 

    if (currentSubject === "Verbal Reasoning") {
      topics = Object.keys(SUBJECTS.VRTopics)
    }

    if (currentSubject === "English") {
      topics = Object.keys(SUBJECTS.EnglishTopics)
    }

    console.log("Topics normally")
    console.log(topics)
    console.log("Tile colour codes normally")
    console.log(tileColourCodes)

    return <>
            <Grid item>
              <TopicTileList
                topics = {topics}
                tileColourCodes={tileColourCodes}
                onTileClick={changeTileLevel}
              />
            </Grid>
          </>;

    // var tiles = [];
    // for (var i = 0; i < topics.length; i++) {
    //     tiles.push
    //     (<Grid item>
    //       <TopicTile
    //         // key={i}
    //         title={topics[i]}
    //         onTileClick={changeTileLevel}
    //         tileColor={getTileColour(topics[i])}
    //       />
    //         </Grid>)
    // }
    // return tiles
  }



  function populateSubTopicTiles() {
    // Either populate sub topic tiles or the actual input text boxes
    // Can check as final layer keys would be a list of numbers as opposed to strings
    // let topics = null;
    // if (currentTopic == "Maths") {
    //   topics = SUBJECTS.MathTopics[currentParentTile] 
    // }
    let hasChildren = false
    if (currentSubject === "Maths") {
       hasChildren = Boolean(!(Object.keys(SUBJECTS.MathTopics[currentParentTile])[0] === '0'));
    }
    if (currentSubject === "Verbal Reasoning") {
      hasChildren = Boolean(!(Object.keys(SUBJECTS.VRTopics[currentParentTile])[0] === '0'));
    }
    if (currentSubject === "English") {
      hasChildren = Boolean(!(Object.keys(SUBJECTS.EnglishTopics[currentParentTile])[0] === '0'));
    }

    let subTopics = [];
    console.log("showing sub topics")

    if (hasChildren) {
      if (currentSubject === "Maths") {
        subTopics = Object.keys(SUBJECTS.MathTopics[currentParentTile]);
      }
     if (currentSubject === "Verbal Reasoning") {
      subTopics = Object.keys(SUBJECTS.VRTopics[currentParentTile]);
     }
     if (currentSubject === "English") {
      subTopics = Object.keys(SUBJECTS.EnglishTopics[currentParentTile]);
     }
    } else {
      setLevel('question-choices');
      console.log("No children here but we have")
      console.log("Topic: " + currentTopic)
      console.log("Subtopic : " + currentSubTopic)
      return getQuestionTypes();
    }
    var tiles = [];
    for (var i = 0; i < subTopics.length; i++) {
        tiles.push(
        <Grid item>
          <SubTopicTile
            key={i}
            title={subTopics[i]}
            className={classes.TopicTiles}
            onTileClick={changeTileLevel}/>
        </Grid>)
    }
    return tiles
  }
  
  function getQuestionTypes() {
    let questionTypes = [];
    if (!previousParentTile) {
      if (currentSubject === "Maths") {
        questionTypes = Object.values(SUBJECTS.MathTopics[currentParentTile])
      }
      if (currentSubject === "Verbal Reasoning") {
        questionTypes = Object.values(SUBJECTS.VRTopics[currentParentTile])
      }
      if (currentSubject === "English") {
        questionTypes = Object.values(SUBJECTS.EnglishTopics[currentParentTile])
      }
    } else {
      if (currentSubject === "Maths") {
        questionTypes = Object.values(SUBJECTS.MathTopics[previousParentTile][currentParentTile]);
      }
      if (currentSubject === "Verbal Reasoning") {
        questionTypes = Object.values(SUBJECTS.VRTopics[previousParentTile][currentParentTile]);
      }
      if (currentSubject === "English") {
        questionTypes = Object.values(SUBJECTS.EnglishTopics[previousParentTile][currentParentTile]);
      }
    }
    console.log("In get question types")
    return <>
        <Grid container direction="column">
          <form noValidate autoComplete="off">
            {getQuestionTypesHelper(questionTypes)}
          </form>

            {isValidInputForQuestionAmounts ? 
              <div></div> : <div><FormHelperText error="true">*Value must be between 0 and 20</FormHelperText></div>}
        </Grid>
        <div className="w-full mx-autp flex md:flex-nowrap flex-wrap md:px-10 px-4">
          <button className="num-selection-buttons" onClick={() => changeTileLevel('topics')}>Back to Topics list</button>
          <button className="num-selection-buttons" onClick={() => getQuestions()}>Get Questions</button>
        </div>

        </>;
  }

  function getQuestionTypesHelper(questions) {
    console.log("In get question types helper")
    var tiles = [];
    for (var i = 0; i < questions.length; i++) {
      tiles.push(
        <Grid item xs={12}>
          <QuestionQuantityTile title={questions[i]} onValueEntered={onValueEntered}/>
        </Grid>
      )}
      return tiles
  }

  function checkQuestionInputsAreValid() {
    console.log("Checking question inputs")
    // iterate through question requests, check if at least one value is greater than zero
    const allRequestedQuantities = Object.values(questionRequests)
    if (allRequestedQuantities.length === 0) {
      console.log("Is false")
      setIsValidInputForQuestionAmounts(false);
    }

    for (let i = 0; i < allRequestedQuantities.length; i++) {
      console.log("Checking: " + allRequestedQuantities[i] )

      if (allRequestedQuantities[i] < 0) {
        setIsValidInputForQuestionAmounts(false)
        // set value here to nothing
        return
      }
    }
    setIsValidInputForQuestionAmounts(true)
    return

  }

  function getQuestions() {
    console.log("In the use effect")
    setQuestionNumber(0)
    console.log(level)
    console.log(Object.keys(questionRequests)[0])
    allRequestedQuestionTypes = Object.keys(questionRequests)
    allRequestedQuantities = Object.values(questionRequests)
    console.log("Set types and quantities:")
    console.log(allRequestedQuantities)
    console.log(allRequestedQuestionTypes)
    setFinalTopicsList(allRequestedQuestionTypes)
    setFinalTopicsQuantitiesList(allRequestedQuantities)


    for (let i = (allRequestedQuantities.length - 1); i >= 0; i--) {
      console.log("Checking: " + allRequestedQuantities[i] )

      if (allRequestedQuantities[i] <= 0 ) {
        allRequestedQuantities.splice(i, 1)
        allRequestedQuestionTypes.splice(i,1)
        console.log("Removed non positive quantity")
      }
    }

    if (allRequestedQuestionTypes.length === 0) {
      console.log("No questions with positive number of selections")
      setIsValidInputForQuestionAmounts(false);
      return
    }


    let questionType = Object.keys(questionRequests)[0]
    let parent = previousParentTile ? previousParentTile : "no prev parent tile";
    console.log(parent)
    console.log("Making get request for questions")
    if (currentSubject == "English") {
      console.log("Getting English Questions")
      QuestionsAndUserDataService.getEnglishQuestions(currentParentTile, allRequestedQuantities)
        .then(question => {
          console.log(question.data)
          setCurrentQuestions(question.data)
          setLevel('questions')
          setShowWhatWouldYouLikeToPractice(false)
        })
    } else {
    QuestionsAndUserDataService.getQuestions(currentSubject, parent, currentParentTile, allRequestedQuestionTypes, allRequestedQuantities)
        .then(question => {
          console.log("Completing promise to get questions")
          console.log(question.data)
          setCurrentQuestions(question.data)
          setLevel('questions')
          setShowWhatWouldYouLikeToPractice(false)
        }, (error) => {
          console.log("Error")
          console.log(error)
          console.log(error.headers)
      });
      }
      console.log("Completed get request")
      console.log(currentQuestions)
     setQuestionRequests([])
    }


  // Questions already generated and stored in state by this point
  // function is rendering each question one by one.
  // questionNumbers refers to the number of the current question the user is on
  function generateQuestions() {
    //  Want option to check answer? or maybe check answers at end of worksheet.
    console.log("Question being retrieved from state")
    console.log(currentQuestions)
    console.log(currentQuestions[questionNumber])

    console.log(typeof(currentQuestions))
    if (typeof(currentQuestions) == "number"){
      console.log("We're dealing with numbers")
      setLevel('topics')
      // Regardless we want to show upgrade modal with the only difference being the message - either used up all questions or can only request 'x' more 
      handleOpen()
      return 
    }

    if (currentSubject == "English") {
      console.log("Returning English tile")
      console.log(currentQuestions[questionNumber].multipleChoiceAnswerOptions)
      
      const multipleChoiceAnswerOptions = currentQuestions[questionNumber].multipleChoiceAnswerOptions.split("||")

      // Needed for this as the backend stores the comprehension question text attribute under a different variable name to grammer questions
      let currentQuestion 
        = currentTopic === "Comprehension" 
              ? 
              currentQuestions[questionNumber].questionText 
              : 
              currentQuestions[questionNumber].englishGrammarQuestionText




    // return <Grid container spacing={3}>
    //       {/* <Title>hI</Title> */}
    //         <Grid item xs={12}>
    //           <QuestionTileEnglish
    //             questionNumber={questionNumber}
    //             question={currentQuestion}
    //             answer={currentQuestions[questionNumber].correctAnswer}
    //             updateUserAnswer={updateUserAnswers}
    //             currentUserAnswer={currentUserAnswers[questionNumber]}
    //             multipleChoiceOptions={multipleChoiceAnswerOptions}
    //             grammarQuestionType={currentQuestions[questionNumber].grammarQuestionType}
    //             comprehensionDescription={currentQuestions.comprehensionDescription}
    //             comprehensionPassage={currentQuestions.passageText}
    //           />
    //         </Grid>
    //         <Grid item xs={12}>
    //           {getNextAndPrevButtons(currentQuestions.length)}
    //         </Grid>
    //       </Grid>;

    console.log("Comprehension Passage")

      return <Grid container spacing={3}>
            {/* <Title>hI</Title> */}
              <Grid item xs={12}>
                <QuestionTileEnglish
                  questionNumber={questionNumber}
                  question={currentQuestion}
                  answer={currentQuestions[questionNumber].correctAnswer}
                  updateUserAnswer={updateUserAnswers}
                  currentUserAnswer={currentUserAnswers[questionNumber]}
                  multipleChoiceOptions={multipleChoiceAnswerOptions}
                  grammarQuestionType={currentQuestions[questionNumber].grammarQuestionType}
                  comprehensionDescription={comprehensionDescription}
                  comprehensionPassage={comprehensionPassage}
                />
              </Grid>
              <Grid item xs={12}>
                {getNextAndPrevButtons(currentQuestions.length)}
              </Grid>
            </Grid>;
    }

return <Grid container spacing={3}>
            {/* <Title>hI</Title> */}
              <Grid item xs={12}>
                <QuestionTile
                  questionNumber={questionNumber}
                  question={currentQuestions[questionNumber].question}
                  answer={currentQuestions[questionNumber].answer}
                  updateUserAnswer={updateUserAnswers}
                  currentUserAnswer={currentUserAnswers[questionNumber]}
                />
              </Grid>
              <Grid item xs={12}>
                {getNextAndPrevButtons(currentQuestions.length)}
              </Grid>
            </Grid>;
  }

  // Store the question number in state to trigger re-render on question change (and hence show new question)
  function getNextAndPrevButtons(numberOfQuestions) {
    if ((questionNumber < (numberOfQuestions-1)) && questionNumber >= 1) {
      return <Grid container direction="row"  spacing={10}>
        <Grid className={classes.QuestionNavButtons} justify="space-between" item xs={5}>
          <Button variant="contained" onClick={() => setQuestionNumber(questionNumber-1)}>Prev</Button>
        </Grid>
        {/* <Grid item xs={6}><div></div></Grid> */}
        <Grid className={classes.QuestionNavButtons} justify="space-between" item xs={5}>
          <Button variant="contained" onClick={() => setQuestionNumber(questionNumber+1)}>Submit</Button>
        </Grid>
      </Grid>;
    }

    if (questionNumber >= 1) {
      return <Grid container direction="row"  spacing={10}>
      <Grid className={classes.QuestionNavButtons} justify="space-between" item xs={5}>
        <Button variant="contained" onClick={() => setQuestionNumber(questionNumber-1)}>Prev</Button>
      </Grid>
      <Grid className={classes.QuestionNavButtons} justify="space-between" item xs={5}>
        <Button variant="contained" onClick={() => setLevel("answers")}>View Answers</Button>
      </Grid>
    </Grid>;
    }

    if (numberOfQuestions === 1) {
      return <Grid container direction="row"  spacing={10}>
      <Grid className={classes.QuestionNavButtons} justify="space-between" item xs={5}>
        <Button variant="contained" onClick={() => setLevel("answers")}>View Answer</Button>
      </Grid>
    </Grid>;
    }

    if (questionNumber < (numberOfQuestions-1)) {
      return <Button variant="contained" onClick={() => setQuestionNumber(questionNumber+1)}>Submit</Button>;
    }
  }

  function showAnswersPage() {
    // let correctAnswers = ["firstAnswer", "secondAnswer"]
    // Need to send list of topics in worksheet with matching list of numbers of each questiontype
    // We store all requested question types and all requested quantities
    console.log(currentQuestions) 
    let correctAnswers = currentQuestions.map((question) => question.answer)
    let worksheetQuestions = currentQuestions.map((question) => question.question)
    let worksheetSolutions = currentQuestions.map((question) => question.solution)
    let worksheetQuestionsId = currentQuestions.map((question) => question.questionId)

    let userAnswers = new Array(correctAnswers.length)
    let parent = previousParentTile ? previousParentTile : "no prev parent tile";
    let multipleChoiceAnswerOptions = null
    let subTopicIfItExists = currentParentTile

    let finalTopicsListToBeSent = finalTopicsList
    let finalTopicsQuantitiesListToBeSent = finalTopicsQuantitiesList

    // Any other data for specific topics - so far only need to store the comprehension passage
    let extraWorksheetData = {}
    var englishQuestionTopic = ""

    for (let i = 0; i < correctAnswers.length; i++) {
      userAnswers[i] = (currentUserAnswers[i] == null) ? "" : currentUserAnswers[i]
    } 

    if (currentSubject == 'English') {
      multipleChoiceAnswerOptions = currentQuestions.map((question) => question.multipleChoiceAnswerOptions.split("||"))
      correctAnswers = currentQuestions.map((question) => question.correctAnswer)
      worksheetQuestions = currentQuestions.map((question) => question.englishGrammarQuestionText)
      worksheetQuestionsId = currentQuestions.map((question) => question.questionId)
      // Solutions are sent as a list of steps from backend for maths - to adapt, need to convert 
      // correct sentence string to a list of one item so its read as a string array as opposed to char array
      worksheetSolutions = currentQuestions.map((question) => [question.correctSentence]) 

      if (currentQuestions != null || currentQuestions != "undefined") {
        englishQuestionTopic = currentQuestions[0].grammarQuestionType
        if (englishQuestionTopic === "COMPREHENSION_QUESTION") {
          worksheetQuestions = currentQuestions.map((question) => question.questionText)
          extraWorksheetData = getFormattedExtraWorksheetDataHelperForComprehensions()
          finalTopicsListToBeSent = ["English Comprehension"]
          finalTopicsQuantitiesListToBeSent = [correctAnswers.length.toString()]
          parent = "no prev parent tile"
          subTopicIfItExists = "English Comprehension"
        }
      }
    }

   

    console.log("On answers page")
    console.log("Top level: " + parent)
    console.log("Current parent tile: " + currentParentTile)
    console.log("We have " + finalTopicsListToBeSent.length + " question types: ")
    console.log(finalTopicsListToBeSent)
    console.log("All requested question quantities: " + finalTopicsQuantitiesListToBeSent)
    console.log(currentQuestions)
    console.log(correctAnswers)
    console.log(userAnswers)
    console.log("Multiple choice options")
    console.log(multipleChoiceAnswerOptions)

// final topics list isn't updated for comprehensions so need to check if current subject is english
    return <AnswerPage 
              userAnswers={userAnswers} 
              correctAnswers={correctAnswers} 
              parentTile={parent} 
              subTopicIfItExists={subTopicIfItExists}
              topicsList={finalTopicsListToBeSent}
              topicsQuantitiesList={finalTopicsQuantitiesListToBeSent}
              worksheetQuestions={worksheetQuestions}
              switchToReviewMode={changeToReviewMode}
              worksheetSolutions={worksheetSolutions}
              currentSubject={currentSubject}
              multipleChoiceAnswerOptions={multipleChoiceAnswerOptions}
              worksheetQuestionsId={worksheetQuestionsId}
              extraWorksheetData={extraWorksheetData}
            />
  }


  function getFormattedExtraWorksheetDataHelperForComprehensions() {
    return {comprehensionDescription, comprehensionPassage}
  }

  function showWorksheetInReviewMode() {
    console.log("Question Data: ")
    console.log(completedQuestionsInfo)
    
    return (
      <WorksheetReview
        questionsData={completedQuestionsInfo}
        questionToGoTo={questionToViewFirstInReviewMode}
        backToDashboard={backToDashboardFromReviewMode}
        backToAnalyticsBoard={backToAnalyticsAreaFromReviewMode}
      />
    )
  }

  function backToDashboardFromReviewMode() {
    toggleIsReviewingWorksheet(false)
    // toggleAnalyticsDashboard(false)
  }

  function backToAnalyticsAreaFromReviewMode() {
    history.push("/app/analytics")
  }

  function updateUserAnswers(questionNumber, answer) {
    console.log("Updating user answer details")
    console.log(questionNumber)
    console.log(answer)
    // currentUserAnswers[questionNumber] = answer
    let copy = currentUserAnswers
    copy[questionNumber] = answer
    setCurrentUserAnswers(copy)
    console.log("Current user answers: " + currentUserAnswers)
  }

  function onValueEntered(question, amount) {
    checkQuestionInputsAreValid()
    if (amount <= 0 || amount > 20) {
      setIsValidInputForQuestionAmounts(false)
      questionRequests[question] = null
      console.log("Value must between 0 and 20")
      return
    }
    console.log("Would add " + amount + " for " + question)
    questionRequests[question] = amount
    console.log(questionRequests);
    // allRequestedQuestionTypes = Object.keys(questionRequests)
    // allRequestedQuantities = Object.values(questionRequests)
  }

  // First param indicates last part of the trail, second param is the current level
  function generateSpecificBreadCrumb(levelLabel, breadCrumbLevel) {
    if (levelLabel) {
      if (level === breadCrumbLevel) {
        console.log("On the tail")
        console.log(level)
        console.log(levelLabel)
        return <Typography color="textPrimary">{levelLabel}</Typography>
      }
      console.log(shownLevels)
      if (shownLevels.includes(breadCrumbLevel)) {
        console.log("In checking shown levels")
        return(<Link color="inherit" onClick={() => changeTileLevel(breadCrumbLevel, levelLabel)}>
                  {levelLabel}
              </Link>);
      }
    }
  }

// Get clicked elems to show when clicked
// Keep parents in the list
// Checks each level and see if it should be rendered
function generateBreadcrumbs() {
  console.log("In generate bread crumbs")
  console.log(level)
  return (<Breadcrumbs separator="›" aria-label="breadcrumb">
  {generateSpecificBreadCrumb(currentSubject, 'topics')}
  {generateSpecificBreadCrumb(currentTopic, 'sub-topics')}
  {generateSpecificBreadCrumb(currentSubTopic, 'question-choices')}
</Breadcrumbs>);
}

// function toggleAnalyticsDashboardHelper(toggle) {
//   console.log("Firing analytics helper function")
//   toggleAnalyticsDashboard(false)
//   setIsAnalytics(false)
//   toggleAnalyticsDashboard(toggle)
// }


  return (
    <div>
  
      {  
        isReviewingWorksheet ? showWorksheetInReviewMode() 
      // <div>
      //   <Analytics 
      //     toggleAnalyticsDashboardHelper={toggleAnalyticsDashboardHelper}
      //     switchToReviewMode={changeToReviewMode}
      //   /> 
      // </div>)
      :
      <div>
        {showWhatWouldYouLikeToPractice ? 
        <Typography style={{textAlign: "center"}} variant="h6">What would you like to practice?</Typography> : <></>
        }
        <div className={classes.breadCrumbs}>
          {generateBreadcrumbs()}
        </div>

        <Grid container spacing={2}>
          {populateRelevantTiles()}
        </Grid>
        <div className="button-case">
          { (level === 'topics' || level === 'question-choices' || level === 'subject') ? 
          <></> : 
          <button 
            className="toTopicsList" 
            onClick={() => changeTileLevel('topics')}
          > 
            Back to Topics list
          </button>}
        </div>
      </div> }
      <Modal
          open={open}
          onClose={handleClose}
          className="landing-page-upcoming-mock-exams-modal"
        >
          <Box sx={style}>
            <UpgradeToStandardModal WhizzdomLogo={WhizzdomLogo} questionsLeft={currentQuestions}/>
          </Box>
        </Modal>
    </div>
  );
}
