import React from 'react';
// import { useHistory } from "react-router";

import IndividualQuestionInTeacherWorksheet from './IndividualQuestionInTeacherWorksheet.js';

// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 400,
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   p: 4,
// };


export default function NewTeacherWorksheet({questionsData}) {
  // let history = useHistory();
  
  function showQuestions() {
    var questions = []
    for (let i = 0; i < questionsData.length; i++) {
      questions.push(
        <IndividualQuestionInTeacherWorksheet 
          questionNumber={i+1}
          question={questionsData[i].question}
          answer={questionsData[i].answer}
        />)
    }
    return questions
  }

  return (
    <div>
      {showQuestions}

        
    </div>
  );
}
