import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useHistory } from "react-router-dom";


import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import QuestionsAndUserDataService from '../../services/QuestionsAndUserDataService'


export default function ReportQuestionModal({questionNumber, question, answer, userAnswer, isCorrect, solution, multipleChoiceOptions, setQuestionFlagAndCloseModal}) {
  
  const [isTypoBoxChecked, setIsTypoBoxChecked] = useState(false)
  const [isMarkingIssueBoxChecked, setIsMarkingIssueBoxChecked] = useState(false)
  const [isHardQuestionBoxChecked, setIsHardQuestionBoxChecked] = useState(false)

  console.log("Multiple choice options are:")
  console.log(multipleChoiceOptions)

  const handleTypoChange = (event) => {
    setIsTypoBoxChecked(event.target.checked);
  }

  const handleMarkingIssueBoxChange = (event) => {
    setIsMarkingIssueBoxChecked(event.target.checked);
  }

  const handleHardQuestionBoxChange = (event) => {
    setIsHardQuestionBoxChecked(event.target.checked);
  }

  const validationSchema = yup.object({
    Comments: yup
      .string('Enter your name'),
  });


  // const WithMaterialUI = () => {
    const formik = useFormik({
      initialValues: {
        Comments: '',
        customCheckLogin: false,
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {

        console.log("formik values")
        console.log(isTypoBoxChecked)
        console.log(isMarkingIssueBoxChecked)
        console.log(isHardQuestionBoxChecked)
        console.log(formik.values.Comments)

        // QuestionsAndUserDataService.createTeacher(formik.values.firstName, formik.values.lastName, formik.values.email, title, isHeadTeacher, schoolId).then(history.push((isHeadTeacher ? "/app/admin-whizz" : "/teacher/headteacher")))
        // updateUserDetails(formik.values.firstName, formik.values.lastName, formik.values.email).then(onStepChange(2))
        QuestionsAndUserDataService.flagQuestion(questionNumber, question, answer, userAnswer, isCorrect, solution, multipleChoiceOptions, isTypoBoxChecked, isMarkingIssueBoxChecked, isHardQuestionBoxChecked, formik.values.Comments).then(
          setQuestionFlagAndCloseModal()
        )

      },
    });

  return (
    
    <div>
     <form
        onSubmit={formik.handleSubmit}
        >
          <div style={{paddingBottom: "3%", textAlign: "center"}}>
            <label>What would you like to report?</label>
         </div>
        <div className="relative w-full mb-3">

          <div style={{padding: "3%"}}>
            <FormControlLabel
              id="typo"
              control={<Checkbox checked={isTypoBoxChecked} onChange={handleTypoChange} style={{ marginRight: "10px" }}/>}
              label="The question has a typo."
              labelPlacement="end"
            />
          </div>

          <div style={{padding: "3%"}}>
            <FormControlLabel
              id="markingIssue"
              control={<Checkbox checked={isMarkingIssueBoxChecked} onChange={handleMarkingIssueBoxChange} style={{ marginRight: "10px" }}/>}
              label="My answer should be correct."
              labelPlacement="end"
            />
          </div>

          <div style={{padding: "3%"}}>
            <FormControlLabel
              id="hardQuestion"
              control={<Checkbox checked={isHardQuestionBoxChecked} onChange={handleHardQuestionBoxChange} style={{ marginRight: "10px" }}/>}
              label="The question is too hard."
              labelPlacement="end"
            />
          </div>

          <TextField
              fullWidth
              id="Comments"
              name="Comments"
              label="Any other comments..."
              value={formik.values.Comments}
              onChange={formik.handleChange}
            />

        </div>
      
        <div className="text-center mt-6">
          <Button
            colour="primary"
            className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
            type="submit"
          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
}
