import React, { useState } from "react";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useLocation } from 'react-router-dom';

// Mui imports

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Card from '@mui/material/Card';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { pink } from '@mui/material/colors';
import { Checkbox } from '@mui/material';

// Component imports 

import QuestionsAndUserDataService from 'services/QuestionsAndUserDataService';

export default function RegistrationStepOne({handleOpen}) {

  // const [privacyPolicySelection, setPrivacyPolicySelection] = useState(false)
  const [selectedValue, setSelectedValue] = useState('1');
  const location = useLocation();

  // Use a URLSearchParams object to parse the query string
  const queryParams = new URLSearchParams(location.search);
  const fbAdId = queryParams.get('fbAdId');
  const fbAdName = queryParams.get('fbAdName');

  const phoneRegExp = /^(?=(?:\D*\d){7,15}$)(\+[1-9]{1,4}[ \-]*)?(\([0-9]{2,3}\)[ \-]*)?([0-9]{1,4}[ \-]*)*?[0-9]{1,4}?[ \-]*[0-9]{1,4}?$/

  const validationSchema = yup.object({
    firstName: yup
      .string('Enter your name')
      .required('Field is required'),
    lastName: yup
      .string('Enter your name')
      .required('Field is required'),
    email: yup
      .string('Enter your email')
      .email('Enter a valid email')
      .required('Email is required'),
    childName: yup
      .string('Enter your child\'s name'),
    phoneNumber: yup
      .string()
      .matches(phoneRegExp, 'Please enter a valid phone number')
      .required(),
    acceptTerms: yup
      .bool()
      .test('acceptTerms', "Please agree to the Terms and Conditions", function(value){
        return this.parent.acceptTerms === true
     })
     .required("Please agree to the Terms and Conditions")
  });


  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      childName: '',
      email: '',
      phoneNumber: '',
      acceptTerms: false,
      customCheckLogin: false,
    },
    // validationSchema: validationSchema,
    onSubmit: (values) => {

      console.log("Mock Exam registration info")
      console.log("formik values")
      console.log(formik.values.firstName)
      console.log(formik.values.lastName)
      console.log(formik.values.email)
      console.log(selectedValue)
      if (formik.values.acceptTerms) {
        QuestionsAndUserDataService.createFreeMockExamUserAndSendLoginInstructions(formik.values.firstName, formik.values.lastName, formik.values.childName, formik.values.email, formik.values.phoneNumber, fbAdId, fbAdName)
      }
      // updateUserDetails(formik.values.firstName, formik.values.lastName, formik.values.email).then(onStepChange(2))
      handleOpen()

    },
  });

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: 'color-radio-button-demo',
    inputProps: { 'aria-label': item },
  });

  return (
    <div>

      <Card className="p-4" sx={{border: 15, borderColor: '#F6AE2D'}}>
        <Typography className="p-4" variant="h5">Book your child's spot</Typography>

          <form
            onSubmit={formik.handleSubmit}
            >
            <label>Parent's details</label>

            <div className="relative w-full mb-3">

            <TextField
                fullWidth
                id="firstName"
                name="firstName"
                label="First Name*"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                helperText={formik.touched.firstName && formik.errors.firstName}
              />

            </div>

            <div className="relative w-full mb-3">

              <TextField
                  fullWidth
                  id="lastName"
                  name="lastName"
                  label="Last Name*"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                  helperText={formik.touched.lastName && formik.errors.lastName}
                />

              </div>

              <div className="relative w-full mb-3">

              <TextField
                  fullWidth
                  id="childName"
                  name="childName"
                  label="Child's Name*"
                  value={formik.values.childName}
                  onChange={formik.handleChange}
                  error={formik.touched.childName && Boolean(formik.errors.childName)}
                  helperText={formik.touched.childName && formik.errors.childName}
                />

              </div>



            <div className="relative w-full mb-3">
            <TextField
              fullWidth
              id="email"
              name="email"
              label="Email*"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            </div>


            <div className="relative w-full mb-3">
            <TextField
              fullWidth
              id="phoneNumber"
              name="phoneNumber"
              label="Telephone Number*"
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
              helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
            />
            </div>

            <FormControl style={{textAlign: "left", marginLeft: "-30px"}}>
              {/* <FormLabel id="demo-radio-buttons-group-label">Product</FormLabel> */}
              {/* <RadioGroup>
                <FormControlLabel
                  control={<Radio {...controlProps("123")} 
                              sx={{
                              color: pink[800],
                              '&.Mui-checked': {
                                color: pink[600],
                              },
                }}/>} label="Book both (£35) and SAVE £15" />
                <FormControlLabel control={<Radio {...controlProps('1')}/>} label="Book 11th September (£25)" />
                <FormControlLabel control={<Radio {...controlProps('2')}/>} label="Book 18th September (£25)" />
              </RadioGroup> */}
            </FormControl>

            <div>
            <FormControlLabel
              id="acceptTerms"
              control={<Checkbox checked={formik.values.acceptTerms} />}
              name="acceptTerms"
              label=""
              onChange={formik.handleChange}
              
            />
            I agree to the 
                <a 
                  href="https://www.11plusmock.co.uk/Whizzdom/PrivacyDocuments/WhizzdomTermsAndConditions.html"
                  target="_blank"
                > <u>
                  terms and conditions
                  </u>
                </a>
                  <div className="form-field-error" style={{color: 'red'}}>{formik.touched.acceptTerms && formik.errors.acceptTerms}</div> 

          </div>

            <div>
              <button
                // colour="primary"
                // style={{ color: "white"}}
                // className="active:bg-blueGray-600 rounded shadow hover:shadow-lg w-full ease-linear transition-all duration-150"
                // className="eleven-plus-book-now-button"
                className="eleven-plus-book-now-button"
                type="submit"
              >
                Register Now
              </button>
            </div>
          </form>
      </Card>
      </div>
  );
};
