import React from "react";
import { Switch, Redirect } from "react-router-dom";

import "../assets/styles/mock-exams.css"

// components

import UserNavbar from "components/Navbars/UserNavbar.js";
import TeacherSidebar from "components/Sidebar/TeacherSidebar";
import HeaderStats from "components/Headers/HeaderStats.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

// views

import ProtectedRoute from "views/auth/ProtectedRoute";
import WhiteWhizzdomLogo from "../assets/logoVariations/Primarylogomark_purple_black_white-03.png"
import MockExamUserDashboard from "views/mockExams/MockExamUserDashboard.js"
import MockExamUserPastExams from "views/mockExams/MockExamUserPastExams";
import MockExamUserUpcomingExams from "views/mockExams/MockExamUserUpcomingExams";
import MockExamStartPage from "views/mockExams/MockExamStartPage";
import MockExamBreakPage from "views/mockExams/MockExamBreakPage";
import MockExamSessionCompletePage from "views/mockExams/MockExamSessionCompletePage";
import MockExamUserPractiseExam from "views/mockExams/MockExamUserPractiseExam";
import MockExamDemoTest from "views/mockExams/MockExamDemoPage";
import MockExamDemoCompletePage from "views/mockExams/MockExamDemoCompletePage";
import ReviewMockExamPaper from "views/mockExams/ReviewMockExamPaper";

// Routes
import * as CONSTANTS from "../StringConstants.js"
import ProtectedMockExamRoute from "views/mockExams/ProtectedMockExamRoute";
import MockExamPage from "views/mockExams/MockExamPage";


export default function MockExamUser() {
  return (
    <>
    <div>

    {/* <TeacherSidebar/> */}
    {/* <UserNavbar/> */}
    {/* <HeaderStats /> */}
    {/* <Grid container style={{color:"white"}}
    >
      <Grid item style={{width: "100%"}} xs={12} md={12} sm={12}>
        <Box
            sx={{
              maxHeight: { xs: 70, md: 70 },
              // width: { xs: "100px", md: 70 },
              backgroundColor: "purple",
            }}
        >
          <Box
              component="img"
              sx={{
                // height: 233,
                // width: 350,
                maxHeight: { xs: 70, md: 70 },
                maxWidth: "100%",
              }}
              alt="11plus logo."
              src={WhiteWhizzdomLogo}
            />
          </Box>
      </Grid>
    </Grid> */}

          {/* <div
            className="absolute top-0 w-full h-full bg-blueGray-800 bg-no-repeat bg-full"
            style={{
              backgroundImage:
                "url(" + require("assets/img/register_bg_2.png").default + ")",
            }}
          ></div> */}
          <Switch>
            {/* <Route path="/teacher" exact component={teacherDashboard} /> */}
            <ProtectedMockExamRoute path={CONSTANTS.MockExamDashboard} exact component={MockExamUserDashboard} />
            <ProtectedMockExamRoute path={CONSTANTS.MockExamPastExams} exact component={MockExamUserPastExams} />
            <ProtectedMockExamRoute path={CONSTANTS.MockExamUpcomingExams} exact component={MockExamUserUpcomingExams} />
            <ProtectedMockExamRoute path={CONSTANTS.MockExamStartPage} exact component={MockExamStartPage} />
            <ProtectedMockExamRoute path={CONSTANTS.MockExamPage} exact component={MockExamPage} />
            <ProtectedMockExamRoute path={CONSTANTS.MockExamBreakPage} exact component={MockExamBreakPage} />
            <ProtectedMockExamRoute path={CONSTANTS.MockExamSessionCompletePage} exact component={MockExamSessionCompletePage} />
            <ProtectedMockExamRoute path={CONSTANTS.MockExamPractisePage} exact component={MockExamUserPractiseExam} />
            <ProtectedMockExamRoute path={CONSTANTS.MockExamDemoTest} exact component={MockExamDemoTest} />
            <ProtectedMockExamRoute path={CONSTANTS.MockExamDemoCompletePage} exact component={MockExamDemoCompletePage} />
            <ProtectedMockExamRoute path={CONSTANTS.ReviewMockExamPaper + "/:paperId"} exact component={ReviewMockExamPaper} />

            <Redirect from={CONSTANTS.MockExamBase} to={CONSTANTS.MockExamDashboard} />
          </Switch>
      </div>
    </>
  );
}
