import React, { useState } from 'react';
import { Switch, Redirect } from "react-router-dom";

// components

import UserNavbar from "components/Navbars/UserNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import HeaderStats from "components/Headers/HeaderStats.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";

//App views

import AdminRoute from "views/admin/AdminView.js"
import Maps from "views/Maps.js";
import Settings from "views/Settings.js";
import Tables from "views/Tables.js";
import ProtectedRoute from "views/auth/ProtectedRoute";
import ProtectedAdminRoute from 'views/admin/ProtectedAdminRoute.js';
import SubjectTiles from 'views/SubjectTiles';
import Analytics from 'views/Analytics';

// Admin Views

import ViewMockExamsInSet from 'views/admin/AdminComponents/ViewMockExamsInSet.js';
import MockExamDashboard from 'views/admin/MockExamDashboard.js';
import MockExamSessionsDashboard from 'views/admin/MockExamSessionsDashboard.js';
import ViewSectionsInMockExamSet from 'views/admin/AdminComponents/ViewSectionsInMockExamSet.js';
import ViewQuestionsInMockExamSection from "views/admin/AdminComponents/ViewQuestionsInMockExamSection"
import AddEnglishQuestion from 'views/admin/AddingQuestions/AddEnglishQuestion'
import AddGrammarQuestion from 'views/admin/AddingQuestions/AddGrammarQuestion.js';
import AddComprehensionQuestion from 'views/admin/AddingQuestions/AddComprehensionQuestion.js';
import ViewAndManageAllUsersDashboard from 'views/admin/ViewAndManageAllUsersDashboard.js';
import MockExamIndividualSessionUsers from 'views/admin/MockExamIndividualSessionUsers.js';

// string constants

import * as CONSTANTS from "../StringConstants.js"

export default function User() {

  // const [isOnAnalyticsDashboard, setIsOnAnalyticsDashboard] = useState(false)
  const [isReviewingWorksheet, setIsReviewingWorksheet] = useState(false)
  const [questionsData, setQuestionsData] = useState([])
  const [questionNumber, setQuestionNumber] = useState(0)

  // Questions data consists of the following for each question:
  // correct answer, question, questionAnsweredCorrectly, questionId, questionNumber, userAnswer, worksheet (this is null)
  function goToReviewModeFromCompletingWorksheet(questionsData, questionNumberToGoTo) {
    setQuestionsData(questionsData)
    setQuestionNumber(questionNumberToGoTo)
    // setIsOnAnalyticsDashboard(true)
    setIsReviewingWorksheet(true)
    console.log("Question info: ")
    console.log(questionsData)
  }

  // function toggleAnalyticsDashboard(showDashboard) {
  //   console.log("Switching to analytics dashboard")
  //   console.log("Changing to " + showDashboard)
  //   setIsOnAnalyticsDashboard(showDashboard)
  
  // }

  function toggleReviewWorksheetMode(reviewWorksheetMode) {
    console.log("Switching to analytics dashboard")
    console.log("Changing to " + reviewWorksheetMode)
    setIsReviewingWorksheet(reviewWorksheetMode)
  
  }

  function isEntryEmail() {
    const admin = JSON.parse(localStorage.getItem('user'));
    let email = admin.email;
    console.log(email)
    return email.toLowerCase() === "entry@whizzdom.co.uk"
  }

  return (
    
    <>
      {isEntryEmail() ?  <></> : <Sidebar 
        // toggleAnalyticsDashboard={toggleAnalyticsDashboard}
      />
    }

      <div className={isEntryEmail() ? "" : "relative md:ml-64"}>
        <UserNavbar 
          // switchToAnalyticsDashboard={toggleAnalyticsDashboard}
        />
        {/* Header */}
        <HeaderStats />
        {/* p = padding, m = margin, x sets left and right, t sets top and bottom. See https://getbootstrap.com/docs/4.0/utilities/spacing/ for more  */}
        <div className="p-4 m-4">
          <Switch>
            {/* render={(props) => <PropsPage {...props} title={`Props through render`} />} */}
            <ProtectedAdminRoute path={CONSTANTS.AdminRoute} exact component={AdminRoute} />
            <ProtectedAdminRoute path={CONSTANTS.AdminMockExamDashboardRoute} exact component={MockExamDashboard} />
            <ProtectedAdminRoute path={CONSTANTS.AdminMockExamPapersRoute + "/:mockExamSetId"} exact component={ViewMockExamsInSet} />
            <ProtectedAdminRoute path={CONSTANTS.AdminMockExamSectionsRoute + "/:mockExamId"} exact component={ViewSectionsInMockExamSet} />
            <ProtectedAdminRoute path={CONSTANTS.AdminMockExamSectionQuestionsRoute + "/:mockExamSectionId"} exact component={ViewQuestionsInMockExamSection} />
            <ProtectedAdminRoute path={CONSTANTS.AdminAddEnglishQuestion} exact component={AddEnglishQuestion} />
            <ProtectedAdminRoute path={CONSTANTS.AdminAddGrammarQuestion} exact component={AddGrammarQuestion} />
            <ProtectedAdminRoute path={CONSTANTS.AdminAddComprehensionQuestion} exact component={AddComprehensionQuestion} />
            <ProtectedAdminRoute path={CONSTANTS.AdminMockExamSessionsView} exact component={MockExamSessionsDashboard} />
            <ProtectedAdminRoute path={CONSTANTS.AdminViewAllUsers} exact component={ViewAndManageAllUsersDashboard} />
            <ProtectedAdminRoute path={CONSTANTS.AdminViewMockExamSessionUsers + "/:mockExamSessionId"} exact component={MockExamIndividualSessionUsers} />


            <ProtectedRoute path={CONSTANTS.DashboardRoute} exact component={Maps} />
            <ProtectedRoute path={CONSTANTS.MathTopicsRoute + "/:stateView?"} exact component={() => 
              <SubjectTiles
                isReviewingWorksheet={isReviewingWorksheet}
                toggleIsReviewingWorksheet={toggleReviewWorksheetMode}
                updateQuestionInfoForGoingToReview={goToReviewModeFromCompletingWorksheet}
                completedQuestionsInfo={questionsData}
                questionToViewFirstInReviewMode={questionNumber}
                currentSubject="Maths"
              />}
            />
            <ProtectedRoute path={CONSTANTS.EnglishTopicsRoute + "/:stateView?"} exact component={() => 
              <SubjectTiles
                isReviewingWorksheet={isReviewingWorksheet}
                toggleIsReviewingWorksheet={toggleReviewWorksheetMode}
                updateQuestionInfoForGoingToReview={goToReviewModeFromCompletingWorksheet}
                completedQuestionsInfo={questionsData}
                questionToViewFirstInReviewMode={questionNumber}
                currentSubject="English"
              />}
            />
              <ProtectedRoute path={CONSTANTS.VRTopicsRoute + "/:stateView?"} exact component={() => 
              <SubjectTiles
                isReviewingWorksheet={isReviewingWorksheet}
                toggleIsReviewingWorksheet={toggleReviewWorksheetMode}
                updateQuestionInfoForGoingToReview={goToReviewModeFromCompletingWorksheet}
                completedQuestionsInfo={questionsData}
                questionToViewFirstInReviewMode={questionNumber}
                currentSubject="Verbal Reasoning"
              />}
            />
            <ProtectedRoute path="/app/analytics" exact component={() =>
              <Analytics 
                switchToReviewMode={goToReviewModeFromCompletingWorksheet}
                toggleReviewWorksheetMode={toggleReviewWorksheetMode}
              />
          }/>
            <ProtectedRoute path="/app/maps" exact component={Maps} />
            <ProtectedRoute path="/app/settings" exact component={Settings} />
            <ProtectedRoute path="/app/tables" exact component={Tables} />
            <Redirect from="/app" to="/app/math" />
          </Switch>
          <FooterAdmin/>
        </div>
      </div>
    </>
  );
}
