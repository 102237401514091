import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';

import QuestionsAndUserDataService from '../../../services/QuestionsAndUserDataService'

import { Typography } from '@material-ui/core';



export default function IndividualComprehensionQuestionForm({index, updateQuestionsData}) {

  const [questionText, setQuestionText] = useState("")
  const [multipleChoiceAnswerOptions, setMultipleChoiceAnswerOptions] = useState("")
  const [correctAnswer, setCorrectAnswer] = useState("")


  // const validationSchema = yup.object({
  //   questionText: yup
  //     .string('Enter the correct sentence'),
  //   multipleChoiceOptions: yup
  //     .string('Enter the multiple choice options'),
  //   correctAnswer: yup
  //     .string("Which is the correct answer option")
  //     .required('Field is required'),
  // });


  // // const WithMaterialUI = () => {
  //   const formik = useFormik({
  //     initialValues: {
  //       questionText: '',
  //       multipleChoiceOptions: '',
  //       correctAnswer: '',
  //     },
  //     validationSchema: validationSchema,
  //     onSubmit: (values) => {
  //       console.log("Updating index: " + index)
  //       updateQuestionsData(index, formik.values.questionText, formik.values.multipleChoiceOptions, formik.values.correctAnswer)
  //     },
  //   });

  return (
    
    <div>
     {/* <form
        onSubmit={updateQuestionsData(index, formik.values.questionText, formik.values.multipleChoiceOptions, formik.values.correctAnswer)}
        > */}
        <br></br>
        <label><b>New Individual Comprehension Question</b></label>

        <div className="relative w-full mb-3">
        <TextField
            fullWidth
            id="questionText"
            name="questionText"
            label="Question"
            value={questionText}
            onChange={e => setQuestionText(e.target.value)}
            // error={formik.touched.questionText && Boolean(formik.errors.questionText)}
            // helperText={formik.touched.questionText && formik.errors.questionText}
          />
        </div>

        <div className="relative w-full mb-3">
        <TextField
            fullWidth
            id="multipleChoiceOptions"
            name="multipleChoiceOptions"
            label="Answer Options (|| separate options)"
            value={multipleChoiceAnswerOptions}
            onChange={e => setMultipleChoiceAnswerOptions(e.target.value)}
            // error={formik.touched.multipleChoiceOptions && Boolean(formik.errors.multipleChoiceOptions)}
            // helperText={formik.touched.multipleChoiceOptions && formik.errors.multipleChoiceOptions}
          />
        </div>
        
        <div className="relative w-full mb-3">
        <TextField
            fullWidth
            id="correctAnswer"
            name="correctAnswer"
            label="Correct Answer"
            value={correctAnswer}
            onChange={e => setCorrectAnswer(e.target.value)}
            // error={formik.touched.correctAnswer && Boolean(formik.errors.correctAnswer)}
            // helperText={formik.touched.correctAnswer && formik.errors.correctAnswer}
          />
        </div>

        <div className="text-center mt-6">
          <Button
            colour="primary"
            className="px-6 py-3 rounded shadow hover:shadow-lg mr-1 mb-1"
            onClick={() => updateQuestionsData(index, questionText, multipleChoiceAnswerOptions, correctAnswer)}
          >
            Save Question
          </Button>
        </div>
      {/* </form> */}
    </div>
  );
}
