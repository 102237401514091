import React from "react";
import ReactDOM from "react-dom";
import Cookies from 'universal-cookie';

import PromoBarStyle from "../../assets/styles/PromoBar.scss"

export default class PromoBar extends React.Component {
    state = {
      // Test to check if bar is open.
      isOpen: false,
      // 
      timeLeft: null,
      // 
      countdown: null
    };
    // Sets isOpen to false,
    // and sets a cookie
    // closing the promo bar,
    // and keeping open until
    // the browser session
    // is refreshed.
    handleClose = () => {
      
      cookies.set("sPromoBar");
      this.setState({
        isOpen: false
      });
    };
    // When the component renders
    // as long as a cookie hasn't
    // been set, render the promo bar.
    componentDidMount() {
      if (!cookies.get("sPromoBar")) {
        this.setState({
          isOpen: true
        });
        // Set a variable to the end
        // date passed by props.endTime
        const end = new Date(this.props.endTime).getTime();
        this.setState({
          // set the state variable
          // countdown to an interval
          // based on the difference
          // in time between now
          // and the end time prop
          countdown: setInterval(() => {
            const now = new Date().getTime();
            this.setState({
              // pass the difference in
              // time as a state varialble
              /// for the time remaining.
              timeLeft: end - now
            });
            // check for a change in time
            // every second, or 1000 milliseconds
          }, 1000)
        });
      }
    }
    componentWillUnmount() {
      // When the component is ready to
      // be cleared from the DOM,
      // stop the countdown function
      // from checking each second
      clearInterval(this.state.countdown);
    }
    render() {
      // When the promo bar is allowed
      // to be open because isOpen is true,
      // and the timer hasn't reached its
      // end yet, render the markup for
      // the promo bar.
      return this.state.isOpen && this.state.timeLeft > 0 ? (
        <div className="promoWrap">
          <div className="promo container-fluid">
            {/*The anchor element is high in the DOM tree so the whole bar can act as a link, set by the link prop*/}
            <a href={this.props.link} target="_blank" rel="noopener">
              <div className="row">
                <div className="col">
                  <div className="row">
                    <div className="col-sm-auto promoText">
                      <span className="msg">{this.props.message}</span>
                      <Expires expires={this.state.timeLeft} />
                    </div>
                    <PillButton cta={this.props.btnLabel} />
                  </div>
                </div>
              </div>
            </a>
          </div>
          <CloseButton close={this.handleClose} />
        </div>
      ) : null;
    }
  }

  const cookies = new Cookies()
  
  const PillButton = props => (
    <div className="pillWrap col-sm-auto">
      <button className="pill">{props.cta}</button>
    </div>
  );
  
  const CloseButton = props => (
    <button className="closeBtn" aria-label="Close" onClick={props.close}>
      <div className="ecks x1" />
      <div className="ecks x2" />
    </button>
  );
  
  const Expires = props => {
    const padNum = num => {
      return num < 10 ? "0" + num : num;
    };
    const timeString = exp => {
      const days = Math.floor(exp / (1000 * 60 * 60 * 24)),
        hours = Math.floor((exp % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
        minutes = Math.floor((exp % (1000 * 60 * 60)) / (1000 * 60)),
        seconds = Math.floor((exp % (1000 * 60)) / 1000);
      const plural = days > 1 ? "days" : "day";
      const daysStr = days ? " in " + days + " " + plural + " " : "";
      const hoursStr = hours ? padNum(hours) + ":" : "";
      const minsStr = minutes ? padNum(minutes) + ":" : "";
      return daysStr + hoursStr + minsStr + padNum(seconds);
    };
    return props.expires ? (
      <div className="expires">Book your GL Mock Exam with a free 1 to 1 review {timeString(props.expires)}</div>
    ) : null;
  };
  
  const examDate = new Date('August 14, 2022 10:00:00'),
    dynEnd = examDate.getTime();
  
  // const App = (
  //   <PromoBar
  //     message={"Save 25% on Product"}
  //     btnLabel={"Shop now"}
  //     link={"http://google.com"}
  //     endTime={dynEnd}
  //   />
  // );
    