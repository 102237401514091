import React, { useState, useEffect } from 'react';


import { useFormik } from 'formik';

import TextField from '@material-ui/core/TextField';

import { Typography } from '@material-ui/core';

import TLHEndpoints from 'services/TLHEndpoints';

import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Link } from '@mui/material';
import * as CONSTANTS from "../../StringConstants.js"


export default function TLHProspectiveParentsEvenings() {

  const buttonStyle = {
    padding: '10px 20px',
    fontSize: '16px',
    color: 'white',
    backgroundColor: '#0056b3',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    display: 'inline-block',
    margin: '10px 0',
    textDecoration: 'none',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    transition: 'all 0.3s ease',
  };

  const handleMouseOver = (e) => {
    e.target.style.backgroundColor = '#004085';
    e.target.style.boxShadow = '0 8px 16px rgba(0, 0, 0, 0.2)';
  };

  const handleMouseOut = (e) => {
    e.target.style.backgroundColor = '#0056b3';
    e.target.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.1)';
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%', // Adjust the width as needed
    maxHeight: '90vh', // Max height of the modal
    overflowY: 'auto', // Enable vertical scrolling
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const [isLoadingProspectiveParentsEveningInfo, setIsLoadingProspectiveParentsEveningInfo] = useState(true)
  const [prospectiveParentsEveningsData, setProspectiveParentsEveningsData] = useState()

  const [openSuccessfulSubmissionModal, setOpenSuccessfulSubmissionModal] = useState(false);
  const [badSubmissionModal, setBadSubmissionModal] = useState(false);

  function handleCloseSuccessfulSubmissionModal() {
    setOpenSuccessfulSubmissionModal(false);
    formik.resetForm();
  }

  useEffect(() => {
    if (isLoadingProspectiveParentsEveningInfo) {
      getAllProspectiveParentsEvenings()
    }
  }, [prospectiveParentsEveningsData]);

  function getAllProspectiveParentsEvenings() {
    TLHEndpoints.getAllProspectiveParentsEvenings().then(result => {
      console.log(result.data)
      const sortedProspectiveParentsEveningsByDate = sortProspectiveParentsEveningsByDate(result.data)
      // console.log(sortedEnquiriesByCreationDate)
      setProspectiveParentsEveningsData(sortedProspectiveParentsEveningsByDate)
      setIsLoadingProspectiveParentsEveningInfo(false)
    })
  }

  function sortProspectiveParentsEveningsByDate(parentsEvenings) {
    return parentsEvenings.sort((a, b) => new Date(b.prospectiveParentsEveningDate) - new Date(a.prospectiveParentsEveningDate));
  }

  function formatFriendlyDate(dateString) {

    if (dateString === "" || dateString === null) {
      return ""
    }

    const date = new Date(dateString);
    
    // Check if the date is valid
    if (isNaN(date)) {
        return "Invalid date";
    }

    // Create a formatter for a friendly date format
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Intl.DateTimeFormat('en-US', options).format(date);
}

function removeNullStringFromName(clientName) {
  if (clientName == null || clientName == undefined) {
    return ""
  }
  return clientName
}

function renderChildNames(studentData) {
  var studentNames = ""
  for (var i = 0; i < studentData.length; i ++) {
    var firstName = removeNullStringFromName(studentData[i].studentFirstName)
    var surname = removeNullStringFromName(studentData[i].studentSurname)
    if (studentNames === "") {
      studentNames = firstName + " " + surname
    } else {
      studentNames = studentNames + ", " + firstName + " " + surname
    }
  }
  return studentNames
}

function formatStringFromEnumForm(status) {
  let formatConversionStatus = status.toLowerCase();
  formatConversionStatus = formatConversionStatus.replace(/_/g, " ");
  formatConversionStatus = formatConversionStatus.charAt(0).toUpperCase() + formatConversionStatus.slice(1);
  return formatConversionStatus;
}

const formik = useFormik({
  initialValues: {
    parentsMeetingDate: '',
    parentsMeetingTime: '',
  },
  onSubmit: (values) => {
    console.log(values)
    if (values.parentsMeetingDate === '' || values.parentsMeetingTime === '') {
      setBadSubmissionModal(true)
    } else {
      setOpenSuccessfulSubmissionModal(true)
      TLHEndpoints.createProspectiveParentsEvening(values.parentsMeetingDate, values.parentsMeetingTime).then(result => {
        console.log(result)
        setIsLoadingProspectiveParentsEveningInfo(true)
        getAllProspectiveParentsEvenings()
        // const sortedProspectiveParentsEveningsByDate = sortProspectiveParentsEveningsByDate(result.data)
        // // console.log(sortedEnquiriesByCreationDate)
        // setProspectiveParentsEveningsData(sortedProspectiveParentsEveningsByDate)
        // setIsLoadingProspectiveParentsEveningInfo(false)
      })
    }
    
  },
  
});


  return (
    
    <div>

      <Typography variant="h4" align='center' style={{padding: "15px"}}>
        Prospective Parents' Evenings
      </Typography>



      {isLoadingProspectiveParentsEveningInfo ?
        <div className="table-container" style={{textAlign: "center", fontWeight: "bold", fontSize: "25px"}}>
          Loading Prospective Parents Evenings Data...
        </div>
        :
      <div>
        <div>
          <Typography variant="h6" align='left' style={{padding: "15px"}}>
            Currently Planned Prospective Parents' Evenings
          </Typography>
        </div>

        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Time</TableCell>
                        <TableCell>Details</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {prospectiveParentsEveningsData.map(item => (
                        <TableRow key={item.id}>
                            <TableCell>{formatFriendlyDate(item.prospectiveParentsEveningDate)}</TableCell>
                            <TableCell>{item.prospectiveParentsEveningTime}</TableCell>
                            <TableCell>
                                <Link href={CONSTANTS.TLHParentEvenings + "/" + item.id} color="primary">
                                    View Details
                                </Link>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>

        <div>
          <Typography variant="h6" align='left' style={{padding: "15px"}}>
            Add New Prospective Parents' Evenings
          </Typography>
        </div>


        <form
          onSubmit={formik.handleSubmit}
          style={{padding: '20px'}}
        >

          <div className="relative w-full" style={{ display: 'flex', justifyContent: 'space-between', padding: "16px"}}>

            <div style={{ flexBasis: '45%' }}>
              <TextField
                id="parentsMeetingDate"
                name="parentsMeetingDate"
                label="Parents Meeting Date"
                type="date"
                style={{paddingRight: '200px'}}
                InputLabelProps={{
                  shrink: true,
                }}
                value={formik.values.parentsMeetingDate}
                onChange={formik.handleChange}
              />
            </div>

            <div style={{ flexBasis: '45%'}}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <TimePicker
                  label="Parents Meeting Time"
                  id="parentsMeetingTime"
                  name="parentsMeetingTime"
                  value={formik.values.parentsMeetingTime}
                  onChange={(value) => formik.setFieldValue('parentsMeetingTime', value)}
                  renderInput={(params) => <TextField style={{width: '225px'}} {...params} />}
                />
              </LocalizationProvider>
            </div>
          </div>

          <button
            className="uppercase px-6 py-3 rounded shadow-lg hover:shadow-lg focus:outline mr-1 mb-1"
            type="submit"
          >Click to Add New Parents Evening</button>

        </form>



      </div>}


      <Modal
        open={openSuccessfulSubmissionModal}
        onClose={handleCloseSuccessfulSubmissionModal}
      >
        <Box sx={style}>
          <Typography variant="h5" align='center' style={{padding: "15px"}}>
            Prospective Parents Evening Successfully Created
          </Typography>
        </Box>
      </Modal>
 

      <Modal
        open={badSubmissionModal}
        onClose={() => setBadSubmissionModal(false)}
      >
        <Box sx={style}>
          <Typography variant="h5" align='center' style={{padding: "15px"}}>
            Either Time or Date (or both) incorrectly set
          </Typography>
        </Box>
      </Modal>

      
    </div>
  );
}

