import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router";

// MUI Imports

import { makeStyles } from "@material-ui/core/styles";
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar'; 
import CssBaseline from '@mui/material/CssBaseline'; 
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu'; 
import Modal from '@mui/material/Modal';
import { Link } from "react-router-dom";

// System imports

import SideDrawer from './SideDrawer';
import * as CONSTANTS from "../../StringConstants"
import MockExamAccountSettingsButton from './MockExamAccountSettingsButton';
import QuestionsAndUserDataService from 'services/QuestionsAndUserDataService';
import FooterAdmin from "components/Footers/FooterAdmin.js";
import whiteLogo from "./../../assets/logoVariations/Primarylogomark_purple_black_white-03.png"
import CountdownTimer from './CountdownTimer';

const drawerWidth = 260;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: "white",
    boxShadow: theme.shadows[5],
    outline: "none",
    border: '2px solid #000',
    padding: theme.spacing(2, 4, 3),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  }
}));


export default function MockExamDashboard() {
  const [open, setOpen] = useState(true);
  const [isLoadingMockExamSets, setIsLoadingMockExamSets] = useState(true)
  const [disabledButtonModal, setDisabledButtonModal] = useState(false);
  const [isMockExamTakingPlace, setIsMockExamTakingPlace] = useState(false)
  const [timeUntilExam, setTimeUntilExam] = useState(null)
  const classes = useStyles();

  // const [disabled, setDisabled] = useState(false);
  let history = useHistory();

  useEffect(() => {
    if (isLoadingMockExamSets) {
      checkIfMockExamSessionTakingPlace()
      setIsLoadingMockExamSets(false)
    }
  });

  const openDisabledButtonModal = () => setDisabledButtonModal(true);
  const handleCloseModal = () => setDisabledButtonModal(false);

  function checkIfMockExamSessionTakingPlace() {
    const user = JSON.parse(localStorage.getItem('user'));
    console.log("User data: " + user.email)
    QuestionsAndUserDataService.checkIfCurrentMockExamSessionTakingPlace().then(result => {
      console.log("Checking if currently mock exam time")
      console.log(result.data)
      console.log(typeof result.data)
      console.log(Date.now() + result.data)

      if (result.data == "") {
        console.log("No mock exams running")
      } else if (typeof result.data === 'number') {
        console.log("We have " + result.data + " seconds till the next mock exam session")
        setTimeUntilExam(result.data)
      } else {
        setIsMockExamTakingPlace(true)
      }
      setIsLoadingMockExamSets(false)
    })
    console.log("timeUntilExam")
    console.log(timeUntilExam)
    console.log(timeUntilExam === null)
  }

  function redirectToExamSession() {
    console.log("Going to exam")
    history.push(CONSTANTS.MockExamStartPage)
  }


  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };


 // The function and variable below are currently unused but can be called if dummy data is needed on the front end

 let dummyData = [
  createDummyData("2022-05-21", 0.6), // 1
  createDummyData("2022-05-21", 0.4), // 1
  createDummyData("2022-05-23", 0.7), // 2
  createDummyData("2022-05-23", 0.83), // 3
  createDummyData("2022-05-24", 0.73), // 4
  createDummyData("2022-05-25", 0.91), // 5
]

 function createDummyData(time, amount) {
  return {time, amount}
}



  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} style={{backgroundColor: "purple"}}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>

            <img alt="11plus-Whizzdom-logo" style={{height: '60px'}} src={whiteLogo}></img>


        <div className="w-full mx-autp flex justify-end md:flex-nowrap flex-wrap md:px-10 px-4" >
          <div>
           <MockExamAccountSettingsButton/>
          </div>
        </div>
        </Toolbar>

      </AppBar>

      <SideDrawer drawerStatus={open} handleDrawerClose={handleDrawerClose}/>

      {/* <Box sx={{width: "100%", maxWidth: 500}}> */}
        <div style={{marginTop: "230px"}}>
          {/* Empty div to account for the navbar - should do some better coding to avoid the need for this */}
        </div>
        <div style={{ position: "relative", minHeight: "100vh" }}>

          <Grid container spacing={3}> 

            <Grid item xs={12} md={12}>
            {timeUntilExam !== null ? 
            <Typography variant="h6" style={{ color:"red", marginTop: "94px", textAlign: "center"}}>
                  Time until Exam:
                  <CountdownTimer 
                    targetTime={timeUntilExam} 
                    handleTimeExpired={redirectToExamSession}
                  />
              </Typography> : <div></div>
            }
              <Typography variant="h4" style={{ marginTop: (timeUntilExam !== null ? "14px" : "94px"), textAlign: "center"}}>
                  Eleven Plus Mock Exam Instructions
              </Typography>
            </Grid>


            <Grid xs={12}>
              <ol className="mockExamInstructionsList">
                <li className="mockExamInstruction">1) Your mock exam will start at the time mentioned in the table. This will be at the same time as students across the country taking the same exam so its important that you are not late.
                  This is to simulate the real 11Plus exam. Once it begins, you will not be allowed to use any external resources to aid your attempt.</li>
                <li className="mockExamInstruction">2) During your attempt, you will have a timer that will be visible whilst you're undertaking the exam and show how much time you have left. </li>
                <li className="mockExamInstruction">3) The exam will only finish when the timer runs out, this is to encourage you to go back and check your work and to simulate the exam day conditions. </li>
                <li className="mockExamInstruction">4) You are recommended to use a rough piece of paper and a pen to carry out any working during the exam.</li>
                <li className="mockExamInstruction">5) If you cannot complete a question, you should move on and come back to it later at the end of the exam. All questions are equally waited so use your time efficiently.</li>
                <li className="mockExamInstruction">6) Attempt the mock exam in a quiet environment where you will have no disturbances. This will enable you to simulate the exam day environment. </li>
                <li className="mockExamInstruction">7) You are strongly advised to carry out the mock exam on a laptop or desktop. </li>
                <li className="mockExamInstruction">8) <Link to={CONSTANTS.MockExamDemoTest}><u>Click here to take a practice exam</u></Link> to allow you to get used to the system. </li>
                <li className="mockExamInstruction">9) Good luck and remember to try your best! </li>
              </ol>
            </Grid>
            
            <Grid item xs={12} md={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <div style={{ textAlign: "center", width: "100%", marginBottom: "50px" }}>
                {/* <Link to="/auth/register/1"> */}
                    <button
                      className={isMockExamTakingPlace ? "start-mock-exam-button" : "start-mock-exam-button-disabled"}
                      type="button"
                      onClick={isMockExamTakingPlace ? redirectToExamSession : openDisabledButtonModal}
                    >
                    Start Mock Exam
                  </button>
                {/* </Link> */}
                {/* You'll be able to start your mock exam session  */}
              </div>
            </Grid>
          </Grid>
          <Box component="main" sx={{ flexGrow: 1, left: "50%", position: "absolute", bottom: 0, width: "100%"}}>
            <FooterAdmin/>
          </Box>
        </div>

        <Modal open={disabledButtonModal} onClose={handleCloseModal} className="landing-page-upcoming-mock-exams-modal">
          <div className={classes.paper}>
            <Typography variant="h5" gutterBottom style={{textAlign: "center"}}>
              Your exam session has not started.
            </Typography>
            <Typography variant="body1">
              You'll be able to access your exam during the exam session. You may need to refresh the page.
            </Typography>
          </div>
        </Modal>

    </Box>
  );
}