import React, { useState } from "react";
// import { Redirect, Route, Switch } from "react-router";
// import { useSearchParams } from "react-router-dom";
import { Link } from 'react-router-dom';

// import { Link } from "react-router-dom";
import { useFormik } from 'formik';
import TextField from '@material-ui/core/TextField';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import QuestionsAndUserDataService from '../../services/QuestionsAndUserDataService'
// string constants

import * as CONSTANTS from "../../StringConstants.js"

export default function ChangePassword() {

  const [doPasswordsMatch, setDoPasswordsMatch] = useState(true);
  const [showPasswordChangedConfirmation, setShowPasswordChangedConfirmation] = useState(false);
  const [showError, setShowError] = useState(false);
  const [passwordSubmitted, setPasswordSubmitted] = useState(false);

  const queryParams = new URLSearchParams(window.location.search);

  function showPasswordsNotMatchingWarning() {
    if (!doPasswordsMatch) {
      return (
        <div>
          <span className="ml-2 text-sm font-semibold text-red-600">
            Passwords do not match.
          </span>
        </div>
      )
    }
  }

  const validationSchema = yup.object({
    password: yup
      .string('Enter your password')
      .min(8, 'Password should be of minimum 8 characters length')
      .required('Password is required'),

  });

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (values.password === values.confirmPassword) {
        let token = queryParams.get("t");
        console.log("Sending change password reqeuest")
        QuestionsAndUserDataService.changePassword(values.password, token)
          .then(result => {
            console.log("Waiting for result of promise")
            if (result) {
              // password changed successfully - relocate to login page - inform of success then redirect to login page.
              console.log("Successful password change") 
              setPasswordSubmitted(true)
              setShowPasswordChangedConfirmation(true)
            } else {
              // issue with token - throw error page and redirect to forgot password page (with guidance)
              console.log("Error with password change")
              setShowError(true)
              setPasswordSubmitted(true)
            }
          })
      } else {
        setDoPasswordsMatch(false)
      }
    },
  });

  function RenderAppropriateBoxContents() {

    var login = <u><Link to={CONSTANTS.IndividualLogin}>login</Link></u>
    var forgotPassword = <u><Link to="/auth/forgot-password">click here</Link></u>



    if (passwordSubmitted) {
      console.log("password submitted")
      if (showPasswordChangedConfirmation) {
        // show confirmation and redirect to login
        return ( <div>
                    <div className="text-center mb-3">
                      <h6 className="text-blueGray-500 text-sm font-bold">
                        Password changed successfully, you may now {login} with your new credentials. 
                      </h6>
                    </div>
                  </div> )       
      } 
      if (showError) {
        // error
        return (<div>
                  <div className="text-center mb-3">
                    <h6 className="text-blueGray-500 text-sm font-bold">
                      It looks like there was an error resetting your password, this may be because your link has expired.
                      Please {forgotPassword} to try again or contact support@whizzdom.co.uk if the problem continues.  
                    </h6>
                  </div>
                </div> )    

      }
    } else {
      return <>
        <div className="text-center mb-3">
                  <h6 className="text-blueGray-500 text-sm font-bold">
                    Change password
                  </h6>
                </div>
                <form
                  onSubmit={formik.handleSubmit}
                >
                  <div className="relative w-full mb-3">
                    <TextField
                      fullWidth
                      id="password"
                      name="password"
                      label="Password"
                      type="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      error={formik.touched.password && Boolean(formik.errors.password)}
                      helperText={formik.touched.password && formik.errors.password}
                    />
                  </div>
                  <div className="relative w-full mb-3">
                    <TextField
                      fullWidth
                      id="confirmPassword"
                      name="confirmPassword"
                      label="Confirm password"
                      type="password"
                      value={formik.values.confirmPassword}
                      onChange={formik.handleChange}
                      error={formik.touched.password && Boolean(formik.errors.password)}
                      helperText={formik.touched.password && formik.errors.password}
                    />
                  </div>
                  {showPasswordsNotMatchingWarning()}
                  <div className="text-center mt-6">
                    <Button
                      colour="primary"
                      className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="submit"
                    >
                      Change Password
                    </Button>
                  </div>
                </form> 
                </>
    }
  }



  return (
    <>
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
              <div className="rounded-t mb-0 px-6 py-6">

                {RenderAppropriateBoxContents()}

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
