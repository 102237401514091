import React, { useState, useEffect } from 'react';
import "../../assets/styles/index.css";
import Title from "./../AnalyticsComponents/Title.js"
import { Link } from "react-router-dom";

// Material UI imports

// import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import QuestionsAndUserDataService from '../../services/QuestionsAndUserDataService'

import EditHeadTeacherPasswordForm from './EditHeadTeacherPasswordForm.js';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function AdminSchoolView() {

  const [isLoadingSchoolsData, setIsLoadingSchoolsData] = useState(true)
  const [schoolsData, setSchoolsData] = useState([])
  const [selectedSchool, setSelectedSchool] = useState([])
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (isLoadingSchoolsData) {
        getSchoolsData()
    }
  
  });

  function getSchoolsData() {
    console.log("Making request for schools data")
    QuestionsAndUserDataService.getSchoolsData().then(schoolsData => {
      console.log(schoolsData)
      setSchoolsData(schoolsData.data)
      setIsLoadingSchoolsData(false)
    })
  }

  function getHeadTeacherName(school) {
    console.log("Looking at: " + school.schoolId)
    var teachers = school.teacherSet
    for (let i = 0; i < teachers.length; i++) {
      var teacher = teachers[i]
      if (teacher.roles[0].name === "ROLE_HEADTEACHER") {
        return teacher.firstName + " " + teacher.surname
      }
    }
    return "Error - no head teacher set for school"
  }

  function calculateNumberOfStudentsForTheSchool(school) {
    var studentCount = 0
    var teachers = school.teacherSet
    for (let i = 0; i < teachers.length; i++) {
      // var teacher = teachers[i]
      // Need to iterate through all the classes for each teacher and add their number of students. 
    }
    return studentCount
  }
  
  function getSpecificSchoolData() {
    // Needs to be implemented
  }

  function getHeadTeacher(school) {
    console.log("Looking at: " + school.schoolId)
    var teachers = (school.teacherSet ? school.teacherSet : 0)
    for (let i = 0; i < teachers.length; i++) {
      var teacher = teachers[i]
      if (teacher.roles[0].name === "ROLE_HEADTEACHER") {
        return teacher
      }
    }
    console.log("Head teacher does not exist for this school")
  }

  const handleOpen = (selected) => {
    console.log("School selected: ")
    console.log(selected)
    setSelectedSchool(selected)
    setOpen(true);
  }
  const handleClose = () => setOpen(false);

  return (
    <React.Fragment>   
      <Link to="/app/admin-whizz">Back to Admin Dashboard</Link>

      <Title>Schools</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            {/* Clicking schools should take us to a school info page */}
            <TableCell>School</TableCell> 
            <TableCell>Head Teacher</TableCell>
            <TableCell>Number of students</TableCell>

            {/*<TableCell align="right">Sale Amount</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {schoolsData.map((school) => (
            <TableRow key={school.schoolId}>
              <TableCell><Link onClick={() => getSpecificSchoolData()}>{school.schoolName}</Link></TableCell>
              <TableCell><Link onClick={() => handleOpen(school)}>{getHeadTeacherName(school)}</Link></TableCell>
              <TableCell>{calculateNumberOfStudentsForTheSchool(school)}</TableCell>
              {/* <TableCell><Button>Edit</Button></TableCell> */}


              {/* <TableCell align="right">{`$${row.amount}`}</TableCell> */}
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <EditHeadTeacherPasswordForm teacher={getHeadTeacher(selectedSchool)}/>
                </Box>
              </Modal>
            </TableRow>
            
          ))}
         
        </TableBody>
      </Table>

  </React.Fragment>
  );
}
