import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router";

// MUI Imports

import { makeStyles } from "@material-ui/core/styles";
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

// System imports

import * as CONSTANTS from "../../StringConstants"
import MockExamAccountSettingsButton from './MockExamAccountSettingsButton';
import QuestionsAndUserDataService from 'services/QuestionsAndUserDataService';
import FooterAdmin from "components/Footers/FooterAdmin.js";
import whiteLogo from "./../../assets/logoVariations/Primarylogomark_purple_black_white-03.png"

const drawerWidth = 260;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: "white",
    boxShadow: theme.shadows[5],
    outline: "none",
    border: '2px solid #000',
    padding: theme.spacing(2, 4, 3),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  }
}));


export default function MockExamStartPage() {
  const [open, setOpen] = useState(false);
  const [isLoadingMockExamSets, setIsLoadingMockExamSets] = useState(true)
  const [isMockExamTakingPlace, setIsMockExamTakingPlace] = useState(false)
  const [currentMockExamSession, setCurrentMockExamSession] = useState(null)


  // const [disabled, setDisabled] = useState(false);
  let history = useHistory();

  useEffect(() => {
    if (isLoadingMockExamSets) {
      getMockExamSessionDetails()
      setIsLoadingMockExamSets(false)
    }
  });




  function getMockExamSessionDetails() {
    const user = JSON.parse(localStorage.getItem('user'));
    console.log("User data: " + user.email)
    QuestionsAndUserDataService.checkIfCurrentMockExamSessionTakingPlace().then(result => {
      console.log("Checking if currently mock exam time")
      console.log(result.data)

      if (result.data == "") {
        history.push("/mock-exams/dashboard")
      } else {
        // Get mock exam 
        setCurrentMockExamSession(result.data)

      }
      setIsLoadingMockExamSets(false)
    })
  }

  


  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position="fixed" open={open} style={{backgroundColor: "purple"}}>
        <Toolbar>
  
            <img alt="11plus-Whizzdom-logo" style={{height: '60px'}} src={whiteLogo}></img>

        <div className="w-full mx-autp flex justify-end md:flex-nowrap flex-wrap md:px-10 px-4" >
          <div>
           <MockExamAccountSettingsButton/>
          </div>
        </div>
        </Toolbar>

      </AppBar>


      {/* <Box sx={{width: "100%", maxWidth: 500}}> */}
        <div style={{marginTop: "230px"}}>
          {/* Empty div to account for the navbar - should do some better coding to avoid the need for this */}
        </div>
        <div style={{ position: "relative", minHeight: "100vh" }}>

          <Grid container spacing={3}>

            <Grid item xs={12} md={12}>
              <Typography variant="h4" style={{ marginTop: "94px", textAlign: "center"}}>
                  Session Summary
              </Typography>
            </Grid>


            <Grid xs={12}>
              <ul className="mockExamInstructionsList">
                <li className="mockExamInstruction"> - This session will consist of 2 exams, each 50 minutes in length.</li>
                <li className="mockExamInstruction"> - There will be a short break between both exams.</li>
                <li className="mockExamInstruction"> - Do not close the current window/tab at any point during the session.</li>
                <li className="mockExamInstruction"> - Ensure you are completing this exam on a desktop, laptop or tablet/ipad.</li>
                <li className="mockExamInstruction"> - You <b>must</b> click "Submit Answer" after selecting an answer option to save your answer.</li>
                <li className="mockExamInstruction"> - Good Luck.</li>

              </ul>
            </Grid>
            
            <Grid item xs={12} md={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <div style={{ textAlign: "center", width: "100%", marginBottom: "50px" }}>
                {/* <Link to="/auth/register/1"> */}
                    <button
                      className={"start-mock-exam-button"}
                      type="button"
                      onClick={() => history.push(CONSTANTS.MockExamPage)}
                    >
                    Start First Exam
                  </button>
              </div>
            </Grid>
          </Grid>
          <Box component="main" sx={{ flexGrow: 1, left: "50%", position: "absolute", bottom: 0, width: "100%"}}>
            <FooterAdmin/>
          </Box>
        </div>
    </Box>
  );
}