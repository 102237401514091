import React, { useState, useEffect } from 'react';
import "../../../assets/styles/index.css";
import { Link, useParams, useHistory } from "react-router-dom";

// Material UI imports

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import QuestionsAndUserDataService from 'services/QuestionsAndUserDataService';
import Title from "../../AnalyticsComponents/Title.js"
import { Button } from '@material-ui/core';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import AddSectionToMockExam from './AddSectionToMockExam';

import * as CONSTANTS from "../../../StringConstants.js"
import AddTableDataToMockExamSection from './AddTableDataToMockExamSection';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function ViewSectionsInMockExamSet() {

  const [isLoadingMockExamSections, setIsLoadingMockExamSections] = useState(true)
  const [mockExamSections, setMockExamSections] = useState([])
  const { mockExamId } = useParams();
  const [sectionImage, setSectionImage] = useState()
  // This value is always true - can oscillate with false if we start showing the example image as a separate image on this page
  const [isSectionImage, setIsSectionImage] = useState(true)
  const [showImageSubmitButton, setShowImageSubmitButton] = useState(false)
  const [showingSectionImage, setShowingSectionImage] = useState(false)
  const [mockExamSectionId, setMockExamSectionId] = useState()
  let history = useHistory();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openTableModal, setOpenTableModal] = useState(false);
  // function handleOpenTableModal(mockExamSectionId) {
  //   setMockExamSectionId(mockExamSectionId)
  //   setOpenTableModal(true);
  // }
  const handleCloseTableModal = () => setOpenTableModal(false);

  useEffect(() => {
    if (isLoadingMockExamSections) {
      getMockExam()
    }
  });


  function getMockExam() {
    console.log("Getting mock exam - " + mockExamId)
    QuestionsAndUserDataService.getMockExamSectionsForMockExam(mockExamId).then(papers => {
      console.log(papers.data)
      setMockExamSections(papers.data.mockExamSections)
      setIsLoadingMockExamSections(false)
    })
  }

  function onFileUpdateHandler(e, mockExamSectionId) {
    e.preventDefault()
    setSectionImage(e.target.files[0])
    setMockExamSectionId(mockExamSectionId)
    setShowImageSubmitButton(true)
  }

  function submitSectionImage() {
    QuestionsAndUserDataService.addImageToMockExamSection(sectionImage, mockExamSectionId).then((result) => {
      console.log(result)
      setShowImageSubmitButton(false)
      window.location.reload(false);
    })
  }

  function getSectionImage(mockExamSectionId) {
    console.log("View section image")
    setShowingSectionImage(true)
    setIsSectionImage(true)
    setMockExamSectionId(mockExamSectionId)
    QuestionsAndUserDataService.getMockExamSectionImage(mockExamSectionId).then((result) => {
      console.log(result)
      console.log(result.data.length)
      setSectionImage("data:image/png;base64," + result.data)
    })
  }

  function showQuestionsForMockExamSection(mockExamSectionId) {
    console.log("Showing questions for mockExamSectionId: " + mockExamSectionId)
    history.push(CONSTANTS.AdminMockExamSectionQuestionsRoute + "/" + mockExamSectionId)
  }

  // Can be section image or example image - will use boolean but expecting that one of these will be redundant 
  // and that we'll never need two 
  function deleteSectionImage() {
    QuestionsAndUserDataService.deleteMockExamSectionImage(mockExamSectionId, isSectionImage).then((result) => {
      setShowingSectionImage(false)
      window.location.reload(false);
    })
  }


  function isEntryEmail() {
    const admin = JSON.parse(localStorage.getItem('user'));
    let email = admin.email;
    console.log(email)
    return email.toLowerCase() === "entry@whizzdom.co.uk"
  }


  return (
    <React.Fragment>   
      {isEntryEmail ? <></> : <Link to="/app/admin-whizz">Back to Admin Dashboard</Link> }


      <Title>Sections in Mock Exam {mockExamId}</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            {/* Clicking schools should take us to a school info page */}
            <TableCell>Section Name</TableCell> 
            <TableCell>Section Length (minutes)</TableCell>
            {/* <TableCell>Number of Questions</TableCell> */}
            <TableCell>Add Section Image</TableCell>
            {showImageSubmitButton ? <TableCell>Submit Section Image</TableCell> : <></>}
            <TableCell>View Section Image</TableCell>
            {/* <TableCell>Table Data</TableCell> */}

          </TableRow>
        </TableHead>
        <TableBody>
          {mockExamSections.map((mockExamSections) => (
            <TableRow key={mockExamSections.mockExamSectionId}>
              {/* <TableCell><Link onClick={showPapersForMockExam(mockExamPaper.mockExamSetId)}><u>{mockExamPaper.examSetName}</u></Link></TableCell> */}
              {/* <TableCell><button onClick={() => showSectionsForMockExam(mockExamPaper.mockExamId)}><u>{mockExamPaper.examName}</u></button></TableCell> */}
              {/* <TableCell><button onClick={() => showSectionsForMockExam(mockExamPaper.mockExamId)}><u>{mockExamPaper.examName}</u></button></TableCell> */}

              <TableCell><button onClick={() => showQuestionsForMockExamSection(mockExamSections.mockExamSectionId)}><u>{mockExamSections.sectionName}</u></button></TableCell>
              <TableCell>{mockExamSections.sectionTimeInMinutes}</TableCell>
              {/* <TableCell>{mockExamSections.mockExamQuestions.length}</TableCell> */}
              <TableCell>
                <input id="file" name="file" type="file" 
                  onChange={(e) => onFileUpdateHandler(e, mockExamSections.mockExamSectionId)} 
                  className="form-control" />
              </TableCell>
              {showImageSubmitButton ? <TableCell><button onClick={submitSectionImage}>Submit</button></TableCell> : <></>}
              <TableCell>{ mockExamSections.sectionImage !== null  ? <button onClick={() => getSectionImage(mockExamSections.mockExamSectionId)}>View Section Image</button> : "No Image"}</TableCell>
              {/* <TableCell><button onClick={() => handleOpenTableModal(mockExamSections.mockExamSectionId)}>View Table Data</button></TableCell> */}

            </TableRow>
          ))}
         
        </TableBody>
      </Table>

      <br></br>

      <Button onClick={handleOpen}>Add Section to Mock Exam</Button>

      {showingSectionImage ?
      <div>
        <button className="p-2" onClick={() => deleteSectionImage()}>Delete Image</button>
      <br></br>
        <img alt="11plus mock exam section" id="itemPreview" src={sectionImage} />
      </div>
      :
      <></>
      }

      <Modal
        open={openTableModal}
        onClose={handleCloseTableModal}
      >
        <Box sx={style}>
            {/* <AddQuestionToMockExamSectionForm mockExamSectionId={mockExamSectionId}/> */}
            <AddTableDataToMockExamSection mockExamSectionId={mockExamSectionId}/>
        </Box>
      </Modal>


      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box sx={style}>
          <AddSectionToMockExam mockExamId={mockExamId}/>
        </Box>
      </Modal>

  </React.Fragment>
  );
}
