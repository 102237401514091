import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import QuestionsAndUserDataService from '../../services/QuestionsAndUserDataService'


export default function ResetUserPasswordForm(user) {

  const validationSchema = yup.object({
    firstName: yup
      .string('Enter new password'),
  });

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      customCheckLogin: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {

      console.log("formik values")

      console.log(values.newPassword)
      console.log(user.user.userId)
      console.log(formik.values.newPassword)      

      QuestionsAndUserDataService.resetPasswordFromDashboard(formik.values.newPassword, user.user.userId)
    },
  });
   
     

  return (
    
    <div>
     <form
        onSubmit={formik.handleSubmit}
        >
         <label> Enter new password</label>

        <div className="relative w-full mb-3">

        <TextField
            fullWidth
            id="newPassword"
            name="newPassword"
            label="New Password"
            value={formik.values.newPassword}
            onChange={formik.handleChange}
            error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
            helperText={formik.touched.newPassword && formik.errors.newPassword}
          />

        </div>
         

        <div className="text-center mt-6">
          <Button
            colour="primary"
            className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
            type="submit"
          >
            Reset Password
          </Button>
        </div>
      </form>
    </div>
  );
}
