import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { Link } from "react-router-dom";
import { useFormik } from 'formik';
import TextField from '@material-ui/core/TextField';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';

import QuestionsAndUserDataService from '../../services/QuestionsAndUserDataService'
import signUpAndLoginBackgroundImage from "assets/img/register_bg_2.png"
import signUpAndLoginBackgroundImageWebP from "assets/WebPImages/Registration&Login/register_bg_2.webp"

// string constants

import * as CONSTANTS from "../../StringConstants.js"

export default function LoginComponent({loginType}) {

  // const
  let history = useHistory();
  const [invalidCredentialsEntered, setInvalidCredentialsEntered] = useState(false);

  // Validates user's details from the db.
  function showIncorrectCredentialsWarning() {
    if (invalidCredentialsEntered) {
      return (
        <div>
          <span className="ml-2 text-sm font-semibold text-red-600">
              Incorrect Credentials
          </span>
        </div>
      )
    }
  }

  const validationSchema = yup.object({
    email: yup
      .string('Enter your email')
      .email('Enter a valid email')
      .required('Email is required'),
    password: yup
      .string('Enter your password')
      .min(8, 'Password should be of minimum 8 characters length')
      .required('Password is required'),

  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log("Making login post request to back end")
      console.log(values)
      console.log(typeof(values))
      console.log("Printed values!")
      console.log(loginType)

      var pathToPushTo = ""
    
      QuestionsAndUserDataService.validateUserLogin(values, loginType).then(role => {
          console.log("Checked from db")
          console.log("Results details and layout")
          console.log(role)
          if (!role) {
            console.log("No role set")
            setInvalidCredentialsEntered(true)
            return
          }
          // console.log("Are they equal: " + role == "ROLE_ADMIN")
          // console.log("Are they included: " + role.includes("ROLE_ADMIN"))

          console.log(values)

          if (role.includes("ROLE_MOCK_EXAM_STUDENT")) {
            // Go to mock exam main page
            console.log("Pushing mock exam page")
            pathToPushTo = CONSTANTS.MockExamDashboard
          }

          if (role.includes("ROLE_USER") || role.includes("ROLE_FREE_USER")) {
//            Go to user main page
            console.log("Pushing user page")
            pathToPushTo = CONSTANTS.AnalyticsPage
          }

          if (role.includes("ROLE_HEADTEACHER")) {
            // Go to teacher area
            pathToPushTo = "/teacher/headteacher"
          }


          if (role.includes("ROLE_ADMIN")) {
            console.log("Details: ")
            console.log(values)
           let userEmail = values.email;
           console.log("Looking at admins for: " + userEmail)
            if (userEmail.toLowerCase() == "entry@whizzdom.co.uk") {
              console.log("Should be pushing for entry@whizzdom.co.uk")
              // Go to mock exam papers route
              pathToPushTo = CONSTANTS.AdminMockExamDashboardRoute
            }
            if (userEmail.toLowerCase() == "tlh@whizzdom.com") {
              // TODO: Implement tlh user login
              console.log("Pushing tlh staff route - " + CONSTANTS.TLHStaffDashboard)
              pathToPushTo = CONSTANTS.TLHStaffDashboard
            } else {
            //Go to admin board
              console.log("Pushing admin route - " + CONSTANTS.AdminRoute)
              pathToPushTo = CONSTANTS.AdminRoute
            }
          } 
        
        console.log("Should have shown: " + pathToPushTo)
        history.push(pathToPushTo)
        history.push(pathToPushTo)

      })
    },
  });

  return (
    <>
         <div
            className="absolute top-0 w-full h-full bg-blueGray-800 bg-no-repeat bg-full"
            style={{
              backgroundImage: `url(${signUpAndLoginBackgroundImage}), url(${signUpAndLoginBackgroundImageWebP})`,
            }}
          ></div>
      <div >
 
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
              <div className="rounded-t mb-0 px-6 py-6">
                <div className="text-center mb-3">
                  <h6 className="text-blueGray-500 text-sm font-bold">
                    Sign in
                  </h6>
                </div>
                {/* <div className="btn-wrapper text-center">
                  <button
                    className="bg-white active:bg-blueGray-50 text-blueGray-700 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
                    type="button"
                  >
                    <img
                      alt="..."
                      className="w-5 mr-1"
                      src={require("assets/img/google.svg").default}
                    />
                    Google
                  </button>
                </div>
                <hr className="mt-6 border-b-1 border-blueGray-300" />
              </div>
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <div className="text-blueGray-400 text-center mb-3 font-bold">
                  <small>Or sign in with credentials</small>
                </div> */}
                <form
                  onSubmit={formik.handleSubmit}
                >
                  <div className="relative w-full mb-3">
                  <TextField
                    fullWidth
                    id="email"
                    name="email"
                    label="Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                    {/* <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Email"
                    /> */}
                  </div>

                  <div className="relative w-full mb-3">
                  <TextField
                    fullWidth
                    id="password"
                    name="password"
                    label="Password"
                    type="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                  />
                    {/* <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Password
                    </label>
                    <input
                      type="password"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Password"
                    /> */}
                  </div>

                  {showIncorrectCredentialsWarning()}

                  <div>
                    <label className="inline-flex items-center cursor-pointer">
                      <input
                        id="customCheckLogin"
                        type="checkbox"
                        className="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                      />
                      <span className="ml-2 text-sm font-semibold text-blueGray-600">
                        Remember me
                      </span>
                    </label>
                  </div>

                  <div className="text-center mt-6">
                    <button
                      className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="submit"
                    >
                      Login
                    </button>
                    {/* <Button
                      colour="primary"
                      className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="submit"
                    >
                      Login
                    </Button> */}
                  </div>
                </form>
              </div>
            </div>
            <div className="flex flex-wrap mt-6 relative">
              <div className="w-1/2">
                <Link to={CONSTANTS.ForgotPassword} className="text-blueGray-200">
                  <small>Forgot password?</small>
                </Link>
                {/* <a
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  className="text-blueGray-200"
                >
                  <small>Forgot password?</small>
                </a> */}
              </div>
              <div className="w-1/2 text-right">
                <Link to={CONSTANTS.StandardUserSignUpStart} className="text-blueGray-200">
                  <small>Create new account</small>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
