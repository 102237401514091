import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import * as CONSTANTS from "../../../StringConstants.js"


import Typography from '@material-ui/core/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { CardActionArea } from '@mui/material';


export default function AddEnglishQuestion() {



  var maxCardWidth = 375
  var minCardHeight = 200


  return (
    
    <div>
        <Grid container style={{justifyContent: "center"}}>
          <Grid item xs={3}>
            <Link to={CONSTANTS.AdminAddComprehensionQuestion}>

             <CardActionArea>

               <Card sx={{ maxWidth: maxCardWidth, minHeight: minCardHeight}} className="pt-2" >

                    <div style={{backgroundColor: "white"}}>
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div" textAlign={"center"}>
                          Add Comprehension Question
                        </Typography>
                      </CardContent>
                    </div>

                </Card>
              </CardActionArea>
            </Link>

          </Grid>      


          <Grid item xs={1}>
          </Grid>

          
            <Grid item xs={3}>

              <Link to={CONSTANTS.AdminAddGrammarQuestion}>
                <CardActionArea>
                  <Card sx={{ maxWidth: maxCardWidth, minHeight: minCardHeight}} className="pt-2" >
                      

                        <div style={{backgroundColor: "white"}}>
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="div" textAlign={"center"}>
                            Add Grammar Question
                            </Typography>
                          </CardContent>
                        </div>

                  </Card>
                </CardActionArea>
              </Link>
            </Grid>        
          </Grid>
    </div>
  );
}
