import React, { useState, useEffect } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';

import { Typography } from '@material-ui/core';

import TLHEndpoints from 'services/TLHEndpoints';
import ViewBookedAssesmentDetails from 'theLearningHub/layouts/HelperComponents/ViewBookedAssesmentDetails';
import RescheduleAppointmentOrAssessment from 'theLearningHub/layouts/HelperComponents/RescheduleAppointmentOrAssessment'

export default function TLHStaffDashboardAssessmentsTable({date, isAssessmentsOrAssessmentsMeetingTable}) {

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const [isLoadingData, setIsLoadingData] = useState(true)
  const [assessmentSubjectsAndYearGroupsDict, setAssessmentSubjectsAndYearGroupsDict] = useState()
  const [enquiriesData, setEnquiriesData] = useState([])
  const [currentAssessmentViewInfo, setCurrentAssessmentViewInfo] = useState();
  const [checkboxes, setCheckboxes] = useState([])
  // const [checkboxes, setCheckboxes] = useState([])
  const [updatedTable, setUpdatedTable] = useState(false)
  const [reasonRequired, setReasonRequired] = useState(true)

  const [rescheduleId, setRescheduleId] = useState()
  const [rescheduleAssessmentOrMeeting, setRescheduleAssessmentOrMeeting] = useState()
  const [openRescheduleModal, setOpenRescheduleModal] = useState(false) 
  var isToday = isTodaysDate(date)


  function isTodaysDate(date) {
    const today = new Date();

    return date.getDay() === today.getDay() &&
           date.getMonth() === today.getMonth() &&
           date.getFullYear() === today.getFullYear();
  }
  
  function handleOpenRescheduleModal(id, assessmentOrMeeting) {
    setRescheduleAssessmentOrMeeting(assessmentOrMeeting)
    setRescheduleId(id)
    // If an assessment meeting - if they had a meeting booked already, check they 
    if (assessmentOrMeeting == "Meeting") {
      var relevantParentEnquiry = findAssessmentById(enquiriesData, id)   
      const needsReason = (relevantParentEnquiry.parentBookingEnquiryDetails.assessmentMeetingDate != null && relevantParentEnquiry.parentBookingEnquiryDetails.assessmentMeetingTime != null)
      setReasonRequired(needsReason)
      setRescheduleId(relevantParentEnquiry.parentBookingEnquiryDetails.enquiryAndAssessmentBookingId)
    }
    setOpenRescheduleModal(true);

  }

  function findAssessmentById(assessments, id) {
    for (let i = 0; i < assessments.length; i++) {
        if (assessments[i].assessmentStudentId === id) {
            return assessments[i]; // Return the matching assessment object
        }
    }
    return null; // Return null if no matching assessment is found
  }

  function findStudentsByEnquiryId(data, enquiryId) {
    return data.filter(student => 
      student.parentBookingEnquiryDetails && 
      student.parentBookingEnquiryDetails.enquiryAndAssessmentBookingId === enquiryId
    ).map(student => student.assessmentStudentId);
  }

  const handleCloseRescheduleModal = () => setOpenRescheduleModal(false);

  function handleUpdatedTable() {
    console.log("In handle update to table")
    setUpdatedTable(true);
    // setReasonRequired(true)
    // setOpenRescheduleModal(false);
    window.location.reload();

  }

  const [bookedAssessmentDetails, setBookedAssessmentDetails] = useState(false);

  function handleOpenBookedAssessmentDetails(assessment) {
    setBookedAssessmentDetails(true);
    setCurrentAssessmentViewInfo(assessment)
  }
  const handleCloseBookedAssessmentDetails = () => setBookedAssessmentDetails(false);

  useEffect(() => {
    console.log("Getting assessments for the date")
    if (isLoadingData) {
      if (isAssessmentsOrAssessmentsMeetingTable === "Assessments") {
        getAllBookedAssessmentsForSpecificDate(date)
      }
      if (isAssessmentsOrAssessmentsMeetingTable === "Assessment Meetings") {
        getAllBookedAssessmentMeetingsForSpecificDate(date)
      }
    }
  },[updatedTable]);

  function getAllBookedAssessmentMeetingsForSpecificDate(date) {
    TLHEndpoints.getAllBookedAssessmentMeetingsForSpecificDate(date).then(result => {
      console.log("Setting assessment meetings for " + date.toDateString())
      console.log(result.data)
      var assessmentsData = result.data
      
      const assessmentSubjectsAndYearGroupsDict = {};
      const attendedMeeting = {}
      const confirmedForAssessmentMeeting = {}

      if (assessmentsData) {
        assessmentsData.forEach(item => {
          const { assessmentStudentId, assessmentsBooked, parentBookingEnquiryDetails} = item;
          const attendedAssessmentMeeting = parentBookingEnquiryDetails.attendedAssessmentMeeting
          const confirmedAttendanceForAssessmentMeeting = parentBookingEnquiryDetails.confirmedAttendanceForAssessmentMeeting
          console.log()
          if (assessmentsBooked.length > 0) {
            const assessmentYearGroup = assessmentsBooked[0].assessmentYearGroup.replace('_', ' ');
            const subjects = assessmentsBooked
              .map(assessment => capitalizeFirstLetter(assessment.assessmentSubject))
              .join(", ");
              assessmentSubjectsAndYearGroupsDict[assessmentStudentId] = [assessmentYearGroup, subjects];
            }
            attendedMeeting[assessmentStudentId] = attendedAssessmentMeeting
            confirmedForAssessmentMeeting[assessmentStudentId] = confirmedAttendanceForAssessmentMeeting
        });
     }
     console.log(attendedMeeting)
     console.log(confirmedForAssessmentMeeting)

      // console.log(assessmentSubjectsAndYearGroupsDict)
      setAssessmentSubjectsAndYearGroupsDict(assessmentSubjectsAndYearGroupsDict);
      // console.log(attendedAssessmentInfo)
      if (isToday) {
        setCheckboxes(attendedMeeting)
      } else {
        setCheckboxes(confirmedForAssessmentMeeting)
      }
      setEnquiriesData(assessmentsData)
      setIsLoadingData(false)
    })
  }


  function getAllBookedAssessmentsForSpecificDate(date) {
    TLHEndpoints.getAllBookedAssessmentsForSpecificDate(date).then(result => {
      console.log(result.data)
      // setMockExamSets(result.data)
      // console.log("Assessments")
      // console.log(result.data)
      var assessmentsData = result.data
      // sortAssessments(assessmentsData)
      
      const assessmentSubjectsAndYearGroupsDict = {};
      const attendedAssessmentInfo = {}
      const confirmedForAssessmentInfo = {}

      if (assessmentsData) {
        assessmentsData.forEach(item => {
          const { assessmentStudentId, assessmentsBooked, attendedAssessment, confirmedAttendanceForAssessment } = item;

          if (assessmentsBooked.length > 0) {
            const assessmentYearGroup = assessmentsBooked[0].assessmentYearGroup.replace('_', ' ');
            const subjects = assessmentsBooked
              .map(assessment => capitalizeFirstLetter(assessment.assessmentSubject))
              .join(", ");
              assessmentSubjectsAndYearGroupsDict[assessmentStudentId] = [assessmentYearGroup, subjects];
              attendedAssessmentInfo[assessmentStudentId] = attendedAssessment
              confirmedForAssessmentInfo[assessmentStudentId] = confirmedAttendanceForAssessment
            }
        });
     }

      // console.log(assessmentSubjectsAndYearGroupsDict)
      setAssessmentSubjectsAndYearGroupsDict(assessmentSubjectsAndYearGroupsDict);
      // console.log(attendedAssessmentInfo)
      if (isToday) {
        setCheckboxes(attendedAssessmentInfo)
      } else {
        setCheckboxes(confirmedForAssessmentInfo)
      }
      setEnquiriesData(assessmentsData)
      setIsLoadingData(false)
    })
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  

const handleToggle = (id) => {

  var isForAssessmentMeetings = isAssessmentsOrAssessmentsMeetingTable === "Assessment Meetings"

  
  var studentIds = [id]

  if (isForAssessmentMeetings) {
    // get parent id 
    var parentEnquiryId = findAssessmentById(enquiriesData, id).parentBookingEnquiryDetails.enquiryAndAssessmentBookingId
    console.log("Assessment Enquiry id")
    console.log(enquiriesData)
    console.log(parentEnquiryId)
    studentIds = findStudentsByEnquiryId(enquiriesData, parentEnquiryId)
    console.log(studentIds)
    // check if any other students with same parent id 
    // update theres to the same as well 
  }

  let newCheckboxesState = { ...checkboxes }
  for (var i = 0; i < studentIds.length; i++) {
    let studentId = studentIds[i]
    console.log("Updating checkbox for " + id)
    newCheckboxesState[studentId] = !checkboxes[studentId];
  }

  setCheckboxes(newCheckboxesState);
  
  console.log("Logging information")
  console.log(checkboxes)
  const checked = !checkboxes[id]
  console.log(checked)
  if (!isForAssessmentMeetings) {
    console.log("Looking at meetings")
    if (isToday) {
      console.log("Looking at meetings today")
      TLHEndpoints.toggleChildAsAttendedAssessmentStatusOnBackend(id, checked)
    } else {
      console.log("Looking at meetings tomorrow")
      TLHEndpoints.toggleChildAsConfirmedForAssessmentStatusOnBackend(id, checked)
    }
  } else {
    console.log("Looking at assessments")
    if (isToday) {
      console.log("Looking at assessments today")
      TLHEndpoints.toggleAssessmentMeetingAttendedStatusOnBackend(id, checked)
    } else {
      console.log("Looking at assessments tomorrow")
      TLHEndpoints.toggleConfirmedForAssessmentMeetingStatusOnBackend(id, checked)
    }
  }

}; 

function stringNullCheck(name) {
  if (name === null || name == undefined || name == "") {
    return ""
  }
  return name
}


  return (
    
    <div>
      {isLoadingData ?
        <div className="table-container" style={{textAlign: "center", fontWeight: "bold", fontSize: "25px"}}>
          Loading {isAssessmentsOrAssessmentsMeetingTable + " for " + date.toDateString()} ...
        </div>
        :
        enquiriesData.length == 0 ? <div style={{textAlign: "center", fontWeight: "bold", fontSize: "25px"}}>
          No {isAssessmentsOrAssessmentsMeetingTable} booked for {date.toDateString()}
        </div> :

                    <Paper sx={{ p: 4, display: 'flex', flexDirection: 'column' }}>

      <div style={{ overflowX: 'auto', width: '100%'}}>
         <Typography variant="h5" align='center' style={{padding: "15px"}}>
        {isAssessmentsOrAssessmentsMeetingTable} for {date.toDateString()}
      </Typography>
        <Table size="small" style={{tableLayout: 'auto'}}>
          <TableHead >
            <TableRow>
              {isAssessmentsOrAssessmentsMeetingTable === "Assessments" ? <TableCell>Start Time</TableCell> : <></>}
              <TableCell>Student Name</TableCell>
              <TableCell>Assessment(s) Booked</TableCell>
              <TableCell>Parent Name</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Customer Notes</TableCell>
              {isAssessmentsOrAssessmentsMeetingTable === "Assessments" ? <TableCell>Reschedule Assessment</TableCell> : <></>}
              <TableCell>Reschedule  {isAssessmentsOrAssessmentsMeetingTable === "Assessments" ? " Assessment " : ""} Meeting</TableCell>
              {isAssessmentsOrAssessmentsMeetingTable === "Assessments" ? <></> : <TableCell>Meeting Type</TableCell>}
              <TableCell>{isToday ? "Attended" : "Contacted/Confirmed"}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {enquiriesData.map((assessment) => (
              <TableRow key={assessment.assessmentStudentId}>
                {isAssessmentsOrAssessmentsMeetingTable === "Assessments" ? <TableCell>{assessment.assessmentStartTime}</TableCell> : <></>}
                <TableCell>{stringNullCheck(assessment.studentFirstName) + " " + stringNullCheck(assessment.studentSurname)}</TableCell>
                <TableCell>{assessmentSubjectsAndYearGroupsDict[assessment.assessmentStudentId] ? assessmentSubjectsAndYearGroupsDict[assessment.assessmentStudentId][0] + " - " + assessmentSubjectsAndYearGroupsDict[assessment.assessmentStudentId][1] : "Subjects not Entered"}</TableCell>
                <TableCell>{assessment.parentBookingEnquiryDetails ? (stringNullCheck(assessment.parentBookingEnquiryDetails.parentFirstName)  + " " + stringNullCheck(assessment.parentBookingEnquiryDetails.parentLastname)) : "" }</TableCell>
                <TableCell>{assessment.parentBookingEnquiryDetails ? (assessment.parentBookingEnquiryDetails.phoneNumber + (assessment.parentBookingEnquiryDetails.altPhoneNumber ? ("/" + assessment.parentBookingEnquiryDetails.altPhoneNumber) : (""))) : ""}</TableCell>
                <TableCell><button onClick={() => handleOpenBookedAssessmentDetails(assessment)}><u>View Notes</u></button></TableCell>
                {isAssessmentsOrAssessmentsMeetingTable === "Assessments" ? <TableCell><button onClick={() => handleOpenRescheduleModal(assessment.assessmentStudentId, "Assessment")}><u>Click to Reschedule</u></button></TableCell> : <></>}
                <TableCell>
                  <button onClick={() => handleOpenRescheduleModal(assessment.assessmentStudentId, "Meeting")}>
                    <u>
                    {assessment.parentBookingEnquiryDetails ? 
                      (assessment.parentBookingEnquiryDetails.assessmentMeetingTime ?
                        (assessment.parentBookingEnquiryDetails.assessmentMeetingTime + ", " + assessment.parentBookingEnquiryDetails.assessmentMeetingDate ) : "Not Booked") : "" }
                    </u>
                  </button>
                </TableCell>
                {isAssessmentsOrAssessmentsMeetingTable === "Assessment Meetings" ? <TableCell>{assessment.parentBookingEnquiryDetails.conversionStatus == "INTRODUCTORY_MEETING_SCHEDULED" ? "Introductory Meeting" : "Assessment Meeting"}</TableCell> : <></>}
                <TableCell>
                  <Checkbox
                    checked={isToday ? checkboxes[assessment.assessmentStudentId] : checkboxes[assessment.assessmentStudentId]}
                    onChange={() => handleToggle(assessment.assessmentStudentId)}
                  />
                </TableCell>

              </TableRow>
            ))}
          </TableBody>
        </Table>
        
        <Modal
          open={bookedAssessmentDetails}
          onClose={handleCloseBookedAssessmentDetails}
          aria-labelledby="modal-add-mock-exam"
          aria-describedby="modal-able-to-add-a-set"
        >
          <Box sx={style}>
            <ViewBookedAssesmentDetails 
              currentAssessmentViewInfo={currentAssessmentViewInfo}
            />
          </Box>
        </Modal>

        <Modal
          open={openRescheduleModal}
          onClose={handleCloseRescheduleModal}
          // aria-labelledby="modal-add-mock-exam"
          // aria-describedby="modal-able-to-add-a-set"
        >
          <Box sx={style}>
            {/* <ViewBookedAssesmentDetails 
              currentAssessmentViewInfo={currentAssessmentViewInfo}
            /> */}
            <RescheduleAppointmentOrAssessment
              rescheduleId={rescheduleId}
              handleUpdatedTable={handleUpdatedTable}
              rescheduleAssessmentOrMeeting={rescheduleAssessmentOrMeeting}
              reasonRequired={reasonRequired}
            />
          </Box>
        </Modal>

      </div>
      </Paper>}


    </div>
  );
}

