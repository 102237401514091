import React, { useState, useEffect } from 'react';
import { Typography, TextField, Checkbox } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';

import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { useFormik } from 'formik';
import * as yup from 'yup';
import * as CONSTANTS from "../../../StringConstants.js"
import TLHClassesAPICalls from 'services/TLHClassesAPICalls.js';

export default function CreateNewClassModel({handleClose}) {

  const [tlhLessons, setTlhLessons] = useState()
  const [isLoadingLessons, setIsLoadingLessons] = useState(true)


  const validationSchema = yup.object({
    className: yup
      .string()
      .required('Class name is required'),
    classStartDate: yup
      .date()
      .required('Lesson start date required'),
    classType: yup
      .string()
      .required('Class Type is required'),
    startTime: yup
      .date()
      .required('Start time required').nullable(),
    subject: yup
      .string()
      .required('Subject is required'),
    roomNumber: yup
      .string()
      .required('Room Number is required'),
    yearGroup: yup
      .string()
      .required('Year Group is required'),
    lessonFrequency: yup.number().when('classType', {
      is: '1_to_1',
      then: yup.number().required('How often will the class run - e.g every 7 days, every 1 day...'),
      otherwise: yup.number().nullable()
    }),
    // dayOfTheWeek: yup
    //   .string()
    //   .required('Day of the Week is required'),
    classEndDate: yup.date().when('classType', {
        is: 'holiday_workshop',
        then: yup.date().required('Class End Date is required for holiday workshops'),
        otherwise: yup.date().nullable()
      }),
    numHoursBooked: yup.date().when('classType', {
        is: '1_to_1',
        then: yup.date().required('Number of booked hours is required for 1 to 1'),
        otherwise: yup.date().nullable()
      }),
  });

  useEffect(() => {
    if (isLoadingLessons) {
      getAllTLHLessons()
    }
  }, [tlhLessons]);

  function getAllTLHLessons() {
    TLHClassesAPICalls.getAllTLHLessons().then(result => {
      // console.log(result.data)
      // setMockExamSets(result.data)
      console.log("Lessons")
      console.log(result.data)
      setTlhLessons(result.data)
      setIsLoadingLessons(false)
    })
  }


  const formik = useFormik({
    initialValues: {
      classStartDate: '',
      classEndDate: '',
      startTime: '',
      className: '',
      classDurationMins: 60,
      subject: '',
      roomNumber: '',
      yearGroup: '',
      // dayOfTheWeek: '',
      classType: '',
      numHoursBooked: '',
      lessonFrequency: '',
      relatedClasses: [],
      duplicateClasses: []
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log("formik values")
      console.log(values)
      console.log(formik.values)
      console.log("Printed values")
      TLHClassesAPICalls.createClassFromTLHClassesDashboard(values)  
      // handleClose(false)
    },
  });


  return (


    <div>
      {isLoadingLessons ? <></> : 
      <form
          onSubmit={formik.handleSubmit}
      >
          <Typography variant="h6" style={{marginBottom: "20px"}}>
            Create new class
          </Typography>

          <div className="relative w-full" style={{ paddingBottom: '20px', display: 'flex', justifyContent: 'space-between'}}>
              <TextField
                label="Class Name (Format: 'Class name - Subject')"
                id="className"
                name="className"
                fullWidth
                value={formik.values.className}
                onChange={formik.handleChange}
                error={formik.touched.className && Boolean(formik.errors.className)}
                helperText={formik.touched.className && formik.errors.className}
            />
          </div>

          <div className="relative w-full" style={{ paddingBottom: '20px', display: 'flex', justifyContent: 'space-between'}}>

            <div style={{ flexBasis: '45%' }}> 

              <TextField
                id="classStartDate"
                name="classStartDate"
                label="Class Start Date"
                type="date"
                style={{paddingRight: '200px'}}
                InputLabelProps={{
                  shrink: true,
                }}
                value={formik.values.classStartDate}
                onChange={formik.handleChange}
                error={formik.touched.classStartDate && Boolean(formik.errors.classStartDate)}
                helperText={formik.touched.classStartDate && formik.errors.classStartDate}
              />
           </div>

          </div>

          <div className="relative w-full" style={{ paddingBottom: '20px', display: 'flex', justifyContent: 'space-between'}}>

            <div style={{ flexBasis: '45%'}}> 
              <LocalizationProvider  dateAdapter={AdapterDateFns}>
                <TimePicker
                    id="startTime"
                    name="startTime"
                    label="Class Start Time (12 hour clock format)"
                    value={formik.values.startTime}
                    onChange={(value) => formik.setFieldValue('startTime', value)}
                    renderInput={(params) => (
                      <TextField 
                        style={{width: '340px'}} 
                        error={formik.touched.startTime && Boolean(formik.errors.startTime)}
                        helperText={formik.touched.startTime && formik.errors.startTime}
                        {...params} 
                      />)}
                  />
              </LocalizationProvider>
            </div>

            <div style={{ flexBasis: '45%' }}> 
              <TextField
                label="Class Duration (Minutes)"
                id="classDurationMins"
                name="classDurationMins"
                type="number"
                value={formik.values.classDurationMins}
                onChange={formik.handleChange}
                error={formik.touched.classDurationMins && Boolean(formik.errors.classDurationMins)}
                helperText={formik.touched.classDurationMins && formik.errors.classDurationMins}  
                inputProps={{
                  min: 1, // Ensures the input is a positive number
                  onInput: (e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 9)
                  }
                }}
              />
            </div>
          </div>

          <div className="relative w-full" style={{ paddingBottom: '20px', display: 'flex', justifyContent: 'space-between'}}>

          <div style={{ flexBasis: '45%' }}>
          <FormControl fullWidth>
            <InputLabel id="subject">Subject</InputLabel>
            <Select
              labelId="subject"
              id="subject"
              name="subject"
              value={formik.values.subject}
              onChange={formik.handleChange}
              fullWidth
              error={formik.touched.subject && Boolean(formik.errors.subject)}
            >
              {CONSTANTS.TLHSubjects.map((subject, index) => (
                <MenuItem key={index} value={subject.toLowerCase()}>
                  {subject.charAt(0).toUpperCase() + subject.slice(1).toLowerCase()}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          </div>

          <div style={{ flexBasis: '45%' }}>

            <FormControl fullWidth>
              <InputLabel id="roomNumber">Room Number</InputLabel>
              <Select
                labelId="roomNumber"
                id="roomNumber"
                name="roomNumber"
                value={formik.values.roomNumber}
                onChange={formik.handleChange}
                error={formik.touched.roomNumber && Boolean(formik.errors.roomNumber)}
              >
                {CONSTANTS.TLHRooms.map((subject, index) => (
                  <MenuItem key={index} value={subject.toLowerCase()}>
                    {subject.replace("_", " ")}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

           </div>
          </div>

          <div className="relative w-full" style={{ paddingBottom: '20px', display: 'flex', justifyContent: 'space-between'}}>

            <FormControl fullWidth style={{ maxWidth: '250px', paddingBottom: '20px' }}>
              <InputLabel id="yearGroup">Level</InputLabel>
              <Select
                labelId="yearGroup"
                id="yearGroup"
                name="yearGroup"
                value={formik.values.yearGroup}
                onChange={formik.handleChange}
                fullWidth
                error={formik.touched.yearGroup && Boolean(formik.errors.yearGroup)}
              >
                {CONSTANTS.TLHYearGroups.map((subject, index) => (
                  <MenuItem key={index} value={subject.toLowerCase()}>
                    {subject.replace("_", " ")}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

          </div>

    
          <FormControl fullWidth style={{paddingBottom: "20px"}}> 
            <InputLabel id="classType">Class Type</InputLabel>
            <Select
              labelId="classType"
              id="classType"
              name="classType"
              value={formik.values.classType}
              onChange={formik.handleChange}
              fullWidth
              error={formik.touched.classType && Boolean(formik.errors.classType)}
            >
              <MenuItem value="holiday_workshop">Holiday Workshop</MenuItem>
              <MenuItem value="weekly_class">Weekly Class</MenuItem>
              <MenuItem value="one_to_one">1 to 1</MenuItem>
              <MenuItem value="booster_class">Booster Class</MenuItem>
            </Select>
          </FormControl>


          {formik.values.classType === "holiday_workshop" || formik.values.classType === "booster_class" ? 
            <div style={{ flexBasis: '45%' }}> 

              <TextField
                id="classEndDate"
                name="classEndDate"
                label="Date of Last Class"
                type="date"
                fullWidth
                style={{paddingRight: '200px'}}
                InputLabelProps={{
                  shrink: true,
                }}
                value={formik.values.classEndDate}
                onChange={formik.handleChange}
                error={(formik.values.classType === "holiday_workshop" || formik.values.classType === "booster_class") && formik.touched.classEndDate && Boolean(formik.errors.classEndDate)}
                helperText={formik.touched.classEndDate && formik.errors.classEndDate}
              />
            </div>

            : <></>}

          {formik.values.classType === "one_to_one" ? 
            <div className="relative w-full" style={{ paddingBottom: '20px', display: 'flex', justifyContent: 'space-between'}}>
              <div style={{ flexBasis: '45%' }}> 
                  <TextField
                    numHoursBooked
                    label="Number of Hours Booked"
                    id="numHoursBooked"
                    name="numHoursBooked"
                    type="number"
                    style={{width: '240px'}} 
                    value={formik.values.numHoursBooked}
                    onChange={formik.handleChange}
                    error={formik.values.classType === "one_to_one" && formik.touched.numHoursBooked && Boolean(formik.errors.numHoursBooked)}
                    helperText={formik.touched.numHoursBooked && formik.errors.numHoursBooked}  
                    inputProps={{
                      min: 1, // Ensures the input is a positive number
                      onInput: (e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 9)
                      }
                    }}
                  />
                </div>
                
                <div style={{ flexBasis: '45%' }}> 
                <TextField
                  label="How often do lessons occur (every 7 days if weekly)"
                  id="lessonFrequency"
                  name="lessonFrequency"
                  type="number"
                  style={{width: '440px'}} 
                  value={formik.values.lessonFrequency}
                  onChange={formik.handleChange}
                  error={formik.touched.lessonFrequency && Boolean(formik.errors.lessonFrequency)}
                  helperText={formik.touched.lessonFrequency && formik.errors.lessonFrequency}  
                  inputProps={{
                    min: 1, // Ensures the input is a positive number
                    onInput: (e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 9)
                    }
                  }}
                />
              </div>
            </div>

               : <></>}

          <FormControl fullWidth style={{ paddingBottom: '20px' }}>
            <InputLabel id="relatedClasses">Related Classes</InputLabel>
            <Select
              labelId="relatedClasses"
              id="relatedClasses"
              name="relatedClasses"
              multiple
              value={formik.values.relatedClasses}
              onChange={formik.handleChange}
              renderValue={(selected) => selected.map(id => tlhLessons.find(lesson => lesson.classId === id)?.className).join(', ')}
              error={formik.touched.relatedClasses && Boolean(formik.errors.relatedClasses)}
            >
              {tlhLessons.map((lesson) => (
                <MenuItem key={lesson.classId} value={lesson.classId}>
                  {lesson.className}
                </MenuItem>
              ))}
            </Select>
          </FormControl>  

          <Typography>
            Is this class a duplicate of any existing classes (same lesson material but running at different times)
          </Typography>

          <FormControl fullWidth style={{ paddingBottom: '20px' }}>
            <InputLabel id="duplicateClasses">Duplicate Classes</InputLabel>
            <Select
              labelId="duplicateClasses"
              id="duplicateClasses"
              name="duplicateClasses"
              multiple
              value={formik.values.duplicateClasses}
              onChange={formik.handleChange}
              renderValue={(selected) => selected.map(id => tlhLessons.find(lesson => lesson.classId === id)?.className).join(', ')}
              error={formik.touched.duplicateClasses && Boolean(formik.errors.duplicateClasses)}
            >
              {tlhLessons.map((lesson) => (
                <MenuItem key={lesson.classId} value={lesson.classId}>
                  {lesson.className}
                </MenuItem>
              ))}
            </Select>
          </FormControl>  

          <div style={{textAlign: "center", paddingTop: "25px"}}>
            <button 
                style={{
                  padding: '10px 20px',
                  fontSize: '18px',
                  color: 'white',
                  backgroundColor: '#0056b3',
                  border: 'none',
                  borderRadius: '5px',
                  boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                  cursor: 'pointer',
                  transition: 'all 0.3s ease',
              }}
              onMouseOver={(e) => {
                  e.target.style.backgroundColor = '#004085';
                  e.target.style.boxShadow = '0 8px 16px rgba(0,0,0,0.2)';
              }}
              onMouseOut={(e) => {
                  e.target.style.backgroundColor = '#0056b3';
                  e.target.style.boxShadow = '0 4px 8px rgba(0,0,0,0.1)';
              }}
              type="submit"
            >
                Create New Class
            </button>
          </div>
      </form>
    }
    </div>

  );
}
