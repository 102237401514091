import React, { useState } from 'react';
import { useHistory } from "react-router";

import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import QuestionsAndUserDataService from '../../services/QuestionsAndUserDataService'
import * as CONSTANTS from "../../StringConstants"


export default function PastWorksheets({data, switchToReviewMode}) {

  const [hasLoadedWorksheet, setHasLoadedWorksheet] = useState(false)
  let history = useHistory();

  // console.log("Previous worksheets")
  // console.log(data)

  function loadWorksheet(worksheetId, subject) {
    console.log(worksheetId)
    QuestionsAndUserDataService.getWorksheetQuestions(worksheetId).then(questions => {
      console.log("Just retrieved worksheet")
      console.log(questions)
      if (questions.data.worksheetQuestions.length == 0) {
        console.log("ERROR - REQUESTED WORKSHEET SHOULDN'T EXIST - HAS 0 QUESTIONS") 
        console.log("Worksheet info")
        console.log(questions)
        console.log("Keeping on current page - no action")
        return
      }
      if (questions.data.comprehensionDescription === "") {
        let sortedQuestions = questions.data.worksheetQuestions.sort((a,b) => a.questionNumber > b.questionNumber ? 1 : -1)
        switchToReviewMode(sortedQuestions, 0)
      } else {
        switchToReviewMode(questions.data, 0)
      }
      setHasLoadedWorksheet(true)
      if (subject === "Verbal Reasoning") {
        history.push(CONSTANTS.VRTopicsRoute)
      } 
      if (subject == "Maths") {
        history.push(CONSTANTS.MathTopicsRoute)
      }
      if (subject == "English") {
        history.push(CONSTANTS.EnglishTopicsRoute)
      }
    })
  }

  return (
    <React.Fragment>
      <Title>Recent Worksheets</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Topic</TableCell>
            <TableCell>Question type(s)</TableCell>
            <TableCell>Score</TableCell>
            {/*<TableCell align="right">Sale Amount</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.worksheetId}>
              <TableCell>{row.date}</TableCell>
              <TableCell><Link onClick={() => loadWorksheet(row.worksheetId, row.subject)}>{row.topic}</Link></TableCell>
              <TableCell>{row.questionTypes}</TableCell>
              <TableCell>{row.numberQuestionsCorrectlyAnswered}/{row.numberOfQuestionsInTheWorksheet}</TableCell>
              {/* <TableCell align="right">{`$${row.amount}`}</TableCell> */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {/* If theres a need to limit the number of worksheets shown */}
      {/* <Link color="primary" href="#" onClick={preventDefault} sx={{ mt: 3 }}>
         See older worksheets
      </Link> */}
    </React.Fragment>
  );
}
