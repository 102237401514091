import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

// Component imports

import Title from "./../AnalyticsComponents/Title.js"
import CreateHeadTeacherForm from 'views/admin/CreateHeadTeacherForm.js';
import AddSchool from "./AddSchool.js"

//MUI imports 

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@material-ui/core/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import QuestionsAndUserDataService from '../../services/QuestionsAndUserDataService'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function HeadTeacher() {

  const [open, setOpen] = useState(false);
  const [isLoadingTeacherData, setIsLoadingTeacherData] = useState(true)
  const [isLoadingSchoolData, setIsLoadingSchoolData] = useState(true)
  const [teacherData, setTeacherData] = useState([])
  // const [teachers, setTeachers] = useState([])
  const [school, setSchool] = useState([])
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  useEffect(() => {
    if (isLoadingTeacherData) {
        getCurrentHeadTeacherInfo()
    }
    if (isLoadingSchoolData) {
      getSchool()
    }
  });

  function getTeacherData(teacherId) {

  }

  function getCurrentHeadTeacherInfo() {
    QuestionsAndUserDataService.getAllTeacherDataForHeadTeacher().then(teacherData => {
      console.log(teacherData)
      setIsLoadingTeacherData(false)
      setTeacherData(teacherData.data)
      if (teacherData.data.school) {
        setSchool(teacherData.data.school.schoolName)
      }
    })
  }

  function getSchool() {
    QuestionsAndUserDataService.getSchool()
      .then(schoolData => {
        console.log("School data")
        console.log(schoolData)
        if (schoolData) {
          setSchool(schoolData)
        }
        setIsLoadingSchoolData(false)
      })
  }

  function countNumberOfStudents(teacher) {
    var totalNumberOfStudents = 0
    for (let i = 0; i < teacher.classes.length; i++) {
      totalNumberOfStudents += teacher.classes[i].studentsInClass.length
    }
    return totalNumberOfStudents
  }

  function giveClassNames(teacher) {
    var classNames = ""
    for (let i = 0; i < teacher.classes.length; i++) {
      classNames += teacher.classes[i].className + ", "
    }
    return classNames === "" ? "No classes yet" : classNames.substring(0, classNames.length - 2)
  }


  return (
    
    <div className='p-4'>
      <Title>Teachers {school.data ? "at " + school.data.schoolName : ""}</Title>
      {isLoadingTeacherData ? "Loading ..." :
      (school === []) ? 
       "No teachers added yet. Add your school info before you can add your teachers and classes" 
        :

    <div>
      <Table size="small">
        <TableHead>
          <TableRow>
            {/* Clicking schools should take us to a school info page */}
            <TableCell>Teacher</TableCell> 
            <TableCell>Classes</TableCell>
            <TableCell>Number of students</TableCell>

            {/*<TableCell align="right">Sale Amount</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {teacherData.map((teacher) => (
            <TableRow key={teacher.teacherId}>
              <TableCell>{teacher.firstName + " " + teacher.surname}</TableCell>
              <TableCell>{giveClassNames(teacher)}</TableCell>
              <TableCell>{countNumberOfStudents(teacher)}</TableCell>
              <TableCell><Link onClick={() => getTeacherData()}>Edit</Link></TableCell>
              <TableCell><Button>Change Password</Button></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table> 

      <div>
      {/* <Button variant="contained" onClick={addTeacher()}>Add teacher</Button> */}
      <Button variant='contained' onClick={handleOpen}>Add teacher</Button>
        <Modal
          open={open}
          onClose={handleClose}
        >
          <Box sx={style}>
            <CreateHeadTeacherForm isHeadTeacher={false} schoolId={school.data ? school.data.schoolId : -1}/>
          </Box>
        </Modal>
      </div>

      </div>}

      {school === "" ? 
      <div>
      {/* <Button variant="contained" onClick={addTeacher()}>Add teacher</Button> */}
      <Button variant='contained' onClick={handleOpen}>Add School</Button>
        <Modal
          open={open}
          onClose={handleClose}
        >
          <Box sx={style}>
            <AddSchool/>
          </Box>
        </Modal>
      </div> : <div></div>}
    </div>
  );
}
