import React from "react";
import { Switch, Redirect } from "react-router-dom";

// components

import UserNavbar from "components/Navbars/UserNavbar.js";
import TeacherSidebar from "components/Sidebar/TeacherSidebar";
import HeaderStats from "components/Headers/HeaderStats.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";

// views

import HeadTeacher from "views/Teacher/HeadTeacher";
import ClassesView from "views/Teacher/ClassesView.js";
import ProtectedRoute from "views/auth/ProtectedRoute";
import CreateClassForm from "views/Teacher/TeacherComponents/CreateNewClassForm.js";
import ViewClass from "views/Teacher/ViewClass.js"
import ViewStudent from "views/Teacher/ViewStudent.js";
import AddAssignmentView from "views/Teacher/AddAssignmentView.js";

// Routes
import * as CONSTANTS from "../StringConstants.js"

export default function Teacher() {
  return (
    <>
    <div className="relative md:ml-64">

    <TeacherSidebar/>
    <UserNavbar/>
    <HeaderStats />

          {/* <div
            className="absolute top-0 w-full h-full bg-blueGray-800 bg-no-repeat bg-full"
            style={{
              backgroundImage:
                "url(" + require("assets/img/register_bg_2.png").default + ")",
            }}
          ></div> */}
          <Switch>
            {/* <Route path="/teacher" exact component={teacherDashboard} /> */}
            <ProtectedRoute path={CONSTANTS.HeadTeacherDashboard} exact component={HeadTeacher} />
            <ProtectedRoute path={CONSTANTS.ClassesView} exact component={ClassesView} />
            <ProtectedRoute path={CONSTANTS.CreateNewClass} exact component={CreateClassForm} />
            <ProtectedRoute path={CONSTANTS.ViewClass + "/:classId"} exact component={ViewClass} />
            <ProtectedRoute path={CONSTANTS.ViewStudent + "/:studentId"} exact component={ViewStudent} />
            <ProtectedRoute path={CONSTANTS.AddAssignmentView} exact component={AddAssignmentView} />

            <Redirect from="/auth" to="/auth/login" />
          </Switch>
          <FooterAdmin/>
      </div>
    </>
  );
}
