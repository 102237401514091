import React from 'react';
import { AppBar, Toolbar, Typography } from '@mui/material';

const TLHHeaderComponent = () => {
  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div">
            The Learning Hub 
          </Typography>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default TLHHeaderComponent;
