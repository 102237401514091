import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useHistory } from "react-router-dom";


import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';

import QuestionsAndUserDataService from '../../services/QuestionsAndUserDataService'


export default function CreateHeadTeacherForm({isHeadTeacher, schoolId}) {

  let history = useHistory();
  const [title, setTitle] = React.useState("");   
  
  const handleChange = (event) => {
    setTitle(event.target.value);
  };

  const validationSchema = yup.object({
    firstName: yup
      .string('Enter your name'),
    lastName: yup
      .string('Enter your name'),
    email: yup
      .string('Enter your email')
      .email('Enter a valid email')
      .required('Email is required'),
  });


  // const WithMaterialUI = () => {
    const formik = useFormik({
      initialValues: {
        firstName: '',
        lastName: '',
        email: '',
        customCheckLogin: false,
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {

        console.log("formik values")
        console.log(formik.values.firstName)
        console.log(formik.values.lastName)
        console.log(formik.values.email)
        console.log(schoolId)

        QuestionsAndUserDataService.createTeacher(formik.values.firstName, formik.values.lastName, formik.values.email, title, isHeadTeacher, schoolId).then(history.push((isHeadTeacher ? "/app/admin-whizz" : "/teacher/headteacher")))
        // updateUserDetails(formik.values.firstName, formik.values.lastName, formik.values.email).then(onStepChange(2))

      },
    });

  return (
    
    <div>
     <form
        onSubmit={formik.handleSubmit}
        >
         <label> Teacher's details</label>
        <div className="relative w-full mb-3">

        <TextField
            fullWidth
            id="firstName"
            name="firstName"
            label="First Name"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
          />

        </div>

        <div className="relative w-full mb-3">

          <TextField
              fullWidth
              id="lastName"
              name="lastName"
              label="Last Name"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
            />

          </div>


        <div className="relative w-full mb-3">
        <TextField
          fullWidth
          id="email"
          name="email"
          label="Email*"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        </div>
      
        <Box style={{textAlign:"center"}}>

          <InputLabel id="title">Title</InputLabel>
          <Select
            labelId="title"
            id="title"
            value={title}
            autoWidth
            label="Title"
            onChange={handleChange}
            className="px-2"
          >
              <MenuItem value="MR">Mr</MenuItem>
              <br></br>
              <MenuItem value="MRS">Mrs</MenuItem>
              <br></br>
              <MenuItem value="MISS">Miss</MenuItem>
              <br></br>
              <MenuItem value="MS">Ms</MenuItem>
          </Select>

          </Box>



        <div className="text-center mt-6">
          <Button
            colour="primary"
            className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
            type="submit"
          >
            Add Teacher
          </Button>
        </div>
      </form>
    </div>
  );
}
