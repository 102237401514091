import React, { useEffect } from 'react';
import "../../assets/styles/index.css";
import { Link } from "react-router-dom";

import * as CONSTANTS from "../../StringConstants.js"


import { styled, useTheme } from '@mui/material/styles';
import List from '@mui/material/List';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';

import GraduationCapImg from "../../assets/mockExamImages/graduationCap.png"
import calendarIconImg from "../../assets/mockExamImages/calendarIcon.png"
import notepadIconImg from "../../assets/mockExamImages/notepadIcon.png"
import backToQuestionsIconImg from "../../assets/mockExamImages/backToQuestionsIcon.png"
import practiseExamIconImg from "../../assets/mockExamImages/exam.png"

const drawerWidth = 260;


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // backgroundColor: 'purple',
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));


export default function SideDrawer({drawerStatus, handleDrawerClose}) {

  // const [isLoadingMockExams, setIsLoadingMockExams]  = useState(true)
  // const [userMockExams, setUserMockExams] = useState([])
  const theme = useTheme();
  const [open, setOpen] = React.useState(drawerStatus);
  const user = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    if (drawerStatus) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  });

  function isStandardSubscriptionUser(roles) {
    console.log("Roles for this use include")
    console.log(roles)
    for (var i = 0; i < roles.length; i ++) {
      if (roles[i] == "ROLE_USER" || roles[i] == "ROLE_FREE_USER") {
        return true
        }
    }
    return false
}


  return (
    <Drawer variant="permanent" open={open}>
    <DrawerHeader>
      <IconButton style={{background: "lightgray", fontSize: "18px"}} onClick={handleDrawerClose}>
        Close {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
      </IconButton>
    </DrawerHeader>
    <Divider />
    <List>

    <Link to={CONSTANTS.MockExamDashboard}>
      <ListItem key={"Instructions Dashboard"} disablePadding sx={{ display: 'block' }}>
          <ListItemButton
            sx={{
              minHeight: 48,
              // minWidth: 100,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                maxWidth: 40,
                mr: open ? 3 : 'auto',
                justifyContent: 'center',
              }}
            >
              {/* {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
              {/* <GraduationCapImg /> */}
              <img src={notepadIconImg} alt="" />                           
            </ListItemIcon>
            <ListItemText primary={"Instructions Dashboard"} sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
      </Link>

      {/* {[('Upcoming Exam Dates', <InboxIcon />, "hi"), ('Review Past Exams', <MailIcon />, "hi")].map((text, icon, hi) => ( */}
       <Link to={CONSTANTS.MockExamUpcomingExams}>
          <ListItem key={"Upcoming Exam Dates"} disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                // minWidth: 100,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  maxWidth: 40,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                {/* {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                {/* <MailIcon /> */}
                <img src={calendarIconImg} alt="" />              
              </ListItemIcon>
              <ListItemText primary={"Upcoming Exam Dates"} sx={{ opacity: open ? 1 : 0 }} />
              
            </ListItemButton>
          </ListItem>
        </Link>

        <Link to={CONSTANTS.MockExamPastExams}>
        <ListItem key={"Review Past Exams"} disablePadding sx={{ display: 'block' }}>
          <ListItemButton
            sx={{
              minHeight: 48,
              // minWidth: 100,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                maxWidth: 40,
                mr: open ? 3 : 'auto', 
                justifyContent: 'center',
              }}
            >
              {/* {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
              {/* <MailIcon /> */}
              <img src={GraduationCapImg} alt="" /> 

            </ListItemIcon>
            <ListItemText primary={"Review Past Exams"} sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
        </Link>

        <Link to={CONSTANTS.MockExamPractisePage}>
        <ListItem key={"Practice Exam"} disablePadding sx={{ display: 'block' }}>
          <ListItemButton
            sx={{
              minHeight: 48,
              // minWidth: 100,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                maxWidth: 40,
                mr: open ? 3 : 'auto',
                justifyContent: 'center',
              }}
            >
              {/* {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
              {/* <MailIcon /> */}
              <img src={practiseExamIconImg} alt="" /> 

            </ListItemIcon>
            <ListItemText primary={"Practice Exam"} sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
        </Link>

   

      {/* ))} */}
    </List>
    { isStandardSubscriptionUser(user.roles) ? <>
    <Divider />
      <List>
        <Link to={CONSTANTS.AnalyticsPage}>
          <ListItem key={"Back to Whizzdom Questions"} disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                // minWidth: 100,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  maxWidth: 40,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                {/* {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                {/* <MailIcon /> */}
                <img src={backToQuestionsIconImg} alt="" /> 

              </ListItemIcon>
              <ListItemText primary={"Questions Portal"} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          </Link>
       </List>
       </>
      :
      <></>
      
      }
     {/* {['All mail', 'Trash', 'Spam'].map((text, index) => (
        <ListItem key={text} disablePadding sx={{ display: 'block' }}>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : 'auto',
                justifyContent: 'center',
              }}
            >
              {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
            </ListItemIcon>
            <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
      ))}
    </List> */}
  </Drawer>
  );
}
