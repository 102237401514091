import axios from 'axios'
import authHeader from "./auth-header";


const LOCAL_HOST = 'https://localhost:8443'
const PRODUCTION_PORT = 'https://whizzdom.co.uk:8443'
const LOCAL_OR_PRODUCTION = PRODUCTION_PORT;
const senderEmailApiToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiZjMwY2UwZWI2MGNlOGYwODQyYzkyMTZjZGQwYjcwMGQ2ZDNmOWYwOTk3ZDlmN2QzZDQ4NzA0ODAwMmJiZGQxZWRlNjcxOTJkOTE5Y2VlNmUiLCJpYXQiOjE2NTc1Mjk2NjkuMDM4NjEsIm5iZiI6MTY1NzUyOTY2OS4wMzg2MTksImV4cCI6NDgxMTEzMzI2OS4wMzU5NDEsInN1YiI6Ijc0MTI0MiIsInNjb3BlcyI6W119.dVuzJsyZsWGgiDDx_WNT2z25We4-I63ePYrpB6-Hf515XvLYqG2Iy1GsfgIv2Er8qS-k0lZbynrEpyKVzrNsUg"

const POSTMAN_CONVERSION_API = "https://graph.facebook.com/v15.0/631827471693892/events?access_token=EAARb48tiFHcBACXkoNBrZB7CRZCNDJT4jze0ZBRfaj7CaocBuYQ7UKyljNCuuGxroU8efLUguJ0O7K7nJxt1nZAO7bDySmZCb3O2F4ARo4RU1tC39ZA8AGjp3ctXRokhWW56IXjgZAmsoZCza9G7SbSfj7hxX9F1xyTMzDM0YISbH5pMrdgduTIRPRK1IeKReoYZD"
const FB_CONVERSION_API_VERSION = "v15.0"
const FB_PIXEL_ID = "631827471693892"
const FB_ACCESS_KEY_TOKEN = "EAARb48tiFHcBACXkoNBrZB7CRZCNDJT4jze0ZBRfaj7CaocBuYQ7UKyljNCuuGxroU8efLUguJ0O7K7nJxt1nZAO7bDySmZCb3O2F4ARo4RU1tC39ZA8AGjp3ctXRokhWW56IXjgZAmsoZCza9G7SbSfj7hxX9F1xyTMzDM0YISbH5pMrdgduTIRPRK1IeKReoYZD"
const FB_CONVERSION_API_ENDPOINT = "https://graph.facebook.com/" + FB_CONVERSION_API_VERSION + "/" + FB_PIXEL_ID + "/events?access_token=" + FB_ACCESS_KEY_TOKEN

// This changes often
const test_event_code = "TEST81581"

async function encodeInSHA256(message) {
    // encode as UTF-8
    const msgBuffer = new TextEncoder().encode(message);                    

    // hash the message
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

    // convert ArrayBuffer to Array
    const hashArray = Array.from(new Uint8Array(hashBuffer));

    // convert bytes to hex string                  
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
}

class QuestionsAndUserDataService {

      //creating function to load ip address from the API
    getQuestions(subject, parent, questionTopic, questionType, numQuestions) {
           console.log("Request being made")
           console.log("get Questions request being made")
           console.log("Parent: " + parent)
           console.log("questionTopic: " + questionTopic)
           console.log("questionType: " + questionType)
           console.log("numQuestions: " + numQuestions )
           console.log("User email is: ")
           const user = JSON.parse(localStorage.getItem('user'));
           console.log(user.email)
           let userEmail = user.email;

           return axios.get(`${LOCAL_OR_PRODUCTION}/getSomeQuestions/${subject}/${parent}/${questionTopic}/${questionType}/${numQuestions}/${userEmail}`, {headers: authHeader() });
       }

       getEnglishQuestions(questionTopic, numQuestions) {
           const user = JSON.parse(localStorage.getItem('user'));
           console.log(user.email)
           let userEmail = user.email;
           return axios.post(LOCAL_OR_PRODUCTION + `/getEnglishQuestions`, {
            email: userEmail,
            questionTopic: questionTopic,
            numQuestions: numQuestions
        }, {headers: authHeader() });

       }

       getEnglishComprehension() {
        const user = JSON.parse(localStorage.getItem('user'));
        console.log(user.email)
        let userEmail = user.email;
        return axios.post(LOCAL_OR_PRODUCTION + `/getEnglishComprehension`, {
         email: userEmail,
     }, {headers: authHeader() });
       }

    updateUserQuestionAnalytics(parentTile, subTopicIfItExists, topicsList, topicsQuantitiesList, numQuestionsCorrectPerTopicList, numQuestionsLeftBlankPerTopic, worksheetQuestionsId, currentSubject, userAnswers, correctAnswers) {
        console.log("Updating correct answer metric")
        const user = JSON.parse(localStorage.getItem('user'));
        console.log(user.email)
        let userEmail = user.email;
        console.log("current subject: ")
        console.log(currentSubject)


        return axios.post(LOCAL_OR_PRODUCTION + `/updateUserQuestionAnalytics`, {
            email: userEmail,
            parent: parentTile, 
            questionTopic: subTopicIfItExists,
            topicsList: topicsList,
            topicsQuantitiesList: topicsQuantitiesList,
            numQuestionsCorrectPerTopicList: numQuestionsCorrectPerTopicList,
            numQuestionsLeftBlankPerTopic: numQuestionsLeftBlankPerTopic,
            worksheetQuestionsId: worksheetQuestionsId,
            currentSubject: currentSubject,
            userAnswers: userAnswers,
            correctAnswers: correctAnswers
        }, {headers: authHeader() });
    }

    saveWorksheet(parentTile,
        subTopicIfItExists,
        topicsList,
        numCorrectlyAnsweredQuestions,
        topicsQuantitiesList,
        numQuestionsInWorksheet, 
        subject, 
        workSheetQuestions, 
        correctAnswers, 
        userAnswers,
        isCorrectList,
        multipleChoiceAnswerOptions,
        worksheetSolutions,
        extraWorksheetData) {

            const user = JSON.parse(localStorage.getItem('user'));
            console.log(user.email)
            let userEmail = user.email;

            console.log("Worksheet Questions")
            console.log(workSheetQuestions)
            console.log(subject)
            console.log(userAnswers)
            console.log(correctAnswers)
            console.log("Worksheet Solutions")
            console.log(worksheetSolutions)
            console.log("Extra worksheet data")
            console.log(extraWorksheetData)

            return axios.post(LOCAL_OR_PRODUCTION + `/saveWorksheet`, {
                email: userEmail,
                parentTopic: parentTile,
                subTopicIfItExists: subTopicIfItExists,
                questionTypes: topicsList,
                subject: subject, 
                workSheetQuestions: workSheetQuestions,
                correctAnswers: correctAnswers,
                userAnswers: userAnswers,
                numCorrectlyAnsweredQuestions: numCorrectlyAnsweredQuestions,
                topicsQuantitiesList: topicsQuantitiesList,
                numQuestionsInWorksheet: numQuestionsInWorksheet,
                isCorrectList: isCorrectList,
                multipleChoiceAnswerOptions: multipleChoiceAnswerOptions,
                worksheetSolutions: worksheetSolutions,
                extraWorksheetData: extraWorksheetData
            }, {headers: authHeader() })

    }

    validateUserLogin(loginData, loginType) {
      console.log("Validating user")
      console.log("Login type:")
      console.log(loginType)
        return axios.post(LOCAL_OR_PRODUCTION + `/auth/validateUser`, {
            email: loginData.email,
            password: loginData.password,
            loginType: loginType
          }).then((response) => {
            console.log("Response: ")
            console.log(response);
            if (response.data.accessToken) {
                console.log("Printing user access token")
                console.log(JSON.stringify(response.data.accessToken))
                console.log(response.data)
                localStorage.setItem("user", JSON.stringify(response.data))
            }
            console.log("Endpoint: " + LOCAL_OR_PRODUCTION + `/auth/validateUser`)
            var role = response.data.roles
            return role;
        }, (error) => {
            console.log("Error")
            console.log(error)
            console.log(error.headers)
            console.log("Endpoint: " + LOCAL_OR_PRODUCTION + `/auth/validateUser`)
            return false
        })
    }

    registerUserDetails(registrationData) {
        return axios.post(LOCAL_OR_PRODUCTION + `/auth/registerUser`,{
            studentName: registrationData.name,
            parentEmail: registrationData.email,
            password: registrationData.password,

        }).then((response) => {
            console.log("User registered")
            console.log(response);
            console.log("Endpoint: " + LOCAL_OR_PRODUCTION + `/auth/validateUser`)
            return true
        }, (error) => {
            console.log("error registering user")
            console.log(error);
            console.log("Endpoint: " + LOCAL_OR_PRODUCTION + `/auth/validateUser`)
            return false
        })
    }

    getTimeLeftOnFreemiumTrial() {
        const user = JSON.parse(localStorage.getItem('user'));
        let userEmail = user.email;

        return axios.post(LOCAL_OR_PRODUCTION + `/getTimeLeftOnFreemiumTrial`,{
            // Any params can be passed here
            email: userEmail
        }, {headers: authHeader() }).then((response) => {
            console.log("Response " + response.data)
            return response
        }, (error) => {
            console.log("Unable to check trial")
            return null
        })
    }

    createCheckoutSession(signUpData, ip, productId, mockExamSessionId) {
        console.log("Sending sign up data - " + signUpData)

        console.log(signUpData.studentName)
        console.log(signUpData.dateOfBirth)
        console.log(signUpData.password)

        console.log(signUpData.parentFirstName)
        console.log(signUpData.parentLastName)
        console.log(signUpData.parentEmail)

        let clientUserAgent = navigator.userAgent;

        // Conversion API info 
        console.log("Sending intiateCheckout Conversion API update")
        this.iniateCheckoutConversionAPIUpdate(signUpData.parentEmail, signUpData.parentFirstName, signUpData.parentLastName, ip).then(result => {
            console.log(result)
          })


        return axios.post(LOCAL_OR_PRODUCTION + `/payments/create-checkout-session` ,{
            // Details required for sign up 
            studentName: signUpData.name,
            studentDob: signUpData.studentDob,
            parentFirstName: signUpData.parentFirstName,
            parentLastName: signUpData.parentLastName,
            parentEmail: signUpData.parentEmail,
            password: signUpData.password,
            acceptedTerms: signUpData.acceptTerms,
            ip: ip,
            clientUserAgent: clientUserAgent,
            productId: productId,
            mockExamSessionId: mockExamSessionId
            // Details still required for FB Conversion Api
            // click id 

        }).then((response) => {
            //Evaluate response and see return success page if success else fail
            // Need to find out what happens if  unable to return a url - then handle error based on that response
            console.log("Redirect to " + response.data)
            this.sendWelcomeEmailToNewSubscriber(signUpData.parentEmail, true, ["eXQLMV"])
            window.location.href = response.data;

        }, (error) => {
            console.log("Redirect to a registration error page - error when creating checkout session")
            console.log("Error" + error)
        })
    }

    createCustomerPortal() {
        const user = JSON.parse(localStorage.getItem('user'));
        let userEmail = user.email;

        return axios.post(LOCAL_OR_PRODUCTION + `/payments/create-portal-session` ,{
            // Any params can be passed here
            email: userEmail
        }).then((response) => {
            //Evaluate response and see return success page if success else fail
            // Need to find out what happens if  unable to return a url - then handle error based on that response
            console.log("Redirect to " + response.data)
            window.location.href = response.data;

        }, (error) => {
            console.log("Redirect to a registration error page - creating customer portal")
        })
    }


    createCheckoutSessionFromDashboard(productId, mockExamSessionId) {
        
        const user = JSON.parse(localStorage.getItem('user'));
        let userEmail = user.email;


        return axios.post(LOCAL_OR_PRODUCTION + `/payments/create-checkout-session-from-dashboard` ,{
            // Details required for sign up 
            parentEmail: userEmail,
            productId: productId,
            mockExamSessionId: mockExamSessionId
        }).then((response) => {
            //Evaluate response and see return success page if success else fail
            // Need to find out what happens if  unable to return a url - then handle error based on that response
            console.log("Redirect to " + response.data)
            // this.sendWelcomeEmailToNewSubscriber(signUpData.parentEmail, true, ["eXQLMV"])
            window.location.href = response.data;

        }, (error) => {
            console.log("Redirect to a registration error page - error when creating checkout session")
            console.log("Error" + error)
        })
    }

    directCustomerToRelevantMockExamCheckout(firstName, lastName, email, selectedExamProduct, hasAcceptedTAndCs) {
        console.log("Taking customer to payments checkout")
        return axios.post(LOCAL_OR_PRODUCTION + `/payments/direct-customer-mock-exam-checkout` ,{
            // Any params can be passed here
            parentFirstName: firstName,
            parentLastName: lastName,
            parentEmail: email,
            selectedExamProduct: selectedExamProduct,
            acceptedTerms: hasAcceptedTAndCs

        }).then((response) => {
            //Evaluate response and see return success page if success else fail
            // Need to find out what happens if  unable to return a url - then handle error based on that response
            console.log("Redirect to " + response.data)
            // this.sendWelcomeEmailToNewSubscriber(signUpData.parentEmail, true)
            window.location.href = response.data;

        }, (error) => {
            console.log("Redirect to a registration error page - error when creating checkout session")
            console.log("Error" + error)
        })
    }
    // redirectToPaymentLink(link) {
    //     // code to redirect to payment link 
    // }

    createFreeStandardQuestionsUserAndSendLoginInstructions(firstName, lastName, email, password, childName, fbAdId, fbAdName) {
        console.log("Signing user up for free standard questions account")
         return axios.post(LOCAL_OR_PRODUCTION + `/auth/createFreeStandardQuestionsUserAndSendLoginInstructions` ,{
            parentFirstName: firstName,
            parentLastName: lastName,
            parentEmail: email,
            password: password,
            childName: childName, 
            fbAdId: fbAdId,
            fbAdName: fbAdName
        }).then((response) => {
            //Evaluate response and see return success page if success else fail
            // Need to find out what happens if  unable to return a url - then handle error based on that response
            console.log("Redirect to " + response.data)
            // this.sendWelcomeEmailToNewSubscriber(signUpData.parentEmail, true)
        }, (error) => {
            console.log("error when creating free standard user account")
            console.log("Error" + error)
        })
    }



     createFreeMockExamUserAndSendLoginInstructions(firstName, lastName, childName, email, telephone, fbAdId, fbAdName) {
        console.log("Signing user up for free exam")
         return axios.post(LOCAL_OR_PRODUCTION + `/auth/createFreeMockExamUserAndSendLoginInstructions` ,{
            parentFirstName: firstName,
            parentLastName: lastName,
            parentEmail: email,
            childName: childName, 
            telephone: telephone,
            fbAdId: fbAdId, 
            fbAdName: fbAdName

        }).then((response) => {
            //Evaluate response and see return success page if success else fail
            // Need to find out what happens if  unable to return a url - then handle error based on that response
            console.log("Redirect to " + response.data)
            // this.sendWelcomeEmailToNewSubscriber(signUpData.parentEmail, true)

        }, (error) => {
            console.log("Redirect to a registration error page - error when creating checkout session")
            console.log("Error" + error)
        })
    }


    getNextFreeMockExamSession() {
        console.log("Getting next free exam session date")
         return axios.post(LOCAL_OR_PRODUCTION + `/auth/getNextFreeMockExamSession`)
    }

    logout() {
        localStorage.removeItem("user");
        // history.push("/")
    }

    forgotPassword(data) {
        console.log("User forgot password - making get request")
        return axios.post(LOCAL_OR_PRODUCTION + `/auth/forgotPassword`, {
            email: data.email,
        })
    }

    changePassword(password, token) {
        console.log("Creating change password request");
        return axios.post(LOCAL_OR_PRODUCTION + `/auth/changePassword`, {
            password: password,
            token: token
        })
    }

    resetPasswordFromDashboard(password, userId) {
        const admin = JSON.parse(localStorage.getItem('user'));
        let adminEmail = admin.email;
        return axios.post(LOCAL_OR_PRODUCTION + `/auth/resetPasswordFromAdminDashboard`, {
            password: password,
            adminEmail: adminEmail,
            userId: userId
        })
    }

    toggleAccountActivationFromDashboard(newAccountStatus, userId) {
        const admin = JSON.parse(localStorage.getItem('user'));
        let adminEmail = admin.email;
        return axios.post(LOCAL_OR_PRODUCTION + `/auth/toggleAccountActivationFromDashboard`, {
            newAccountStatus: newAccountStatus,
            adminEmail: adminEmail,
            userId: userId
        })
    }

    getUserWorksheets() {
        const user = JSON.parse(localStorage.getItem('user'));
        let userEmail = user.email;
        return axios.post(LOCAL_OR_PRODUCTION + `/getUserWorksheets`, {
            userEmail: userEmail
        },
         {headers: authHeader() })
    }

    getUserAnalyticsData() {
        const user = JSON.parse(localStorage.getItem('user'));
        let userEmail = user.email;
        return axios.post(LOCAL_OR_PRODUCTION + `/getUserAnalyticsData`, {
            userEmail: userEmail
        },
         {headers: authHeader() })
    }

    getWorksheetQuestions(worksheetId) {
        return axios.post(LOCAL_OR_PRODUCTION + `/getWorksheetQuestions`, {
            worksheetId: worksheetId
        },
         {headers: authHeader() })
    }

    createTeacher(firstName, lastName, email, title, isHeadTeacher, schoolId) {
        return axios.post(LOCAL_OR_PRODUCTION + `/createTeacher`, {
            firstName: firstName, 
            lastName: lastName,
            email: email, 
            title: title,
            isHeadTeacher:isHeadTeacher,
            schoolId: schoolId
        },
         {headers: authHeader() })
    }

    addSchool(schoolName, schoolEmail, schoolPhoneNumber, schoolAddress) {
        const user = JSON.parse(localStorage.getItem('user'));
        console.log("User info")
        console.log(user)
        let teacherEmail = user.email;
        return axios.post(LOCAL_OR_PRODUCTION + `/addSchool`, {
            schoolName: schoolName,
            schoolEmail: schoolEmail,
            schoolPhoneNumber: schoolPhoneNumber,
            schoolAddress: schoolAddress,
            teacherEmail: teacherEmail
        },
        {headers: authHeader() })
    }

    getAllTeacherDataForHeadTeacher() {
        const user = JSON.parse(localStorage.getItem('user'));
        let teacherEmail = user.email;
        return axios.post(LOCAL_OR_PRODUCTION + `/getAllTeacherDataForHeadTeacher`, {
            email: teacherEmail
        },
        {headers: authHeader() })
    }

    getAllClassesDataForTeacher() {
        const user = JSON.parse(localStorage.getItem('user'));
        let teacherEmail = user.email;
        return axios.post(LOCAL_OR_PRODUCTION + `/getAllClassesDataForTeacher`, {
            email: teacherEmail
        },
        {headers: authHeader() })
    }

    getSchool() {
        const user = JSON.parse(localStorage.getItem('user'));
        let teacherEmail = user.email;
        return axios.post(LOCAL_OR_PRODUCTION + `/getSchool`, {
            email: teacherEmail
        },
        {headers: authHeader() }) 
    }

    getSchoolsData() {
        const user = JSON.parse(localStorage.getItem('user'));
        let adminEmail = user.email;
        return axios.post(LOCAL_OR_PRODUCTION + `/getSchoolsData`, {
            email: adminEmail
        },
        {headers: authHeader() })
    }

    changeTeacherPassword(teacherId, password) {
        const user = JSON.parse(localStorage.getItem('user'));
        let adminEmail = user.email;
        return axios.post(LOCAL_OR_PRODUCTION + "/changeTeacherPassword", {
            adminEmail: adminEmail,
            password: password,
            teacherId: teacherId
        },
        {headers: authHeader()})
    }

    getTeacherName() {
        const user = JSON.parse(localStorage.getItem('user'));
        let teacherEmail = user.email;
        return axios.post(LOCAL_OR_PRODUCTION + `/getTeacherName`, {
            email: teacherEmail
        },
        {headers: authHeader() })
    }

    createClass(className, yearGroup, studentData, schoolId) {
        const user = JSON.parse(localStorage.getItem('user'));
        let teacherEmail = user.email;
        return axios.post(LOCAL_OR_PRODUCTION + "/createClass", {
            className: className,
            yearGroup: yearGroup,
            studentData: studentData,
            teacherEmail: teacherEmail,
        },
        {headers: authHeader()})
    }

    getClassData(classId) {
        const user = JSON.parse(localStorage.getItem('user'));
        let teacherEmail = user.email;
        return axios.post(LOCAL_OR_PRODUCTION + `/getClassData`, {
            email: teacherEmail,
            classId: classId
        },
        {headers: authHeader() })
    }

    getStudentData(studentId) {
        const user = JSON.parse(localStorage.getItem('user'));
        let teacherEmail = user.email;
        return axios.post(LOCAL_OR_PRODUCTION + `/getStudentData`, {
            email: teacherEmail,
            studentId: studentId
        },
        {headers: authHeader() })
    }

    addToSubscriptionList(newSubscriberEmail, ip, fbclid) {
        console.log("Adding " + newSubscriberEmail)
        const currentPage = "https://whizzdom.co.uk"

        this.leadConversionAPIUpdate(newSubscriberEmail, null, null, ip, fbclid, currentPage).then(result => {
            console.log(result)
          })
        return axios.post(LOCAL_OR_PRODUCTION + `/auth/addToSubscriptionList`,{
            email: newSubscriberEmail,

        }).then((response) => {
            console.log("Subscriber added")
            return true
        }, (error) => {
            console.log("error adding to subscriber list")
            console.log(error);
            return false
        })
    }

    
      addEmailToPartialUserSignUpsSubscribersGroup(newSubscriberEmail) {
        return null
    }
    
    // This function works
    sendWelcomeEmailToNewSubscriber(newSubscriberEmail, isPaid, groupId, firstName, surname) {
        // Adds email to a sender group which triggers the workflow
        console.log("Adding: " + newSubscriberEmail)
        const unpaidWelcomeGroupId = ["epZgwQ"]
        const paidWelcomeGroupId = ["eXQLMV"]

        const group = isPaid ? paidWelcomeGroupId : unpaidWelcomeGroupId

        var firstname = "Subscriber"
        var lastname = "Support"

        console.log("Getting partial user add")
        console.log(groupId[0] == "epZqyp")
        console.log(groupId[0])

        if (groupId[0] == "epZqyp") {
            firstname = firstName
            lastname = surname
        }

        const url = new URL(
            "https://api.sender.net/v2/subscribers"
        );
        
        let headers = {
            "Authorization": "Bearer " + senderEmailApiToken,
            "Content-Type": "application/json",
            "Accept": "application/json",
        };
        return axios.post(url, {
            "email": newSubscriberEmail,
            "firstname": firstname,
            "lastname": lastname,
            "groups": groupId,
        },
        { headers: headers })   
    }

    sendEmailForIncompleteUserSignUp(firstName, surname, email, productId) {
        if (LOCAL_OR_PRODUCTION == LOCAL_HOST) {
            console.log("In local so not sending any user info")
            return true
        }
        return axios.post(LOCAL_OR_PRODUCTION + `/auth/collectPartialSignUpInfo`,{
            firstName: firstName, 
            surname: surname,
            email: email,
            productId: productId,
        }).then((response) => {
            console.log("Sent partial sign up details")
            return true
        }, (error) => {
            console.log("error sending partial sign up details")
            console.log(error);
            return false
        })
    }

    createMockExamUserFromAdminBoard(parentFirstName, parentLastName, email, childName, password, selectedDates) {
        console.log("Creating mock exam user")
        return axios.post(LOCAL_OR_PRODUCTION + `/createMockExamUserFromAdminBoard`, {
            parentFirstName: parentFirstName, 
            parentLastName: parentLastName,
            email: email, 
            childName: childName,
            password: password,
            selectedDates: selectedDates
        },
         {headers: authHeader() })
    }

    createAdminUserFromDashboard(email, password) {
        console.log("Creating admin user")
        return axios.post(LOCAL_OR_PRODUCTION + `/createAdminUserFromDashboard`, {
            email: email, 
            password: password,
        },
         {headers: authHeader() })
    }


    addMockExamSession(examSessionDate, examSessionTime, isCatchUpSession, isFreeSession) {
        return axios.post(LOCAL_OR_PRODUCTION + `/createMockExamSession`,{
            examSessionDate: examSessionDate,
            examSessionTime: examSessionTime,
            isCatchUpSession: isCatchUpSession,
            isFreeSession: isFreeSession
        }, 
            {headers: authHeader()})
        .then((response) => {
            console.log("Mock exam session created")
            return true
        }, (error) => {
            console.log("error creating mock exam session")
            console.log(error);
            return false
        })    
    }

    getMockExamSessions() {
        const admin = JSON.parse(localStorage.getItem('user'));
        let email = admin.email;
        return axios.post(LOCAL_OR_PRODUCTION + `/getMockExamSessions`,{
            email: email,
        }, 
            {headers: authHeader()})
    }

    getAllUsers() {
        const admin = JSON.parse(localStorage.getItem('user'));
        let email = admin.email;
        return axios.post(LOCAL_OR_PRODUCTION + `/getAllUsers`,{
            email: email,
        }, 
            {headers: authHeader()})
    }

    updateUserSubscriberType(userId, subscriberType, selectedDates) {
        const admin = JSON.parse(localStorage.getItem('user'));
        let email = admin.email;
        return axios.post(LOCAL_OR_PRODUCTION + `/updateUserSubscriberType`,{
            email: email,
            userId: userId,
            subscriberType: subscriberType,
            selectedDates: selectedDates
        }, 
            {headers: authHeader()})
    }


    checkIfCurrentMockExamSessionTakingPlace() {
        const user = JSON.parse(localStorage.getItem('user'));
        let email = user.email;
        return axios.post(LOCAL_OR_PRODUCTION + `/getCurrentMockExamSessionIfTakingPlace`,{
            email: email,
        }, 
            {headers: authHeader()})
    }

// Either creates or gets if one has already been created for the current session for the current user
    createMockExamSessionTracker() {
        const admin = JSON.parse(localStorage.getItem('user'));
        let email = admin.email;
        return axios.post(LOCAL_OR_PRODUCTION + `/getTrackerForMockExamSession`,{
            email: email,
        }, 
            {headers: authHeader()})
    }

    checkIfCurrentMockExamSessionTakingPlace() {
        const user = JSON.parse(localStorage.getItem('user'));
        let email = user.email;
        return axios.post(LOCAL_OR_PRODUCTION + `/getCurrentMockExamSessionIfTakingPlace`,{
            email: email,
        }, 
            {headers: authHeader()})
    }


    addMockExamSet(examSetName, examSetDate, examSetTime, examType) {
        return axios.post(LOCAL_OR_PRODUCTION + `/createMockExamSet`,{
            examSetName: examSetName,
            examSetDate: examSetDate,
            examSetTime: examSetTime,
            examType: examType
        }, 
            {headers: authHeader()})
        .then((response) => {
            console.log("Mock exam set added")
            return true
        }, (error) => {
            console.log("error adding mock exam set")
            console.log(error);
            return false
        })
    }

    getMockExamSets() {
        const admin = JSON.parse(localStorage.getItem('user'));
        let email = admin.email;
        return axios.post(LOCAL_OR_PRODUCTION + `/getMockExamSets`,{
            email: email,
        }, 
            {headers: authHeader()})
    }

    getMockExamPapersForSet(mockExamSetId) {
        console.log("Mock exam Set id: ")
        console.log(mockExamSetId)
        const admin = JSON.parse(localStorage.getItem('user'));
        let email = admin.email;
        return axios.post(LOCAL_OR_PRODUCTION + `/getMockExamPapersForSet`,{
            email: email,
            mockExamSetId: mockExamSetId,
        }, 
            {headers: authHeader()})
    }

    addMockExamToSet(mockExamSetId, examName, examTime) {
        const admin = JSON.parse(localStorage.getItem('user'));
        let email = admin.email;
        return axios.post(LOCAL_OR_PRODUCTION + `/addMockExamToSet`,{
            email: email,
            mockExamSetId: mockExamSetId,
            examName: examName, 
            examTime: examTime
        }, 
            {headers: authHeader()})
    }

    addSectionToMockExam(mockExamId, sectionTitle, sectionInstructions, sectionDuration, tableData, exampleInstructions, subject) {
        const admin = JSON.parse(localStorage.getItem('user'));
        let email = admin.email;
        console.log("Email: " + email)
        return axios.post(LOCAL_OR_PRODUCTION + `/addSectionToMockExam`,{
            email: email,
            mockExamId: mockExamId,
            sectionTitle: sectionTitle, 
            sectionInstructions: sectionInstructions,
            sectionDuration: sectionDuration,
            tableData: tableData,
            exampleInstructions: exampleInstructions,
            subject: subject
        }, 
        {headers: authHeader()})
    }

    getMockExamSectionsForMockExam(mockExamId) {
        console.log("Mock exam id: ")
        console.log(mockExamId)
        const admin = JSON.parse(localStorage.getItem('user'));
        let email = admin.email;
        return axios.post(LOCAL_OR_PRODUCTION + `/getMockExamSectionsForMockExam`,{
            email: email,
            mockExamId: mockExamId,
        }, 
            {headers: authHeader()})
    }

    addImageToMockExamSection(sectionImage, mockExamSectionId) {
        const admin = JSON.parse(localStorage.getItem('user'));
        let email = admin.email;

        console.log("Mock exam section id")
        console.log(mockExamSectionId)

        let formData = new FormData()
        formData.append("file", sectionImage)
        formData.append("mockExamSectionId", mockExamSectionId)
        formData.append("email", email)

        console.log("Getting file back")
        for (var key of formData.entries()) {
            console.log(key[0] + ', ' + key[1]);
        } 


        return axios.post(LOCAL_OR_PRODUCTION + "/addImageToMockExamSection", formData, {
            headers: {
                "Authorization": 'Bearer ' + admin.accessToken ,
                "Content-Type": "multipart/form-data",
            },
          });
    }

    deleteMockExamSectionImage(sectionId, isSectionImage) {
        console.log("Mock exam section: " + sectionId)

        const admin = JSON.parse(localStorage.getItem('user'));
        let email = admin.email;
        return axios.post(LOCAL_OR_PRODUCTION + `/deleteMockExamSectionImage`,{
            email: email,
            sectionId: sectionId,
            isSectionImage: isSectionImage,
        }, 
            {headers: authHeader()})
    }

    getMockExamSectionImage(mockExamSectionId) {
        console.log("Mock exam section id: ")
        console.log(mockExamSectionId)
        const admin = JSON.parse(localStorage.getItem('user'));
        let email = admin.email;
        return axios.post(LOCAL_OR_PRODUCTION + `/getMockExamSectionImage`,{
            email: email,
            mockExamSectionId: mockExamSectionId,
        }, 
            {headers: authHeader()})
    }

    addQuestionToSection(formData) {
        console.log("Adding question to section")
        for (var key of formData.entries()) {
            console.log(key[0] + ', ' + key[1]);
        }       
        const admin = JSON.parse(localStorage.getItem('user'));
        let email = admin.email;
        formData.append("email", email)
        return axios.post(LOCAL_OR_PRODUCTION + `/addQuestionToMockExamSection`,formData, 
            {headers: authHeader()})
    }

    editQuestionInSection(mockExamQuestionId, questionNumber, questionText, multipleChoiceOptions, correctAnswer, explanation) {
        console.log("Editing question in section")
         
        const admin = JSON.parse(localStorage.getItem('user'));
        let email = admin.email;
        console.log(explanation)

        return axios.post(LOCAL_OR_PRODUCTION + `/editQuestionInMockExamSection`,{
            email: email,
            mockExamQuestionId: mockExamQuestionId,
            questionNumber: questionNumber, 
            questionText: questionText,
            multipleChoiceOptions: multipleChoiceOptions, 
            correctAnswer: correctAnswer,
            explanation: explanation
        }, 
            {headers: authHeader()})
    }

    getMockExamQuestionsForSections(mockExamSectionId) {
        console.log("Mock exam section id: " + mockExamSectionId)
        const admin = JSON.parse(localStorage.getItem('user'));
        let email = admin.email;
        return axios.post(LOCAL_OR_PRODUCTION + `/getMockExamQuestionsForSections`,{
            email: email,
            mockExamSectionId: mockExamSectionId,
        }, 
            {headers: authHeader()})
    }


    getMockExamQuestionImage(questionId, gettingQuestion) {
        console.log("Mock exam questionId: " + questionId)

        const admin = JSON.parse(localStorage.getItem('user'));
        let email = admin.email;
        console.log("Getting question: " + gettingQuestion)
        return axios.post(LOCAL_OR_PRODUCTION + `/getMockExamQuestionImage`,{
            email: email,
            questionId: questionId,
            gettingQuestion: gettingQuestion,
        }, 
            {headers: authHeader()})
    }

    
    deleteMockExamQuestionImage(questionId, gettingQuestion) {
        console.log("Mock exam questionId: " + questionId)

        const admin = JSON.parse(localStorage.getItem('user'));
        let email = admin.email;
        return axios.post(LOCAL_OR_PRODUCTION + `/deleteMockExamQuestionImage`,{
            email: email,
            questionId: questionId,
            gettingQuestion: gettingQuestion,
        }, 
            {headers: authHeader()})
    }

    addImageToMockExamQuestion(questionId, addingQuestion, image) {
        const admin = JSON.parse(localStorage.getItem('user'));
        let email = admin.email;
        let formData = new FormData()
        formData.append("questionId", questionId)
        formData.append("addingQuestion", addingQuestion)
        formData.append("image", image)
        formData.append("email", email)
        for (var key of formData.entries()) {
            console.log(key[0] + ', ' + key[1]);
        }   
        return axios.post(LOCAL_OR_PRODUCTION + "/addImageToMockExamQuestion", formData, {
            headers: {
                "Authorization": 'Bearer ' + admin.accessToken ,
                "Content-Type": "multipart/form-data",
            },
          });
    }

    getMockExamSessionsCompletedByUser() {
        const user = JSON.parse(localStorage.getItem('user'));
        let email = user.email;
        return axios.post(LOCAL_OR_PRODUCTION + `/getMockExamSessionsCompletedByUser`,{
            email: email,
        }, 
            {headers: authHeader()})

    }

    getUpcomingMockExamSessionsForUser() {
        const user = JSON.parse(localStorage.getItem('user'));
        let email = user.email;
        return axios.post(LOCAL_OR_PRODUCTION + `/getUpcomingMockExamSessionsForUser`, {
            email: email,
        },
        {headers: authHeader()})
    }

    getMockExamPaperWithUserAnswerSet(paperId) {
        console.log("Mock exam paper id: " + paperId)
        const user = JSON.parse(localStorage.getItem('user'));
        let email = user.email;
        return axios.post(LOCAL_OR_PRODUCTION + `/getMockExamPaperWithUserAnswerSet`, {
            email: email,
            mockExamPaperId: paperId,
        },
        {headers: authHeader()})
    }

    getAllUpcomingMockExams() {
        return axios.post(LOCAL_OR_PRODUCTION + `/auth/getAllUpcomingMockExamSessions`)
    }

    getUsersForMockExamSession(mockExamSessionId) {
        const user = JSON.parse(localStorage.getItem('user'));
        let email = user.email;
        return axios.post(LOCAL_OR_PRODUCTION + `/getUsersForMockExamSession`, {
            email: email,
            mockExamSessionId: mockExamSessionId
        },
        {headers: authHeader()})
    }

    getMockExamReportForMockExamUser(mockExamUserId, mockExamSessionId) {
        const user = JSON.parse(localStorage.getItem('user'));
        let email = user.email;
        return axios.post(LOCAL_OR_PRODUCTION + `/getMockExamReportForMockExamUser`, {
            email: email,
            mockExamUserId: mockExamUserId,
            mockExamSessionId: mockExamSessionId
        },
        {headers: authHeader()})
    }

    assignAViableMockExamSetForMockExamSession(mockExamSessionId){
        const user = JSON.parse(localStorage.getItem('user'));
        let email = user.email;
        console.log("User email")
        console.log(email)
        console.log(mockExamSessionId)
        return axios.post(LOCAL_OR_PRODUCTION + `/assignAViableMockExamSetForMockExamSession`,{
            email: email,
            mockExamSessionId: mockExamSessionId
        }, 
            {headers: authHeader()})
    }

    addEnglishQuestionToDB(questionText, correctSentenceVersion, multipleChoiceOptions, correctAnswer, topic) {
        const admin = JSON.parse(localStorage.getItem('user'));
        let email = admin.email;

        console.log("Sending english question data to backend")
        console.log(questionText)
        console.log(correctAnswer)
        console.log(correctSentenceVersion)
        console.log(multipleChoiceOptions)
        console.log(topic)

        return axios.post(LOCAL_OR_PRODUCTION + `/addEnglishQuestionToDB`,{
            email: email,
            questionText: questionText,
            correctAnswer: correctAnswer,
            correctSentenceVersion: correctSentenceVersion,
            multipleChoiceOptions: multipleChoiceOptions,
            topic: topic,
        }, 
            {headers: authHeader()})
    }

    updateTimeSpentOnMockExamQuestion(userId, mockExamPaperId, mockExamQuestionId, amountOfTimeSpentOnQuestion) {

        return axios.post(LOCAL_OR_PRODUCTION + `/updateTimeSpentOnMockExamQuestion`,{
            userId: userId,
            mockExamPaperId: mockExamPaperId,
            mockExamQuestionId: mockExamQuestionId,
            amountOfTimeSpentOnQuestion: amountOfTimeSpentOnQuestion
        }, 
            {headers: authHeader()})
    }


    updateMockExamSessionTrackerWithUserAnswer(userId, mockExamPaperId, mockExamQuestionId, userAnswer, amountOfTimeSpentOnQuestion) {
        console.log("User answer is:")
        console.log(userAnswer)

        return axios.post(LOCAL_OR_PRODUCTION + `/updateMockExamSessionTrackerWithUserAnswer`,{
            userId: userId,
            mockExamPaperId: mockExamPaperId,
            mockExamQuestionId: mockExamQuestionId,
            userAnswer: userAnswer,
            amountOfTimeSpentOnQuestion: amountOfTimeSpentOnQuestion
        }, 
            {headers: authHeader()})
    }



    endMockExam(mockExamSessionTrackerCompositeId, mockExamPaperId) {
        const userId = mockExamSessionTrackerCompositeId.mockExamUserId
        const sessionId = mockExamSessionTrackerCompositeId.mockExamSessionId
        return axios.post(LOCAL_OR_PRODUCTION + `/endMockExam`,{
            userId: userId,
            sessionId: sessionId,
            mockExamPaperId: mockExamPaperId,
        }, 
            {headers: authHeader()})
    }

    startBreakSession() {
        const user = JSON.parse(localStorage.getItem('user'));
        let userEmail = user.email;
        return axios.post(LOCAL_OR_PRODUCTION + `/startBreakSession`,{
            userEmail: userEmail,
        }, 
            {headers: authHeader()})
    }

    endMockExamSession() {
        const user = JSON.parse(localStorage.getItem('user'));
        let userEmail = user.email;
        return axios.post(LOCAL_OR_PRODUCTION + `/endMockExamSession`,{
            userEmail: userEmail,
        }, 
            {headers: authHeader()})
    }

    endBreakSession(mockExamSessionTrackerCompositeId) {
        const userId = mockExamSessionTrackerCompositeId.mockExamUserId
        const sessionId = mockExamSessionTrackerCompositeId.mockExamSessionId
        return axios.post(LOCAL_OR_PRODUCTION + `/endBreakSession`,{
            userId: userId,
            sessionId: sessionId
        }, 
            {headers: authHeader()})
    }

    addEnglishComprehensionToDB(comprehensionPassage, comprehensionDescription, comprehensionQuestionsData) {
        const admin = JSON.parse(localStorage.getItem('user'));
        let email = admin.email;

        console.log("Sending english comprehension data to backend")
        console.log("Comprehension")
        console.log(comprehensionPassage)
        console.log("Comprehension Questions")
        console.log(comprehensionQuestionsData)

        return axios.post(LOCAL_OR_PRODUCTION + `/addEnglishComprehensionToDB`,{
            email: email,
            comprehensionPassage: comprehensionPassage,
            comprehensionDescription: comprehensionDescription,
            comprehensionQuestionsData: comprehensionQuestionsData,
        }, 
            {headers: authHeader()})
    }

    flagQuestion(questionNumber, question, answer, userAnswer, isCorrect, solution, multipleChoiceOptions,  isTypoBoxChecked, isMarkingIssueBoxChecked, isHardQuestionBoxChecked, userComments) {
        const user = JSON.parse(localStorage.getItem('user'));
        let email = user.email;

        console.log("Flagging question")

        return axios.post(LOCAL_OR_PRODUCTION + `/flagQuestion`,{
            email: email,
            questionNumber: questionNumber,
            question: question,
            answer: answer,
            userAnswer: userAnswer, 
            isCorrect: isCorrect, 
            solution: solution, 
            multipleChoiceOptions: multipleChoiceOptions, 
            isTypoBoxChecked: isTypoBoxChecked, 
            isMarkingIssueBoxChecked: isMarkingIssueBoxChecked, 
            isHardQuestionBoxChecked: isHardQuestionBoxChecked,
            userComments: userComments
        }, 
            {headers: authHeader()})
    }

    // Fb Converstion Api calls

    // function getUnixTimeStamp() {
    //     const dateTime = Date.now();
    //     const timestamp = Math.floor(dateTime / 1000);
    //     return timestamp;
    // }

    viewContentConversionApiUpdate(ip, fbclid) {
        // https://graph.facebook.com/{API_VERSION}/{PIXEL_ID}/events?access_token={TOKEN}

        const dateTime = Date.now();
        const timestamp = Math.floor(dateTime / 1000);

        const fbcCookie = "fb.1." + dateTime + "." + fbclid


        // const headers = {Authorization: 'Bearer ' + user.accessToken}
        const body = {}
        console.log("User ip address")
        console.log(ip)
        console.log("FB Click Id")
        console.log(fbclid)
        let clientUserAgent = navigator.userAgent;
        console.log("Client user agent")
        console.log(clientUserAgent)
        const event_source_url = window.location.href

        // client user agent automatically configured for events sent
        // through server
        const data = [
                {
                    "event_name": "ViewContent",
                    "event_time": timestamp,
                    "action_source": "website",
                    "event_source_url": event_source_url,
                    "user_data": {
                        "client_ip_address": ip,
                        "client_user_agent": clientUserAgent,
                        "fbc": fbcCookie
                    },
                }]

        body["data"] = data
        if (LOCAL_OR_PRODUCTION === LOCAL_HOST) {
            body["test_event_code"] = test_event_code
        }
        
            
        console.log("Attempting to send conversion api message")
        return axios.post(FB_CONVERSION_API_ENDPOINT, body)
    }

    generateAndSendMockExamReports(mockExamSessionId) {
        const admin = JSON.parse(localStorage.getItem('user'));
        let email = admin.email;

        console.log("Sending mock exam session reports")

        return axios.post(LOCAL_OR_PRODUCTION + `/generateAndSendMockExamReports`,{
            email: email,
            mockExamSessionId: mockExamSessionId,
        }, 
            {headers: authHeader()})
    }

    generateAndSendOverallMockExamReport(mockExamSessionId) {
        const admin = JSON.parse(localStorage.getItem('user'));
        let email = admin.email;
        console.log("Sending Overall Mock Exam Session Report")

        return axios.post(LOCAL_OR_PRODUCTION + `/generateAndSendOverallMockExamReport`,{
            email: email,
            mockExamSessionId: mockExamSessionId,
        }, 
            {headers: authHeader()})
    }

    getColourCodesForTiles() {
        const user = JSON.parse(localStorage.getItem('user'));
        let userEmail = user.email;
        return axios.post(LOCAL_OR_PRODUCTION + `/getColourCodesForTiles`, {
            email: userEmail
        },
        {headers: authHeader() })
    }

    async updateBlogsViewData(blogViewed, viewSource) {
        return axios.post(LOCAL_OR_PRODUCTION + `/auth/updateBlogsViewData`, {
            blogViewed: blogViewed,
            viewSource: viewSource
        })
    }
    
   async leadConversionAPIUpdate(email, firstName, lastName, ip, fbclid, event_source_url) {

        const dateTime = Date.now();
        const timestamp = Math.floor(dateTime / 1000);
        let clientUserAgent = navigator.userAgent;
        
        var cookies = document.cookie
        .split(';')
        .map(cookie => cookie.split('='))
        .reduce((accumulator, [key, value]) => ({ ...accumulator, [key.trim()]: decodeURIComponent(value) }), {});
        var fbclick = cookies._fbc;
        // var fbp = cookies._fbp;
    
        var fbcCookieClickId = null

        if (fbclick) {
            console.log("FB Click cookie")
            console.log(fbclick);	
            fbcCookieClickId = "fb.1." + dateTime + "." + fbclick.fbclid
        } else {
            fbcCookieClickId = null   
        }

        let hashedEmail = await encodeInSHA256(email)
        var hashedFirstName = null
        var hashedLastName = null
        if (event_source_url === "https://whizzdom.co.uk/auth/register") {
            hashedFirstName = await encodeInSHA256(firstName)
            hashedLastName = await encodeInSHA256(lastName)
        }

        console.log("Hashed details")
        console.log(typeof hashedEmail)
        console.log(hashedEmail)
        console.log(hashedFirstName)
        console.log(hashedLastName)
        const data = [
                {
                    "event_name": "Lead",
                    "event_time": timestamp,
                    "action_source": "website",
                    "event_source_url": event_source_url,
                    "user_data": {
                        "em": hashedEmail,
                        "fn": [
                            hashedFirstName
                        ],
                        "ln": [
                            hashedLastName
                        ],
                        "client_ip_address": ip,
                        "client_user_agent": clientUserAgent,
                        "fbc": fbcCookieClickId
                    },
                }]   

        const body = {}
        body["data"] = data
        if (LOCAL_OR_PRODUCTION === LOCAL_HOST) {
            body["test_event_code"] = test_event_code
        }
        console.log("Sending leads conversion api")
        return axios.post(FB_CONVERSION_API_ENDPOINT, body)
    }

    async iniateCheckoutConversionAPIUpdate(email, firstName, lastName, ip) {


        const dateTime = Date.now();
        const timestamp = Math.floor(dateTime / 1000);
        let clientUserAgent = navigator.userAgent;

        var cookies = document.cookie
        .split(';')
        .map(cookie => cookie.split('='))
        .reduce((accumulator, [key, value]) => ({ ...accumulator, [key.trim()]: decodeURIComponent(value) }), {});
        var fbclick = cookies._fbc;
        // var fbp = cookies._fbp;
    
        var fbcCookieClickId = null

        if (fbclick) {
            console.log("FB Click cookie")
            console.log(fbclick);	
            fbcCookieClickId = "fb.1." + dateTime + "." + fbclick.fbclid
        } else {
            fbcCookieClickId = null   
        }

        let hashedEmail = await encodeInSHA256(email)
        let hashedFirstName = await encodeInSHA256(firstName)
        let hashedLastName = await encodeInSHA256(lastName)


        const data = [
                {
                    "event_name": "InitiateCheckout",
                    "event_time": timestamp,
                    "action_source": "website",
                    "event_source_url": "https://whizzdom.co.uk/auth/register",
                    "user_data": {
                        "em": hashedEmail,
                        "fn": [
                            hashedFirstName
                        ],
                        "ln": [
                            hashedLastName
                        ],
                        "client_ip_address": ip,
                        "client_user_agent": clientUserAgent,
                        "fbc": fbcCookieClickId
                    },
                }]   

        const body = {}
        body["data"] = data
        if (LOCAL_OR_PRODUCTION === LOCAL_HOST) {
            body["test_event_code"] = test_event_code
        }
        console.log("Sending initiateCheckout conversion api")
        return axios.post(FB_CONVERSION_API_ENDPOINT, body)

    }

}
export default new QuestionsAndUserDataService();
