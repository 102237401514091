import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import TextField from '@material-ui/core/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Link from '@material-ui/core/Link';
import { Typography } from '@material-ui/core';
import Modal from '@mui/material/Modal';
import Divider from '@mui/material/Divider';
import * as CONSTANTS from "../../StringConstants";


// import CorrectlyAnsweredQuestionsTile from '../AnalyticsComponents/CorrectlyAnsweredQuestionsTile';
import QuestionsAndUserDataService from '../../services/QuestionsAndUserDataService'
import "../../assets/styles/landingPage.css";

// Images

import GLTitleImage from "../../assets/blogImages/GLBlog/GLTitleImage.jpg"
import GLEnglish from "../../assets/blogImages/GLBlog/GLEnglish.jpg"
import GLMath from "../../assets/blogImages/GLBlog/GLMath.jpg"
import GLVR from "../../assets/blogImages/GLBlog/GLVR.png"
import GLNVR from "../../assets/blogImages/GLBlog/GLNVR.png"

import GLTitleImageWebP from "../../assets/WebPImages/BlogImages/GLBlog/GLTitleImage.webp"
import GLEnglishWebP from "../../assets/WebPImages/BlogImages/GLBlog/GLEnglish.webp"
import GLMathWebP from "../../assets/WebPImages/BlogImages/GLBlog/GLMath.webp"
import GLVRWebP from "../../assets/WebPImages/BlogImages/GLBlog/GLVR.webp"
import GLNVRWebP from "../../assets/WebPImages/BlogImages/GLBlog/GLNVR.webp"


export default function GLBlog() {
    let history = useHistory();

    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [hasSubmittedAnEmail, setHasSubmittedAnEmail] = useState(false)
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const viewSource = queryParams.get('source');  

    useEffect(() => {
        let blogViewed = CONSTANTS.GLBlogTitle
        QuestionsAndUserDataService.updateBlogsViewData(blogViewed, viewSource)
    });

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };

    const validationSchema = yup.object({
      email:yup
      .string('Email')
      .email('Enter a valid email')
    });
  
  
    const formik = useFormik({
      initialValues: {
        email: '',
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {
        if (formik.values.email === "") {
          console.log("Email value is blank")
        } else {
          console.log("Adding subscriber email to list")
          setHasSubmittedAnEmail(true)
          // sendWelcomeEmailToNewSubscriber()
          QuestionsAndUserDataService.addToSubscriptionList(formik.values.email).then(setOpen(false))
        }
      },
    });

    function createCheckoutSession(lookupKey) {
        // Handle multiple keys and products later - for now just direct to sign up with the one look up 
        history.push("/auth/register/1")
    }

    function directToCEMBlog() {
        // Need to scroll to top otherwise react carries the scroll level you were at to the next page
        window.scrollTo(0, 0);
        history.push("/auth/preparing-for-the-cem-11plus-exams")
    }
    


  return (        
        <div className='eleven-plus-preparation-blog'>

            <Helmet>
                <title>Conquering the GL 11 plus exams</title>
                <meta name="A guide about the GL 11 plus exams and how to prepare" content="What are the GL 11 plus exams.
                 How to prepare for the GL exams. What are the style and types of 11 plus questions. The GL 11 plus subjects and how to prepare." />
            </Helmet>
           
            <div className='eleven-plus-info-blog-title-and-image'>
                <Typography className="eleven-plus-blog-title" variant="h3"><b>Conquering the GL 11plus Exams</b></Typography>
                <Typography variant="h6">
                Preparing for the 11 plus exams is no simple measure and following on from last week, our 11 plus experts at 
                Whizzdom have prepared this handy guide to the 11plus GL papers.
                </Typography>
                <img
                className='eleven-plus-info-blog-image'
                alt="CEM-11plus-preparation-blog-info-image"
                src={GLTitleImageWebP}
                srcSet={GLTitleImage}
                />  
            </div>  

            <div className='eleven-plus-preparation-blog-content'>
            <Typography variant="h6">
            The GL 11 plus exams (also previously known as NFER) have been used in schools for the past 35 years to assess children for
            entry into competitive grammar and independent schools. Although it's the most commonly used, not all schools use the GL 
            assessment format so it's important to be check when preparing. Email us at <b>11plus@whizzdom.co.uk</b> if
            you are unsure and one of our team will be happy to help. The exam format 
            itself assesses 4 key areas: 
                <div className="eleven-plus-preparation-blog-bullet-point">
                    <il>
                        {'\u2022'} <Typography variant="h9"><b>English</b> - this will typically include reading, comprehension and vocabulary</Typography>
                    </il>
                </div>
                <div className="eleven-plus-preparation-blog-bullet-point">
                    <il>
                        {'\u2022'} <Typography variant="h9"><b>Maths</b> - consisting of numbers, measurement, statistics and geometry</Typography>
                    </il>
                </div>
                <div className="eleven-plus-preparation-blog-bullet-point">
                    <il>
                        {'\u2022'} <Typography variant="h9"><b>Verbal Reasoning</b> - consisting of <b>21</b> different types of questions</Typography>
                    </il>
                </div>
                <div className="eleven-plus-preparation-blog-bullet-point">
                    <il>
                        {'\u2022'} <Typography variant="h9"><b>Non-Verbal Reasoning</b> - utilising diagrams and images to assess spatial awareness</Typography>
                    </il>
                </div>
            </Typography>
            <Typography variant="h6">
            The exams are typically multiple choice with this becoming increasingly common. The format itself consists of one paper per subject, each around 45 mins 
            but can vary around this mark based on the school's test. However, some schools may sometimes choose to only test students on 2 or 3 of the subjects.
            </Typography>
            
            <div className="pricing-button-container">
                <button
                    className="pricing-button-pro"
                    onClick={() => createCheckoutSession("lookupkey")}
                >Get Practising
                </button>
            </div>

            <br></br>
            <Divider/>
            <br></br>

            <Typography className="eleven-plus-preparation-blog-subheading" variant="h4"><b>The GL 11 Plus Subjects </b></Typography>
   
            <Grid container textAlign={"center"} alignItems="center" className='py-4'>
                <Grid xs={12} sm={5}>
                    {/* <div className='eleven-plus-preparation-blog-content'> */}

                        <Typography className="eleven-plus-preparation-blog-subheading" style={{textAlign:"left"}} variant="h6"><b>GL English</b></Typography>
                        <Typography style={{textAlign:"left"}} variant="h6">
                        A large proportion of the English paper will be made up of the comprehension questions. In addition to this, students will be assessed on their 
                        grammar and their understanding of word definitions and spelling. As well as their understanding of specific words, they'll also be expected to 
                        identify the best word to use in a specific context.    
                        </Typography>
                    {/* </div> */}
                </Grid>
                <Grid xs={1}/>
                <Grid xs={12} sm={5} style={{align:"center"}} className="eleven-plus-blog-image">
                    <img
                    alt="CEM-english-11plus-preparation-blog-image"
                    src={GLEnglishWebP}
                    srcSet={GLEnglish}
                    /> 
                </Grid>
            </Grid>
            <Grid container textAlign={"center"} alignItems="center" className='py-4'>
                <Grid xs={12} sm={5} style={{align:"center"}} className="eleven-plus-blog-image">
                    <img
                    alt="CEM-Verbal-Reasoning-11plus-preparation-blog-image"
                    src={GLVRWebP}
                    srcSet={GLVR}
                    /> 
                </Grid>
                <Grid xs={1}/>
                <Grid xs={12} sm={5}>
                    {/* <div className='eleven-plus-preparation-blog-content'> */}

                        <Typography className="eleven-plus-preparation-blog-subheading" style={{textAlign:"left"}} variant="h6"><b>GL Verbal Reasoning</b></Typography>
                        <Typography style={{textAlign:"left"}} variant="h6">
                        The VR paper can consist of up to 21 different question types and can be multiple choice or in a standard format. Due to the large variety, it's important
                        to ensure that your child gets enough exposure to each of the different types so that they're comfortable on test day. 
                        </Typography>
                    {/* </div> */}
                </Grid>
            </Grid>

            <Grid container textAlign={"center"} alignItems="center" className='py-4'>
                <Grid xs={12} sm={5}>
                    {/* <div className='eleven-plus-preparation-blog-content'> */}

                        <Typography className="eleven-plus-preparation-blog-subheading" style={{textAlign:"left"}} variant="h6"><b>GL Maths</b></Typography>
                        <Typography style={{textAlign:"left"}} variant="h6">
                        The maths paper will typically assess KS2 knowledge. This consists of Arithmetic, statistics, geometry, probability and worded maths problems. Ensure 
                        your child is comfortable with a thorough review of the school syllabus and ensure to practise each of the question types independently as well.  
                        </Typography>
                    {/* </div> */}
                </Grid>
                <Grid xs={1}/>
                <Grid xs={12} sm={5} style={{align:"center"}} className="eleven-plus-blog-image">
                    <img
                    alt="CEM-Math-11plus-preparation-blog-image"
                    src={GLMathWebP}
                    srcSet={GLMath}
                    /> 
                </Grid>
            </Grid>

            <Grid container textAlign={"center"} alignItems="center" className='py-4'>
                <Grid xs={5} style={{align:"center"}} className="eleven-plus-blog-image">
                    <img
                    alt="CEM-Non-Verbal-Reasoning-11plus-preparation-blog-image"
                    src={GLNVRWebP}
                    srcSet={GLNVR}
                    /> 
                </Grid>
                <Grid xs={1}/>
                <Grid xs={12} sm={5}>
                    {/* <div className='eleven-plus-preparation-blog-content'> */}

                        <Typography className="eleven-plus-preparation-blog-subheading" style={{textAlign:"left"}} variant="h6"><b>GL Non-Verbal Reasoning</b></Typography>
                        <Typography style={{textAlign:"left"}} variant="h6">
                        This differs the least from other non GL tests and will include questions in areas such as manipulating 3D shapes and other spatial problem solving
                        questions. Here, a student may have to identify similar or odd shapes or deal with mirror images or reflections/rotations. 
                        </Typography>
                    {/* </div> */}
                </Grid>
            </Grid>



            <Typography className="eleven-plus-preparation-blog-subheading" variant="h4"><b>How to Prepare</b></Typography>
            <Typography variant="h6">
            Unlike <Link onClick={directToCEMBlog}> the CEM 11 plus exam </Link>, students are allowed to go backwards to previous sections but, they should still do mock exams under timed conditions so that they 
            can hone in their exam strategy for test day. The GL paper also is more of a traditional style of 11 plus paper and the questions themselves come from a question bank. 
            Hence, our main recommendation would be to practice in order to gain a familiarisation with these questions.                
            <br></br>
            <br></br>
                As a result, we've provided an unlimited number of questions and mock tests for students to prepare with to help get them ready for their 11plus exams. Parents
                 can also constantly track their progress with granular answer analysis and real time feedback on their mistakes whilst our smart system will learn of their 
                 progress and highlight any areas they may need to improve. We look forward to welcoming you on the platform! Sign up to our <Link onClick={handleOpen}><u><b>our 
                    mailing list</b></u></Link>. to keep up to date with all the latest content to do with the 11plus GL and <Link onClick={directToCEMBlog}> CEM exams </Link> and more!

            </Typography>
        
                <div className="pricing-button-container">
                    <button
                        className="pricing-button-pro"
                        onClick={() => createCheckoutSession("lookupkey")}
                    >Get Started Today
                    </button>
                </div>
                <div className="pt-6">
                { hasSubmittedAnEmail ? 
                <b style={{textAlign: "center"}}>You've been added to our subscriber list! </b> : ""
                } 
                </div>        
            </div>
  
        <Modal
          open={open}
          onClose={handleClose}
        >
          <Box sx={style}>
            <form onSubmit={formik.handleSubmit}>
                <div className="relative w-full mb-3">
                    <TextField
                        fullWidth
                        id="email"
                        name="email"
                        label="Email"
                        onChange={(e) => {
                        formik.handleChange(e);
                        }}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                    />
                </div>

                <div className="text-center mt-6">
                <button
                    className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="submit"
                >
                    Get Started
                </button>
                </div>
            </form>
          </Box>
        </Modal>
        </div>
  );
}
