import React from 'react';

export default function MockExamRegistrationConfirmation() {

  return (
    <>
    <div style={{textAlign: "center", fontWeight: "bold", fontSize: "25px", margin: "20px"}}>
     Registration successful! Login now to get a copy of the Parent's 11 Plus Playbook! <br/><u>Check your spam</u> or contact us at 11plus@whizzdom.co.uk if it doesn't arrive!
    </div>
    </>
  );
}
