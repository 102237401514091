import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import { Typography } from '@material-ui/core';

import TLHEndpoints from 'services/TLHEndpoints';
import ViewBookedAssesmentDetails from 'theLearningHub/layouts/HelperComponents/ViewBookedAssesmentDetails';
import RescheduleAppointmentOrAssessment from 'theLearningHub/layouts/HelperComponents/RescheduleAppointmentOrAssessment';


export default function TLHViewBookedAssessments() {

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  

  const [isLoadingAssessments, setIsLoadingAssessments] = useState(true)
  const [assessmentSubjectsAndYearGroupsDict, setAssessmentSubjectsAndYearGroupsDict] = useState()
  const [enquiriesData, setEnquiriesData] = useState()
  const [bookedAssessmentDetails, setBookedAssessmentDetails] = useState(false);
  const [currentAssessmentViewInfo, setCurrentAssessmentViewInfo] = useState();

  const [rescheduleId, setRescheduleId] = useState()
  const [openRescheduleModal, setOpenRescheduleModal] = useState(false) 
  const [updatedTable, setUpdatedTable] = useState(false)


  function handleOpenRescheduleModal(id) {
    setOpenRescheduleModal(true);
    setRescheduleId(id)
  }
  const handleCloseRescheduleModal = () => setOpenRescheduleModal(false);

  function handleUpdatedTable() {
    console.log("In handle update to table")
    setUpdatedTable(true);
    // setOpenRescheduleModal(false);
    window.location.reload();

  }

  function handleOpenBookedAssessmentDetails(assessment) {
    setBookedAssessmentDetails(true);
    setCurrentAssessmentViewInfo(assessment)
  }
  const handleCloseBookedAssessmentDetails = () => setBookedAssessmentDetails(false);

  useEffect(() => {
    console.log("Getting assessments")
    if (isLoadingAssessments) {
      console.log("Getting in if")
      getAllBookedAssessmentsGoingBack1MonthToTheFuture()
    }
  });

  function getAllBookedAssessmentsGoingBack1MonthToTheFuture() {
    TLHEndpoints.getAllBookedAssessmentsGoingBack1MonthToTheFuture().then(result => {
      // console.log(result.data)
      // setMockExamSets(result.data)
      console.log("Enquiries")
      console.log(result.data)
      var assessmentsData = result.data
      sortAssessments(assessmentsData)
      
      const assessmentSubjectsAndYearGroupsDict = {};

      assessmentsData.forEach(item => {
        const { assessmentStudentId, assessmentsBooked } = item;
        if (assessmentsBooked.length > 0) {
          const assessmentYearGroup = assessmentsBooked[0].assessmentYearGroup.replace('_', ' ');
          const subjects = assessmentsBooked
            .map(assessment => capitalizeFirstLetter(assessment.assessmentSubject))
            .join(", ");
            assessmentSubjectsAndYearGroupsDict[assessmentStudentId] = [assessmentYearGroup, subjects];
          }
      });

      console.log(assessmentSubjectsAndYearGroupsDict)
      setAssessmentSubjectsAndYearGroupsDict(assessmentSubjectsAndYearGroupsDict);
      setEnquiriesData(assessmentsData)
      setIsLoadingAssessments(false)
    })
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  
  function sortAssessments(data) {
    data.sort((a, b) => {
        // Handling null assessmentDate
        if (!a.assessmentDate && b.assessmentDate) return 1;
        if (a.assessmentDate && !b.assessmentDate) return -1;
        if (a.assessmentDate === b.assessmentDate) {
            // Dates are equal or both null, proceed to sort by time
            if (!a.assessmentStartTime && b.assessmentStartTime) return 1;
            if (a.assessmentStartTime && !b.assessmentStartTime) return -1;
            if (a.assessmentStartTime === b.assessmentStartTime) {
                // Times are equal, sort by ID descending
                return b.assessmentStudentId - a.assessmentStudentId;
            }
            // Sort times in descending order
            return b.assessmentStartTime.localeCompare(a.assessmentStartTime);
        }
        // Sort dates in descending order
        return b.assessmentDate.localeCompare(a.assessmentDate);
    });
}

function formatFriendlyDate(dateString) {

  if (dateString === "" || dateString === null) {
    return ""
  }

  const date = new Date(dateString);
  
  // Check if the date is valid
  if (isNaN(date)) {
      return "Invalid date";
  }

  // Create a formatter for a friendly date format
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return new Intl.DateTimeFormat('en-US', options).format(date);
}

function removeNullStringFromName(clientName) {
  if (clientName == null || clientName == undefined) {
    return ""
  }
  return clientName
}



  return (
    
    <div>

      <Typography variant="h4" align='center' style={{padding: "15px"}}>
        Booked Assessments
      </Typography>

      {isLoadingAssessments ?
        <div className="table-container" style={{textAlign: "center", fontWeight: "bold", fontSize: "25px"}}>
          Loading Assessments ...
        </div>
        :
      <div style={{ overflowX: 'auto', width: '100%'}}>
        <Table size="small" style={{tableLayout: 'auto'}}>
          <TableHead >
            <TableRow>
              <TableCell>Assessment Date</TableCell>
              <TableCell>Student Name</TableCell>
              <TableCell>Assessments Booked</TableCell>
              <TableCell>Parent Name</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Customer Notes</TableCell>
              <TableCell>Reschedule</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {enquiriesData.map((assessment) => (
              <TableRow key={assessment.assessmentStudentId}>
                <TableCell>{formatFriendlyDate(new Date(assessment.assessmentDate).toISOString().split('T')[0])}</TableCell>
                {/* <td>{new Date(enquiry.enquiryCreationDate).toISOString().split('T')[0]}</td> */}
                <TableCell>{removeNullStringFromName(assessment.studentFirstName) + " " + removeNullStringFromName(assessment.studentSurname)}</TableCell>
                <TableCell>{assessmentSubjectsAndYearGroupsDict[assessment.assessmentStudentId] ? assessmentSubjectsAndYearGroupsDict[assessment.assessmentStudentId][0] + " - " + assessmentSubjectsAndYearGroupsDict[assessment.assessmentStudentId][1] : "View Notes For Assessment Details"}</TableCell>
                <TableCell>{assessment.parentBookingEnquiryDetails ? (removeNullStringFromName(assessment.parentBookingEnquiryDetails.parentFirstName) + " " + removeNullStringFromName(assessment.parentBookingEnquiryDetails.parentLastname)) : "" }</TableCell>
                <TableCell>{assessment.parentBookingEnquiryDetails ? (assessment.parentBookingEnquiryDetails.phoneNumber + (assessment.parentBookingEnquiryDetails.altPhoneNumber ? ("/" + assessment.parentBookingEnquiryDetails.altPhoneNumber) : (""))) : ""}</TableCell>
                <TableCell><button onClick={() => handleOpenBookedAssessmentDetails(assessment)}><u>View Notes</u></button></TableCell>
                <TableCell><button onClick={() => handleOpenRescheduleModal(assessment.assessmentStudentId)}><u>Click to Reschedule</u></button></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        
        <Modal
          open={bookedAssessmentDetails}
          onClose={handleCloseBookedAssessmentDetails}
          aria-labelledby="modal-add-mock-exam"
          aria-describedby="modal-able-to-add-a-set"
        >
          <Box sx={style}>
            <ViewBookedAssesmentDetails 
              currentAssessmentViewInfo={currentAssessmentViewInfo}
            />
          </Box>
        </Modal>

        <Modal
          open={openRescheduleModal}
          onClose={handleCloseRescheduleModal}
          // aria-labelledby="modal-add-mock-exam"
          // aria-describedby="modal-able-to-add-a-set"
        >
          <Box sx={style}>
            {/* <ViewBookedAssesmentDetails 
              currentAssessmentViewInfo={currentAssessmentViewInfo}
            /> */}

             <RescheduleAppointmentOrAssessment
               rescheduleId={rescheduleId}
               handleUpdatedTable={handleUpdatedTable}
             />
          </Box>
        </Modal>

      </div>}

      
    </div>
  );
}

