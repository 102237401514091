// /*eslint-disable*/
// import React from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { useState, useEffect } from 'react';
import * as React from 'react';
import axios from 'axios'
import {useParams} from "react-router-dom";

// mui imports

import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import InfoIcon from '@mui/icons-material/Info';
import ArticleIcon from '@mui/icons-material/Article';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import { Modal } from '@mui/material';


// images
import whiteLogo from "./../../assets/logoVariations/Primarylogomark_purple_black_white-03.png"
import colouredLogo from "./../../assets/logoVariations/Primarylogomark_purple_black_white-01.png"
import whiteLogoWebP from "./../../assets/WebPImages/Logo/Primarylogomark_purple_black_white-03.webp"
import colouredLogoWebP from "./../../assets/WebPImages/Logo/Primarylogomark_purple_black_white-01.webp"

// String Constants 
import * as CONSTANTS from "../../StringConstants"
import QuestionsAndUserDataService from "services/QuestionsAndUserDataService";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

const style = {
  position: 'absolute',
  top: '10%',
  left: '10%',
  // transform: 'translate(-0%, -20%)',
  width: "80%",
  height: "80%",
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  // boxShadow: 24,
  // p: 4,
};

const drawerWidth = 240;

export default function Navbar(props) {

  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [ip, setIP] = useState('');
  const { fbclid } = useParams()
  const [bookingModalIsOpen, setBookingModalIsOpen] = useState(false)

  const open = Boolean(anchorEl);
  let history = useHistory()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleOpenBookingModal() {
    setBookingModalIsOpen(true);
}

function handleCloseBookingModal() {
  setBookingModalIsOpen(false);
}

// function CalendlyWidget() {
//   useEffect(() => {
//     const script = document.createElement('script');
//     script.src = 'https://assets.calendly.com/assets/external/widget.js';
//     script.async = true;
//     document.body.appendChild(script);

//     return () => {
//       document.body.removeChild(script);
//     };
//   }, []);

// }

    //creating function to load ip address from the API
    const getData = async () => {
      const res = await axios.get('https://geolocation-db.com/json/')
      console.log(res.data);
      var ip = res.data.IPv6
      if (!ip || ip === 'undefined' || ip === null) {
        ip = res.data.IPv4
      }
      setIP(ip)
    }
    
    useEffect( () => {
      //passing getData method to the lifecycle method
      getData()
  
    }, [])


  function directToBlog() {
    // Send call to fb saying user has viewed content
    console.log("Making fb api call")
    QuestionsAndUserDataService.viewContentConversionApiUpdate(ip, fbclid).then((response) => {
      console.log("Response: ")
      console.log(response);
    })

    history.push(CONSTANTS.MainBlog)
    handleClose()
  }

  function directToCEMBlog() {
    history.push(CONSTANTS.CEMBlog)
    handleClose()
  }

  function directToGLBlog() {
    history.push(CONSTANTS.GLBlog)
    handleClose()
  }

  function directToParentsPlaybookBlog() {
    history.push(CONSTANTS.ParentsElevenPlusPlaybook)
    handleClose()
  }

  function directToMockExamsBlog() {
    history.push(CONSTANTS.TheImportanceOf11PlusMockExams)
    handleClose()
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Link 
        className="landing-page-nav-bar-white-logo"
        to="/"
      >
        <img alt="11plus-Whizzdom-logo" style={{padding: '25px'}} src={colouredLogoWebP} srcSet={colouredLogo}></img>
      </Link>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: 'center' }}>
            <Link to="/auth">Login</Link>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: 'center' }}>
            <Link to="/auth/register/1">Sign up</Link>
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: 'center',  paddingY: '10px', paddingBlock: '10px' }}>
            <a href="https://calendly.com/11plus-consultation/11plus-meeting" target="_blank" rel="noopener noreferrer">Book an 11 Plus Consultation</a>
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: 'center', paddingY: '10px' }}>
            <b>Blogs</b>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <Link onClick={directToBlog}>About the 11 Plus</Link>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <Link onClick={directToCEMBlog}>The CEM Exams</Link>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <Link onClick={directToGLBlog}>The GL Exams</Link>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <Link onClick={directToMockExamsBlog}>11 plus Mock Exams</Link>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <Link onClick={directToParentsPlaybookBlog}>Parents Tips for 11 Plus Success</Link>
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;



  return (
    <>
       <Box sx={{ display: 'flex' }}>
      <AppBar component="nav" className="top-0 z-50" style={{position: 'sticky'}}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon sx={{ mr: 2, display: { sm: 'none' } }}/>
          </IconButton>
              <Link 
                className="landing-page-nav-bar-white-logo"
                to="/"
              >
                <Box
                  component="img"
                  className="landing-page-nav-bar-white-logo"
                  sx={{ mr: 2, display: { xs: 'none', sm: 'block' } }}
                  alt="11-Plus-Whizzdom-logo"
                  src={whiteLogoWebP}
                  srcSet={whiteLogo}
                />
              </Link>

          <Box style={{marginLeft: 'auto'}} sx={{ display: { xs: 'none', sm: 'block' }}}>
              <ul className="flex flex-row lg:flex-row list-none lg:ms-auto">

                <li className="flex items-center">

                <div
                    className={
                      "text-sm py-2 px-4 font-normal block whitespace-nowrap bg-transparent text-blueGray-700"
                    }
                    data-url="https://calendly.com/11plus-consultation/11plus-meeting?background_color=050505&amp;text_color=ffffff&amp;primary_color=ee00ff"
                  >
                    <a className="sign-up" href="https://calendly.com/11plus-consultation/11plus-meeting" target="_blank" rel="noopener noreferrer">Book an 11 Plus Consultation</a>
                  </div>

                   {/* <Link
                      // onClick={directToBlog}
                      onClick={handleOpenBookingModal}
                      className={
                        "text-sm py-2 px-4 font-normal block whitespace-nowrap bg-transparent text-blueGray-700"
                      }>
                      <button
                        className={"sign-up"}
                        type="button"
                      >
                       Book an 11 Plus Consultation
                      </button>
                    </Link> */}
                    {/* <!-- Calendly badge widget begin --> */}
                      {/* <link href="https://assets.calendly.com/assets/external/widget.css" rel="stylesheet">
                      <script src="https://assets.calendly.com/assets/external/widget.js" type="text/javascript" async></script>
                      <script type="text/javascript">window.onload = function() { Calendly.initBadgeWidget({ url: 'https://calendly.com/11plus-consultation/11plus-meeting?background_color=050505&text_color=ffffff&primary_color=ee00ff', text: 'Book an 11 Plus Consultation', color: '#d800ff', textColor: '#ffffff', branding: true }); }</script> */}
                      {/* <!-- Calendly badge widget end --> */}
                      {/* </link> */}
               </li>


                <li className="flex items-center">
                  {/* <Link
                    onClick={directToBlog}
                    className={
                      "text-sm py-2 px-4 font-normal block whitespace-nowrap bg-transparent text-blueGray-700"
                    }>
                    <button
                      className={"sign-up"}
                      type="button"
                    >
                      11 Plus Preparation
                    </button>
                  </Link> */}
                  <div>

                    <Link
                      // onClick={directToBlog}
                      onClick={handleClick}
                      className={
                        "text-sm py-2 px-4 font-normal block whitespace-nowrap bg-transparent text-blueGray-700"
                      }>
                      <button
                        className={"sign-up"}
                        type="button"
                      >
                       11 Plus Resources
                        <KeyboardArrowDownIcon />
                      </button>
                    </Link>
                
                    <StyledMenu
                      id="demo-customized-menu"
                      MenuListProps={{
                        'aria-labelledby': 'demo-customized-button',
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                    >
                      <MenuItem onClick={directToBlog} disableRipple>
                        <InfoIcon />
                        About the 11 Plus
                      </MenuItem>
                      <MenuItem onClick={directToCEMBlog} disableRipple>
                        <ArticleIcon />
                        The CEM exams
                      </MenuItem>
                      {/* <Divider sx={{ my: 0.5 }} /> */}
                      <MenuItem onClick={directToGLBlog} disableRipple>
                        <ArticleIcon />
                        The GL exams
                      </MenuItem>
                      <MenuItem onClick={directToMockExamsBlog} disableRipple>
                        <ArticleIcon />
                        The importance of Mock Exams
                      </MenuItem>
                      <MenuItem onClick={directToParentsPlaybookBlog} disableRipple>
                        <ArticleIcon />
                        Parents Tips for 11 Plus Success
                      </MenuItem>
                      {/* <MenuItem onClick={handleClose} disableRipple>
                        <MoreHorizIcon />
                        More
                      </MenuItem> */}
                    </StyledMenu>
                  </div>
                </li>


                <li className="flex items-center">
                  <Link
                  to="/auth"
                  className={
                    "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
                  }>
                    <button
                      // className="bg-white text-blue active:bg-blue text-xs font-bold px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
                      className="log-in"
                      type="button"
                    >

                      {/* <i className="fas fa-arrow-alt-circle-down"></i>  */}
                      Login
                    </button>
                </Link>
                </li>

                
                <li className="flex items-center">
                  <Link
                    to="/auth/register/1"
                    className={
                      "text-sm py-2 px-4 font-normal block whitespace-nowrap bg-transparent text-blueGray-700"
                    }>
                    <button
                      className={"sign-up"}
                      type="button"
                    >
                      Sign up
                    </button>
                  </Link>
                </li>
        
              </ul>
          </Box>

        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none', md: 'none', lg: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      </Box>

    </>
  );
}
