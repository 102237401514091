import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@mui/material/Typography';

import QuestionsAndUserDataService from '../../services/QuestionsAndUserDataService'


// string constants


export default function EditHeadTeacherPasswordForm({teacher}) {

  const [doPasswordsMatch, setDoPasswordsMatch] = useState(true);

  function showPasswordsNotMatchingWarning() {
    if (!doPasswordsMatch) {
      return (
        <div>
          <span className="ml-2 text-sm font-semibold text-red-600">
            Passwords do not match.
          </span>
        </div>
      )
    }
  }



  const validationSchema = yup.object({
    newPassword: yup
      .string('Enter your new password')
      .min(8, 'Password should be of minimum 8 characters length')
      .required('Password is required'),
  });


  // const WithMaterialUI = () => {
    const formik = useFormik({
      initialValues: {
        newPassword: '',
        confirmPassword: '',
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {

        if (values.newPassword === values.confirmPassword) {
          console.log("Sending change password reqeuest")
          setDoPasswordsMatch(true)
          QuestionsAndUserDataService.changeTeacherPassword(teacher.teacherId, values.newPassword)
            .then(console.log("Teacher password changed"))
          // QuestionsAndUserDataService.changePassword(values.password, token)
          //   .then(result => {
          //     console.log("Waiting for result of promise")
          //     if (result) {
          //       // password changed successfully - relocate to login page - inform of success then redirect to login page.
          //       console.log("Successful password change") 
          //     } else {
          //       // issue with token - throw error page and redirect to forgot password page (with guidance)
          //       console.log("Error with password change")
          //     }
          //   })
        } else {
          setDoPasswordsMatch(false)
        }
      },
    });

  return (
    
    <div>
     <form
        onSubmit={formik.handleSubmit}
        >
         <label> Teacher's details</label>
        <div className="relative w-full mb-3">

          <Typography id="modal-modal-title" variant="h6" component="h2">
            First name: {teacher.firstName}
          </Typography>

          </div>


        <div className="relative w-full mb-3">
          <TextField
            fullWidth
            id="newPassword"
            name="newPassword"
            label="New Password*"
            type="password"
            value={formik.values.newPassword}
            onChange={formik.handleChange}
            error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
            helperText={formik.touched.newPassword && formik.errors.newPassword}
          />
        </div>

        <div className="relative w-full mb-3">
          <TextField
            fullWidth
            id="confirmPassword"
            name="confirmPassword"
            label="Confirm password"
            type="password"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
        </div>
        {showPasswordsNotMatchingWarning()}

        <div className="text-center mt-6">
          <Button
            colour="primary"
            className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
            type="submit"
          >
            Change Teacher Password
          </Button>
        </div>
      </form>
    </div>
  );
}
