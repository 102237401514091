import React, { useState, useEffect } from 'react';
import "../../../assets/styles/index.css";
import { Link } from "react-router-dom";
import {useParams} from "react-router-dom";
import { useHistory } from "react-router-dom";

// Material UI imports

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import QuestionsAndUserDataService from 'services/QuestionsAndUserDataService';
import Title from "../../AnalyticsComponents/Title.js"
import { Button } from '@material-ui/core';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import AddMockExamToSetForm from './AddMockExamToSetForm';
import * as CONSTANTS from "../../../StringConstants.js"

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function ViewMockExamsInSet() {

  const [isLoadingMockExams, setIsLoadingMockExams] = useState(true)
  const [mockExamPapers, setMockExamPapers] = useState([])
  const { mockExamSetId } = useParams();
  let history = useHistory();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (isLoadingMockExams) {
      getMockExamPapers()
    }
  });


  function getMockExamPapers() {
    QuestionsAndUserDataService.getMockExamPapersForSet(mockExamSetId).then(papers => {
      console.log(papers.data.mockExams)
      setMockExamPapers(papers.data.mockExams)
      setIsLoadingMockExams(false)
    })
  }

  function showSectionsForMockExam(mockExamId) {
    console.log("Show sections for mock exam: " + mockExamId)
    history.push(CONSTANTS.AdminMockExamSectionsRoute + "/" + mockExamId)

  }

  function isEntryEmail() {
    const admin = JSON.parse(localStorage.getItem('user'));
    let email = admin.email;
    console.log(email)
    return email.toLowerCase() === "entry@whizzdom.co.uk"
  }


  return (
    <React.Fragment>   
      {isEntryEmail ? <></> : <Link to="/app/admin-whizz">Back to Admin Dashboard</Link> }


      <Title>Mock Exams in set {mockExamSetId}</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            {/* Clicking schools should take us to a school info page */}
            <TableCell>Mock Exams</TableCell> 
            <TableCell>Length (minutes)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {mockExamPapers.map((mockExamPaper) => (
            <TableRow key={mockExamPaper.mockExamId}>
              {/* <TableCell><Link onClick={showPapersForMockExam(mockExamPaper.mockExamSetId)}><u>{mockExamPaper.examSetName}</u></Link></TableCell> */}
              <TableCell><button onClick={() => showSectionsForMockExam(mockExamPaper.mockExamId)}><u>{mockExamPaper.examName}</u></button></TableCell>
              <TableCell>{mockExamPaper.examDurationInMinutes}</TableCell>
            </TableRow>
            
          ))}
         
        </TableBody>
      </Table>

      <br></br>

      <Button onClick={handleOpen}>Add Mock Exam to Set</Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-add-mock-exam"
        aria-describedby="modal-able-to-add-a-set"
      >
        <Box sx={style}>
          <AddMockExamToSetForm mockExamSetId={mockExamSetId}/>
        </Box>
      </Modal>

  </React.Fragment>
  );
}
