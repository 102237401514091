import React, { useEffect, useState } from 'react';
// import { Link } from "react-router-dom";
import QuestionsAndUserDataService from 'services/QuestionsAndUserDataService';

export default function UpcomingExamSessionsTable({data}) {

  const[sortedDates, setSortedDates] = useState([])

  useEffect(() => {
    console.log(data)
    data.sort((a,b) => new Date(a.mockExamDate) - new Date(b.mockExamDate))
    console.log(data)
    setSortedDates(removeCatchUpSessionsIfNotRegistered(data))
  });

  function removeCatchUpSessionsIfNotRegistered(data) {
      console.log(data)
      for (var i = data.length - 1; i >= 0; i-- ) {
        if (data[i].isCatchUpSession === "true") {
          if (data[i].isRegistered === "No") {
            data.splice(i, 1)
          }
        }
      }
      return data
  }

  function formatDate(date) {
    console.log(date)
    var dateComponents = date.split("-")
    console.log(dateComponents)
    var dateString = dateComponents[2]
    console.log("Date string")
    console.log(dateString)
    console.log(typeof(dateString))
    var wordingForNumber = ""

    if (dateString[0] == 1) {
      wordingForNumber = "th"
    }
    else if(dateString[1] === "1") {
      wordingForNumber = "st"
    }
    else if (dateString[1] === "2") {
      wordingForNumber = "nd"
    }
    else if (dateString[1] === "3") {
      wordingForNumber = "rd"
      
    }
    else {
      console.log("Giving th for " + dateString)
      console.log(dateString[0])
      console.log(dateString[1])
      wordingForNumber = "th"
    }
    dateString += wordingForNumber
    console.log(dateString)

    var month = convertMonthNumberToCorrespondingWords(dateComponents[1])
    var year = dateComponents[0]

    return dateString + " " + month + " " + year
  }

  function formatTime(time) {
    var timeComponents = time.split(":")
    console.log(timeComponents)
    return timeComponents[0] + ":" + timeComponents[1] + " " + (timeComponents[0] < 12 ? "am" : "pm")

  }

  function convertMonthNumberToCorrespondingWords(monthNumber) {
    switch(monthNumber) {
      case "01":
        return "January"
      case "02":
        return "February"
      case "03":
        return "March"
      case "04":
        return "April"
      case "05":
        return "May"
      case "06":
        return "June"
      case "07":
        return "July"
      case "08":
        return "August"
      case "09":
        return "September"
      case "10":
        return "October"
      case "11":
        return "November"
      case "12":
        return "December"
      default:
        return ""
    }
  }

  function getLinkTarget(blogType) {
    if (blogType === "GL") {
      return "https://whizzdom.co.uk/auth/preparing-for-the-GL-11plus-exams"
    } else {
      return "https://whizzdom.co.uk/auth/preparing-for-the-cem-11plus-exams"
    }
  }

  function isRegistered(renderRegistrationButton, mockExamSessionId) {
    console.log("session data: ")
    console.log(data)
    console.log("Mock Exam Session Id:" + mockExamSessionId)
    console.log("Is registered:" + renderRegistrationButton)
    // Would be 3 as this is for an individual mock exam
    const productId = "3"
    return (
    renderRegistrationButton == "Yes" ?
      <>Yes</>
      :
      (data[0]?.isSubscriber == "true" ? "Pending" :
      <button
        onClick={() => QuestionsAndUserDataService.createCheckoutSessionFromDashboard("3", mockExamSessionId)}
        className="register-for-mock-exam-button"
        type="button"
      >
      Book Now
    </button>)
    
    )
  }

  return (
    <div className="table-container">
      <table className="table">
        <thead className="table-header">
          <tr>
            <th>Type</th>
            <th>Date</th>
            <th>Start Time</th>
            <th>Duration</th>
            <th>Registered</th>
          </tr>
        </thead>
        <tbody>
        {sortedDates.map((row) => (
            <tr key={row.mockExamSessionId} className="table-row">
              <td><u><a href={getLinkTarget(row.mockExamType)} target="_blank">{row.mockExamType}</a></u></td>
              <td>{formatDate(row.mockExamDate)}</td>
              <td>{formatTime(row.mockExamTime)}</td>
              <td>2 papers - 50 minutes each</td>
              <td>{row.isRegistered == "yes" ? row.isRegistered : isRegistered(row.isRegistered, row.mockExamSessionId)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};



