import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useHistory } from "react-router-dom";


import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import QuestionsAndUserDataService from '../../services/QuestionsAndUserDataService'


// string constants


export default function AddSchool() {

  let history = useHistory();
  // const [title, setTitle] = React.useState("");   
  
  // const handleChange = (event) => {
  //   setTitle(event.target.value);
  // };

  const validationSchema = yup.object({
    schoolName: yup
      .string('Enter your school\'s name')
      .required('School name is required'),
    schoolEmail: yup
      .string('Enter your school\'s email')
      .email('Enter a valid email')
      .required('Email is required'),
    schoolPhoneNumber: yup
      .string('Enter your school\'s phone nunmber')
      .required('Phone number is required'),
    schoolAddress: yup
      .string("Enter your school\'s address")
      .required("Address is required"),
  });


  // const WithMaterialUI = () => {
    const formik = useFormik({
      initialValues: {
        schoolName: '',
        schoolEmail: '',
        schoolPhoneNumber: '',
        schoolAddress: '',
        customCheckLogin: false,
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {

        // console.log("Submitting registration info - page 1 of 3")
        // console.log("formik values")
        console.log(formik.values.schoolName)
        console.log(formik.values.schoolEmail)
        console.log(formik.values.schoolPhoneNumber)
        console.log(formik.values.schoolAddress)

        QuestionsAndUserDataService.addSchool(formik.values.schoolName, formik.values.schoolEmail, formik.values.schoolPhoneNumber, formik.values.schoolAddress).then(history.push("/teacher/headteacher"))
        // updateUserDetails(formik.values.firstName, formik.values.lastName, formik.values.email).then(onStepChange(2))

      },
    });

  return (
    
    <div>
     <form
        onSubmit={formik.handleSubmit}
        >
         <label> School's details</label>
        <div className="relative w-full mb-3">

        <TextField
            fullWidth
            id="schoolName"
            name="schoolName"
            label="School Name*"
            value={formik.values.schoolName}
            onChange={formik.handleChange}
            error={formik.touched.schoolName && Boolean(formik.errors.schoolName)}
            helperText={formik.touched.schoolName && formik.errors.schoolName}
          />

        </div>

        <div className="relative w-full mb-3">

          <TextField
              fullWidth
              id="schoolEmail"
              name="schoolEmail"
              label="School Email*"
              value={formik.values.schoolEmail}
              onChange={formik.handleChange}
              error={formik.touched.schoolEmail && Boolean(formik.errors.schoolEmail)}
              helperText={formik.touched.schoolEmail && formik.errors.schoolEmail}
            />

          </div>


        <div className="relative w-full mb-3">
        <TextField
          fullWidth
          id="schoolPhoneNumber"
          name="schoolPhoneNumber"
          label="School Phone Number*"
          value={formik.values.schoolPhoneNumber}
          onChange={formik.handleChange}
          error={formik.touched.schoolPhoneNumber && Boolean(formik.errors.schoolPhoneNumber)}
          helperText={formik.touched.schoolPhoneNumber && formik.errors.schoolPhoneNumber}
        />
        </div>
      
        <div className="relative w-full mb-3">
        <TextField
          fullWidth
          id="schoolAddress"
          name="schoolAddress"
          label="School Address*"
          value={formik.values.schoolAddress}
          onChange={formik.handleChange}
          error={formik.touched.schoolAddress && Boolean(formik.errors.schoolAddress)}
          helperText={formik.touched.schoolAddress && formik.errors.schoolAddress}
        />
        </div>



        <div className="text-center mt-6">
          <Button
            colour="primary"
            className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
            type="submit"
          >
            Add School
          </Button>
        </div>
      </form>
    </div>
  );
}
