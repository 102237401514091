import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useHistory } from "react-router-dom";

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Box from '@mui/material/Box';
import { Grid } from '@material-ui/core';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Modal from '@mui/material/Modal';

import QuestionsAndUserDataService from '../../../services/QuestionsAndUserDataService'
import EditStudentDetails from './EditStudentDetails'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  flexDirection: 'row'
};

function createData(firstName, lastName, parentEmail, studentNumber) {
  return { firstName, lastName, parentEmail, studentNumber };
}

function FillInFullNameDialogBox(props) {
  const { onClose, open } = props;
  const handleClose = () => {
    onClose();
  };

  return (
    <div>
      <Dialog onClose={handleClose} open={open} style={{textAlign:"center"}}>
        <DialogTitle>Please fill in the student's full name to add them to your class.</DialogTitle>
        <div className='p-4'>
          <Button variant="contained" onClick={handleClose}>Close</Button>
        </div>
      </Dialog>
    </div>
  );
}


function AddLastStudent(props) {
  const { onClose, openAddLastStudentModal, continueClassCreation } = props;
  const handleCloseAddLastStudentModal = (isGoingBack) => {
    onClose(isGoingBack);
  };

  return (
    <div>
      <Dialog onClose={() => handleCloseAddLastStudentModal(true)} open={openAddLastStudentModal} style={{textAlign:"center"}}>
        <DialogTitle>You've still got unsaved student details, would you like to go back?</DialogTitle>
        <Grid container className='p-4'>
          <Grid xs={6}>
            <Button variant="contained" onClick={() => handleCloseAddLastStudentModal(true)}>Go Back</Button>
          </Grid>
          <Grid xs={6}>
            <Button variant="contained" onClick={() => handleCloseAddLastStudentModal(false)}>Continue</Button>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}

// SimpleDialog.propTypes = {
//   onClose: PropTypes.func.isRequired,
//   open: PropTypes.bool.isRequired,
//   selectedValue: PropTypes.string.isRequired,
// };

export default function CreateClassForm() {
  
  let history = useHistory();

  // const [studentDetails, setStudentDetails] = useState([])
  const [firstNameInput, setFirstNameInput] = useState("")
  const [lastNameInput, setLastNameInput] = useState("")
  const [parentEmailInput, setParentEmailInput] = useState("")
  const [data, setData] = useState([])
  const [open, setOpen] = useState(false)
  const [openAddLastStudentModal, setOpenAddLastStudentModal] = useState(false)
  const [studentNumber, setStudentNumber] = useState(0)
  const [isEditingStudentDetails, setIsEditingStudentDetails] = useState(false)
  const [studentIdBeingEdited, setStudentIdBeingEdited] = useState(0)
  const [currentStudent, setCurrentStudent] = useState([])
  const [isEditingStudent, setIsEditingStudent] = useState(false)
  const [showClassIsEmptyError, setShowClassIsEmptyError] = useState(false)
  

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseAddLastStudentModal = (isGoingBack) => {
    setOpenAddLastStudentModal(false);
    if (!isGoingBack) {
      setFirstNameInput("")
      setLastNameInput("")
      setParentEmailInput("")
      formik.handleSubmit()
    }
  };




  function addStudent() {
    // Won't add a student if there's an error with the email  (empty emails are allowed)
    setIsEditingStudent(true)
    if (Boolean(formik.errors.email)) {
      console.log("Attempting to add student with invalid email address")
      return
    }
    // Will give error message asking for student name
    else if (firstNameInput === "" || lastNameInput === "") {
      setOpen(true)
      return
    } else {
      console.log("Email to add is:")
      console.log(formik.values.parentEmail)
      var studentData = data.concat(createData(firstNameInput, lastNameInput, parentEmailInput, studentNumber))
      setData(studentData)
      setShowClassIsEmptyError(false)
      console.log("Added student")
      console.log("Details")
      console.log(studentNumber)
      setFirstNameInput("")
      setLastNameInput("")
      setParentEmailInput("")

      setStudentNumber(studentNumber+1)
    }
  }

  function removeStudent(studentIdToBeRemoved) {
    setIsEditingStudent(true)

    console.log("Removing student with id: " + studentIdToBeRemoved)
    var newData = []

    for (let index = 0; index < data.length; index++) {
      const student = data[index];
      if (student.studentNumber !== studentIdToBeRemoved) {
        newData = newData.concat(student)
      }
    }
      setData(newData)
  }

  function editStudentDetails(studentIdToBeEdited) {
    // So that when saving the data in the handle close function, we can update the correct row
    setIsEditingStudent(true)
    setStudentIdBeingEdited(studentIdToBeEdited)
    let studentDetails = getStudentById(studentIdToBeEdited)
    setCurrentStudent(studentDetails)
    console.log("Current student details being passed to modal")
    console.log("Firstname: " + currentStudent.firstName)
    console.log("Lastname: " + currentStudent.lastName)
    console.log("Parent Email: " + currentStudent.parentEmail)
    // Setting modal values
    setFirstNameInput(studentDetails.firstName)
    setIsEditingStudentDetails(true)
  }

  function passValuesUpAndCloseModal(firstName, lastName, parentEmail) {
    
    // Updating the new student
    let updatedStudentData = []
      for (let index = 0; index < data.length; index++) {
        if (studentIdBeingEdited === data[index].studentNumber) {
          const updatedStudent = createData(firstName, lastName, parentEmail, studentIdBeingEdited)
          updatedStudentData = updatedStudentData.concat(updatedStudent)
        } else {
          updatedStudentData = updatedStudentData.concat(data[index])
        }
      }
      setData(updatedStudentData)

    handleCloseEditStudentsModal()
  }


  const handleCloseEditStudentsModal = () => {
    
    setIsEditingStudentDetails(false);
    // Cleaning up
    setFirstNameInput("")
    setLastNameInput("")
    setParentEmailInput("")
  };

  function getStudentById(studentIdToBeEdited) {
    console.log("Getting student by id")
    console.log("Id: " + studentIdToBeEdited)
    for (let index = 0; index < data.length; index++) {
      console.log("Looping through, on index " + index)
      const student = data[index];
      if (student.studentNumber === studentIdToBeEdited) {
        console.log("Found student")
        console.log(student)
        console.log("Student being returned:")
        console.log("Firstname: " + student.firstName)
        console.log("Lastname: " + student.lastName)
        console.log("Parent Email: " + student.parentEmail)
        return student
      }
    }
    console.log("Student not found - returning -1")
    return -1
  }

  const validationSchema = yup.object({
    className: yup
      .string('Enter your name')
      .required('Class name is required'),
    yearGroup: yup
      .number('What year is your class in?')
      .typeError('Please enter a number')
      .integer('Only whole numbers allowed')
      .positive()
      .max(6, "Only years 3-6 can be added")
      .min(3, "Only years 3-6 can be added")
      .required('Year group is required'),
    email:yup
    .string('Enter parent email')
    .email('Enter a valid email')
  });

  // const WithMaterialUI = () => {
    const formik = useFormik({
      initialValues: {
        className: '',
        yearGroup: '',
        email: '',
        customCheckLogin: false,
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {
        if (isEditingStudent) {
        // QuestionsAndUserDataService.createTeacher(formik.values.className, formik.values.yearGroup, formik.values.email, title, isHeadTeacher, schoolId).then(history.push((isHeadTeacher ? "/app/admin-whizz" : "/teacher/headteacher")))
          setIsEditingStudent(false)
        } else {
          console.log("Submitting new class info")
          console.log("formik values")
          console.log(formik.values.className)
          console.log(formik.values.yearGroup)
          console.log(data)
          if (data.length === 0) {
            console.log("Class is empty")
            setShowClassIsEmptyError(true)
          } else {
            console.log("Class is not empty")
            setShowClassIsEmptyError(false)
            if (firstNameInput || lastNameInput || parentEmailInput) {
              console.log("The last student hasn't been saved")
              setOpenAddLastStudentModal(true)
            } else {
              console.log("Would create class")
              QuestionsAndUserDataService.createClass(formik.values.className, formik.values.yearGroup, data).then(history.push("/teacher/my-classes"))
            }
          }
        }
      },
    });

  return (
    
    <div className='p-4'>
     <form
        onSubmit={formik.handleSubmit}
        >

        <Grid container
            // direction="column"
            alignItems="center"
            justifyContent="center"
        >
    
          <Grid item xs={12}  style={{textAlign: "center"}}>
            <Typography variant="h4" component="div" gutterBottom>
                {formik.values.className === "" ? "New Class Details" : formik.values.className + " Class Details"}
            </Typography>
          </Grid>
          
          {/* <Grid item xs={3}>
          </Grid> */}
      
        <Grid item xs={5} >
          <TextField
              fullWidth
              id="className"
              name="className"
              label="Class Name"
              value={formik.values.className}
              // onChange={formik.handleChange, setTitle(formik.values.className)}
              onChange={formik.handleChange}
              error={formik.touched.className && Boolean(formik.errors.className)}
              helperText={formik.touched.className && formik.errors.className}
            />
        </Grid>

        <Grid item xs={2}>
        </Grid>


        <Grid item xs={3}>
          <TextField
              fullWidth
              id="yearGroup"
              name="yearGroup"
              label="Year Group"
              value={formik.values.yearGroup}
              onChange={formik.handleChange}
              error={formik.touched.yearGroup && Boolean(formik.errors.yearGroup)}
              helperText={formik.touched.yearGroup && formik.errors.yearGroup}
            />
        </Grid>


        <Grid item xs={12} className="p-4">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell><b>Student Firstname</b></TableCell>
                  <TableCell><b>Student Lastname</b></TableCell>
                  <TableCell><b>Parent Email (Optional)</b></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row) => (
                  <TableRow
                    key={row.studentNumber}
                    // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>{row.firstName}</TableCell>
                    <TableCell>{row.lastName}</TableCell>
                    <TableCell>{row.parentEmail}</TableCell>
                    <TableCell><button type='button' onClick={() => editStudentDetails(row.studentNumber)}>Edit Details</button></TableCell>
                    <TableCell><button type='button' onClick={() => removeStudent(row.studentNumber)}>Remove Student</button></TableCell>
                  </TableRow>
                ))}
                    <TableRow>
                      <TableCell>
                        <TextField 
                          value={isEditingStudentDetails ? "" : firstNameInput} 
                          label="Firstname"
                          onChange={(event) => setFirstNameInput(event.target.value)} 
                          className='add-item-input' 
                          placeholder='Add new student...' />
                      </TableCell>
                      <TableCell>
                      <TextField 
                          value={lastNameInput} 
                          label="Lastname"
                          onChange={(event) => setLastNameInput(event.target.value)} 
                          className='add-item-input' 
                          placeholder='Lastname' />
                      </TableCell>
                      <TableCell>
                      {/* <input 
                          value={parentEmailInput} 
                          onChange={(event) => setParentEmailInput(event.target.value)} 
                          className='add-item-input' 
                          placeholder='Parent Email' /> */}
                          <TextField
                            fullWidth
                            id="email"
                            name="email"
                            label="Parent Email"
                            value={parentEmailInput}
                            onChange={(e) => {
                              formik.handleChange(e);
                              setParentEmailInput(e.target.value)
                            }}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                          />
                      </TableCell>
                      <TableCell><button onClick={() => addStudent()}>Add Student</button></TableCell>
                    </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {showClassIsEmptyError ?
        <Grid item xs={12}  style={{textAlign: "left", color:"red"}} className="px-4">
            <Typography variant="h12" component="div" gutterBottom>
                *Please add at least one student to create a class
            </Typography>
          </Grid>
        : <></>}
        <Grid item xs={3} className="p-4">
          <Button
            colour="primary"
            className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
            type="submit"
          >
            Create Class
          </Button>
        </Grid>      
      </Grid>
      <AddLastStudent
        openAddLastStudentModal={openAddLastStudentModal}
        onClose={handleCloseAddLastStudentModal}
        continueClassCreation={formik.handleSubmit}
      />
      </form>
      <FillInFullNameDialogBox
        open={open}
        onClose={handleClose}
      />
      

      <Modal
        open={isEditingStudentDetails}
        onClose={() => handleCloseEditStudentsModal(false)}
      >
        <Box sx={style}>
          <EditStudentDetails 
            specificStudentData={currentStudent}
            passValuesUpAndCloseModal={passValuesUpAndCloseModal}
          />
        </Box>
      </Modal>
    </div>
  );
}
