import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router";


// Mui imports
import Modal from '@mui/material/Modal';
import { Grid } from '@material-ui/core';
import Box from '@mui/material/Box';
import Typography from '@material-ui/core/Typography';

import MockExamSignUpPart1 from "./MockExamSignUpPart1"
import WhiteWhizzdomLogo from "../assets/logoVariations/Primarylogomark_purple_black_white-03.png"
import Card from '@mui/material/Card';
import QuestionsAndUserDataService from 'services/QuestionsAndUserDataService';
import MockExamRegistrationConfirmation from './MockExamRegistrationConfirmation';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
};

export default function MockExamSignUp() {

  const [nextFreeExamSessionDate, setNextFreeExamSessionDate] = useState("")
  const [nextFreeExamSessionTime, setNextFreeExamSessionTime] = useState("")
  let history = useHistory();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => history.push("/");

  useEffect(() => {
    getNextFreeMockExamSession()
  });

  function getNextFreeMockExamSession() {
    // Want this to show the date of the next mock exam on the page 
    QuestionsAndUserDataService.getNextFreeMockExamSession().then(session => {
      console.log("Completing promise to get next free mock exam session")
      console.log(session)
      setNextFreeExamSessionDate(session.data.mockExamDate)
      setNextFreeExamSessionTime(session.data.mockExamTime)
     
    });

  }

  function formatDate(date) {
    console.log(date)
    var dateComponents = date.split("-")
    console.log(dateComponents)
    var dateString = dateComponents[2]
    console.log("Date string")
    console.log(dateString)
    console.log(typeof(dateString))
    var wordingForNumber = ""

    if (dateString[0] == 1) {
      wordingForNumber = "th"
    }
    else if(dateString[1] === "1") {
      wordingForNumber = "st"
    }
    else if (dateString[1] === "2") {
      wordingForNumber = "nd"
    }
    else if (dateString[1] === "3") {
      wordingForNumber = "rd"
      
    }
    else {
      console.log("Giving th for " + dateString)
      console.log(dateString[0])
      console.log(dateString[1])
      wordingForNumber = "th"
    }
    dateString += wordingForNumber
    console.log(dateString)

    var month = convertMonthNumberToCorrespondingWords(dateComponents[1])
    var year = dateComponents[0]

    return dateString + " " + month + " " + year
  }

  function convertMonthNumberToCorrespondingWords(monthNumber) {
    switch(monthNumber) {
      case "01":
        return "January"
      case "02":
        return "February"
      case "03":
        return "March"
      case "04":
        return "April"
      case "05":
        return "May"
      case "06":
        return "June"
      case "07":
        return "July"
      case "08":
        return "August"
      case "09":
        return "September"
      case "10":
        return "October"
      case "11":
        return "November"
      case "12":
        return "December"
      default:
        return ""
    }
  }

  function formatTime(time) {
    var timeComponents = time.split(":")
    console.log(timeComponents)
    return timeComponents[0] + ":" + timeComponents[1] + " " + (timeComponents[0] < 12 ? "am" : "pm")

  }


    function MockExamSellingPoints() {
      var sellingPoints = [
        "Each exam session contains 2 GL style papers",
        "Guaranteed to cover all aspects of the 11 Plus syllabus",
        "Receive a detailed performance feedback report",
        "Access to detailed solutions to every question on completion",
        "Competitive ranking analysis against the national cohort",
        "Pinpoint any knowledge gaps to focus on",
        "Perfect their exam day technique",
        "Reduce nerves on exam day",
        "Offer available for first time customers", 
      ]
      var tiles = []
      for (var i = 0; i < sellingPoints.length; i++) {
        tiles.push
        (
        <div className="eleven-plus-mock-exam-selling-points">
            <i style={{paddingTop: "10px", paddingLeft: "4px"}} className="fas fa-check"></i>
            <b>{sellingPoints[i]}</b>
        </div>
        )
        tiles.push(<br></br>)
       }
      return tiles
    }

    return (

      <div className='mock-exam-landing-background'
      style={{ backgroundImage: 
      "url(" + require("../assets/mockExamLandingPageImages/mockExamStudent.jpg").default + ")"}}> 

        <Grid container style={{textAlign: "center", color:"white", textAlign: "-webkit-center"}}
          >
          <Grid item style={{width: "100%"}} xs={12} md={12} sm={12}>
            <Box
                sx={{
                  maxHeight: { xs: 70, md: 70 },
                  // width: { xs: "100px", md: 70 },
                  backgroundColor: "purple",
                }}
             >
               <Box
                  component="img"
                  sx={{
                    // height: 233,
                    // width: 350,
                    maxHeight: { xs: 70, md: 70 },
                    maxWidth: "100%",
                  }}
                  alt="11plus logo."
                  src={WhiteWhizzdomLogo}
                />
              </Box>
          </Grid>
          {/* <img className="mock-exam-landing-background" src={MockExamStudent} alt="11-plus-mock-exam"/> */}
          <Grid item className="p-4 mb-4" xs={12} sm={12}>
              <Typography variant="h4">Professional Online GL Mock Exams</Typography>
              <Typography variant="h6">11Plus Entrance examinations style paper </Typography>
              <Typography variant="h6">{nextFreeExamSessionDate == "" && nextFreeExamSessionTime == "" ?
              "Please check our website to register for any upcoming exam sessions" :
               "Exam date: " + formatDate(nextFreeExamSessionDate) + " at " + formatTime(nextFreeExamSessionTime)
              }</Typography>


          </Grid>
          
          <Grid container  >
            <Grid item xs={12} sm={6} order={{ xs:2, sm: 2, md: 2, lg: 2}} >
              <Card style={{marginTop: "10px"}} className="p-4" sx={{width: 300, height: 600}}>
          
                <Typography variant="h5">Get your child ready!</Typography>

                <div style={{marginTop: "15px"}}>
                  {MockExamSellingPoints()}
                </div>
              </Card>
            </Grid>
          <Grid item xs={12} sm={6} order={{ xs:1, sm: 1, md: 1, lg: 1}}>
            <Box
            style={{marginTop: "10px"}}
              sx={{
                width: 400,
                height: 380,
              }}>
              <MockExamSignUpPart1 handleOpen={handleOpen}/>
            </Box>
          </Grid>
          </Grid>
        </Grid>
        <Modal
          open={open}
          onClose={handleClose}
          className="landing-page-upcoming-mock-exams-modal"
        >
          <Box sx={style}>
            <MockExamRegistrationConfirmation/>
          </Box>
        </Modal>
        </div>

    )
}
