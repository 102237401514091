import React from "react";
import LoginComponent from "./LoginComponent.js";



export default function SchoolTeacherLogin({loginType}) {

  return (
    <LoginComponent loginType={loginType}/>
  );
}
