import React from 'react';
// import { useTheme } from '@material-ui/core/styles';
// import { ResponsiveContainer } from 'recharts';
import Title from '../Title';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import { white } from 'tailwindcss/colors';



const useStyles = makeStyles({
    root: {
      minWidth: 200,
      maxWidth: 200,
      height: 100,
      textAlign: 'center',
      padding: 10,
      color: "#0284C7",
      border: '1pt solid',
      '&:hover': {
        background: "#0284C7",
        color: white,
     },
    },
  });



export default function SubjectTile({title, onTileClick, key}) {
    const classes = useStyles();
    /**  const [subTopics, setSubTopics] = useState([])
     setSubTopics(["Standard Fractions", "Mixed Numbers and Top Heavy", "Mean, Median, Mode"]) */

    // Define useEffect function - will allow us to see if it has any subtopics on render


    return (
        <React.Fragment>
            {/* <ResponsiveContainer> */}
            <ButtonBase onClick={() => onTileClick('topics', title)} >
              <Card variant="outlined" className={classes.root}>
                    <Title>{title}</Title>
              </Card>
            </ButtonBase>
            {/* </ResponsiveContainer> */}
        </React.Fragment>
    )
}  