import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useHistory } from "react-router-dom";


import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';

import QuestionsAndUserDataService from '../../services/QuestionsAndUserDataService'


export default function ChangeSubscriberTypeOfUserForm(user) {

  const [subscriberType, setSubscriberType] = useState(getUserType(user.user.userRoles))
  const [isLoadingMockExamSessionsData, setIsLoadingMockExamSessionsData] = useState(true)
  const [mockExamSessionsData, setMockExamSessionsData] = useState([])
  const [selectedDates, setSelectedDates] = useState([])


  useEffect(() => {
    if (isLoadingMockExamSessionsData) {
        getUpcomingMockExamSessions()
    }
  });

  function getUpcomingMockExamSessions(){
    QuestionsAndUserDataService.getAllUpcomingMockExams().then(sessionsData => {
      setMockExamSessionsData(sessionsData.data)
      setIsLoadingMockExamSessionsData(false)
    })
  }

  const handleChange = (event) => {
    setSubscriberType(event.target.value);
  };


  const validationSchema = yup.object({
  });

  // const WithMaterialUI = () => {
    const formik = useFormik({
      initialValues: {
        customCheckLogin: false,
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {

        if (subscriberType === null) {
          console.log("Must have a subscriber type set")
          return 
        }
        console.log(user.user.userId)
        console.log(subscriberType)
        QuestionsAndUserDataService.updateUserSubscriberType(user.user.userId, subscriberType, selectedDates)
      },
    });

    function getUserType() {
      console.log(user)
      console.log(user.userRoles)
      console.log(user.user.userRoles)
      // Possible Roles
      // Mock exam subscriber 
      // Standard user 
      // Freemium User 
    
      let isPaidUser = user.user.isPaidCustomer
      let hasMockExamRole = checkForRoles(user.user.userRoles, "ROLE_MOCK_EXAM_STUDENT")
      let hasStandardUserRole = checkForRoles(user.user.userRoles, "ROLE_USER")
      let hasFreemiumUserRole = checkForRoles(user.user.userRoles, "ROLE_FREE_USER")

      console.log("Paid user: ")
      console.log(isPaidUser)
      console.log("hasMockExamRole")
      console.log(hasMockExamRole)
      console.log("hasStandardUserRole")
      console.log(hasStandardUserRole)
      console.log("hasFreemiumUserRole")
      console.log(hasFreemiumUserRole)


    
      if (user.isMockExamSubscriber) {
        return "Mock Exam Subscriber"
      } 
      if (hasStandardUserRole) {
        return "Standard User"
      }
      if (hasFreemiumUserRole) {
        return "Freemium User"
      }
      if (hasMockExamRole && isPaidUser) {
        return "One Off Mock Exam User"
      }
      return "ERROR - UNABLE TO READ USER ROLES"
    }
    
    function checkForRoles(userRoles, roleToCheckFor) {
      console.log("User roles:")
      console.log(userRoles)
      console.log("Checking for: " + roleToCheckFor)
    
      if (!userRoles) {
        console.log("User roles is false")
        return false
      }

    
      for (var i = 0; i < userRoles.length; i++) {
        console.log("Checking: " + userRoles[i].name)
        if (userRoles[i].name == roleToCheckFor) {
          console.log("FOUND WHAT WE'RE LOOKING FOR")
          return true
        }
      }
      return false
    }

    // function getUserType(userRoles) {

    //   console.log("User roles")
    //   console.log(userRoles)

    //   console.log(user)
    //   var roles = []
    //   console.log("Output of !userRoles")
    //   console.log(userRoles == null)
    //   if (userRoles == null) {
    //     return "ERROR - ROLES DON'T EXIST"
    //   }
      
    //   for (let i = 0; i < userRoles.size; i++) {
    //     roles.push(userRoles.name)
    //   }

    //   console.log("Roles")
    //   console.log(roles)

    //   if (userRoles.length == 2) {
    //     return "Mock Exam Subscriber"
    //   }
    //   if (userRoles.length == 1) {
    //     const role = userRoles[0].name
    //     console.log(role)
    //     if (role == "ROLE_MOCK_EXAM_STUDENT") {
    //       return "Mock Exam Student but NOT Subscriber"
    //     }
    //     if (role == "ROLE_USER") {
    //       return "Standard Learning Platform User"
    //     }
    //   }
    //   return "ERROR - UNABLE TO READ USER ROLES"
    // }

      // Handle the change in selected dates
      const handleDateChange = (event) => {
        const { value } = event.target;
        const currentDates = selectedDates;
        const index = currentDates.indexOf(value)
        // console.log(typeof selectedDates)
        if (index > -1) {
          // console.log("Current date: " + value + " - already included, removing now")
          // currentDates.splice(index, 1)
          setSelectedDates(selectedDates.filter((d) => d !== value));
  
  
        } else {
          // console.log("Current date: " + value + " - not included, including now")
          // currentDates.push(value)
          // setSelectedDates(currentDates)
          setSelectedDates([...selectedDates, value]);
  
        }
        // setSelectedDates(currentDates);
      };

      function currentlySelectedDatesContainMockExamSession(mockExamDate) {
        return selectedDates.includes(mockExamDate)
      }
  

  return (
    
    <div>
     <form
        onSubmit={formik.handleSubmit}
        >
         <label> Click to select the new subscriber status (This <b>won't change any payments</b> being made on Stripe)</label>
         <br></br>
         <br></br>
         <div>
          <b>Email:</b> {user.user.email}
          <br></br>
          <b>Parent Name:</b> {user.user.parentFirstName + " " + user.user.parentLastName}
          <br></br>
          <b>Child Name:</b> {user.user.studentName}
          <br></br>
          <b>Current subscriber status:</b> {getUserType(user.user.roles)}
         </div>
     
         <Box style={{textAlign:"center"}}>

          <InputLabel className='mb-4' id="subcriberType">Subscriber Type</InputLabel>
            <Select
              labelId="subcriberType"
              id="subcriberType"
              value={subscriberType}
              autoWidth
              label="subcriberType"
              onChange={handleChange}
              className="px-2"
            >
                <MenuItem value="MOCK_EXAM_SUBSCRIBER">Mock Exam Subscriber</MenuItem>
                <br></br>
                <MenuItem value="STANDARD_LEARNING">Standard Learning Platform Only</MenuItem>
                <br></br>
                <MenuItem value="MOCK_EXAM_NON_SUBSCRIBER">Mock Exam Non Subscriber</MenuItem>
            </Select>

          </Box>


        {isLoadingMockExamSessionsData ? <>Loading upcoming mock exam sessions</> :
      

            <Box style={{textAlign:"center"}}>
              <InputLabel id="dates">Select Dates - This will add to their existing dates</InputLabel>
                <FormGroup>
                  <FormControlLabel
                      key={"all"}
                      control={
                        <Checkbox
                          checked={currentlySelectedDatesContainMockExamSession("all")}
                          onChange={handleDateChange}
                          value={"all"}
                        />
                      }
                      label={"Make Subscriber"}
                    />
                  {mockExamSessionsData ? (mockExamSessionsData.map((mockExamSession) => (
                    <FormControlLabel
                      key={mockExamSession.mockexamSessionId}
                      control={
                        <Checkbox
                          checked={currentlySelectedDatesContainMockExamSession(mockExamSession.mockExamDate)}
                          onChange={handleDateChange}
                          value={mockExamSession.mockExamDate}
                        />
                      }
                      label={mockExamSession.mockExamDate}
                    />)
                  )) : <div>No mock exam sessions</div>}
                </FormGroup>
            </Box>
            }

        <div className="text-center mt-6">
          <Button
            colour="primary"
            className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
            type="submit"
          >
            Update Subscriber Status
          </Button>
        </div>
      </form>
    </div>
  );
}
