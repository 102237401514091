import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

// Mui imports
import TextField from '@material-ui/core/TextField';
import Box from '@mui/material/Box';
import Link from '@material-ui/core/Link';
import { Typography } from '@material-ui/core';
import Modal from '@mui/material/Modal';


// System imports
import QuestionsAndUserDataService from '../../services/QuestionsAndUserDataService'
import "../../assets/styles/landingPage.css";
import * as CONSTANTS from "../../StringConstants";

import elevenPlusSchoolBanner from "../../assets/blogImages/ChoosingTheCorrectSchool/Choosing11PlusSchoolBanner.jpg"


export default function ChoosingTheCorrectSchool() {
    let history = useHistory();

    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [hasSubmittedAnEmail, setHasSubmittedAnEmail] = useState(false)
      // Use a URLSearchParams object to parse the query string
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const viewSource = queryParams.get('source');  

    useEffect(() => {
        let blogViewed = CONSTANTS.ChoosingYourPerfectElevenPlusSchoolTitle
        QuestionsAndUserDataService.updateBlogsViewData(blogViewed, viewSource)
    });

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };

    const validationSchema = yup.object({
      email:yup
      .string('Email')
      .email('Enter a valid email')
    });
  
  
    const formik = useFormik({
      initialValues: {
        email: '',
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {
        if (formik.values.email === "") {
          console.log("Email value is blank")
        } else {
          console.log("Adding subscriber email to list")
          setHasSubmittedAnEmail(true)
          // sendWelcomeEmailToNewSubscriber()
          QuestionsAndUserDataService.addToSubscriptionList(formik.values.email).then(setOpen(false))
        }
      },
    });

    function createCheckoutSession(lookupKey) {
        // Handle multiple keys and products later - for now just direct to sign up with the one look up 
        history.push("/auth/register/2")
        }


  return (        
        <div className='eleven-plus-preparation-blog'>

            <Helmet>
                <title>Choosing the Correct School for The 11 Plus</title>
                <meta name="title" content="Choosing the Right School After the 11 Plus Exam"/>
                <meta name="description" content="Guide on selecting the best school post 11 Plus in the UK, differentiating between Grammar and Private Schools, understanding child's needs, researching options, and making an informed decision."/>
                <meta name="keywords" content="11 Plus, choosing school, Grammar Schools, Private Schools, Ofsted ratings, child's education, academic success"/>
            </Helmet>
           
            <div className='eleven-plus-info-blog-title-and-image'>
                <Typography className="eleven-plus-blog-title" variant="h3"><b>Choosing the Correct School for The 11 Plus</b></Typography>
                <img
                className='eleven-plus-info-blog-image'
                alt="11plus-preparation-blog-info-image"
                src={elevenPlusSchoolBanner}
                />  
            </div>  

            <div className='eleven-plus-preparation-blog-content'>
            {/* <Typography className="eleven-plus-preparation-blog-subheading" variant="h6"><b>What is the 11plus</b></Typography> */}
            <Typography variant="h6">
            Choosing the right school for your child is one of the most important decisions you'll make as a parent. After the 11 Plus exam in the UK, 
            the options and opportunities open up, making this decision even more crucial. This blog post discusses the key factors to consider when 
            selecting the correct school for them to set them on the right path. It's also important not to overlook your child's needs when considering
            their schooling options as some environments will help them succeed a lot more than others. 
            </Typography>
   

            <Typography className="eleven-plus-preparation-blog-subheading" variant="h6">
              <b>The Difference Between Grammar Schools and Private Schools</b>
            </Typography>
            <Typography variant="h6">
            The two types can vary greatly in terms of their requirements from parents and the support they provide to children. Grammar schools are typically 
            state-funded and free, while private schools will require parents to pay fees (the amount of which depends on the school). Admission to grammar 
            schools is based on academic performance, precisely the 11 Plus exam results. Private schools are open to anyone who can afford it, although some 
            have selective criteria, these will typically be a lot less competitive than grammar schools.
            <br/>
            <br/>
            Another distinguishing factor between the two types of schools is the cohort size. Grammar schools tend to have larger class sizes, with 
            government-provided resources that prioritise academic excellence. On the other hand, private schools often have smaller class sizes, allowing
            for more personalised attention to students. Their tuition-based funding also usually enables better facilities and extracurricular activities.
            </Typography>

            <Typography className="eleven-plus-preparation-blog-subheading" variant="h6"><b>Understanding Your Child's Needs and Aspirations</b></Typography>
            <Typography variant="h6">
            When choosing the correct school for your child is understanding their unique needs and aspirations. Consider their strengths, 
            weaknesses, interests, and learning styles. Do they thrive in a competitive environment or prefer a more relaxed setting? Are they interested 
            in specific subjects or extracurricular activities? Understanding your child's individuality will help you identify a school that aligns with 
            their needs and fosters growth. Having this alignment will ultimately best suit their long term development and set them up for success.
            </Typography>
            <br></br>
            <br></br>
            <br></br>
            <Typography variant="h6">We understand the importance of the 11 Plus and believe that all parents should have a full understanding as well in order
             to prepare their child. For any queries in regards to the process, email <u>11plus@whizzdom.co.uk</u> and our team will address any concerns or queries 
             that you may have. We also send out free resources and 11 Plus information each week via <Link onClick={handleOpen}><u>our mailing list.</u></Link>
            </Typography>
        
                <div className="pricing-button-container">
                    <button
                        className="pricing-button-standard"
                        onClick={() => createCheckoutSession("lookupkey")}
                    >Get started today
                    </button>
                </div>
                <div className="pt-6">
                { hasSubmittedAnEmail ? 
                <b style={{textAlign: "center"}}>You've been added to our subscriber list! </b> : ""
                } 
                </div>        
            </div>
  
        <Modal
          open={open}
          onClose={handleClose}
        >
          <Box sx={style}>
            <form onSubmit={formik.handleSubmit}>
                <div className="relative w-full mb-3">
                    <TextField
                        fullWidth
                        id="email"
                        name="email"
                        label="Email"
                        onChange={(e) => {
                        formik.handleChange(e);
                        }}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                    />
                </div>

                <div className="text-center mt-6">
                <button
                    className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="submit"
                >
                    Get Started
                </button>
                </div>
            </form>
          </Box>
        </Modal>



        </div>
  );
}
