import React, { useState, useEffect } from 'react';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';


import { Typography } from '@mui/material';
import TLHClassesForEachDay from 'theLearningHub/layouts/HelperComponents/TLHClassesForEachDay';
import CreateNewClassModel from 'theLearningHub/layouts/HelperComponents/CreateNewClassModel';
import TLHClassesAPICalls from 'services/TLHClassesAPICalls';

export default function TLHClasses() {

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const [createNewClassModel, setCreateNewClassModel] = useState(false);
  const handleOpenCreateNewClassModel = () => setCreateNewClassModel(true);
  // const handleCloseCreateNewClassModel = () => setCreateNewClassModel(false);
  
  function handleCloseCreateNewClassModel(upToDate) {
    setCreateNewClassModel(false);
    console.log("Is updated: " + upToDate)
    console.log(upToDate)
    if (!upToDate) {
      window.location.reload();
    }
  }

  const [isLoadingClassesData, setIsLoadingClassesData] = useState(true)
  const [lessonsData, setLessonsData] = useState([])


  useEffect(() => {
    if (isLoadingClassesData) {
      getAllTLHLessons()
    }
  }, [lessonsData]);

  function getAllTLHLessons() {
    TLHClassesAPICalls.getAllTLHLessons().then(result => {
      console.log(result.data)
      var sortedLessons = sortClassesByDay(result.data)
      setLessonsData(sortedLessons)
      setIsLoadingClassesData(false)
    })
  }

  function sortClassesByDay(classes) {
    // Initialize an empty object to store sorted classes
    const sortedClasses = {};

    // Iterate over the classes and sort them into the object
    classes.forEach(cls => {
        const day = cls.classDay;
        // If the day does not exist in the object, initialize it with an empty array
        if (!sortedClasses[day]) {
            sortedClasses[day] = [];
        }
        // Append the class to the corresponding day array
        sortedClasses[day].push(cls);
    });

    return sortedClasses;
}

const daysOfTheWeek = ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"];
const daysOfTheWeekCapitalized = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];


const renderClassesForAllDays = (lessonsData) => {
  return daysOfTheWeek.map((day, index) => {
    const classesForDay = lessonsData[day];
    if (!classesForDay || classesForDay.length === 0) {
      return null; // Skip rendering if there are no classes for the day
    }
    return (
      <React.Fragment key={day}>
        <TLHClassesForEachDay
          classesData={classesForDay}
          dayOfTheWeek={daysOfTheWeekCapitalized[index]}
        />
      </React.Fragment>
    );
  });
};

  return (
    
    <div>
       <div style={{ overflowX: 'auto', width: '100%'}}>
          <Typography variant="h5" align='center' style={{padding: "15px"}}>
            TLH Classes
          </Typography>
        </div>

      {isLoadingClassesData ? 
      <>
        <Typography variant="h6" align='center' style={{padding: "15px"}}>
            Loading classes data ...
        </Typography>
      </> :
        <>
          {/* <TLHClassesForEachDay
            classesData={lessonsData["MONDAY"]}
            dayOfTheWeek={"Monday"}
          /> */}
            {renderClassesForAllDays(lessonsData)}
        </>
      }

      <div style={{textAlign: 'center', padding: '30px'}}>
        <button 
            style={{
              padding: '10px 20px',
              fontSize: '18px',
              color: 'white',
              backgroundColor: '#0056b3',
              border: 'none',
              borderRadius: '5px',
              boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
              cursor: 'pointer',
              transition: 'all 0.3s ease',
          }}
          onMouseOver={(e) => {
              e.target.style.backgroundColor = '#004085';
              e.target.style.boxShadow = '0 8px 16px rgba(0,0,0,0.2)';
          }}
          onMouseOut={(e) => {
              e.target.style.backgroundColor = '#0056b3';
              e.target.style.boxShadow = '0 4px 8px rgba(0,0,0,0.1)';
          }}
          onClick={handleOpenCreateNewClassModel}
          type="submit"
        >
            Create New Class
        </button>
      </div>



      <Modal
          open={createNewClassModel}
          onClose={handleCloseCreateNewClassModel}
          // aria-labelledby="modal-add-mock-exam"
          // aria-describedby="modal-able-to-add-a-set"
        >
          <Box sx={style}>
            <CreateNewClassModel
              handleClose={handleCloseCreateNewClassModel}
            />
          </Box>
        </Modal>
      
    </div>
    
  );
}

