import React from 'react';
import { useHistory } from "react-router";

// Material UI imports

import Typography from '@material-ui/core/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { CardActionArea } from '@mui/material';

// string constants

import * as CONSTANTS from "../../../StringConstants.js";


export default function ClassCard({classData}) {
  let history = useHistory();

  var maxCardWidth = 345
  var minCardHeight = 250

  function viewClassDetails() {
    console.log(classData)
    console.log("Viewing class details")
    history.push(CONSTANTS.ViewClass + "/" + `${classData.classId}`)
  }


  return (
    <div>
          <Grid>
            <CardActionArea onClick={viewClassDetails}> 
            <Card sx={{ width: maxCardWidth, height:minCardHeight }} className="pt-2" >
                <div style={{backgroundColor: "white"}}>
                <CardContent>
                    <div>
                        <Typography gutterBottom variant="h4" component="div" style={{textAlign:"center"}}>
                            {classData.className}
                        </Typography>
                    </div>
                    <Typography gutterBottom variant="h5" component="div">
                        Latest Homework Completion: {classData.numberStudentsCompletedLastAssignment}
                    </Typography>
                </CardContent>
                </div>
                <div style={{textAlign: "center"}}>
                    <Typography gutterBottom variant="h5" component="div">
                        View Class Details
                    </Typography>
                </div>
              </Card>
            </CardActionArea>
          </Grid>      
        {/* </Grid> */}

        
    </div>
  );
}
