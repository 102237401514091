import React, { useState } from 'react';
import "../../assets/styles/index.css";
import { Link } from "react-router-dom";

// import CreateHeadTeacherForm from 'views/admin/CreateHeadTeacherForm.js';
// import CreateMockExamUserForm from 'views/admin/CreateMockExamUserForm.js';
// import * as CONSTANTS from "../../StringConstants.js";
// import CreateMockExamSetForm from './AdminComponents/CreateMockExamSetForm';
// import CreateMockExamSessionForm from './AdminComponents/CreateMockExamSessionForm';


// Material UI imports

import Button from '@material-ui/core/Button';
import { AppBar, Toolbar, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { CardActionArea } from '@mui/material';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TLHStaffDashboardAssessmentsTable from 'theLearningHub/layouts/HelperComponents/TLHStaffDashboardAssessmentsTable';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


export default function TLHStaffMainDashboard() {

  // const [currentNumberOfSchools, setCurrentNumberOfSchools] = useState(0)
  // const [currentNumberOfSchoolStudents, setCurrentNumberOfSchoolStudents] = useState(0)
  // const [currentNumberOfIndividualUsers, setCurrentNumberOfIndividualUsers] = useState(0)

  // var maxCardWidth = 345
  // var minCardHeight = 200

  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  return (
    
    <div>
      <Typography
          gutterBottom
          variant="h4"
          component="div"
          style={{ padding: '20px' }}  // Adds padding of 20 pixels on all sides
        >
          Welcome back
      </Typography>
      
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TLHStaffDashboardAssessmentsTable
            date={today} 
            isAssessmentsOrAssessmentsMeetingTable={"Assessments"}
          />
        </Grid>

        <Grid item xs={12}>
          <TLHStaffDashboardAssessmentsTable
            date={tomorrow} 
            isAssessmentsOrAssessmentsMeetingTable={"Assessments"}
          />
        </Grid>

        <Grid item xs={12}>
          <TLHStaffDashboardAssessmentsTable
            date={today} 
            isAssessmentsOrAssessmentsMeetingTable={"Assessment Meetings"}
          />
        </Grid>

        <Grid item xs={12}>
          <TLHStaffDashboardAssessmentsTable
            date={tomorrow} 
            isAssessmentsOrAssessmentsMeetingTable={"Assessment Meetings"}
          />
        </Grid>
      </Grid>
    </div>
  );
}
