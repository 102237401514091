import { useHistory } from "react-router";
import React, { useState } from 'react';

import "../../assets/styles/index.css";
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Settings from '@mui/icons-material/Settings';
import QuestionsAndUserDataService from '../../services/QuestionsAndUserDataService'
import * as CONSTANTS from "../../StringConstants"
import Divider from '@mui/material/Divider';

import MuiAppBar from '@mui/material/AppBar';



import { styled, useTheme } from '@mui/material/styles';


export default function MockExamAccountSettingsButton() {
  let history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [openSettingsMenu, setOpenSettingsMenu] = useState(false)

  const open = Boolean(anchorEl);
  const drawerWidth = 260;

  
   const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenSettingsMenu(true)
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenSettingsMenu(false)
  };

  function userLogout() {
    history.push(CONSTANTS.IndividualLogin)
    localStorage.removeItem("user")
  }

  function switchToAnalyticsDashboard() {
    history.push("/app/analytics")
  }

  function createBillingPortal() {
    QuestionsAndUserDataService.createCustomerPortal() 
  }

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  return (
    <>
      {/* Navbar */}
      <Box>
              <Tooltip title="Account settings">
                <IconButton
                  onClick={handleClick}
                  size="small"
                  sx={{ ml: 0 }}
                  aria-controls={openSettingsMenu ? 'account-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={openSettingsMenu ? 'true' : undefined}
                >
                  {/* <ListItemIcon> */}
                    <Settings sx={{ color: "white" }} fontSize="large" />
                  {/* </ListItemIcon>  */}
                </IconButton>
             </Tooltip>
            </Box>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={openSettingsMenu}
              onClose={handleClose}
              onClick={handleClose}
            
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  padding: 1,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 2,
                    right: 8,
                    width: 20,
                    height: 20,
                    padding: 1,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
        {/* <MenuItem>
          <Avatar /> Student Profile
        </MenuItem> */}
        <MenuItem
          onClick={createBillingPortal}>
           My Subscription
        </MenuItem>
        <Divider />

        {/* <MenuItem
          onClick={() => switchToAnalyticsDashboard(true)}>
          Review Area
        </MenuItem>
        <Divider /> */}

        <MenuItem
          onClick={userLogout}>
          <ListItemIcon>
            {/* <Logout fontSize="small" /> */}
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
      </>
  );
}
