import React from 'react'
import { Link } from "react-router-dom";

// Mui imports
import { Grid } from '@material-ui/core';
import Box from '@mui/material/Box';
import Typography from '@material-ui/core/Typography';
import StandardUserSignUpPart1 from "./StandardUserSignUpPart1"
import Card from '@mui/material/Card';

import WhiteWhizzdomLogo from "../assets/logoVariations/Primarylogomark_purple_black_white-03.png"
import StandardUserBackground from "../assets/freemiumSignUpLandingPage/signup-page-background.png"

export default function StandardUserSignUp() {

    function StandardUserSignUpSellingPoints() {
      var sellingPoints = [
        "Aligned with the Year 5 + 6 core curriculum.", 
        "Analytics and weekly reports on your child's progress.",
        // "Unlimited Questions.",
        "Easy to view answers.",
        "Review portal for past worksheets.",
        "Measure your child's improvement over time.",
        // "Only £8.99 per month",
        // "Get 10 questions free questions every day.",
        "Try it free today!"
      ]
      var tiles = []
      for (var i = 0; i < sellingPoints.length; i++) {
        tiles.push
        (
        <div className="eleven-plus-mock-exam-selling-points">
            <i style={{paddingTop: "10px", paddingLeft: "4px"}} className="fas fa-check"></i>
            <b>{sellingPoints[i]}</b>
        </div>
        )
        tiles.push(<br></br>)
       }
      return tiles
    }

    return (

      <div className='mock-exam-landing-background'
        style={{               
        backgroundImage: `url(${StandardUserBackground})` ,
        // backgroundSize: 'cover',
        backgroundSize: '2390px 1247px',  // Scaled up from the original image dimensions
        backgroundPosition: 'center',
        }}
      > 

        <Grid container style={{textAlign: "center", color:"white", textAlign: "-webkit-center"}}
          >
          <Grid item style={{width: "100%"}} xs={12} md={12} sm={12}>
            <Box
                sx={{
                  maxHeight: { xs: 70, md: 70 },
                  // width: { xs: "100px", md: 70 },
                  backgroundColor: "purple",
                }}
             >
               <Box
                  component="img"
                  sx={{
                    // height: 233,
                    // width: 350,
                    maxHeight: { xs: 70, md: 70 },
                    maxWidth: "100%",
                  }}
                  alt="11plus logo."
                  src={WhiteWhizzdomLogo}
                />
              </Box>
          </Grid>
          {/* <img className="mock-exam-landing-background" src={MockExamStudent} alt="11-plus-mock-exam"/> */}
          <Grid item className="p-4 mb-4" xs={12} sm={12}>
              <Typography variant="h3">Access Free 11 Plus Resources!</Typography>
              {/* <Typography variant="h4">11Plus Entrance examinations style paper </Typography> */}
              <Typography variant="h5">Get 50 questions a day for 30 days!</Typography>
          </Grid>
          {/* <div style={{ textAlign: "center", width: "100%" }}>
            <Link to="/auth/register/1">
                <button
                  className="eleven-plus-book-now-button"
                  type="button"
                >
                Get Started Today!
              </button>
            </Link>
            </div> */}

          {/* <Grid item className="p-4 mb-4" xs={12} sm={12}>
          <div className="container mx-auto">
            <div className="flex flex-wrap">
              <div className="pb-6 pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
                      <i className="fas fa-award"></i>
                    </div>
                    <h6 className="text-xl font-semibold" style={{color: "black"}}>Unlimited Practice</h6>
                    <p className="mt-2 mb-4 text-blueGray-500">
                      With no limit on the number of questions, your child
                      can keep practising until they feel ready.
                    </p>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-lightBlue-400">
                      <i className="fas fa-retweet"></i>
                    </div>
                    <h6 className="text-xl font-semibold" style={{color: "black"}}>Intuitive Learning Algorithms</h6>
                    <p className="mt-2 mb-4 text-blueGray-500">
                      Don't know where to start? Our smart learning algorithms
                      will identify your child's weak spots so they're
                      ready for anything.
                    </p>
                  </div>
                </div>
              </div>

              <div className="pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-emerald-400">
                      <i className="fas fa-fingerprint"></i>
                    </div>
                    <h6 className="text-xl font-semibold" style={{color: "black"}}> Easy to Use </h6>
                    <p className="mt-2 mb-4 text-blueGray-500">
                      No complicated procedures, just sign up and go!
                      Straight forward learning made simple for your child. 
                    </p>
                  </div>
                </div>
              </div>
            </div>
            </div>
            </Grid> */}

            {/* <div style={{ textAlign: "center", width: "100%", marginTop: "-25px" }}>
              <Link to="/auth/register/1">
                  <button
                    className="eleven-plus-book-now-button"
                    type="button"
                  >
                  Get Learning!
                </button>
              </Link>
            </div> */}

            {/* <Grid container style={{paddingBlock: "20px"}}>
              <Grid item xs={1} ></Grid>
              <Grid item xs={10} >
                <Card style={{marginTop: "10px"}} className="p-4"  sx={{
                    width: [400, 500],
                    height: [300, 400],
                    '@media (max-width: 576px)': {
                      width: '100%',
                      height: 'auto'
                    }
                  }}>
            
                  <h1 className="text-black font-semibold text-5xl" style={{padding: "20px", verticalAlign: "super", paddingBlock: "20px"}}>
                      <i>"It's so clear and easy to use, my 11 plus wouldn't have been possible without Whizzdom."</i>
                    </h1>
                    <p className="mt-4 text-lg text-gray-200">
                    <i>Whizzdom Student</i>
                    </p>
                </Card>
              </Grid>
              <Grid item xs={1}></Grid>
            </Grid> */}
{/* 
            <div style={{ textAlign: "center", width: "100%" }}>
              <Link to="/auth/register/1">
                  <button
                    className="eleven-plus-book-now-button"
                    type="button"
                  >
                  Sign up now!
                </button>
              </Link>
            </div> */}

          <Grid container  >
            <Grid item xs={12} sm={6} order={{ xs:2, sm: 2, md: 2, lg: 2}} >
              <Card style={{marginTop: "15px", marginBottom: "15px"}} className="p-4" sx={{width: 280, height: 440}}>
          
                <Typography variant="h5">Get your child ready!</Typography>

                <div style={{marginTop: "15px"}}>
                  {StandardUserSignUpSellingPoints()}
                </div>
              </Card>
            </Grid>
          <Grid item xs={12} sm={6} order={{ xs:1, sm: 1, md: 1, lg: 1}}>
            <Box
            style={{marginTop: "10px"}}
              sx={{
                width: 400,
                height: 380,
              }}>
              <StandardUserSignUpPart1/>
            </Box>
          </Grid>
          </Grid>
        </Grid>
        </div>

    )
}
