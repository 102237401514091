import React, { useState, useEffect } from "react";
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios'
import {useParams} from "react-router-dom";
import { useHistory } from "react-router";

// MUI Imports 
import Switch from '@mui/material/Switch';
import TextField from '@material-ui/core/TextField';
import { alpha, styled } from '@mui/material/styles';
import { pink } from '@mui/material/colors';

// components

import "../assets/styles/index.css";
import "../assets/styles/landingPage.css";
import Navbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footers/Footer.js";
import PricingCard from "components/Cards/CardPricing.js"
import * as PRICING_DETAILS from "../views/PricingDetails.js";
import PromoBar from './LandingPageComponents/PromoBar'

// images 

import mockExamsPicture from '../assets/landingPage/LandingPageMockExams.jpg' 
import metricsPicture from '../assets/landingPage/MetricsLandingPage.jpg'
import mockExamsPictureWebP from '../assets/WebPImages/LandingPageImages/LandingPageMockExams.webp'
import metricsPictureWebP from '../assets/WebPImages/LandingPageImages/MetricsLandingPage.webp'
import backgroundImageWebP from 'assets/WebPImages/LandingPageImages/landing-page-main-image.webp'
import backgroundImage from "assets/img/landing-page-main-image.jpg"

import QuestionsAndUserDataService from "services/QuestionsAndUserDataService";

export default function Landing() {

  const [hasSubmittedAnEmail, setHasSubmittedAnEmail] = useState(false)
  const [ip, setIP] = useState('');
  const { fbclid } = useParams()
  const [isOn, setIsOn] = useState(false);

  let history = useHistory();

  //creating function to load ip address from the API
  const getData = async () => {
    const res = await axios.get('https://geolocation-db.com/json/')
    console.log(res.data);
    var ip = res.data.IPv6
    if (!ip || ip === 'undefined' || ip === null) {
      ip = res.data.IPv4
    }
    setIP(ip)
  }
  
  useEffect( () => {
    //passing getData method to the lifecycle method
    getData()

  }, [])

  const validationSchema = yup.object({
    email:yup
    .string('Email')
    .email('Enter a valid email')
  });

  const PinkSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: pink[600],
      '&:hover': {
        backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: pink[600],
    },
  }));

  const label = { inputProps: { 'aria-label': 'Color switch demo' } };



  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (formik.values.email === "") {
        console.log("Email value is blank")
      } else {
        console.log("Adding subscriber email to list")
        setHasSubmittedAnEmail(true)
        // sendWelcomeEmailToNewSubscriber()
        QuestionsAndUserDataService.addToSubscriptionList(formik.values.email, ip, fbclid).then(result => {
          console.log(result)
        })
        QuestionsAndUserDataService.sendWelcomeEmailToNewSubscriber(formik.values.email, false, ["epZgwQ"]).then(result => {
          console.log(result)
        })
      }
    },
  });


  // function sendWelcomeEmailToNewSubscriber() {
  //   console.log("Attempting to send email")
  //   QuestionsAndUserDataService.sendWelcomeEmailToNewSubscriber("harshil.r.joshi@gmail.com")
  //   .then(response => {
  //     console.log("Got response:")
  //     console.log(response)
  //   }, (error) => {
  //     console.log("error adding to subscriber list")
  //     console.log(error);
  //   })
  // }

  const dynEnd = new Date('September 18, 2022 10:00:00');


  return (
    <>
      <Navbar transparent />
      <PromoBar
        btnLabel={"Book now"}
        link={"/11plus-mock-exams"}
        endTime={dynEnd}
      />
      <Helmet>
        <title>Whizzdom 11 plus</title>
        <meta name="Whizzdom 11 plus" content="Proving 11 plus support to make the 11 plus clear and easy 
        to understand. Working with children and parents during the 11 plus through adaptive learning, mock exams and
        clear resources to help each child fulfill their potential " />
      </Helmet>
      <main>
        <div className="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75">
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover"
            style={{
              backgroundImage: `url(${backgroundImage}), url(${backgroundImageWebP})`,
              // `url(require("assets/img/landing-page-main-image.jpg").default) ,
                // url(require("assets/WebPImages/LandingPageImages/landing-page-main-image.webp").default)`,
            }}
          >
            {/* <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/landing.jpg").default}
                      /> */}
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-75 bg-black"
            ></span>
          </div>
          <div className="container relative mx-auto" style={{position: "relative"}}>
            <div className="items-center flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                <div>
                  <h1 className="text-white font-semibold text-5xl">
                    <i>"It's so clear and easy to use, my 11 plus wouldn't have been possible without Whizzdom."</i>
                  </h1>
                  <p className="mt-4 text-lg text-blueGray-200">
                   <i>Whizzdom Student</i>
                  </p>
                  <Link to="/auth/register/1">
                  <button
                    className="banner-sign-up-button"
                    type="button"
                  >
                  {/* <i className="fas fa-arrow-alt-circle-down"></i>  */}
                  Get Started Today!
                </button>
                </Link>
                </div>
              </div>
            </div>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-blueGray-200 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </div>

        <section className="pb-20 bg-blueGray-200 -mt-24">
          <div className="container mx-auto">
            <div className="flex flex-wrap">
              <div className="pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
                      <i className="fas fa-award"></i>
                    </div>
                    <h6 className="text-xl font-semibold">Unlimited Practice</h6>
                    <p className="mt-2 mb-4 text-blueGray-500">
                      With no limit on the number of questions or mock exams, your child
                      can keep practising until they feel ready.
                    </p>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-lightBlue-400">
                      <i className="fas fa-retweet"></i>
                    </div>
                    <h6 className="text-xl font-semibold">Intuitive Learning Algorithms</h6>
                    <p className="mt-2 mb-4 text-blueGray-500">
                      Don't know where to start? Our smart learning algorithms
                      will identify your child's weak spots so they're
                      ready for anything.
                    </p>
                  </div>
                </div>
              </div>

              <div className="pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-emerald-400">
                      <i className="fas fa-fingerprint"></i>
                    </div>
                    <h6 className="text-xl font-semibold"> Easy to Use </h6>
                    <p className="mt-2 mb-4 text-blueGray-500">
                      No complicated procedures, just sign up and go!
                      Straight forward learning made simple for your child. 
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="pricing-button-container">
                <button className="pricing-button-pro" onClick={() => history.push("/auth/register/1")}> 
                   Fast track your child's learning
                </button>
            </div>

            <div className="flex flex-wrap items-center mt-4">
              <div className="w-full md:w-5/12 px-4 mr-auto ml-auto">
                <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                  <i className="fas fa-file-alt fa-2x"></i>
                </div>
                <h3 className="text-3xl mb-2 font-semibold leading-normal">
                  Realistic Mock Examinations
                </h3>
                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
                  Once your child has an understanding of their topics the next step is to 
                  get them ready for their exams. Mock exam practice will allow them to perfect 
                  their time management whilst enabling them to develop the right strategy. 
                </p>
                <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-blueGray-600">
                  These tests are specifically designed to simulate a real exam day environment 
                  so that on the day your child feels confident and is able to perform at their 
                  best. With instant feedback available, students are able to constantly improve on their 
                  mistakes.
                </p>
              </div>

              <div className="w-full md:w-4/12 px-4 mr-auto ml-auto">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-lightBlue-500">
                  <img
                    alt="..."
                    src={mockExamsPictureWebP}
                    srcSet={mockExamsPicture}
                    className="w-full align-middle rounded-t-lg"
                  />
                  <blockquote className="relative p-8 mb-4">
                    <svg
                      preserveAspectRatio="none"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 583 95"
                      className="absolute left-0 w-full block h-95-px -top-94-px"
                    >
                      <polygon
                        points="-30,95 583,95 583,65"
                        className="text-lightBlue-500 fill-current"
                      ></polygon>
                    </svg>
                    <h4 className="text-xl font-bold text-white">
                      11 Plus Mock Exams
                    </h4>
                    <p className="text-md font-light mt-2 text-white">

                      Unlimited access to mock exams with individual 
                      question feedback. Boost your child's perfomance 
                      by helping them prepare for anything. 

                    </p>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>

          <div className="pricing-button-container">
                <button className="pricing-button-pro" onClick={() => history.push("/auth/register/2")}> 
                  Simulate the 11plus exam
                </button>
            </div>

        </section>

        <section className="relative py-20">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-white fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4">
            <div className="items-center flex flex-wrap">
              <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
                <img
                  alt="..."
                  className="max-w-full rounded-lg shadow-lg"
                  src={metricsPictureWebP}
                  srcSet={metricsPicture}
                />
              </div>
              <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
                <div className="md:pr-12">
                  <div className="text-lightBlue-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 mt-8 shadow-lg rounded-full bg-lightBlue-300">
                    <i className="fas fa-rocket text-xl mt"></i>
                  </div>
                  <h3 className="text-3xl font-semibold">Analytics on your child's performance</h3>
                  <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                    Track how your child is doing with their preperation, seeing their scores and progress 
                    over time. Isolate any topics they are struggling with to help to guide them 
                    in the right direction. 
                  </p>
                  <ul className="list-none mt-6">
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i className="fas fa-poll"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                            Consice results
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                            <i className="fas fa-thumbs-up"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                            Easy to understand
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                            <i className="fas fa-users-cog"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                            Tailored to your child
                          </h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

{/* pt-20 pb-48 bg-blueGray-200 */}
        <section className="pricing-container">

            {/* <div className="flex flex-wrap justify-center text-center mb-24"> */}
              {/* <div className="w-full lg:w-6/12 px-4"> */}
              <div className="pricing-section-header">
                <h2 className="text-4xl font-semibold color-white">Get Started</h2>

                {/* Can build on this switch when we introduce annual billing */}
                {/* <Switch {...label} defaultChecked color="default" /> */}

              

                {/* <p className="text-lg leading-relaxed m-4">
                  Verbal and Non Verbal Reasoning coming soon.
                </p> */}
              </div>
              <div>
     
                <div className="pricing-cards">
                  <PricingCard 
                    className="pricing-button-pro" 
                    title="Unlimited Maths & English Practice (Standard)" 
                    price="£8.99 per month" 
                    features={PRICING_DETAILS.ProDetails}
                    callToActionButtonText="Start Learning Today"
                    productId="1"
                    isBestDeal={false}
                  />
                </div>

                <div className="pricing-cards">
                  <PricingCard 
                    className="pricing-button-pro" 
                    title="Professional 11plus Mock Exams (Premium)" 
                    price="Billed monthly at £19.49 per set" 
                    features={PRICING_DETAILS.mockExamDetails}
                    callToActionButtonText="Get Exam Day Ready"
                    productId="2"
                    isBestDeal={true}
                  />
                </div>


                <div className="pricing-cards">
                  <PricingCard 
                    className="pricing-button-pro" 
                    title="One Off Professional 11plus Mock Exam" 
                    price="£24.99 per set" 
                    features={PRICING_DETAILS.oneOffMockExamDetails}
                    callToActionButtonText="Select Date"
                    productId="3"
                    isBestDeal={false}
                  />
                </div>

                {/* <div className="pricing-cards">
                  <PricingCard className="pricing-button-standard" title="Verbal & Non Verbal Reasoning" price="Coming Soon" features={PRICING_DETAILS.ProDetails}/>
                </div> */}


              {/* </div> */}
            {/* </div> */}
          
            {/* <div className="flex flex-wrap">
              <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="..."
                    src={require("assets/img/team-1-800x800.jpg").default}
                    className="shadow-lg rounded-full mx-auto max-w-120-px"
                  />
                  <div className="pt-6 text-center">
                    <h5 className="text-xl font-bold">Ryan Tompson</h5>
                    <p className="mt-1 text-sm text-blueGray-400 uppercase font-semibold">
                      Web Developer
                    </p>
                    <div className="mt-6">
                      <button
                        className="bg-lightBlue-400 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                        type="button"
                      >
                        <i className="fab fa-twitter"></i>
                      </button>
                      <button
                        className="bg-lightBlue-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                        type="button"
                      >
                        <i className="fab fa-facebook-f"></i>
                      </button>
                      <button
                        className="bg-pink-500 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                        type="button"
                      >
                        <i className="fab fa-dribbble"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="..."
                    src={require("assets/img/team-2-800x800.jpg").default}
                    className="shadow-lg rounded-full mx-auto max-w-120-px"
                  />
                  <div className="pt-6 text-center">
                    <h5 className="text-xl font-bold">Romina Hadid</h5>
                    <p className="mt-1 text-sm text-blueGray-400 uppercase font-semibold">
                      Marketing Specialist
                    </p>
                    <div className="mt-6">
                      <button
                        className="bg-red-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                        type="button"
                      >
                        <i className="fab fa-google"></i>
                      </button>
                      <button
                        className="bg-lightBlue-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                        type="button"
                      >
                        <i className="fab fa-facebook-f"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="..."
                    src={require("assets/img/team-3-800x800.jpg").default}
                    className="shadow-lg rounded-full mx-auto max-w-120-px"
                  />
                  <div className="pt-6 text-center">
                    <h5 className="text-xl font-bold">Alexa Smith</h5>
                    <p className="mt-1 text-sm text-blueGray-400 uppercase font-semibold">
                      UI/UX Designer
                    </p>
                    <div className="mt-6">
                      <button
                        className="bg-red-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                        type="button"
                      >
                        <i className="fab fa-google"></i>
                      </button>
                      <button
                        className="bg-lightBlue-400 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                        type="button"
                      >
                        <i className="fab fa-twitter"></i>
                      </button>
                      <button
                        className="bg-blueGray-700 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                        type="button"
                      >
                        <i className="fab fa-instagram"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="..."
                    src={require("assets/img/team-4-470x470.png").default}
                    className="shadow-lg rounded-full mx-auto max-w-120-px"
                  />
                  <div className="pt-6 text-center">
                    <h5 className="text-xl font-bold">Jenna Kardi</h5>
                    <p className="mt-1 text-sm text-blueGray-400 uppercase font-semibold">
                      Founder and CEO
                    </p>
                    <div className="mt-6">
                      <button
                        className="bg-pink-500 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                        type="button"
                      >
                        <i className="fab fa-dribbble"></i>
                      </button>
                      <button
                        className="bg-red-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                        type="button"
                      >
                        <i className="fab fa-google"></i>
                      </button>
                      <button
                        className="bg-lightBlue-400 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                        type="button"
                      >
                        <i className="fab fa-twitter"></i>
                      </button>
                      <button
                        className="bg-blueGray-700 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                        type="button"
                      >
                        <i className="fab fa-instagram"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </section> 

        <section className="pb-20 relative block bg-blueGray-800">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-blueGray-800 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4 lg:pt-24 lg:pb-64">
            <div className="flex flex-wrap text-center justify-center">
              <div className="w-full lg:w-6/12 px-4">
                <h2 className="text-4xl font-semibold text-white">
                  Why not try Whizzdom today?
                </h2>
                <p className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-400">
                  {/* text here  */}
                </p>
              </div>
            </div>
            <div className="flex flex-wrap mt-12 justify-center">
              <div className="w-full lg:w-3/12 px-4 text-center">
                <div className="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <i className="fas fa-medal text-xl"></i>
                </div>
                <h6 className="text-xl mt-5 font-semibold text-white">
                  Clear Learning Goals
                </h6>
                <p className="mt-2 mb-4 text-blueGray-400">
                  Easy to keep track of how your child is progressing and make sure they're on track to pass their 11plus. 
                </p>
              </div>
              <div className="w-full lg:w-3/12 px-4 text-center">
                <div className="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <i className="fas fa-poll text-xl"></i>
                </div>
                <h5 className="text-xl mt-5 font-semibold text-white">
                  Approved by Teachers
                </h5>
                <p className="mt-2 mb-4 text-blueGray-400">
                  Students can practice as much as is needed, all our content is teacher approved.
                </p>
              </div>
              <div className="w-full lg:w-3/12 px-4 text-center">
                <div className="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <i className="fas fa-lightbulb text-xl"></i>
                </div>
                <h5 className="text-xl mt-5 font-semibold text-white">
                  Intuitive learning
                </h5>
                <p className="mt-2 mb-4 text-blueGray-400">
                  Hassle free and no confusion. Everything you need in one place. 
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="relative block py-24 lg:pt-0 bg-blueGray-800">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap justify-center lg:-mt-64 -mt-48">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200">
                  <div className="flex-auto p-5 lg:p-10">
                    <h4 className="text-2xl font-semibold py-4">
                      Get <b>free</b> 11 plus resources and updates! 
                    </h4>
                    {/* <p className="leading-relaxed mt-1 mb-4 text-blueGray-500">
                      Complete this form and we will get back to you in 24
                      hours.
                    </p> */}
                    {/* <div className="relative w-full mb-3 mt-8">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="full-name"
                      >
                        Full Name
                      </label>
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Full Name"
                      />
                    </div> */}
                      <form
                        onSubmit={formik.handleSubmit}
                      >
                    <div className="relative w-full mb-3">
                      {/* <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="email"
                      >
                        Email
                      </label> */}
                  
                        {/* <input
                          type="email"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder="Email"
                          onChange={(e) => formik.handleChange(e)}
                          // onSubmit={QuestionsAndUserDataService.addToSubscriptionList(event.target.value)}
                        /> */}
                         <TextField
                            fullWidth
                            id="email"
                            name="email"
                            label="Email"
                            onChange={(e) => {
                              formik.handleChange(e);
                            }}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                          />
                    </div>

                    {/* <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="message"
                      >
                        Message
                      </label>
                      <textarea
                        rows="4"
                        cols="80"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                        placeholder="Type a message..."
                      />
                    </div> */}
                    <div className="text-center mt-6">
                      <button
                        className="pricing-button-pro"
                        type="submit"
                      >
                        Get your resources now
                      </button>
                      <div className="pt-6">
                      { hasSubmittedAnEmail ? 
                    <b style={{textAlign: "center"}}>We've added you to our list! </b> : ""
                    } 
                    </div>
                    </div>
                    </form>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
