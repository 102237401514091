import React from 'react';
import { Grid } from '@material-ui/core';

import QuestionsAndUserDataService from 'services/QuestionsAndUserDataService';


export default function UpgradeToStandardModal({questionsLeft, WhizzdomLogo}) {

  let textSegments = []

  function getTextForUpgradeModal() {
    if (questionsLeft == -2) {
      textSegments.push("Upgrade for unlimited")
      textSegments.push(<br/>)
      textSegments.push("English Comprehensions!")
    }

    if (questionsLeft == -1 || questionsLeft == 0) {
      textSegments.push("You've reached your")
      textSegments.push(<br/>)
      textSegments.push("daily limit!")
    }

    if (questionsLeft > 0) {
      textSegments.push("Only " + questionsLeft + " questions left")
      textSegments.push(<br/>)
      textSegments.push("as part of your daily limit!")
    }
    return textSegments
  }

  return (
    <>
    <Grid container style={{display: "flex", textAlign: "center", border: "20px solid #f6ae2d"}}>
      <Grid style={{display: "flex", justifyContent: "center", alignItems: "center", maxWidth: "25%", margin: "0 auto"}} item xs={12}>
        <img style={{marginTop: "40px", marginBottom: "10px"}} src={WhizzdomLogo} alt="Whizzdom Logo" />
      </Grid>
      <Grid item xs={12} className="table-container" style={{marginBlock: "5px", fontWeight: "bold", fontSize: "18px"}}>
        Premium Feature Discovered
      </Grid>
      <Grid item xs={12} className="table-container" style={{marginTop: "5px", marginBottom: "20px", fontWeight: "bold", fontSize: "30px"}}>
        {getTextForUpgradeModal()}
      </Grid>
      <Grid item xs={12} className="table-container">
      <button
          className={"upgrade-to-standard-user-button"}
          type="button"
          onClick={() => QuestionsAndUserDataService.createCheckoutSessionFromDashboard("1")}
        >
        Upgrade
        </button>
      </Grid>
      <Grid item xs={12} style={{marginTop: "20px", marginBottom: "40px", textAlign: "center", fontSize: "25px"}}>
        Get <b>unlimited 11 Plus GL questions</b><br/>for<br/> <b>just £8.99 per month!</b>
      </Grid>
    </Grid>
  </>
  );
}
