import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch
} from "react-router-dom";


import AdminMainDashboard from './AdminMainDashboard';
import AdminSchoolView from './AdminSchoolView';
import CreateHeadTeacherForm from './CreateHeadTeacherForm.js';


export default function AdminView() {

  let { path } = useRouteMatch();

  // Need cards for creating admins, schools/head teacher, teacher 
  // Need area to view specific data for schools - and then teachers or students 
  //Initial dashboard show - total number of schools, total number of school students, total number of individual users


  return (
    
    <div>
      <Router>     
        <Switch>
          <Route exact path={`${path}`}>
            <AdminMainDashboard/>
          </Route>
          <Route path= "/app/admin-whizz/schools-view">
            <AdminSchoolView/>
          </Route>
          <Route path ="/app/admin-whizz/create-head-teacher">
            <CreateHeadTeacherForm/>
          </Route>
        </Switch>
      </Router>     
    </div>
  );
}
