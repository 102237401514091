import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@mui/material/Typography';

// import QuestionsAndUserDataService from '../../../services/QuestionsAndUserDataService';

// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 300,
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   p: 4,
//   flexDirection: 'row'
// };


export default function EditStudentDetails({specificStudentData, passValuesUpAndCloseModal}) {

  // const [studentDetails, setStudentDetails] = useState([])
  // const [firstNameInput, setFirstNameInput] = useState("")
  // const [lastNameInput, setLastNameInput] = useState("")
  const [parentEmailInput, setParentEmailInput] = useState("")


  const validationSchemaModal = yup.object({
    firstName: yup
      .string('Enter your name')
      .required('First name is required'),
    lastName: yup
      .string('Enter your name')
      .required('Last name is required'),
    email: yup
      .string('Enter your email')
      .email('Enter a valid email'),
  });
  

  const formikModal = useFormik({
    initialValues: {
      firstName: specificStudentData.firstName,
      lastName: specificStudentData.lastName,
      email: specificStudentData.parentEmail,
    },
    validationSchema: validationSchemaModal,
    onSubmit: (values) => {
      // send relevant data up
      console.log("Edited parent email")
      console.log(values.parentEmail)
      setParentEmailInput(values.parentEmail)

      console.log("initial specific student data")
      console.log("Firstname: " + specificStudentData.firstName)
      console.log("Lastname: " + specificStudentData.lastName)
      console.log("Parent Email: " + specificStudentData.parentEmail)

      passValuesUpAndCloseModal(values.firstName, values.lastName, values.email)

      // QuestionsAndUserDataService.createTeacher(formikModal.values.className, formikModal.values.yearGroup, formikModal.values.email, title, isHeadTeacher, schoolId).then(history.push((isHeadTeacher ? "/app/admin-whizz" : "/teacher/headteacher")))
      // updateUserDetails(formikModal.values.className, formikModal.values.yearGroup, formikModal.values.email).then(onStepChange(2))

    },
  });

  return (
    
      <form onSubmit={formikModal.handleSubmit}>
        <Typography variant="h6" textAlign="center" component="h2">
          Student Details 
        </Typography>
      
        <div>
        <TextField
            fullWidth
            id="firstName"
            name="firstName"
            label="First Name"
            value={formikModal.values.firstName}
            onChange={formikModal.handleChange}
            error={formikModal.touched.firstName && Boolean(formikModal.errors.firstName)}
            helperText={formikModal.touched.firstName && formikModal.errors.firstName}
          />

        </div>

        <div className="relative w-full mb-3">

          <TextField
              fullWidth
              id="lastName"
              name="lastName"
              label="Last Name"
              value={formikModal.values.lastName}
              onChange={formikModal.handleChange}
              error={formikModal.touched.lastName && Boolean(formikModal.errors.lastName)}
              helperText={formikModal.touched.lastName && formikModal.errors.lastName}
            />

          </div>
        <TextField 
          id="email"
          name="email"
          label="Parent Email"
          value={formikModal.values.email}
          onChange={formikModal.handleChange} 
          error={formikModal.touched.email && Boolean(formikModal.errors.email)}
          helperText={formikModal.touched.email && formikModal.errors.email} 
          placeholder='Parent Email' 
        />

      <Button
        colour="primary"
        type="submit"
      >
        Update Student Details
      </Button>
    </form>
  );
}
