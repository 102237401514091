import React, { useState } from 'react';
import {useParams} from "react-router-dom";
import { Helmet } from 'react-helmet';

import RegistrationStepOne from './RegistrationStepOne';
import RegistrationStepTwo from './RegistrationStepTwo';
import RegistrationStepThree from './RegistrationStepThree';

export default function RegistrationPage() {

  // const [userDetails, setUserDetails] = []
  const[registrationStepNumber, setRegistrationStepNumber] = useState(1)
  
  const[parentFirstName, setParentFirstName] = useState()
  const[parentLastName, setParentLastName] = useState()
  const[parentEmail, setParentEmail] = useState()

  const { productId } = useParams();
  const { mockExamSessionId } = useParams();


  async function updateParentUserDetails(parentFirstName, parentLastName, parentEmail) {
    setParentFirstName(parentFirstName)
    setParentLastName(parentLastName)
    setParentEmail(parentEmail)

    console.log("In update parent user details function")
    console.log(parentFirstName)
    console.log(parentLastName)
    console.log(parentEmail)

    // userDetails["parentFirstName"] = parentFirstName
    // userDetails["parentLastName"] = parentLastName
    // userDetails["parentEmail"] = parentEmail
    console.log("Updated user details")
  }


  function setRegistrationStepNumberWrapper(stepNumber) {
    setRegistrationStepNumber(stepNumber)
  }

  // Need to combine data from both steps and add them together
  // Can choose to have submit in the final step and just pass the relevant data down 
  // Pass data up from step 1 - store in state or props
  // pass down to step 2 - on submit - combine all data and make request 

  function renderCorrectStep(registrationStepNumber) {
    console.log("In render correct step of registration")
    console.log("Registration Step number: " + registrationStepNumber)
    console.log(parentFirstName)
    console.log(parentLastName)
    console.log(parentEmail)
    
    switch(registrationStepNumber) {
      case 1:
        return <RegistrationStepOne 
                  onStepChange={setRegistrationStepNumberWrapper} 
                  updateUserDetails={updateParentUserDetails}
                  productId={productId}
                />
      case 2:
        return <RegistrationStepTwo 
                  onStepChange={setRegistrationStepNumberWrapper} 
                  parentFirstName={parentFirstName}
                  parentLastName={parentLastName}
                  parentEmail={parentEmail}
                  productId={productId}
                  mockExamSessionId={mockExamSessionId}
                />
      case 3:
        return <RegistrationStepThree onStepChange={setRegistrationStepNumberWrapper} ></RegistrationStepThree>;
      default:
        return <RegistrationStepOne onStepChange={setRegistrationStepNumberWrapper}></RegistrationStepOne>;
    }
  }

  return (
    <>
      {/* <RegistrationStepOne></RegistrationStepOne> */}
      <Helmet>
        <title>Whizzdom 11 Plus Sign Up</title>
        <meta name="Whizzdom 11 Plus Sign Up" content="Sign up for a Whizzdom account for 11 plus support and learning" />
      </Helmet>
      <div
            className="absolute top-0 w-full h-full bg-blueGray-800 bg-no-repeat bg-full"
            style={{
              backgroundImage:
                "url(" + require("assets/WebPImages/Registration&Login/register_bg_2.webp").default + ")",
                srcSet: `${require("assets/img/register_bg_2.png").default}`

            }}
          ></div>
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
              <div className="rounded-t mb-0 px-6 py-6">
              </div>
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <div className="text-blueGray-400 text-center mb-3 font-bold">
                  <small> Sign up below </small>
                </div>
                  {renderCorrectStep(registrationStepNumber)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
