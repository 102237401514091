import React from 'react';
import Title from '../Title';
import TopicTile from './TopicTile';
import Grid from '@material-ui/core/Grid';

// Takes in all the tiles and sorts them into - Weaknesses, Needs Work, Strengths, Not Tried Yet
export default function TopicTileList({topics, onTileClick, key, tileColourCodes}) {

  // Need all the tiles, need the scores for each topic 
  console.log("Topics in topic tile")
  console.log(topics)
  console.log("Tile colour codes")
  console.log(tileColourCodes)


  function renderTopicTilesLists() {
    var sectionTitles = ["Weaknesses", "Needs Work", "Strengths", "Not Tried Yet"]
    var sectionColours = ["red", "#d48b11", "green", "#0284C7"]
    var Weaknesses = getRelevantTopicTitles(sectionTitles[0])
    var NeedsWork = getRelevantTopicTitles(sectionTitles[1])
    var Strengths = getRelevantTopicTitles(sectionTitles[2])
    var NotTriedYet = getRelevantTopicTitles(sectionTitles[3])
    var allTopics = [Weaknesses, NeedsWork, Strengths, NotTriedYet]
    var sections = []

    for (var i = 0; i < sectionTitles.length; i ++) {
      if (allTopics[i].length == 0) {
        continue
      }
      sections.push(
          <Grid item xs={12}>
            <Title style={{textAlign: "center"}}>{sectionTitles[i]}</Title>
            </Grid>
        )

        for (var j = 0; j < allTopics[i].length; j++) {
          sections.push(
            <Grid item>
                <TopicTile
                  key={j}
                  title={allTopics[i][j]}
                  onTileClick={onTileClick}
                  tileColor={sectionColours[i]}
                />
              </Grid>
          ) 
      }
    }
    return sections
  }

  function getRelevantTopicTitles(group) {
    var titlesInGroup = []
    for (var j = 0; j < topics.length; j++) {
      if (getTileGroup(topics[j]) == group) {
        titlesInGroup.push(topics[j])
      }
    }
    return titlesInGroup
}

  function getTileGroup(topic) {
    var scoresForTopic = getValuesByPartialKey(topic)
    const average = calculateAverage(scoresForTopic);
    console.log("Average score for " + topic + ": " + average)
    if (average >= 0.8) {
      return "Strengths"
    }
    if (average >= 0.5) {
      return "Needs Work"
      // return "#d48b11"
    } 
    if (average >= 0) {
      return "Weaknesses"
    }
    return "Not Tried Yet"
  }

  function getValuesByPartialKey(partialKey) {
    const values = [];
    for (const key in tileColourCodes) {
      if (Object.hasOwnProperty.call(tileColourCodes, key) && key.includes(partialKey)) {
        values.push(tileColourCodes[key]);
      }
    }
    return values;
  }

  function calculateAverage(array) {
    if (array.length === 0) {return -1;} // Prevent division by zero
    const sum = array.reduce((acc, val) => acc + val, 0); // Sum all elements
    return sum / array.length; // Divide sum by number of elements
  }
  
  
    return (
      <Grid container spacing={2}>
        {renderTopicTilesLists()}
      </Grid>
    )
}  