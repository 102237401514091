import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import QuestionsAndUserDataService from '../../services/QuestionsAndUserDataService'

export default function RegistrationStepThree({onStepChange}) {


  const validationSchema = yup.object({
    studentName: yup
      .string('Enter the student\'s name')
      .required('Name is required'),
    dateOfBirth: yup
      .string('Student\'s date of birth'),
    password: yup
      .string('Enter your password')
      .min(8, 'Password should be of minimum 8 characters length')
      .required('Password is required'),
  
  });



    const formik = useFormik({
      initialValues: {
        currentSchool: '',
        targetSchool: '',
        customCheckLogin: false,
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {
        console.log("Getting info - page 3 of 3")
        let signUpData = ["This is data"]
        QuestionsAndUserDataService.createCheckoutSession(signUpData) 
      },
    });



  return (
    <>
      <form
        onSubmit={formik.handleSubmit}
        >
          
        <div className="relative w-full mb-3">

        <TextField
            fullWidth
            id="currentSchool"
            name="currentSchool"
            label="Current School"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </div>

        <div className="relative w-full mb-3">
          <TextField
            fullWidth
            id="targetSchool"
            name="targetSchool"
            label="Target School"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </div>

        <div className="text-center mt-6">
          <Button
            colour="primary"
            className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
            type="submit"
          >
            Next
          </Button>
        </div>
      </form>
              
    </>
  );
};
