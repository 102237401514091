import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';

import QuestionsAndUserDataService from '../../../services/QuestionsAndUserDataService'

import { Typography } from '@material-ui/core';



export default function AddGrammarQuestion() {

  const [topic, setTopic] = React.useState("");   
  
  const handleChange = (event) => {
    setTopic(event.target.value);
  };


  const validationSchema = yup.object({
    questionText: yup
      .string('Enter the question')
      .required('Field is required'),
    correctSentenceVersion: yup
      .string('Enter the correct sentence'),
    multipleChoiceOptions: yup
      .string('Enter the multiple choice options'),
    correctAnswer: yup
      .string("Which is the correct answer option")
      .required('Field is required'),
  });


  // const WithMaterialUI = () => {
    const formik = useFormik({
      initialValues: {
        questionText: '',
        correctSentenceVersion: '',
        multipleChoiceOptions: '',
        correctAnswer: '',
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {

        // console.log("Submitting mock exam form")
        // console.log("formik values")
        // console.log(formik.values.questionText)
        // console.log(formik.values.multipleChoiceOptions)

        // formData.append("questionText", formik.values.questionText)
        // formData.append("correctSentenceVersion", formik.values.multipleChoiceOptions)

        // formData.append("multipleChoiceOptions", formik.values.multipleChoiceOptions)
        // formData.append("correctAnswer", formik.values.correctAnswer)
        // formData.append("questionImage", questionImage)
        // formData.append("answerChoices", answerChoicesImage)
        if (topic === '') {
          alert("The topic is null")
        }

        QuestionsAndUserDataService.addEnglishQuestionToDB(formik.values.questionText,
           formik.values.correctSentenceVersion, 
           formik.values.multipleChoiceOptions,
           formik.values.correctAnswer,
           topic).then((result) => {
          console.log(result)
        })

      },
    });

  return (
    
    <div>
     <form
        onSubmit={formik.handleSubmit}
        >
         <label>Question Details</label>

         <Box style={{textAlign:"center"}}>

          <InputLabel className='mb-4' id="topic">Topic</InputLabel>
            <Select
              labelId="topic"
              id="topic"
              value={topic}
              autoWidth
              label="Topic"
              onChange={handleChange}
              className="px-2"
            >
                <MenuItem value="JUMBLED_SENTENCES">Jumbled Sentences</MenuItem>
                <br></br>
                <MenuItem value="SPELLING">Spelling</MenuItem>
                <br></br>
                <MenuItem value="PUNCTUATION">Punctuation</MenuItem>
                <br></br>
                <MenuItem value="MISSING_WORDS">Missing Words</MenuItem>
                <br></br>
                <MenuItem value="SYNONYMS">Synonyms</MenuItem>
                <br></br>
                <MenuItem value="ANTONYMS">Antonyms</MenuItem>
                <br></br>
                <MenuItem value="SHUFFLED_SENTENCES">Shuffled Sentences</MenuItem>
            </Select>

          </Box>


        <div className="relative w-full mb-3">
        <TextField
            fullWidth
            id="questionText"
            name="questionText"
            label="Question Text"
            value={formik.values.questionText}
            onChange={formik.handleChange}
            error={formik.touched.questionText && Boolean(formik.errors.questionText)}
            helperText={formik.touched.questionText && formik.errors.questionText}
          />
        </div>

        <div className="relative w-full mb-3">
        <TextField
            fullWidth
            id="correctSentenceVersion"
            name="correctSentenceVersion"
            label="Correct Sentence Version"
            value={formik.values.correctSentenceVersion}
            onChange={formik.handleChange}
            error={formik.touched.correctSentenceVersion && Boolean(formik.errors.correctSentenceVersion)}
            helperText={formik.touched.correctSentenceVersion && formik.errors.correctSentenceVersion}
          />
        </div>

        <div className="relative w-full mb-3">
        <TextField
            fullWidth
            id="multipleChoiceOptions"
            name="multipleChoiceOptions"
            label="Answer Options (|| separate options)"
            value={formik.values.multipleChoiceOptions}
            onChange={formik.handleChange}
            error={formik.touched.multipleChoiceOptions && Boolean(formik.errors.multipleChoiceOptions)}
            helperText={formik.touched.multipleChoiceOptions && formik.errors.multipleChoiceOptions}
          />
        </div>
        
        <div className="relative w-full mb-3">
        <TextField
            fullWidth
            id="correctAnswer"
            name="correctAnswer"
            label="Correct Answer"
            value={formik.values.correctAnswer}
            onChange={formik.handleChange}
            error={formik.touched.correctAnswer && Boolean(formik.errors.correctAnswer)}
            helperText={formik.touched.correctAnswer && formik.errors.correctAnswer}
          />
        </div>

        <div className="text-center mt-6">
          <Button
            colour="primary"
            className="bg-blueGray-800 active:bg-blueGray-600 uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
            type="submit"
          >
            Add Question
          </Button>
        </div>
      </form>

      <br></br>
      - For Spelling and Punctuation questions we need to split the sentence for options so can insert || which can be later read when displaying
    </div>
  );
}
