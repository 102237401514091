import React from 'react';
import Title from '../Title';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({

    basicQuestionPadding: {
      // paddingBottom: '20px',
      paddingLeft: '20px',
    },

    root: {
      minWidth: 100,
      maxWidth: 100,
      height: 100,
      textAlign: 'center',
      padding: 10
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {

    },
  });

  // Component is currently updating state of parent component on every single key stroke input
export default function QuestionTile({question, questionNumber, answer, updateUserAnswer, currentUserAnswer}) {
    const classes = useStyles();

    function handeleChange(e) {
      updateUserAnswer(questionNumber, e.currentTarget.value)
    }
   
    function getTextBox() {
      return (
        <TextField
          key={answer}
          defaultValue={typeof currentUserAnswer === 'undefined' ? "" : currentUserAnswer}               
          className={classes.basicQuestionPadding}
          onChange={handeleChange}
        /> 
      )
}
    

    return (
        <React.Fragment>
            {/* <ResponsiveContainer> */}
            <Grid container spacing={3} >
              <Grid item xs={12}>
                <Title>{questionNumber + 1}) {question}</Title>
              </Grid>
                <Title>Answer: </Title>
                {getTextBox()}
            </Grid>
            {/* </ResponsiveContainer> */}
        </React.Fragment>
    )
}  