import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import QuestionReviewTile from './QuestionReviewTile';
import Link from '@material-ui/core/Link';

import * as CONSTANTS from "../../StringConstants"
import { checkAnswer } from './AnswerPage';



export default function WorksheetReview({questionsData, questionToGoTo, backToDashboard, backToAnalyticsBoard}) {

  
    const [currentQuestion, setCurrentQuestion] = useState(questionToGoTo)
    const [navBarMultiplier, setNavBarMultiplier] = useState(0)
    var isComprehension = questionsData.comprehensionDescription === "" || questionsData.comprehensionDescription !== undefined
    const [questions, setQuestions] = useState(isComprehension ?  questionsData.worksheetQuestions : questionsData)
    const [areQuestionsSorted, setAreQuestionsSorted] = useState(false)
    console.log("Questions data")
    console.log(questionsData)
    console.log(questionsData.comprehensionDescription)
    console.log(isComprehension)
    console.log("Set questions")
    console.log(questions)

    useEffect(() => {
        console.log("Firing use effect")
        if (questionsData.comprehensionDescription !== "" && !areQuestionsSorted) {
            const comprehensionQuestions = questions
            const sortedComprehensionQuestions = comprehensionQuestions.sort((a,b) => a.questionNumber > b.questionNumber ? 1 : -1)
            setQuestions(sortedComprehensionQuestions)
        }
        setAreQuestionsSorted(true)
        console.log("Questions info")
        console.log(questions)
      });


    function showNumberNavigationBelt() {
        
        let navigationSteps = []
        navigationSteps.push(
        <Grid item xs={1} justifyContent='center'>
            <Link underline="always" onClick={() => setNavBarMultiplier(navBarMultiplier-1)}>{navBarMultiplier > 0 ? "Prev" : null}</Link>
        </Grid>)

        let maxNumberOfQuestionsToShowInNav = 10
        let bottomLimit = maxNumberOfQuestionsToShowInNav*navBarMultiplier
        let topLimit = maxNumberOfQuestionsToShowInNav + (maxNumberOfQuestionsToShowInNav*navBarMultiplier)

        for (let i = bottomLimit + 1; i <= Math.min(topLimit, questions.length); i++) {
            navigationSteps.push(
                <Grid item xs={1} justifyContent='center'>
                    <div className={(i-1) === currentQuestion ? "worksheet-review-navbar-selected" : "worksheet-review-navbar"}>
                        <Link color="inherit" underline={ (i-1) === currentQuestion ? "always" : "hover"} onClick={() => setCurrentQuestion(i-1)}>
                                Q{i}
                        </Link>
                    </div>
                </Grid>
            )
        }
        navigationSteps.push(
            <Grid item xs={1} justifyContent='center'>
                <Link underline="always" onClick={() => setNavBarMultiplier(navBarMultiplier+1)}>{ topLimit >= questions.length ? "" : "Next"}</Link>
            </Grid>)
        return navigationSteps
    }

    function showRelevantQuestionReviewTile(questionToGoTo) {
        let indexOfquestionToGoTo = questionToGoTo 
        console.log("Questions info in worksheet review")
        console.log(questions)

        let answerOptions = questions[indexOfquestionToGoTo].multipleChoiceOptions
        let multipleChoiceAnswerOptions = questions[indexOfquestionToGoTo].multipleChoiceOptions
        let userAnswer = questions[indexOfquestionToGoTo].userAnswer;
        let correctAnswer = questions[indexOfquestionToGoTo].correctAnswer;
        console.log("User answer: " + userAnswer)
        console.log("Correct answer: " + correctAnswer)
        let isCorrect = checkAnswer(userAnswer, correctAnswer)
        // Will already be in a list form if user has just completed the sheet. 
        if (typeof answerOptions === 'string' || answerOptions instanceof String) {
            multipleChoiceAnswerOptions = questions[indexOfquestionToGoTo].multipleChoiceOptions.split("||")
        } 
        // questionToGoTo refers to the actual number not the index value of the question, hence need to subtract one
        return (
                <QuestionReviewTile 
                    question={questions[indexOfquestionToGoTo].question}
                    questionNumber ={questions[indexOfquestionToGoTo].questionNumber}
                    answer={questions[indexOfquestionToGoTo].correctAnswer}
                    userAnswer={questions[indexOfquestionToGoTo].userAnswer}
                    isCorrect={isCorrect}
                    solution={questions[indexOfquestionToGoTo].solution}
                    multipleChoiceOptions={multipleChoiceAnswerOptions}
                    currentQuestion={currentQuestion}
                />
        )
    }

    function returnRelevantQuestionInstructions() {
        // console.log("Grammar Question Type")
        // console.log(grammarQuestionType)
        if (!questions[currentQuestion]) {
          console.log("ERROR - Questions are null = no questions in worksheet")
          console.log("Logging questions below")
          console.log(questions)
          console.log(questionsData)
          backToDashboard()
          return
        }
        var grammarQuestionType = questions[currentQuestion].questionTopic
        console.log("Current grammar question type")
        console.log(grammarQuestionType)

        if (grammarQuestionType == "Jumbled Sentences") {
          return CONSTANTS.JumbledSentenceQuestionInstructions
        } else if (grammarQuestionType == "Spelling") {
          return CONSTANTS.SpellingQuestionInstructions
        } else if (grammarQuestionType == "Punctuation") {
          return CONSTANTS.PunctuationQuestionInstructions
        } else if (grammarQuestionType == "Missing Words") {
          return CONSTANTS.MissingWordsQuestionInstructions
        } else if (grammarQuestionType == "Synonyms") {
          return CONSTANTS.SynonymsQuestionInstructions
        } else if (grammarQuestionType == "Antonyms") {
          return CONSTANTS.AntonymsQuestionInstructions
        } else if (grammarQuestionType == "Shuffled Sentences") {
          return CONSTANTS.ShuffledSentencesQuestionInstructions
        } else if (grammarQuestionType == "English Comprehension") {
          return <b>{questionsData.comprehensionDescription + " " + CONSTANTS.ComprehensionQuestionInstructions}</b>
        }
        return ""
      }

    function formattedComprehensionPassage() {

        // Works with formatting from when comprehensions are inputted - || means new line and ||p|| is new paragraph
  
        var comprehensionParagraphs = questionsData.comprehensionText.split("||")
        var formattedParaphraphs = [] 
        formattedParaphraphs.push(<br></br>)
        for (var i = 0; i < comprehensionParagraphs.length; i++) {
          if (comprehensionParagraphs[i] == "p") {
            formattedParaphraphs.push(<br></br>)
          } else {
            formattedParaphraphs.push(<div>{comprehensionParagraphs[i]}</div>)
          }
        }
        formattedParaphraphs.push(<br></br>)
        return formattedParaphraphs
      }

    return (
        <div>
            <div style={{ textAlign: 'center'}}>
                <Grid container direction="row" justifyContent='center' spacing={12}>
                    <Grid justify="space-between" item xs={3}>
                        <div>
                            <button className="fromWorksheetReview" onClick={backToDashboard}> 
                                Go to Dashboard
                            </button>
                        </div>
                    </Grid>
                    <Grid item xs={6}> 
                        <h1><b>Worksheet Review</b></h1>
                    </Grid>
                    <Grid justify="space-between" item xs={3}>
                        <div>
                            <button className="fromWorksheetReview" onClick={backToAnalyticsBoard}> 
                                Go to Review Area
                            </button>
                        </div>
                    </Grid>
                </Grid>
            </div>

            {returnRelevantQuestionInstructions() == "" ? <></> : <><br/>
           {returnRelevantQuestionInstructions()}<br/></> }


            {isComprehension ? formattedComprehensionPassage() : "" }
            <Grid container className='p-4' direction="row" justifyContent='center' spacing={12} >
                {showNumberNavigationBelt()}
            </Grid>
            <Grid item xs={12}>
                {showRelevantQuestionReviewTile(currentQuestion)}
            </Grid>
        </div>


    )
}
