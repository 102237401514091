import React from 'react';
import Title from '../Title';
import { Grid } from '@material-ui/core';
import AnswerTile from './AnswerTile';
import QuestionsAndUserDataService from '../../services/QuestionsAndUserDataService'
// src/services/QuestionsAndUserDataService.js

export function checkAnswer(userAnswer, correctAnswer) {
  // need to separate out units and answer from actual answer and user answer
  // will be in format of NUMBER - WORD (and then potentially NUMBER - WORD if hrs and mins)
  //     - Will also have to handle differences between 3hrs 4 mins and 3hrs and 4 mins - can trim white space, check for words
  // need to check units in users answer 
  // may also need to check if they give answer as a word (although this is low priority and can become a ticket for later)
  // for answers with one unit:
  // - check number and check unit - when checking unit - check list of different forms - ie m or metres is allowed, handle plurals as well.

  // Solution
  // Tokenise into individual strings with each token being the number or the unit/word after
  // Store tokens in an array - do for both, user and actual answer 
  if (!userAnswer || !correctAnswer) {
    console.log("Couldn't get either user or correct answer")
    return false
  }

  const userAnswerTokens = convertToTokens(userAnswer)
  const correctAnswerTokens = convertToTokens(correctAnswer)
  // console.log("User tokens are:")
  // for (let i = 0; i < userAnswerTokens.length; i++) {
  //   console.log(userAnswerTokens[i])
  // }
  // console.log("Correct answer tokens are:")
  // for (let i = 0; i < correctAnswerTokens.length; i++) {
  //   console.log(correctAnswerTokens[i])
  // }

  // iterate through array and compare
  if (userAnswerTokens.length !== correctAnswerTokens.length) {
    // console.log("Different number of tokens")
    return false
  }

  // When checking tokens here - will also add something for the units .
  for (let i = 0; i < correctAnswerTokens.length; i++) {
    // console.log("Comparing: " + userAnswerTokens[i] + " and " + correctAnswerTokens[i])
    if (!compareTokens(userAnswerTokens[i], correctAnswerTokens[i])) {
      // console.log("Not the same")
      return false
    }
    // console.log(userAnswerTokens[i] + " and " + correctAnswerTokens[i] + " are the same")
  }
  return true; 
}

    // PRE: Backend sends the correct plural form - ie 1 day or 2 days
    function compareTokens(token1, token2) {
      // console.log("In compare tokens")
      // console.log("User answer: " + token1)
      // console.log("Correct answer: " + token2)

      if (!token1 && !token2) {
        // console.log("Both tokens are null")
        return true
      }
      if (!token1 || !token2) {
        // console.log("One of the tokens is null")
        return false
      }
      if (isInteger(token1.charAt(0))) {
        // console.log("Both tokens are numbers")
        // console.log(typeof(token1))
        // console.log(typeof(token2))
        // console.log(token1 === token2)
        return (token1 === token2)
      }
      // at this point we know the tokens aren't null and are words - can add check for 's' plural stuff etc after
      if (token1 === token2) {
        // console.log("Both tokens are non numbers and are the same")
        return true
      }
      // need to have forms of the units - ie km can be kilometres or kms  or kilometres (make a note to fix the plural thing)
      // checking if any of the words or units end with a full stop 
      // token1 = removeFullStopAtEnd(token1)
      // token2 = removeFullStopAtEnd(token2)

      return compareUnits(token1, token2)
    }


// Considered guaranteeing form of a unit but we mix it up to vary the questions
// Brute force - create lists of each one and go through finding which list answer is in and then accept the userAnswer if in that list
// Going to have a list of all units - check if the word is firstly a unit (will avoid an n^2 lookup )

    // different units 
    // mm - millimeters or milimeter
    // cm - centimetres or centimeter 
    // km - kilometre or kilometres
    // m - metres
    // inches - no other form
    // feet - ft
    // miles - no other form
    // yards - no other form 
    // seconds - s, secs
    // mins - minutes
    // min - minute
    // hrs - hours
    // hr - hour
    // days - no other form
    // weeks - wks
    // months - mons
    // years - yrs
    // year - yr
    // ml - milliliters
    // litres - L
    // grams - g
    // kg - kilograms
    // miles per hour - mph, mile per hour 
    // metres per second - m/s
    // kmph - kilometres per hour, kilometre per hour or km/h

    // decilitre or hexalitre - look at including in questions later
    

    // check currencies later - might get sorted as words
    // check time later 

    function compareUnits(correctUnit, userProvidedUnit) {
      // Tokens have all the white space removed so no space with units - e.g kilometres per hour = kilometresperhour
      let completeUnitList = [
        "mm", "millimeters", "millimeter", "cm", "centimetres", "centimeter", "km", "kilometre", "kilometres", "m", "metres", "metre",
        "inch", "inches", "feet", "ft", "miles", "mile", "yards", "yard", "seconds", "second", "secs", "sec", "s", "minutes", "minute",
        "mins", "min", "hrs", "hr", "hours", "hour", "days", "day", "weeks", "week", "wks", "wk", "months", "month", "years", "year",
        "yr", "yrs", "ml", "milliliters", "milliliter", "litres", "litre", "L", "grams", "gram", "g", "kg", "kilograms", "kilogram",
        "milesperhour", "mileperhour", "mph", "metrepersecond", "metrespersecond", "m/s", "kmph", "kilometresperhour", "kilometreperhour", 
        "km/h"
      ];

      if (!completeUnitList.includes(correctUnit)) {
        return false
      }
      
      let allUnitGroups = [
        ["mm", "millimeters", "millimeter"],
        ["cm", "centimetres", "centimeter"],
        ["km", "kilometre", "kilometres"],
        ["m", "metres", "metre"],
        ["inch", "inches"],
        ["feet", "ft"],
        ["miles", "mile"],
        ["yards", "yard"],
        ["seconds", "second", "secs", "sec", "s"],
        ["minutes", "minute", "mins", "min"],
        ["hrs", "hr", "hours", "hour"],
        ["days", "day"],
        ["weeks", "week", "wks", "wk"],
        ["months", "month"],
        ["years", "year", "yr", "yrs"],
        ["ml", "millilitres", "millilitre"],
        ["litres", "litre", "L"],
        ["grams", "gram", "g"],
        ["kg", "kilograms", "kilogram"],
        ["milesperhour", "mileperhour", "mph"],
        ["metrepersecond", "metrespersecond", "m/s"],
        ["kmph", "kilometresperhour", "kilometreperhour", "km/h"]
      ]

      // If both units are in the same list, they're equivalent.
      for (let i = 0; i < allUnitGroups.length; i++) {
        if (allUnitGroups[i].includes(correctUnit)) {
          return allUnitGroups[i].includes(userProvidedUnit)
        }
      }
      return false
    }
    

    function convertToTokens(answer) {
      var tokenArray = []
      if (!answer) {
        return tokenArray
      }
      var hadNums = isInteger(answer[0])

      // example 3hrs and 4 mins or 2 days 3hrs 
      // Want to exclude "and" and white spaces
      // Might just be words or might just be numbers (ie write number as a word) - so will check what the first one is then iter

      var token = "";
      // remove white space and any fullstops at the end
      let trimmed_ans = answer.replace(/\s+/g, '')

      if (trimmed_ans.charAt(trimmed_ans.length-1) === ".") {
        // console.log("Removing trailing dots - before: " + trimmed_ans)
        trimmed_ans = trimmed_ans.slice(0,-1);
        // console.log("Removing trailing dots - after: " + trimmed_ans)

      }

      var trimmed_ans_array = trimmed_ans.split("")
      // console.log("Trimmed answer:  " + trimmed_ans_array)

      // toggle between integer and string
      var isCurrCharAnInteger = isInteger(trimmed_ans_array[0])
      token = trimmed_ans_array[0]
      // console.log("Starting char for token: " + token)

      for (let i = 1; i < trimmed_ans_array.length; i++) {
        let currentChar = trimmed_ans_array[i]
        // console.log("Looping in tokeniser with " + currentChar)
        hadNums = hadNums || isInteger(currentChar)

        // remove commas for formats such as 4,563,435 - maintain consistency with 4563435
        // console.log("checking for ','")
        // console.log(currentChar === ',')
        if (currentChar === ',') {
          // console.log("Skipping a ','")
          continue
        }

        // if the same as isInteger, add on to token
        if (isInteger(currentChar) === isCurrCharAnInteger) {
          // console.log("Curennt token before appending: " + token)
          token += currentChar
          // console.log("Token is now: " + token)
        } else {
        // else if different, add token to token array and switch isInteger, set token to ""
        // Also checking if we've had any numbers so we can remove occurrences of and in tokens 
        // if no numbers then its a word question and we need to keep the and
          if (token.includes("and") && hadNums) {
            // console.log("Getting rid of and from: " + token)
            token = token.replace("and", "")
            // console.log("New token is: " + token)
            // dont want to add an empty token as this would cause a mismatch later
            if (token === "") {
              continue
            }
          } 
          
          // convert any numbers like 2.0 to 2 - the decimal point will split - so we just check if token is 0 and last token is a '.'
          // then we dont add the 0 and remove the .
          if (token === '0' && tokenArray.length > 1) {
            if (tokenArray[tokenArray.length - 1] === '.') {
              // console.log("Found a .0 and removing")
              tokenArray.pop()
              isCurrCharAnInteger = !isCurrCharAnInteger
              token = currentChar
              continue
            }
          }
          // console.log("Token to be added: " + token)
          tokenArray.push(token)
          isCurrCharAnInteger = !isCurrCharAnInteger
          token = currentChar
        }
      }

      // console.log("Final token is: " + token + " and token array length is: " + tokenArray.length)
      if (tokenArray.length > 1 ) {
        if (tokenArray[tokenArray.length - 1] === '.' && token === '0') {
          tokenArray.pop()
          return tokenArray
        }
      }
      tokenArray.push(token)
      return tokenArray
    }
    
    function isInteger(c) {
      return c >= '0' && c <= '9'
    }
// const useStyles = makeStyles({
//     root: {
//       minWidth: 100,
//       maxWidth: 100,
//       height: 100,
//       textAlign: 'center',
//       padding: 10
//     },
//     bullet: {
//       display: 'inline-block',
//       margin: '0 2px',
//       transform: 'scale(0.8)',
//     },
//     title: {
//       fontSize: 14,
//     },
//     pos: {

//     },
//   });

  // Will be passed two lists, one with user answers and the other with the correct answers as well as aditional data for storing topics information. 
export default function AnswerPage({userAnswers, correctAnswers, parentTile, subTopicIfItExists, topicsList, topicsQuantitiesList, worksheetQuestions, switchToReviewMode, worksheetSolutions, currentSubject, multipleChoiceAnswerOptions, worksheetQuestionsId, extraWorksheetData}) {

    let isCorrectCompleteList = []

    function switchStateToRenderReviewMode(questionNumberSelected) {
      console.log("Is correct list from render review mode: ")
      console.log(isCorrectCompleteList)
      // setRenderToReviewMode(true)
      let questions = []
      for (let i = 0; i < worksheetQuestions.length; i ++) {
        console.log("Answer: " + correctAnswers[i])
        console.log("Multiple Choice Answer Options: ")
        console.log(multipleChoiceAnswerOptions)
        if (multipleChoiceAnswerOptions === null || typeof multipleChoiceAnswerOptions === "undefined") {
          questions[i] = createDataHelperMaths(worksheetQuestions[i], (i+1), correctAnswers[i], userAnswers[i], isCorrectCompleteList[i], worksheetSolutions[i])
        } else {
          questions[i] = createDataHelperEnglish(worksheetQuestions[i], (i+1), correctAnswers[i], userAnswers[i], isCorrectCompleteList[i], worksheetSolutions[i], multipleChoiceAnswerOptions[i])
        }
      }
      console.log("Final results")
      console.log(questions)
      console.log(questions[0].question)
              // questionNumberSelected refers to the actual number not the index value of the question, hence need to subtract one
      let indexOfquestionToGoTo = questionNumberSelected -  1 
      switchToReviewMode(questions, indexOfquestionToGoTo)
    }

    function createDataHelperEnglish(question, questionNumber, correctAnswer, userAnswer, isCorrect, solution, multipleChoiceOptions) {
      return { question, questionNumber, correctAnswer, userAnswer, isCorrect, solution, multipleChoiceOptions };
    }

    function createDataHelperMaths(question, questionNumber, correctAnswer, userAnswer, isCorrect, solution) {
      return { question, questionNumber, correctAnswer, userAnswer, isCorrect, solution };
    }

    function generateAnswerTiles() {
      var tiles = [];
      for (var i = 0; i < correctAnswers.length; i++) {
        tiles.push(
          <Grid item xs={12}>
            <AnswerTile 
              questionNumber={(i+1)} 
              userAnswer={userAnswers[i]} 
              correctAnswer={correctAnswers[i]} 
              isCorrect={checkAnswer(userAnswers[i], correctAnswers[i])}
              viewInReviewMode={switchStateToRenderReviewMode}
              currentSubject={currentSubject}
            />
          </Grid>
        )}
      return tiles
    }

    function calculateScore() {
      let userScore = 0
      let blankAnswers = []
      let correctAnsersPerTopicList = []
      let isCorrectList = []


      // topicQuantitiesListEg = [1,3,2,1] 
      // Need to use this list to create the blank answers list 
      // If the list size is n then we'll do n lots of iterations - 
      // the number of iterations on the nth time will be the value of the nth index in the list 
      // - during each time we count the blanks and store it at index n in the new list
      let currentIndex = 0
      console.log("We have this many topics")
      console.log(topicsQuantitiesList)
      for (let i = 0; i < topicsQuantitiesList.length; i++) {
        let numBlanksValuesForTheTopic = 0
        let numCorrectAnswersForTheTopic = 0
        for (let j = 0; j < topicsQuantitiesList[i]; j++) {
          console.log("Looking at index: " + currentIndex)
          console.log("Checking user answer - " + userAnswers[i] + " and correct Answer: " + correctAnswers[i])
          if (userAnswers[currentIndex] === "" || userAnswers[currentIndex] == null) {
            console.log("We have a blank answer")
            numBlanksValuesForTheTopic += 1
            isCorrectList.push(false)
            currentIndex += 1
            continue
          } 
          if (checkAnswer(userAnswers[currentIndex], correctAnswers[currentIndex])) {
            console.log("Above answer is correct")
            numCorrectAnswersForTheTopic +=1
            userScore += 1;
            isCorrectList.push(true)
          } else {
            isCorrectList.push(false)
          }
          currentIndex += 1
        }
        console.log("Current number of blank values and correct answers")
        console.log(numBlanksValuesForTheTopic + " -  " + numCorrectAnswersForTheTopic)

        blankAnswers.push(numBlanksValuesForTheTopic)
        correctAnsersPerTopicList.push(numCorrectAnswersForTheTopic)
        
        console.log("Current blank answers topic list")
        console.log(blankAnswers)
        console.log("Current Correct answers topic list")
        console.log(correctAnsersPerTopicList)
      }

      console.log("Final blank answers list: ")
      console.log(blankAnswers)

      // for (let i = 0; i < correctAnswers.length; i++) {
      //   // console.log("Checking user answer - " + userAnswers[i] + " and correct Answer: " + correctAnswers[i])
      //   // if (checkAnswer(userAnswers[i], correctAnswers[i])) {
      //   //   console.log("Above answer is correct")
      //   //   userScore += 1;
      //   // }
      // }
      isCorrectCompleteList = isCorrectList
      console.log("Is correct list: ")
      console.log(isCorrectCompleteList)
      console.log("Current worksheet questions are")
      console.log(worksheetQuestions)
      console.log("Calling api to update topic answer metrics")
      // if (currentSubject === 'English') {
      //   QuestionsAndUserDataService.updateStudentEnglishAnalytics(parentTile, subTopicIfItExists, topicsList, topicsQuantitiesList, correctAnsersPerTopicList, blankAnswers);
      // } else {
      console.log(currentSubject)
      QuestionsAndUserDataService.updateUserQuestionAnalytics(parentTile, subTopicIfItExists, topicsList, topicsQuantitiesList, correctAnsersPerTopicList, blankAnswers, worksheetQuestionsId, currentSubject, userAnswers, correctAnswers);
      // }
      
      QuestionsAndUserDataService.saveWorksheet(parentTile, subTopicIfItExists, topicsList, userScore, topicsQuantitiesList, worksheetQuestions.length, currentSubject, worksheetQuestions, correctAnswers, userAnswers, isCorrectList, multipleChoiceAnswerOptions, worksheetSolutions, extraWorksheetData)
      return userScore
    }

   

    function removeFullStopAtEnd(input) {
      if (typeof input === "string" && input.endsWith(".")) {
        return input.slice(0, -1);
      }
      return input;
    }




    return (
        <React.Fragment>
          {generateAnswerTiles()}
          <Title>Total Score: {calculateScore()} out of {correctAnswers.length}  </Title>
        </React.Fragment>
    )
}  