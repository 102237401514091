import React from 'react';
import { Typography, TextField, Checkbox } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';


import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { useFormik } from 'formik';
import * as yup from 'yup';
import * as CONSTANTS from "../../../StringConstants.js"
import TLHClassesAPICalls from 'services/TLHClassesAPICalls.js';

export default function AddTermDatesModel({handleClose}) {


  const validationSchema = yup.object({
    commencingSchoolYear: yup
      .number()
      .required('Which year does the school year start'),
    autumnTermStartDate: yup
      .date()
      .required('Autumn Term Start Date Required'),
    autumnTermHalfTermStartDate: yup
      .date()
      .required("Autumn Half Term Start Date Required"),
    autumnTermRestartDate: yup
      .date()
      .required('Autumn Term Restart Date Required'),
    autumnTermEndDate: yup
      .date()
      .required("Autumn Term End Date Required"),
    springTermStartDate: yup
      .date()
      .required('Spring Term Start Date Required'),
    springTermHalfTermStartDate: yup
      .date()
      .required("Spring Half Term Start Date Required"),
    springTermRestartDate: yup
      .date()
      .required('Spring Term Restart Date Required'),
    springTermEnd: yup
      .date()
      .required("When does the Autumn Term Restart"),
    summerTermStartDate: yup
      .date()
      .required('Autumn Term Start Date Required'),
    summerTermHalfTermStartDate: yup
      .date()
      .required("When does the Autumn Term Restart"),
    summerTermRestartDate: yup
      .date()
      .required('Autumn Term Start Date Required'),
    summerTermEnd: yup
      .date()
      .required("When does the Summer Term Restart"),
  });



  const formik = useFormik({
    initialValues: {
      commencingSchoolYear: '',

      autumnTermStartDate: '',
      autumnTermHalfTermStartDate: '',
      autumnTermRestartDate: '',
      autumnTermEndDate: '',

      springTermStartDate: '',
      springTermHalfTermStartDate: '',
      springTermRestartDate: '',
      springTermEnd: '',

      summerTermStartDate: '',
      summerTermHalfTermStartDate: '',
      summerTermRestartDate: '',
      summerTermEnd: '',
  

    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log("formik values")
      console.log(values)
      console.log(formik.values)
      console.log("Printed values")
      TLHClassesAPICalls.addTermDates(values)  
      handleClose(false)
    },
  });


  return (


    <div>
      <form
          onSubmit={formik.handleSubmit}
      >
          <Typography variant="h6" style={{marginBottom: "40px"}}>
            Create new class
          </Typography>

          <div style={{ paddingBottom: '20px', flexBasis: '100%' }}> 
              <TextField
                style={{marginTop: '-20px', width: '300px'}}
                label="Commencing School Year"
                id="commencingSchoolYear"
                name="commencingSchoolYear"
                type="number"
                value={formik.values.commencingSchoolYear}
                onChange={formik.handleChange}
                error={formik.touched.commencingSchoolYear && Boolean(formik.errors.commencingSchoolYear)}
                helperText={formik.touched.commencingSchoolYear && formik.errors.commencingSchoolYear}  
                inputProps={{
                  min: 1, // Ensures the input is a positive number
                  style: {
                    padding: '10px',
                  },
                  onInput: (e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 9)
                  }
                }}
                InputLabelProps={{
                  shrink: formik.values.commencingSchoolYear !== '' || formik.touched.commencingSchoolYear,
                  style: { 
                    backgroundColor: 'white',
                    // padding: '-2px 4px',
                    marginTop: '-8px',
                    marginLeft: '-4px',
                  }
                }}
              />
            </div>

          <Typography variant="h6" style={{marginBottom: "20px"}}>
            Autumn Term
          </Typography>

          <div className="relative w-full" style={{ paddingBottom: '20px', display: 'flex', justifyContent: 'space-between'}}>

            <div style={{ flexBasis: '20%' }}> 
              <TextField
                id="autumnTermStartDate"
                name="autumnTermStartDate"
                label="Autumn Start Date"
                type="date"
                style={{paddingRight: '20px'}}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  style: {
                    padding: '12px 14px', // Adjust the padding as needed
                    height: 'auto', // Make sure height adjusts properly
                  },
                }}
                value={formik.values.autumnTermStartDate}
                onChange={formik.handleChange}
                error={formik.touched.autumnTermStartDate && Boolean(formik.errors.autumnTermStartDate)}
                helperText={formik.touched.autumnTermStartDate && formik.errors.autumnTermStartDate}
              />
           </div>

            <div style={{ flexBasis: '20%' }}> 

            <TextField
                id="autumnTermHalfTermStartDate"
                name="autumnTermHalfTermStartDate"
                label="Autumn Half Term Start Date"
                type="date"
                style={{paddingRight: '20px'}}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  style: {
                    padding: '12px 14px', // Adjust the padding as needed
                    height: 'auto', // Make sure height adjusts properly
                  },
                }}
                value={formik.values.autumnTermHalfTermStartDate}
                onChange={formik.handleChange}
                error={formik.touched.autumnTermHalfTermStartDate && Boolean(formik.errors.autumnTermHalfTermStartDate)}
                helperText={formik.touched.autumnTermHalfTermStartDate && formik.errors.autumnTermHalfTermStartDate}
              />
            </div>

            <div style={{ flexBasis: '20%' }}> 
              <TextField
                  id="autumnTermRestartDate"
                  name="autumnTermRestartDate"
                  label="Autumn Term Restart Start Date"
                  type="date"
                  style={{paddingRight: '20px'}}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    style: {
                      padding: '12px 14px', // Adjust the padding as needed
                      height: 'auto', // Make sure height adjusts properly
                    },
                  }}
                  value={formik.values.autumnTermRestartDate}
                  onChange={formik.handleChange}
                  error={formik.touched.autumnTermRestartDate && Boolean(formik.errors.autumnTermRestartDate)}
                  helperText={formik.touched.autumnTermRestartDate && formik.errors.autumnTermRestartDate}
                />
              </div>

              <div style={{ flexBasis: '20%' }}> 
                <TextField
                    id="autumnTermEndDate"
                    name="autumnTermEndDate"
                    label="Autumn Term End Date"
                    type="date"
                    style={{paddingRight: '200px'}}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      style: {
                        padding: '12px 14px', // Adjust the padding as needed
                        height: 'auto', // Make sure height adjusts properly
                      },
                    }}
                    value={formik.values.autumnTermEndDate}
                    onChange={formik.handleChange}
                    error={formik.touched.autumnTermEndDate && Boolean(formik.errors.autumnTermEndDate)}
                    helperText={formik.touched.autumnTermEndDate && formik.errors.autumnTermEndDate}
                  />
               </div>
            </div>



          <Typography variant="h6" style={{marginBottom: "20px"}}>
            Spring Term
          </Typography>

          <div className="relative w-full" style={{ paddingBottom: '20px', display: 'flex', justifyContent: 'space-between'}}>

            <div style={{ flexBasis: '20%' }}> 
              <TextField
                id="springTermStartDate"
                name="springTermStartDate"
                label="Spring Start Date"
                type="date"
                style={{paddingRight: '20px'}}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  style: {
                    padding: '12px 14px', // Adjust the padding as needed
                    height: 'auto', // Make sure height adjusts properly
                  },
                }}
                value={formik.values.springTermStartDate}
                onChange={formik.handleChange}
                error={formik.touched.springTermStartDate && Boolean(formik.errors.springTermStartDate)}
                helperText={formik.touched.springTermStartDate && formik.errors.springTermStartDate}
              />
           </div>

            <div style={{ flexBasis: '20%' }}> 

            <TextField
                id="springTermHalfTermStartDate"
                name="springTermHalfTermStartDate"
                label="Spring Half Term Start Date"
                type="date"
                style={{paddingRight: '20px'}}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  style: {
                    padding: '12px 14px', // Adjust the padding as needed
                    height: 'auto', // Make sure height adjusts properly
                  },
                }}
                value={formik.values.springTermHalfTermStartDate}
                onChange={formik.handleChange}
                error={formik.touched.springTermHalfTermStartDate && Boolean(formik.errors.springTermHalfTermStartDate)}
                helperText={formik.touched.springTermHalfTermStartDate && formik.errors.springTermHalfTermStartDate}
              />
            </div>

            <div style={{ flexBasis: '20%' }}> 
              <TextField
                  id="springTermRestartDate"
                  name="springTermRestartDate"
                  label="Spring Term Restart Start Date"
                  type="date"
                  style={{paddingRight: '20px'}}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    style: {
                      padding: '12px 14px', // Adjust the padding as needed
                      height: 'auto', // Make sure height adjusts properly
                    },
                  }}
                  value={formik.values.springTermRestartDate}
                  onChange={formik.handleChange}
                  error={formik.touched.springTermRestartDate && Boolean(formik.errors.springTermRestartDate)}
                  helperText={formik.touched.springTermRestartDate && formik.errors.springTermRestartDate}
                />
              </div>

              <div style={{ flexBasis: '20%' }}> 
                <TextField
                    id="springTermEnd"
                    name="springTermEnd"
                    label="Spring Term End Date"
                    type="date"
                    style={{paddingRight: '200px'}}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      style: {
                        padding: '12px 14px', // Adjust the padding as needed
                        height: 'auto', // Make sure height adjusts properly
                      },
                    }}
                    value={formik.values.springTermEnd}
                    onChange={formik.handleChange}
                    error={formik.touched.springTermEnd && Boolean(formik.errors.springTermEnd)}
                    helperText={formik.touched.springTermEnd && formik.errors.springTermEnd}
                  />
               </div>
            </div>

    
          <Typography variant="h6" style={{marginBottom: "20px"}}>
            Summer Term
          </Typography>

          <div className="relative w-full" style={{ paddingBottom: '20px', display: 'flex', justifyContent: 'space-between'}}>

            <div style={{ flexBasis: '20%' }}> 
              <TextField
                id="summerTermStartDate"
                name="summerTermStartDate"
                label="Summer Start Date"
                type="date"
                style={{paddingRight: '20px'}}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  style: {
                    padding: '12px 14px', // Adjust the padding as needed
                    height: 'auto', // Make sure height adjusts properly
                  },
                }}
                value={formik.values.summerTermStartDate}
                onChange={formik.handleChange}
                error={formik.touched.summerTermStartDate && Boolean(formik.errors.summerTermStartDate)}
                helperText={formik.touched.summerTermStartDate && formik.errors.summerTermStartDate}
              />
           </div>

            <div style={{ flexBasis: '20%' }}> 

            <TextField
                id="summerTermHalfTermStartDate"
                name="summerTermHalfTermStartDate"
                label="Summer Half Term Start Date"
                type="date"
                style={{paddingRight: '20px'}}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  style: {
                    padding: '12px 14px', // Adjust the padding as needed
                    height: 'auto', // Make sure height adjusts properly
                  },
                }}
                value={formik.values.summerTermHalfTermStartDate}
                onChange={formik.handleChange}
                error={formik.touched.summerTermHalfTermStartDate && Boolean(formik.errors.summerTermHalfTermStartDate)}
                helperText={formik.touched.summerTermHalfTermStartDate && formik.errors.summerTermHalfTermStartDate}
              />
            </div>

            <div style={{ flexBasis: '20%' }}> 
              <TextField
                  id="summerTermRestartDate"
                  name="summerTermRestartDate"
                  label="Summer Term Restart Start Date"
                  type="date"
                  style={{paddingRight: '20px'}}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    style: {
                      padding: '12px 14px', // Adjust the padding as needed
                      height: 'auto', // Make sure height adjusts properly
                    },
                  }}
                  value={formik.values.summerTermRestartDate}
                  onChange={formik.handleChange}
                  error={formik.touched.summerTermRestartDate && Boolean(formik.errors.summerTermRestartDate)}
                  helperText={formik.touched.summerTermRestartDate && formik.errors.summerTermRestartDate}
                />
              </div>

              <div style={{ flexBasis: '20%' }}> 
                <TextField
                    id="summerTermEnd"
                    name="summerTermEnd"
                    label="Summer Term End Date"
                    type="date"
                    style={{paddingRight: '200px'}}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      style: {
                        padding: '12px 14px', // Adjust the padding as needed
                        height: 'auto', // Make sure height adjusts properly
                      },
                    }}
                    value={formik.values.summerTermEnd}
                    onChange={formik.handleChange}
                    error={formik.touched.summerTermEnd && Boolean(formik.errors.summerTermEnd)}
                    helperText={formik.touched.summerTermEnd && formik.errors.summerTermEnd}
                  />
               </div>
            </div>


          
          <div style={{textAlign: "center", paddingTop: "25px"}}>
            <button 
                style={{
                  padding: '10px 20px',
                  fontSize: '18px',
                  color: 'white',
                  backgroundColor: '#0056b3',
                  border: 'none',
                  borderRadius: '5px',
                  boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                  cursor: 'pointer',
                  transition: 'all 0.3s ease',
              }}
              onMouseOver={(e) => {
                  e.target.style.backgroundColor = '#004085';
                  e.target.style.boxShadow = '0 8px 16px rgba(0,0,0,0.2)';
              }}
              onMouseOut={(e) => {
                  e.target.style.backgroundColor = '#0056b3';
                  e.target.style.boxShadow = '0 4px 8px rgba(0,0,0,0.1)';
              }}
              type="submit"
            >
                Add Term Dates
            </button>
          </div>
      </form>
    </div>

  );
}
