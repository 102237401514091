import React, { useState, useEffect } from 'react';
// import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import { useHistory } from "react-router";

// import CardActions from '@mui/material/CardActions';
// import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import UpcomingMockExamsModal from 'components/UpcomingMockExamsModal';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import QuestionsAndUserDataService from 'services/QuestionsAndUserDataService';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: 850,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  // boxShadow: 24,
  // p: 4,
};
// import Avatar from '@mui/material/Avatar';
// import IconButton from '@mui/material/IconButton';
// import Typography from '@mui/material/Typography';
// import { red } from '@mui/material/colors';
// import FavoriteIcon from '@mui/icons-material/Favorite';
// import ShareIcon from '@mui/icons-material/Share';
// import MoreVertIcon from '@mui/icons-material/MoreVert';

// const ExpandMore = styled((props) => {
//   const { expand, ...other } = props;
//   return <IconButton {...other} />;
// })(({ theme, expand }) => ({
//   transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
//   marginLeft: 'auto',
//   transition: theme.transitions.create('transform', {
//     duration: theme.transitions.duration.shortest,
//   }),
// }));
// const SuccessDisplay = ({ sessionId }) => {
//   return (
//     <section>
//       <div className="product Box-root">
//         <Logo />
//         <div className="description Box-root">
//           <h3>Subscription to starter plan successful!</h3>
//         </div>
//       </div>
//       <form action="/create-portal-session" method="POST">
//         <input
//           type="hidden"
//           id="session-id"
//           name="session_id"
//           value={sessionId}
//         />
//         <button id="checkout-and-portal-button" type="submit">
//           Manage your billing information
//         </button>
//       </form>
//     </section>
//   );
// };

export default function CardPricing({title, price, features, callToActionButtonText, productId, isBestDeal}) {
  
  let history = useHistory();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [isLoadingMockExamSessionsData, setIsLoadingMockExamSessionsData] = useState(true)
  const [mockExamSessionsData, setMockExamSessionsData] = useState([])


  useEffect(() => {
    if (isLoadingMockExamSessionsData) {
        getUpcomingMockExamSessions()
    }
  });

  function getUpcomingMockExamSessions(){
    QuestionsAndUserDataService.getAllUpcomingMockExams().then(sessionsData => {
      console.log(sessionsData)
      setMockExamSessionsData(sessionsData.data)
      setIsLoadingMockExamSessionsData(false)
    })
  }

  function createCheckoutSession(lookupKey) {
    // Handle multiple keys and products later - for now just direct to sign up with the one look up 
    if (productId === "3") {
      handleOpen()
    } else {
      history.push("/auth/register/" + productId)
    }
  }

  function getProductDetails() {
    var details = [];
    for (var i = 0; i < features.length; i++) {
      details.push(
        <div className="pricing-details">
            <i className="fas fa-check"></i>
            <li >
                {features[i]}
            </li>
        </div>
      )}
      return <ul>{details}</ul>
}

  return (
    // <Card className="p-4" sx={{border: 15, borderColor: '#F6AE2D'}}
    <div>
      {isBestDeal ? 
    <Box sx={{backgroundColor: "#e49609", color: "white", fontSize: 30, fontWeight: "bold", paddingTop: "15px"}}>
      Save 40%
    </Box>
    :
    <Box sx={{backgroundColor: "#7e52a0", color: "7e52a0", fontSize: 29, fontWeight: "bold", padding: "20px"}}>
      .
    </Box>
      }

    <Card sx={{ maxWidth: 300, ...(isBestDeal && {border: 15, borderColor: '#e49609', borderRadius: 0} ) }}>
        <div className="pricing-card-header">
            <CardHeader
                // avatar={
                //   <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                //     R
                //   </Avatar>
                // }
                // action={
                //   <IconButton aria-label="settings">
                //     {/* <MoreVertIcon /> */}
                //   </IconButton>
                // }
                title={title}
                // subheader="Maths"
            />
        </div>
        <Divider variant="middle" />
      {/* <CardMedia
        component="img"
        height="194"
        image="/static/images/cards/paella.jpg"
        alt="Paella dish"
      /> */}
        <div style={{paddingBottom: productId == "3" ? "4px" : "", paddingTop: productId == "3" ? "4px" : ""}}>
        <p className="pricing">
            {price}
        </p>
        </div>
        <Divider variant="middle" />
        <p className="pricing-details-block">
            <div>
                {getProductDetails()}
            </div>
        </p>
        <Divider variant="middle" />
        
        <div className="pricing-button-container">
          <button
              className="pricing-button-pro"
              onClick={() => createCheckoutSession("lookupkey")}
          >
            {callToActionButtonText}
          </button>
        </div>

        <Modal
          open={open}
          onClose={handleClose}
          className="landing-page-upcoming-mock-exams-modal"
        >
          <Box sx={style}>
            <UpcomingMockExamsModal upcomingMockExamDates={mockExamSessionsData}/>
          </Box>
        </Modal>
    
      {/* <CardContent>
        <Typography variant="body2" color="text.secondary">
          This impressive paella is a perfect party dish and a fun meal to cook
          together with your guests. Add 1 cup of frozen peas along with the mussels,
          if you like.
        </Typography>
      </CardContent> */}
      {/* <CardActions disableSpacing>
        <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>Method:</Typography>
          <Typography paragraph>
            Heat 1/2 cup of the broth in a pot until simmering, add saffron and set
            aside for 10 minutes.
          </Typography>
          <Typography paragraph>
            Heat oil in a (14- to 16-inch) paella pan or a large, deep skillet over
            medium-high heat. Add chicken, shrimp and chorizo, and cook, stirring
            occasionally until lightly browned, 6 to 8 minutes. Transfer shrimp to a
            large plate and set aside, leaving chicken and chorizo in the pan. Add
            pimentón, bay leaves, garlic, tomatoes, onion, salt and pepper, and cook,
            stirring often until thickened and fragrant, about 10 minutes. Add
            saffron broth and remaining 4 1/2 cups chicken broth; bring to a boil.
          </Typography>
          <Typography paragraph>
            Add rice and stir very gently to distribute. Top with artichokes and
            peppers, and cook without stirring, until most of the liquid is absorbed,
            15 to 18 minutes. Reduce heat to medium-low, add reserved shrimp and
            mussels, tucking them down into the rice, and cook again without
            stirring, until mussels have opened and rice is just tender, 5 to 7
            minutes more. (Discard any mussels that don’t open.)
          </Typography>
          <Typography>
            Set aside off of the heat to let rest for 10 minutes, and then serve.
          </Typography>
        </CardContent>
      </Collapse> */}
    </Card>
    </div>
  );
}
