import React, { useState, useEffect, useRef } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@material-ui/core/TextField';
import TLHEndpoints from 'services/TLHEndpoints';

export default function ViewBookedAssesmentDetails({currentAssessmentViewInfo, isFromEnquiriesSection}) {


  const [isLoadingNotes, setIsLoadingNotes] = useState(true)
  const [newlyAddedNotes, setNewlyAddedNotes] = useState([])
  const tableContainerRef = useRef(null)

  var JUST_NOW = "Just Now"

  useEffect(() => {
    // console.log("Getting assessments for the date")
    if (isLoadingNotes) {
      console.log("Current assessment view info")
      console.log(currentAssessmentViewInfo)
      console.log(currentAssessmentViewInfo.isFromEnquiriesSection)

      var parentId;
      console.log("isFromEnquiriesSection: " + isFromEnquiriesSection)
      if (isFromEnquiriesSection) {
        console.log("This is from the parent enquiry itself, not the assessment")
        parentId = currentAssessmentViewInfo.enquiryAndAssessmentBookingId
      } else {
        console.log("Parent details")
        console.log(currentAssessmentViewInfo.parentBookingEnquiryDetails)
        parentId = currentAssessmentViewInfo.parentBookingEnquiryDetails.enquiryAndAssessmentBookingId
      }
      console.log("Parent id: " + parentId)
      getAllCommunicationNotesForSpecificStudent(parentId)
    }
    const tableContainerElement = tableContainerRef.current;
    if (tableContainerElement) {
      tableContainerElement.scrollTop = tableContainerElement.scrollHeight;
    }
  }, [newlyAddedNotes]);

  function getAllCommunicationNotesForSpecificStudent(id) {
    TLHEndpoints.getAllCommunicationNotesForSpecificStudent(id).then(result => {
      setNewlyAddedNotes(result.data)
      setIsLoadingNotes(false)
    })
  }

  const validationSchema = yup.object({
    // note: yup
    //   .string('Enter your name')
    //   .required('A date is required for the exam set'),
    // time: yup
    //   .string('Enter your time')
    //   // .time('Enter a valid time')
    //   .required('time is required'),
  });

  function formattedDate(dateString ) {
     
    if (dateString == JUST_NOW) {
      return dateString
    }

    // Parse the date string
    const date = new Date(dateString);

    // Create an options object for the DateTimeFormat function
    const options = {
        year: 'numeric', month: 'long', day: 'numeric',
        hour: '2-digit', minute: '2-digit', hour12: true
    };

    // Format the date using Intl.DateTimeFormat
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

    return formattedDate
}

const styles = {
  tableContainer: {
    width: '100%',
    maxHeight: '300px', // Adjust the height as needed
    overflowY: 'auto',
    border: '1px solid #ccc'
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  cell: {
    padding: '8px',
    textAlign: 'left',
    borderBottom: '1px solid #ddd',
  }
};




  // const WithMaterialUI = () => {
    const formik = useFormik({
      initialValues: {
        note: '',
        
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {

        console.log("formik values")
        console.log(formik.values.note)
        var enquiryAndAssessmentBookingId = -1
        if (isFromEnquiriesSection) {
          console.log("The view")
          console.log(currentAssessmentViewInfo)
          console.log(currentAssessmentViewInfo)
          enquiryAndAssessmentBookingId = currentAssessmentViewInfo.enquiryAndAssessmentBookingId
        } else {
          enquiryAndAssessmentBookingId = currentAssessmentViewInfo.parentBookingEnquiryDetails.enquiryAndAssessmentBookingId  
        }
        
        console.log("enquiryAndAssessmentBookingId: " + enquiryAndAssessmentBookingId)
        TLHEndpoints.addNoteToAssessmentBooking(enquiryAndAssessmentBookingId, formik.values.note)
        var newCommunicationNote = {communicationNote: formik.values.note, noteCreationTimestamp: JUST_NOW}
        const newNotes = [...newlyAddedNotes, newCommunicationNote]
        setNewlyAddedNotes(newNotes)
        formik.setFieldValue('note', '');

      },
    });

  return (
    
    <div>
     <form
        onSubmit={formik.handleSubmit}
        >
  
         <div>
           {/* {currentAssessmentViewInfo.parentBookingEnquiryDetails ? currentAssessmentViewInfo.parentBookingEnquiryDetails.communicationNotes : "No notes"} */}
           {newlyAddedNotes.length == 0 ?
        <div className="table-container" style={{textAlign: "center", fontWeight: "bold", fontSize: "25px"}}>
          No customer notes
        </div>
        :
        <div>
        <div style={{textAlign: "center", fontWeight: "bolder", fontSize: "x-large", paddingBottom: "20px"}}>
        <label> Communication Notes </label>
       </div>
      <div ref={tableContainerRef} style={styles.tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Notes</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {newlyAddedNotes.map((note) => (
              <TableRow key={note.communicationNoteId}>
                <TableCell>{formattedDate(note.noteCreationTimestamp)}</TableCell>
                <TableCell>{note.communicationNote}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      </div>}

      <div className="relative w-full mb-3">
        <TextField
            fullWidth
            id="note"
            name="note"
            label="Type here to add Note..."
            value={formik.values.note}
            onChange={formik.handleChange}
          />
      </div>
      <div style={{textAlign: "center"}}>
          <button
                style={{
                    padding: '10px 20px',
                    fontSize: '18px',
                    color: 'white',
                    backgroundColor: '#0056b3',
                    border: 'none',
                    borderRadius: '5px',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                    cursor: 'pointer',
                    transition: 'all 0.3s ease',
                }}
                onMouseOver={(e) => {
                    e.target.style.backgroundColor = '#004085';
                    e.target.style.boxShadow = '0 8px 16px rgba(0,0,0,0.2)';
                }}
                onMouseOut={(e) => {
                    e.target.style.backgroundColor = '#0056b3';
                    e.target.style.boxShadow = '0 4px 8px rgba(0,0,0,0.1)';
                }}
                type="submit"
            >
                Submit
            </button>     
          </div>
         </div>
      </form>
    </div>
  );
}
