import React, { useState, useEffect } from 'react';

import FooterAdmin from "components/Footers/FooterAdmin.js";
import whiteLogo from "./../../assets/logoVariations/Primarylogomark_purple_black_white-03.png"

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar'; //Only used in mock exams (and auth navbar )
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Grid from '@mui/material/Grid';

import SideDrawer from './SideDrawer';
import MockExamAccountSettingsButton from './MockExamAccountSettingsButton';
import QuestionsAndUserDataService from 'services/QuestionsAndUserDataService';
import ReviewPreviousExamSessionsTable from './ReviewPreviousExamSessionsTable';

const drawerWidth = 260;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));


export default function MockExamUserPastExams() {
  const [open, setOpen] = React.useState(true);
  const [isLoadingPastExams, setIsLoadingPastExams] = useState(true)
  const [hasPastExamSets, setHasPastExamSets] = useState(false)
  const [pastSessionsDataWithExamScores, setPastSessionsDataWithExamScores] = useState()


  let dummyData = [
    createDummyData("2022-05-21", 0.6), // 1
    createDummyData("2022-05-21", 0.4), // 1
    createDummyData("2022-05-23", 0.7), // 2
    createDummyData("2022-05-23", 0.83), // 3
    createDummyData("2022-05-24", 0.73), // 4
    createDummyData("2022-05-25", 0.91), // 5
  ]

  useEffect(() => {

    if (isLoadingPastExams) {
      QuestionsAndUserDataService.getMockExamSessionsCompletedByUser().then((result) => {
        console.log("Just got mock exam sets completed by user")
        console.log(result.data)
        setPastSessionsDataWithExamScores(result.data)

        console.log(result.data !== null)
        setHasPastExamSets(result.data !== null)

      })

      setIsLoadingPastExams(false)
    }
  });

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };


 // The function and variable below are currently unused but can be called if dummy data is needed on the front end
 function createDummyData(time, amount) {
  return {time, amount}
}


  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} style={{backgroundColor: "purple"}}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          {/* <Typography variant="h6" noWrap component="div">
            Mini variant drawer
          </Typography> */}
            <img alt="11plus-Whizzdom-logo" style={{height: '60px'}} src={whiteLogo}></img>
            <div className="w-full mx-autp flex justify-end md:flex-nowrap flex-wrap md:px-10 px-4" >
              <div>
                <MockExamAccountSettingsButton/>
              </div>
            </div>
        </Toolbar>
      </AppBar>

      <SideDrawer drawerStatus={open} handleDrawerClose={handleDrawerClose}/>

      {hasPastExamSets ? 
      
      <Grid container spacing={3} className='p-8 mx-8 m-8'>
        <Grid item xs={12}>
          <Typography variant="h4" sx={{paddingTop: "70px"}}>Your Past Exam Sessions</Typography>
            <ReviewPreviousExamSessionsTable data={pastSessionsDataWithExamScores.mockExamSessionsCompletedByUser}/>
          <Typography variant="h7" sx={{paddingTop: "5px"}}>
              Mock exams will be available to review after the report has been sent for the mock exam session. Subscribers will have their 
              exams available for review for the lifetime of their subscription whilst one off mock exam users will have access until September 30th. 
          </Typography>
        </Grid>
      </Grid>
       :

      <Box sx={{width: "100%", maxWidth: 500}}>
        <Typography variant="h4" style={{textAlign: "center", justifyContent: "center", display: "flex", alignItems: "center", height: "100%", position: "fixed", left: "35%", width: "30%"}}>
          Complete your first set of mock exams to review them here.
        </Typography>
      </Box>
      }

      
      {/* <Grid container spacing={3} className='p-4 mt-16'>
        <Grid item xs={12}>
          <Paper
            sx={{
                p: 6,
                // display: 'flex',
                flexDirection: 'column',
                height: 340,
            }}
          >
            <Chart className="p-8" chartData={chartData}/>
          </Paper>
        </Grid> */}

       {/* Past Mock Exams */}
        {/* <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}> */}
                {/* Add check and mention no past worksheets if required */}
                {/* { isWorksheetDataLoading ?
                    <div>Loading data ... </div> : 
                    ((isNoContent) ? 
                        <div>No worksheets completed yet </div> : */}
                        {/* <PastWorksheets 
                            data={worksheetData}
                            switchToReviewMode={false}
                        /> */}
                        {/* )
                } */}
            {/* </Paper>
        </Grid>
      </Grid> */}


      <Box component="main" sx={{ flexGrow: 1, left: "50%", position: "fixed", bottom: 0}}>
        {/* <DrawerHeader /> */}
        {/* <Typography paragraph>
            
        </Typography> */}
        <FooterAdmin/>
      </Box>
    </Box>
  );
}