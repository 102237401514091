import React from 'react';
// import { useTheme } from '@material-ui/core/styles';
// import { ResponsiveContainer } from 'recharts';
import Title from '../Title';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import { white } from 'tailwindcss/colors';


export default function TopicTile({title, onTileClick, key, tileColor}) {
    
    const useStyles = makeStyles({
      root: {
        minWidth: 200,
        maxWidth: 200,
        height: 100,
        textAlign: 'center',
        padding: 10,
        color: tileColor,
        border: '1pt solid',
        '&:hover': {
          // background: "#0284C7",
          background: tileColor,
          color: white,
      },
      },
    });

    const classes = useStyles();
  
    return (
        <React.Fragment>
            {/* <ResponsiveContainer> */}
            <ButtonBase onClick={() => onTileClick('sub-topics', title)} >
              <Card variant="outlined" className={classes.root}>
                    <Title>{title}</Title>
              </Card>
            </ButtonBase>
            {/* </ResponsiveContainer> */}
        </React.Fragment>
    )
}  