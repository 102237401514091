import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// components

import Navbar from "components/Navbars/AuthNavbar.js";
import FooterSmall from "components/Footers/FooterSmall.js";

// views

import IndividualLogin from "views/auth/IndividualLogin.js";
import LoginDirector from "views/auth/LoginDirector";
import SchoolLoginDirector from "views/auth/SchoolLoginDirector";
import SchoolTeacherLogin from "views/auth/SchoolTeacherLogin";
import RegistrationPage from "views/auth/RegistrationPage";
import ForgotPassword from "views/auth/ForgotPassword";
import ChangePassword from "views/auth/ChangePassword";
import PaymentSuccess from "views/stripe-integration/paymentSuccess";
import mockExamPaymentSuccess from "views/stripe-integration/mockExamPaymentSuccess"
import PaymentFailure from "views/stripe-integration/paymentFailure";

// Blogs

import Blog from "views/Blogs/Blog";
import CEMBlog from "views/Blogs/CEMBlog";
import GLBlog from "views/Blogs/GLBlog";
import MockExamsBlog from "views/Blogs/MockExamsBlog";
import ParentsElevenPlusPlaybook from "views/Blogs/ParentsTipsfor11PlusSuccess";
import ChoosingTheCorrectSchool from "views/Blogs/ChoosingTheCorrectSchool";

// import TermsAndConditions from "documentation/TermsAndConditions";
// String constants 
import * as CONSTANTS from "../StringConstants"

export default function Auth() {
  return (
    <>
      <main>
        <Navbar transparent /> 
        <section className="relative w-full h-full min-h-screen py-20">
         
          <Switch>
            <Route path="/auth/login" exact component={LoginDirector} />
            <Route path="/auth/school-login" exact component={SchoolLoginDirector} />

            <Route path={CONSTANTS.MockExamLogin} exact component={() =>
              <IndividualLogin loginType="mock-exam-user" />
              } />

            <Route path="/auth/individual-login" exact component={() =>
              <IndividualLogin loginType="individual" />
              } />
            <Route path="/auth/teacher-login" exact component={() =>
              <SchoolTeacherLogin loginType="teacher" />
              }/>

              {/* Landing page roots */}

            <Route exact path={["/auth/register/:productId", "/auth/register/:productId/:mockExamSessionId"]} component={RegistrationPage} />
            <Route path="/auth/forgot-password" exact component={ForgotPassword} />
            <Route path="/auth/change-password" exact component={ChangePassword} />
            <Route path="/auth/success" exact component={PaymentSuccess}/>
            <Route path="/auth/mock-exam-payment-success" exact component={mockExamPaymentSuccess}/>
            <Route path="/auth/failure" exact component={PaymentFailure}/>
           
           {/* Blogs */}
           
            <Route path={CONSTANTS.MainBlog} exact component={Blog}/>
            <Route path={CONSTANTS.CEMBlog} exact component={CEMBlog}/>
            <Route path={CONSTANTS.GLBlog} exact component={GLBlog}/>
            <Route path={CONSTANTS.TheImportanceOf11PlusMockExams} exact component={MockExamsBlog}/>
            <Route path={CONSTANTS.ParentsElevenPlusPlaybook} exact component={ParentsElevenPlusPlaybook}/>
            <Route path={CONSTANTS.ChoosingYourPerfectElevenPlusSchool} exact component={ChoosingTheCorrectSchool}/>
          
            {/* <Route path="/auth/TermsAndConditions" exact component={TermsAndConditions}/> */}
            <Redirect from="/auth" to={CONSTANTS.IndividualLogin} />
          </Switch>
          <FooterSmall absolute />
        </section>
      </main>
    </>
  );
}
