import React from 'react';
import { useState, useEffect } from 'react';
import {useParams} from "react-router-dom";
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios'

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import QuestionsAndUserDataService from 'services/QuestionsAndUserDataService';

export default function RegistrationStepOne({onStepChange, updateUserDetails, productId}) {

  // const [privacyPolicySelection, setPrivacyPolicySelection] = useState(false)
  const [ip, setIP] = useState('');
  const { fbclid } = useParams()

   //creating function to load ip address from the API
   const getData = async () => {
    const res = await axios.get('https://geolocation-db.com/json/')
    console.log(res.data);
    var ip = res.data.IPv6
    if (!ip || ip === 'undefined' || ip === null) {
      ip = res.data.IPv4
    }
    setIP(ip)
  }
  
  useEffect( () => {
    //passing getData method to the lifecycle method
    getData()

  }, [])


  const validationSchema = yup.object({
    firstName: yup
      .string('Enter your name')
      .required('Field is required'),
    lastName: yup
      .string('Enter your name')
      .required('Field is required'),
    email: yup
      .string('Enter your email')
      .email('Enter a valid email')
      .required('Email is required'),
  });


    function sendPartialDetails(firstName, surname, email) {
      console.log("Sending partial details")
      QuestionsAndUserDataService.sendEmailForIncompleteUserSignUp(firstName, surname, email, productId)
      // QuestionsAndUserDataService.addEmailToPartialUserSignUpsSubscribersGroup(firstName, surname, email)
      QuestionsAndUserDataService.sendWelcomeEmailToNewSubscriber(email, false, ["epZqyp"], firstName, surname)

    }

  // const WithMaterialUI = () => {
    const formik = useFormik({
      initialValues: {
        firstName: '',
        lastName: '',
        email: '',
        customCheckLogin: false,
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {

        const currentPage = "https://whizzdom.co.uk/auth/register"
        console.log("Submitting registration info - page 1 of 3")
        console.log("formik values")
        console.log(formik.values.firstName)
        console.log(formik.values.lastName)
        console.log(formik.values.email)
        QuestionsAndUserDataService.leadConversionAPIUpdate(formik.values.email, formik.values.firstName, formik.values.lastName, ip, fbclid, currentPage).then((response) => {
          console.log("Response: ")
          console.log(response);
        })
        sendPartialDetails(formik.values.firstName, formik.values.lastName, formik.values.email)
        updateUserDetails(formik.values.firstName, formik.values.lastName, formik.values.email).then(onStepChange(2))

      },
    });
  // }


  return (
  
      <form
        onSubmit={formik.handleSubmit}
        >
         <label>Parent's details</label>
        <div className="relative w-full mb-3">

        <TextField
            className="data-hj-allow"
            fullWidth
            id="firstName"
            name="firstName"
            label="First Name"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
          />

        </div>

        <div className="relative w-full mb-3">

          <TextField
              className="data-hj-allow"
              fullWidth
              id="lastName"
              name="lastName"
              label="Last Name"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
            />

          </div>


        <div className="relative w-full mb-3">
        <TextField
          className="data-hj-allow"
          fullWidth
          id="email"
          name="email"
          label="Email*"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        </div>



        <div className="text-center mt-6">
          {/* <Button
            colour="primary"
            className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
            type="submit"
          >
            Next
          </Button> */}
            <button
              className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
              type="submit"
            >
              Next
            </button>
        </div>
      </form>
             
  );
};
