import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import QuestionsAndUserDataService from '../../services/QuestionsAndUserDataService'
import { Checkbox } from '@mui/material';
import { FormControlLabel } from '@mui/material';
import { useState, useEffect } from 'react';
import axios from 'axios'
import {useParams} from "react-router-dom";


export default function RegistrationStepTwo({onStepChange, parentFirstName, parentLastName, parentEmail, productId, mockExamSessionId}) {

  const [ip, setIP] = useState('');

  const getData = async () => {
    const res = await axios.get('https://geolocation-db.com/json/')
    console.log(res.data);
    var ip = res.data.IPv6
    if (!ip || ip === 'undefined' || ip === null) {
      ip = res.data.IPv4
    }
    setIP(ip)
  }

  useEffect( () => {
    //passing getData method to the lifecycle method
    getData()

  }, [])
  
  // console.log("User details")

  // console.log(parentFirstName)
  // console.log(parentLastName)
  // console.log(parentEmail)

  const validationSchema = yup.object({
    studentName: yup
      .string('Enter the student\'s name')
      .required('Name is required'),
    dateOfBirth: yup
      .date(),
    password: yup
      .string('Enter your password')
      .min(8, 'Password should be of minimum 8 characters length')
      .required('Password is required'),
    confirmPassword: yup
      .string()
      .test('passwords-match', 'Passwords must match', function(value){
        return this.parent.password === value
      }),
    acceptTerms: yup
      .bool()
      .test('acceptTerms', 'Please agree to the Terms and Conditions', function(value){
         return this.parent.acceptTerms === true
      })
      .required('Please agree to the Terms and Conditions'),

  });


  // const WithMaterialUI = () => {
    const formik = useFormik({
      initialValues: {
        studentName: '',
        dateOfBirth: '',
        // day: '',
        // month: '',
        // year: '',
        password: '',
        confirmPassword: '',
        acceptTerms: false,
        customCheckLogin: false,
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {
        console.log("Getting info - page 2 of 3")

        console.log("Sign up info as follows:")

        let signUpData = []
        signUpData["parentFirstName"] = parentFirstName
        signUpData["parentLastName"] = parentLastName
        signUpData["parentEmail"] = parentEmail
        signUpData["studentName"] = formik.values.studentName
        signUpData["dateOfBirth"] = formik.values.dateOfBirth
        signUpData["password"] = formik.values.password
        signUpData["acceptTerms"] = formik.values.acceptTerms

        console.log(signUpData["parentFirstName"]);
        console.log(signUpData["parentLastName"]);
        console.log(signUpData["parentEmail"]);
        console.log(signUpData["studentName"]);
        console.log(signUpData["dateOfBirth"]);
        console.log(signUpData["password"]);
        console.log(signUpData["acceptTerms"]);

        QuestionsAndUserDataService.createCheckoutSession(signUpData, ip, productId, mockExamSessionId) 
      },
    });
  // }


  return (
    <>
      <form
        onSubmit={formik.handleSubmit}
        >
        <label>Student's details</label>
        <div className="relative w-full mb-3">

        <TextField
            className="data-hj-allow"
            fullWidth
            id="studentName"
            name="studentName"
            label="Student's Name*"
            value={formik.values.studentName}
            onChange={formik.handleChange}
            error={formik.touched.studentName && Boolean(formik.errors.studentName)}
            helperText={formik.touched.studentName && formik.errors.studentName}
          />
        </div>

        {/* <div className="relative w-full mb-3">
          <TextField
            fullWidth
            id="dateOfBirth"
            name="dateOfBirth"
            label="Date of Birth!!"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            value={formik.values.dateOfBirth}
            onChange={formik.handleChange}
            error={formik.touched.dateOfBirth && Boolean(formik.errors.dateOfBirth)}
            helperText={formik.touched.dateOfBirth && formik.errors.dateOfBirth}
          />
        </div> */}

        <div className="relative w-full mb-3">
          <TextField
            className="data-hj-allow"
            fullWidth
            id="password"
            name="password"
            label="Password*"
            type="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
          </div>

          <div className="relative w-full mb-3">
            <TextField
              className="data-hj-allow"
              fullWidth
              id="confirmPassword"
              name="confirmPassword"
              label="Confirm Password*"
              type="password"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
              helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
            />
          </div>

          <div className="relative w-full mb-3">
            <FormControlLabel
              id="acceptTerms"
              control={<Checkbox checked={formik.values.acceptTerms} />}
              name="acceptTerms"
              label=""
              onChange={formik.handleChange}
              
            />
            I agree to the 
                <a 
                  href="https://www.11plusmock.co.uk/Whizzdom/PrivacyDocuments/WhizzdomTermsAndConditions.html"
                  target="_blank"
                > <u>
                  terms and conditions
                  </u>
                </a>
                  <div className="form-field-error" style={{color: 'red'}}>{formik.touched.acceptTerms && formik.errors.acceptTerms}</div> 

          </div>
          <div className="relative w-full mb-3">
            {/* <label className="checkbox">
              <input 
                name='acceptTerms'
                type="checkbox"
                checked={formik.values.acceptTerms}
                onChange={formik.handleChange}
                error={formik.touched.acceptTerms && Boolean(formik.errors.acceptTerms)}
                helperText={formik.touched.acceptTerms && formik.errors.acceptTerms}
              />
                  I agree to the{' '}
              <a href="https://google.com">terms and conditions</a>
             </label> */}
          </div>

        <div className="sign-up-buttons-group">
          {/* <Button
            // className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
            className="sign-up-buttons"
            onClick={() => onStepChange(1)}
          >
            Prev 
          </Button> */}
           <button
            className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            style={{paddingInline: "31px"}}
            onClick={() => onStepChange(1)}
          >
            Back
          </button>
        {/* </div>
        <div className=""> */}
          {/* <Button
            className="sign-up-buttons"
            type="submit"
          >
            Submit
          </Button> */}
           <button
            className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            style={{paddingInline: "24px"}}
            type="submit"
          >
            Submit
          </button>
        </div>
      </form>
              
    </>
  );
};
