import React, { useState, useEffect } from 'react';
import "../../../assets/styles/index.css";
import { Link } from "react-router-dom";
import {useParams} from "react-router-dom";

// Material UI imports

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import QuestionsAndUserDataService from 'services/QuestionsAndUserDataService';
import Title from "../../AnalyticsComponents/Title.js"
import { Button } from '@material-ui/core';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
// import AddSectionToMockExam from './AddSectionToMockExam';
import AddQuestionToMockExamSectionForm from './AddQuestionToMockExamSectionForm';

import EditQuestionInMockExamSectionForm from './EditQuestionInMockExamSectionForm';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function ViewQuestionsInMockExamSection() {

  const [isLoadingMockExamQuestions, setIsLoadingMockExamQuestions] = useState(true)
  const [mockExamQuestions, setMockExamQuestions] = useState([])
  const { mockExamSectionId } = useParams();
  const [questionImageBeingViewed, setQuestionImageBeingViewed] = useState()
  const [currentQuestionIdInView, setCurrentQuestionIdInView] = useState()
  const [isQuestionImage, setIsQuestionImage] = useState()
  const [imageToAdd, setImageToAdd] = useState()
  const [showImageSubmitButton, setShowImageSubmitButton] = useState(false)

  const formData = new FormData()


  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openAddQuestionModal, setOpenAddQuestionModal] = React.useState(false);
  const handleAddQuestionModalOpen = () => setOpenAddQuestionModal(true);
  const handleAddQuestionModalClose = () => setOpenAddQuestionModal(false);

  const [openEditQuestionModal, setOpenEditQuestionModal] = React.useState(false);
  const handleEditQuestionModalOpen = () => setOpenEditQuestionModal(true);
  const handleEditQuestionModalClose = () => setOpenEditQuestionModal(false);

  useEffect(() => {
    if (isLoadingMockExamQuestions) {
      console.log("Getting mock exam questions")
      getMockExamQuestions()
    }
  });



  function getMockExamQuestions() {
    console.log("Getting mock exam questions for section - " + mockExamSectionId)
    QuestionsAndUserDataService.getMockExamQuestionsForSections(mockExamSectionId).then((section) => {
      console.log("questions response")
      console.log(section.data.mockExamQuestions)

      section.data.mockExamQuestions.sort((a,b) => a.mockExamQuestionNumber - b.mockExamQuestionNumber)
      setMockExamQuestions(section.data.mockExamQuestions)
      setIsLoadingMockExamQuestions(false)
    })

  }


  // getting Question is true if we want the question image otherwise false for the answer image
  function getImage(questionId, gettingQuestion) {
    console.log("Getting image for question with id: " + questionId)
    setCurrentQuestionIdInView(questionId)
    setIsQuestionImage(gettingQuestion)
    QuestionsAndUserDataService.getMockExamQuestionImage(questionId, gettingQuestion).then((result) =>{
      console.log(result)
      setQuestionImageBeingViewed("data:image/png;base64," + result.data)
      handleOpen()
    })
  }

  function deleteImage() {
    QuestionsAndUserDataService.deleteMockExamQuestionImage(currentQuestionIdInView, isQuestionImage).then((result) => {
      console.log("result")
      // Deleted image on the backend - need to remove reference on the frontend 
      for(let i = 0; i < mockExamQuestions.length; i ++) {
        const mockExamQuestionsCopy = mockExamQuestions;
        if (mockExamQuestionsCopy[i].mockExamQuestionId === currentQuestionIdInView) {
          if (isQuestionImage) {
            mockExamQuestionsCopy[i].mockExamQuestionImage = null;
          } else {
            mockExamQuestionsCopy[i].imageMultipleChoiceOptions = null;
          }
        }
      }
      handleClose()
    })
  }

  function editQuestionInSection(mockExamQuestionId) {
    handleEditQuestionModalOpen()
    setCurrentQuestionIdInView(mockExamQuestionId)
  }

  function addImage(questionId, addingQuestion, image) {
    // formData.append("questionId", questionId)
    setCurrentQuestionIdInView(questionId)
    // formData.append("addingQuestion", addingQuestion)
    setIsQuestionImage(addingQuestion)
    // formData.append("image", image)
    setImageToAdd(image)
    for (var key of formData.entries()) {
      console.log(key[0] + ', ' + key[1]);
   }  
    setShowImageSubmitButton(true)
  }

  function isEntryEmail() {
    const admin = JSON.parse(localStorage.getItem('user'));
    let email = admin.email;
    console.log(email)
    return email.toLowerCase() === "entry@whizzdom.co.uk"
  }


  return (
    <React.Fragment>   
      {isEntryEmail ? <></> : <Link to="/app/admin-whizz">Back to Admin Dashboard</Link> }


      <Title>Questions in Mock Exam Section ID: {mockExamSectionId}</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            {/* Clicking schools should take us to a school info page */}
            <TableCell>Question Number</TableCell> 
            <TableCell>Question Text</TableCell>
            <TableCell>View Question Image</TableCell>
            <TableCell>View Answer Image</TableCell>
            <TableCell>Edit Question</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {mockExamQuestions.map((mockExamQuestions) => (
            <TableRow key={mockExamQuestions.mockExamQuestionId}>
              {/* <TableCell><Link onClick={showPapersForMockExam(mockExamPaper.mockExamSetId)}><u>{mockExamPaper.examSetName}</u></Link></TableCell> */}
              {/* <TableCell><button onClick={() => showSectionsForMockExam(mockExamPaper.mockExamId)}><u>{mockExamPaper.examName}</u></button></TableCell> */}
              {/* <TableCell><button onClick={() => showSectionsForMockExam(mockExamPaper.mockExamId)}><u>{mockExamPaper.examName}</u></button></TableCell> */}

              <TableCell>{mockExamQuestions.mockExamQuestionNumber}</TableCell>
              <TableCell>{mockExamQuestions.mockExamQuestionText}</TableCell>
              {/* <TableCell>{mockExamQuestions.mockExamQuestions.length}</TableCell> */}
              <TableCell>
                {mockExamQuestions.mockExamQuestionImage !== null ? 
                  <button onClick={() => getImage(mockExamQuestions.mockExamQuestionId, true)}>View</button>
                  : 
                  <div className="relative w-full mb-3">
                    <input id="file" name="file" type="file" 
                        onChange={(event) => {
                          addImage(mockExamQuestions.mockExamQuestionId, true, event.target.files[0])
                          console.log("Add")
                        }} 
                        className="form-control" />
                  </div>
                  }</TableCell>
              <TableCell>
                {mockExamQuestions.imageMultipleChoiceOptions !== null ? 
                  <button onClick={() => getImage(mockExamQuestions.mockExamQuestionId, false)}>View</button>
                  : 
                  <div className="relative w-full mb-3">
                    <input id="file" name="file" type="file" 
                        onChange={(event) => {
                          addImage(mockExamQuestions.mockExamQuestionId, false, event.target.files[0])
                          console.log("Add")
                        }} 
                        className="form-control" />
                  </div>
                  }</TableCell>
              <TableCell><button onClick={() => editQuestionInSection(mockExamQuestions.mockExamQuestionId)}>Edit</button></TableCell>
              <TableCell>{showImageSubmitButton ?<button onClick={() => QuestionsAndUserDataService.addImageToMockExamQuestion(currentQuestionIdInView, isQuestionImage, imageToAdd).then(setShowImageSubmitButton(false))}>Submit Image</button> : ""}</TableCell>

            </TableRow>
            
          ))}
         
        </TableBody>
      </Table>

      <br></br>

      <Button onClick={handleAddQuestionModalOpen}>Add Question</Button>


      <Modal
        open={openAddQuestionModal}
        onClose={handleAddQuestionModalClose}
      >
        <Box sx={style}>
            <AddQuestionToMockExamSectionForm mockExamSectionId={mockExamSectionId}/>
        </Box>
      </Modal>

      <Modal
        open={openEditQuestionModal}
        onClose={handleEditQuestionModalClose}
      >
        <Box sx={style}>
            <EditQuestionInMockExamSectionForm mockExamQuestionId={currentQuestionIdInView}/>
        </Box>
      </Modal>

      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box sx={style} style={{textAlign:"center"}}>
          {/* <AddSectionToMockExam mockExamId={mockExamId}/> */}
          <img alt="questionView" id="itemPreview" src={questionImageBeingViewed} />
          <div>
            <button className="p-2" onClick={() => console.log("Edit")}>Change</button>
            <br></br>
            <button className="p-2" onClick={() => deleteImage()}>Delete</button>
          </div>
        </Box>
      </Modal>

  </React.Fragment>
  );
}
