import React, { useState, useEffect } from 'react';
import "../../assets/styles/index.css";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

import * as CONSTANTS from "../../StringConstants.js"

// Material UI imports

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import QuestionsAndUserDataService from 'services/QuestionsAndUserDataService';
import Title from "../AnalyticsComponents/Title.js"

export default function MockExamDashboard() {

  const [isLoadingMockExamSets, setIsLoadingMockExamSets] = useState(true)
  const [mockExamSets, setMockExamSets] = useState([])
  let history = useHistory();

  useEffect(() => {
    if (isLoadingMockExamSets) {
        getMockExamSets()
    }
  });

  function getMockExamSets() {
    QuestionsAndUserDataService.getMockExamSets().then(result => {
      console.log(result.data)
      setMockExamSets(result.data)
      setIsLoadingMockExamSets(false)
    })
  }

  function showMockExamsForSet(setId) {
    console.log(setId)
    history.push(CONSTANTS.AdminMockExamPapersRoute + "/" + setId)
  }

  function isEntryEmail() {
    const admin = JSON.parse(localStorage.getItem('user'));
    let email = admin.email;
    console.log(email)
    return email.toLowerCase() === "entry@whizzdom.co.uk"
  }


  return (
    <React.Fragment>   
      {isEntryEmail ? <></> : <Link to="/app/admin-whizz">Back to Admin Dashboard</Link> }

      <Title>Mock Exams</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            {/* Clicking schools should take us to a school info page */}
            <TableCell>Mock Exam Set Id</TableCell> 
            <TableCell>Mock Exam Set</TableCell> 
            <TableCell>Date</TableCell>
            <TableCell>Time</TableCell>
            <TableCell>Type</TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {mockExamSets.map((mockExamSet) => (
            <TableRow key={mockExamSet.mockExamSetId}>
              <TableCell>{mockExamSet.mockExamSetId}</TableCell>
              <TableCell><button onClick={() => showMockExamsForSet(mockExamSet.mockExamSetId)}><u>{mockExamSet.examSetName}</u></button></TableCell>
              <TableCell>{mockExamSet.mockExamDate}</TableCell>
              <TableCell>{mockExamSet.mockExamTime}</TableCell>
              <TableCell>{mockExamSet.mockExamType}</TableCell>          
            </TableRow>
            
          ))}
         
        </TableBody>
      </Table>

  </React.Fragment>
  );
}
