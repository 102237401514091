import React, { useState } from "react";
import { Helmet } from 'react-helmet';

import { useFormik } from 'formik';
import TextField from '@material-ui/core/TextField';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import QuestionsAndUserDataService from '../../services/QuestionsAndUserDataService'

export default function ForgotPassword() {

  // const
  const [showEmailBeingSentPage, setShowEmailBeingSentPage] = useState(false);



  const validationSchema = yup.object({
    email: yup
      .string('Enter your email')
      .email('Enter a valid email')
      .required('Email is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log("Making Forgot password post request to back end")
      QuestionsAndUserDataService.forgotPassword(values)
      // Inform them they've been sent a password reset link. 
      setShowEmailBeingSentPage(true)
    },
  });

  return (
    <>
     <Helmet>
        <title>Whizzdom 11 plus account password recovery</title>
        <meta name="Whizzdom 11 plus account recovery" content="Password reset link for 11 plus subscribers to reclaim their learning account" />
      </Helmet>
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">


          <div className="w-full lg:w-4/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
              


              <div className="rounded-t mb-0 px-6 py-6">
              {showEmailBeingSentPage ? 
              
              <div>
                <div className="text-center mb-3">
                  <h6 className="text-blueGray-500 text-sm font-bold">
                    A password reset link has been sent to your email. This may sometimes take up to a few minutes to arrive. In the 
                    event it does not, please contact support@whizzdom.co.uk to resolve this issue. 
                  </h6>
                </div>
              </div> 
              
              :
              
              <div>

                <div className="text-center mb-3">
                  <h6 className="text-blueGray-500 text-sm font-bold">
                    Enter your email to request a new password. 
                  </h6>
                </div>
                <form
                  onSubmit={formik.handleSubmit}
                >
                  <div className="relative w-full mb-3">
                    <TextField
                      fullWidth
                      id="email"
                      name="email"
                      label="Email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={formik.touched.email && Boolean(formik.errors.email)}
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </div>

                  <div className="text-center mt-6">
                    <Button
                      colour="primary"
                      className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="submit"
                    >
                      Request password
                    </Button>
                  </div>
                </form>
                </div>
            }
              </div>
            
            </div>
          </div>

        </div>
    
      </div>
      
    </>
  );
}
