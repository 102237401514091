import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import { Typography } from '@material-ui/core';

import TLHEndpoints from 'services/TLHEndpoints';
import ChangeEnquiryStatusModel from 'theLearningHub/layouts/HelperComponents/ChangeEnquiryStatusModel';
import NewEnquiryAssessment from './NewEnquiryAssessment';
import ViewBookedAssesmentDetails from 'theLearningHub/layouts/HelperComponents/ViewBookedAssesmentDetails';


export default function TLHViewOpenEnquiries() {

  const buttonStyle = {
    padding: '10px 20px',
    fontSize: '16px',
    color: 'white',
    backgroundColor: '#0056b3',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    display: 'inline-block',
    margin: '10px 0',
    textDecoration: 'none',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    transition: 'all 0.3s ease',
  };

  const handleMouseOver = (e) => {
    e.target.style.backgroundColor = '#004085';
    e.target.style.boxShadow = '0 8px 16px rgba(0, 0, 0, 0.2)';
  };

  const handleMouseOut = (e) => {
    e.target.style.backgroundColor = '#0056b3';
    e.target.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.1)';
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%', // Adjust the width as needed
    maxHeight: '90vh', // Max height of the modal
    overflowY: 'auto', // Enable vertical scrolling
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const [isLoadingEnquiries, setIsLoadingEnquiries] = useState(true)
  const [enquiriesData, setEnquiriesData] = useState()

  const [isChangeEnquiryStatusModelOpen, setIsChangeEnquiryStatusModelOpen] = useState(false);
  const [currentEnquiry, setCurrentEnquiry] = useState()
  const [isAddNewEnquiryModalOpen, setIsAddNewEnquiryModalOpen] = useState(false)

  const [isCommunicationNotesOpen, setIsCommunicationNotesOpen] = useState(false);
  const [currentEnquiryInfo, setCurrentEnquiryInfo] = useState();

  function handleOpenCommunicationNotes(assessment) {
    setIsCommunicationNotesOpen(true);
    setCurrentEnquiryInfo(assessment)
  }
  const handleCloseCommunicationNotes = () => setIsCommunicationNotesOpen(false);

  
  function handleOpenEnquiryStatusModel(enquiry) {
    setIsChangeEnquiryStatusModelOpen(true);
    setCurrentEnquiry(enquiry)
  }

  function handleCloseChangeEnquiryStatusModel(upToDate) {
    setIsChangeEnquiryStatusModelOpen(false);
    console.log("Is updated: " + upToDate)
    console.log(upToDate)
    if (!upToDate) {
      window.location.reload();
    }
  }

  function handleCloseEditEnquiryModal() {
    setIsAddNewEnquiryModalOpen(false);
    setCurrentEnquiryInfo(null);
  }

  function handleOpenAddEnquiryModel(individualEnquiryData) {
    console.log("Individual enquiry info")
    console.log(individualEnquiryData)
    if (individualEnquiryData) {
      console.log("Setting individual enquiry info")
      setCurrentEnquiryInfo(individualEnquiryData)
    }
    setIsAddNewEnquiryModalOpen(true);
  }

  function handleCloseAddEnquiryModel(upToDate) {
    setIsAddNewEnquiryModalOpen(false);
    if (!upToDate) {
      window.location.reload();
    }
  }

  useEffect(() => {
    if (isLoadingEnquiries) {
      getAllEnquiriesInfo()
    }
  });

  function getAllEnquiriesInfo() {
    TLHEndpoints.getAllEnquiriesInfo().then(result => {
      console.log(result.data)
      const sortedEnquiriesByCreationDate = sortEnquiriesByDate(result.data)
      console.log(sortedEnquiriesByCreationDate)
      setEnquiriesData(sortedEnquiriesByCreationDate)
      setIsLoadingEnquiries(false)
    })
  }

  function sortEnquiriesByDate(enquiries) {
    return enquiries.sort((a, b) => new Date(b.enquiryCreationDate) - new Date(a.enquiryCreationDate));
  }

  function formatFriendlyDate(dateString) {

    if (dateString === "" || dateString === null) {
      return ""
    }

    const date = new Date(dateString);
    
    // Check if the date is valid
    if (isNaN(date)) {
        return "Invalid date";
    }

    // Create a formatter for a friendly date format
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Intl.DateTimeFormat('en-US', options).format(date);
}

function removeNullStringFromName(clientName) {
  if (clientName == null || clientName == undefined) {
    return ""
  }
  return clientName
}

function renderChildNames(studentData) {
  var studentNames = ""
  console.log("Child data")
  // console.log(studentData)
  for (var i = 0; i < studentData.length; i ++) {
    var firstName = removeNullStringFromName(studentData[i].studentFirstName)
    var surname = removeNullStringFromName(studentData[i].studentSurname)
    if (studentNames === "") {
      studentNames = firstName + " " + surname
    } else {
      studentNames = studentNames + ", " + firstName + " " + surname
    }
  }
  return studentNames
}

function formatStringFromEnumForm(status) {
  let formatConversionStatus = status.toLowerCase();
  formatConversionStatus = formatConversionStatus.replace(/_/g, " ");
  formatConversionStatus = formatConversionStatus.charAt(0).toUpperCase() + formatConversionStatus.slice(1);
  return formatConversionStatus;
}



  return (
    
    <div>

      <Typography variant="h4" align='center' style={{padding: "15px"}}>
        Enquiries Dashboard
      </Typography>

      <Typography
        variant="h5"
        style={{paddingBottom: "20px"}}
      >
        Current Enquiries: {isLoadingEnquiries ? 0 : enquiriesData.length}
      </Typography>

      {isLoadingEnquiries ?
        <div className="table-container" style={{textAlign: "center", fontWeight: "bold", fontSize: "25px"}}>
          Loading Enquiries
        </div>
        :
      <div>
        <Table>
          <TableHead>
            <TableRow key={0}>
              <TableCell>Parent Name</TableCell>
              <TableCell>Child Name(s)</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Date of Enquiry</TableCell>
              <TableCell>Follow Up Date</TableCell>
              <TableCell>Enquiry Status</TableCell>
              <TableCell>No. Children</TableCell>
              <TableCell>Customer Notes</TableCell>
              <TableCell>More Details</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {enquiriesData.map((enquiry) => (
              <TableRow key={enquiry.assessmentMaterialId}>
                <TableCell>{removeNullStringFromName(enquiry.parentFirstName) + " " + removeNullStringFromName(enquiry.parentLastname)}</TableCell>
                <TableCell>{renderChildNames(enquiry.childrenBooked)}</TableCell>
                <TableCell>{enquiry.phoneNumber + (enquiry.altPhoneNumber ? ("/" + enquiry.altPhoneNumber) : (""))}</TableCell>
                <TableCell>{formatFriendlyDate(new Date(enquiry.enquiryCreationDate).toISOString().split('T')[0])}</TableCell>
                <TableCell>{formatFriendlyDate(enquiry.followUpDate)}</TableCell>
                <TableCell><button onClick={() => handleOpenEnquiryStatusModel(enquiry)}><u>{formatStringFromEnumForm(enquiry.conversionStatus)}</u></button></TableCell>
                <TableCell>{enquiry.childrenBooked.length }</TableCell>
                <TableCell><button onClick={() => handleOpenCommunicationNotes(enquiry)}><u>View</u></button></TableCell>
                <TableCell><button onClick={() => handleOpenAddEnquiryModel(enquiry)}><u>Click for all Details</u></button></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>}

      <div style={{textAlign: 'center', padding: "20px"}}>
        <button
          style={buttonStyle}
          onClick={handleOpenAddEnquiryModel}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
          Add Old Enquiry (If enquiry date is not today)
        </button>
      </div>

      <Modal
        open={isChangeEnquiryStatusModelOpen}
        onClose={handleCloseChangeEnquiryStatusModel}
      >
        <Box sx={style}>
          <ChangeEnquiryStatusModel
            handleClose={handleCloseChangeEnquiryStatusModel}
            enquiry={currentEnquiry}
          />
        </Box>
      </Modal>

      <Modal
        open={isAddNewEnquiryModalOpen}
        onClose={handleCloseAddEnquiryModel}
      >
        <Box sx={style}>
          <NewEnquiryAssessment
            isEnquiryNotFromToday={true}
            enquiryInfo={currentEnquiryInfo}
          />
        </Box>
      </Modal>

      <Modal
        open={isCommunicationNotesOpen}
        onClose={handleCloseCommunicationNotes}
        // aria-labelledby="modal-add-mock-exam"
        // aria-describedby="modal-able-to-add-a-set"
      >
        <Box sx={style}>
          <ViewBookedAssesmentDetails 
            currentAssessmentViewInfo={currentEnquiryInfo}
            isFromEnquiriesSection={true}
          />
        </Box>
      </Modal>

      
    </div>
  );
}

