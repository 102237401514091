import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';

import QuestionsAndUserDataService from '../../../services/QuestionsAndUserDataService'

import { Typography } from '@material-ui/core';
import IndividualComprehensionQuestionForm from './IndividualComprehensionQuestionForm';



export default function AddComprehensionQuestion() {

  const [numberOfQuestionTiles, setNumberOfQuestionTiles] = useState(1)
  const[questionsData, setQuestionsData] = useState([])

  const validationSchema = yup.object({
    comprehensionPassage: yup
      .string('Enter the passage text')
      .required('Field is required'),
    comprehensionDescription: yup
      .string('Enter the initial passage description')
  });

  function updateQuestionsData(questionsDataIndex, questionText, multipleChoiceAnswerOptions, correctAnswer) {
    let newQuestionsData = questionsData
    console.log("Current Questions Data")
    console.log(questionsData)
    console.log("Update data")
    console.log("Index: " + questionsDataIndex)
    console.log("Question Text: " + questionText)
    console.log("Answer Options: " + multipleChoiceAnswerOptions)
    console.log("Correct Answer: " + correctAnswer)

    if (questionText=== "" && multipleChoiceAnswerOptions === "" && correctAnswer === "") {
      console.log("Question tile is empty so not adding question to question data")
      return
    }

    newQuestionsData[questionsDataIndex] = createQuestionsDataHelper(questionsDataIndex+1, questionText, multipleChoiceAnswerOptions, correctAnswer);
    setQuestionsData(newQuestionsData)
    console.log("Updated Questions Data")
    console.log(questionsData)
  }

  function createQuestionsDataHelper(questionNumber, questionText, multipleChoiceAnswerOptions, correctAnswer) {
    return {questionNumber, questionText, multipleChoiceAnswerOptions, correctAnswer}
  }

  function renderQuestionFormTiles(){
    var tiles = []

    for (var i = 0; i < numberOfQuestionTiles; i++ ) {
      tiles.push(<IndividualComprehensionQuestionForm
                    index={i}
                    updateQuestionsData={updateQuestionsData}
                  />)
    }
    return tiles
  }


  // const WithMaterialUI = () => {
    const formik = useFormik({
      initialValues: {
        comprehensionPassage: '',
        comprehensionDescription: '',
        questionText: '',
        multipleChoiceOptions: '',
        correctAnswer: '',
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {
        console.log("Adding comprehension")
        QuestionsAndUserDataService.addEnglishComprehensionToDB(formik.values.comprehensionPassage, formik.values.comprehensionDescription, questionsData)
      },
    });

  return (
    
    <div>
     <form
        onSubmit={formik.handleSubmit}
        >
         <label>New Comprehension Form</label>

         <div className="relative w-full mb-3">
        <TextField
            fullWidth
            id="comprehensionDescription"
            name="comprehensionDescription"
            label="Comprehension Description"
            multiline
            maxRows={30}
            value={formik.values.comprehensionDescription}
            onChange={formik.handleChange}
            error={formik.touched.comprehensionDescription && Boolean(formik.errors.comprehensionDescription)}
            helperText={formik.touched.comprehensionDescription && formik.errors.comprehensionDescription}
          />
        </div>

        <div>
          For the actual passage, insert a "||" for a new line (can be useful for speech, conversations and anything else) and a "||p||" for a new paragraph. 
          This will be then be read in and interpreted on the front end accordingly.
        </div>

        <div className="relative w-full mb-3">
        <TextField
            fullWidth
            id="comprehensionPassage"
            name="comprehensionPassage"
            label="Comprehension Text"
            multiline
            maxRows={30}
            value={formik.values.comprehensionPassage}
            onChange={formik.handleChange}
            error={formik.touched.comprehensionPassage && Boolean(formik.errors.comprehensionPassage)}
            helperText={formik.touched.comprehensionPassage && formik.errors.comprehensionPassage}
          />
        </div>
        {renderQuestionFormTiles()}
          <br></br>
          <div style={{justifyContent: "center", textAlign: "center"}}>
            <Button onClick={() => setNumberOfQuestionTiles(numberOfQuestionTiles + 1)}>Add Question</Button>
          </div>
          <br></br>
          <br></br>
        <Button
            colour="primary"
            className="uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full"
            type="submit"
        > Add Comprehension</Button>
      </form>

      
    </div>
  );
}

  {/* <div className="relative w-full mb-3">
        <TextField
            fullWidth
            id="questionText"
            name="questionText"
            label="Question"
            value={formik.values.questionText}
            onChange={formik.handleChange}
            error={formik.touched.questionText && Boolean(formik.errors.questionText)}
            helperText={formik.touched.questionText && formik.errors.questionText}
          />
        </div>

        <div className="relative w-full mb-3">
        <TextField
            fullWidth
            id="multipleChoiceOptions"
            name="multipleChoiceOptions"
            label="Answer Options (|| separate options)"
            value={formik.values.multipleChoiceOptions}
            onChange={formik.handleChange}
            error={formik.touched.multipleChoiceOptions && Boolean(formik.errors.multipleChoiceOptions)}
            helperText={formik.touched.multipleChoiceOptions && formik.errors.multipleChoiceOptions}
          />
        </div>
        
        <div className="relative w-full mb-3">
        <TextField
            fullWidth
            id="correctAnswer"
            name="correctAnswer"
            label="Correct Answer"
            value={formik.values.correctAnswer}
            onChange={formik.handleChange}
            error={formik.touched.correctAnswer && Boolean(formik.errors.correctAnswer)}
            helperText={formik.touched.correctAnswer && formik.errors.correctAnswer}
          />
        </div>

        <div className="text-center mt-6">
          <Button
            colour="primary"
            className="bg-blueGray-800 active:bg-blueGray-600 uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
            type="submit"
          >
            Add Question
          </Button>
        </div> */}