import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

// Mui imports
import TextField from '@material-ui/core/TextField';
import Box from '@mui/material/Box';
import Link from '@material-ui/core/Link';
import { Typography } from '@material-ui/core';
import Modal from '@mui/material/Modal';
// import * as CONSTANTS from "../StringConstants"


// System imports
import QuestionsAndUserDataService from '../../services/QuestionsAndUserDataService'
import "../../assets/styles/landingPage.css";
import UpcomingMockExamsModal from 'components/UpcomingMockExamsModal';
// import stairsToSuccess from "../../assets/blogImages/ElevenPlusPlaybookBlog/stairsToSucess.jpg"
import stairsToSuccess from "../../assets/blogImages/ElevenPlusPlaybookBlog/stairsToSuccess.jpg"
import stairsToSuccessWebP from "../../assets/WebPImages/BlogImages/ElevenPlusPlaybookBlog/stairsToSuccess.webp"

import * as CONSTANTS from "../../StringConstants";


export default function ElevenPlusMistakesToAvoid() {
    let history = useHistory();

    const [open, setOpen] = useState(false);
    const [openMockExamSessionsTable, setMockExamSessionsTable] = useState(false);

    const [isLoadingMockExamSessionsData, setIsLoadingMockExamSessionsData] = useState(true)
    const [mockExamSessionsData, setMockExamSessionsData] = useState([])
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const viewSource = queryParams.get('source');  

  
    useEffect(() => {
      if (isLoadingMockExamSessionsData) {
          getUpcomingMockExamSessions()
      }
      let blogViewed = CONSTANTS.MainBlogTitle
      QuestionsAndUserDataService.updateBlogsViewData(blogViewed, viewSource)
    });
  
    function getUpcomingMockExamSessions(){
      QuestionsAndUserDataService.getAllUpcomingMockExams().then(sessionsData => {
        console.log(sessionsData)
        setMockExamSessionsData(sessionsData.data)
        setIsLoadingMockExamSessionsData(false)
      })
    }

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleOpenMockExamSessionsTable = () => setMockExamSessionsTable(true);
    const handleCloseMockExamSessionsTable= () => setMockExamSessionsTable(false);

    const [hasSubmittedAnEmail, setHasSubmittedAnEmail] = useState(false)

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };

    const validationSchema = yup.object({
      email:yup
      .string('Email')
      .email('Enter a valid email')
    });
  
  
    const formik = useFormik({
      initialValues: {
        email: '',
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {
        if (formik.values.email === "") {
          console.log("Email value is blank")
        } else {
          console.log("Adding subscriber email to list")
          setHasSubmittedAnEmail(true)
          // sendWelcomeEmailToNewSubscriber()
          QuestionsAndUserDataService.addToSubscriptionList(formik.values.email).then(setOpen(false))
        }
      },
    });

    function createCheckoutSession(lookupKey) {
        // Handle multiple keys and products later - for now just direct to sign up with the one look up 
        history.push("/auth/register/2")
        }


  return (        
        <div className='eleven-plus-preparation-blog'>

            <Helmet>
                <title>The Parent's Playbook for 11plus Success</title>
                <meta name="The Parent's Playbook for 11plus Success" content="Boost your child's 11plus exam preparation with expert tips and strategies. 
                Learn how to be a supportive study partner and help your child succeed. Read this comprehensive guide now for optimal results." />
            </Helmet>
           
            <div className='eleven-plus-info-blog-title-and-image'>
                <Typography className="eleven-plus-blog-title" variant="h3"><b>The Parent's Playbook for 11plus Success</b></Typography>
                <img
                className='eleven-plus-info-blog-image'
                alt="11plus-preparation-blog-info-image"
                src={stairsToSuccessWebP}
                srcSet={stairsToSuccess}
                />  
            </div>  

            <div className='eleven-plus-preparation-blog-content'>
            {/* <Typography className="eleven-plus-preparation-blog-subheading" variant="h6"><b>What is the 11plus</b></Typography> */}
            <Typography variant="h6">
              The 11plus exam is a crucial step in your child's education and can impact their future opportunities and success. 
              As a parent, you can play a vital role in helping your child to prepare for the 11plus exam and achieve their best possible outcome.
              In this blog, we will discuss some of the key strategies that you can use to be an effective 11plus study partner and support 
              your child's education.

            </Typography>
            <Typography className="eleven-plus-preparation-blog-subheading" variant="h6"><b>Understanding the 11 Plus</b></Typography>
            <Typography variant="h6">
              One of the first steps in supporting your child's education is to understand the 11plus exam format and content. 
              This will help you to better understand what your child is expected to know and be able to do. Our blogs on 
              the<Link onClick={() => history.push(CONSTANTS.GLBlog)}> GL </Link>and<Link onClick={() => history.push(CONSTANTS.CEMBlog)}> CEM </Link> 
              exams are a good starting place, if you're looking for more general information then you can also get 
              that <Link onClick={() => history.push(CONSTANTS.MainBlog)}>here.</Link> By having a clear understanding of the exam, you can 
              better support your child's preparation and provide relevant guidance and feedback.

            </Typography>
 
            <Typography className="eleven-plus-preparation-blog-subheading" variant="h6"><b>Regular Study Habits</b></Typography>
            <Typography variant="h6">
                 Another key aspect of supporting your child's education is to encourage regular study habits and a positive attitude
                towards learning. Regular study habits can help your child to stay on track and avoid feeling overwhelmed by the 
                11plus exam. You can encourage your child to set aside specific times for study, establish a routine and stick to it.
                Additionally, fostering a positive attitude towards learning and highlighting the benefits of hard work and
                determination can help to motivate your child. Of course, getting children to work can often be easier said than done 
                so we recommend regularly reminding them of all the positive benefits to keep them focused. For any tips for this, feel 
                free to email us at 11plus@Whizzdom.co.uk and we'd be more than happy to help. We also ensure that all our platform 
                learning is fun and engaging to make the learning process more enjoyable.
            </Typography>

            <Typography className="eleven-plus-preparation-blog-subheading" variant="h6"><b>Having Goals and a Plan</b></Typography>
            <Typography variant="h6">
                Helping your child set realistic and achievable study goals will go a long way with their education. Work with your child
                to create a study plan that is tailored to their needs and abilities. This can include setting daily, weekly and monthly goals.
                We recommend using mock exams to get an idea of how their progressing, you can book a 
                <Link onClick={handleOpenMockExamSessionsTable}><u> one off exam </u></Link>to see how their doing 
                or you can <Link onClick={() => history.push("/auth/register/2")}><u>subscribe for monthly mock exams</u></Link> so that you can track their progress over time.   
                By having a clear understanding of what they are trying to achieve, your child will have a roadmap to follow and will 
                be more motivated to succeed. This doesn't have to be rigid and should have some flexibility for any aspects that may 
                hinder their learning such as illness or even just the harder topics. 
            </Typography>

            <Typography className="eleven-plus-preparation-blog-subheading" variant="h6"><b>Monitor their Progress</b></Typography>
            <Typography variant="h6">
                 Staying involved and informed about your child's progress and needs is another critical aspect of supporting their education.
                 After you've set their goals, keeping track of their progress will allow you to celebrate their milestones but also adjust quickly
                 if they are having issues. Staying informed about their grades and performance can help you to better 
                 understand their strengths and weaknesses. This information can help you to provide targeted support and guidance, and to 
                 address any areas of concern.
            </Typography>

            <Typography className="eleven-plus-preparation-blog-subheading" variant="h6"><b>Encourage Independance</b></Typography>
            <Typography variant="h6">
            Finally, it is important to foster independence and self-motivation in your child's studies. Encourage your child to take 
            responsibility for their own learning and to set their own goals. This can help to develop their independence and self-motivation,
            and can also lead to a greater sense of accomplishment.
            </Typography>
            <br></br>
            <br></br>
            <br></br>
            <Typography variant="h6">We understand the importance of the 11 Plus and believe that all parents should have a full understanding as well in order
             to prepare their child. For any queries in regards to the process, email <u>11plus@whizzdom.co.uk</u> and our team will address any concerns or queries 
             that you may have. We also send out free resources and 11 Plus information each week via <Link onClick={handleOpen}><u>our mailing list.</u></Link>
             
             <br></br>
             <br></br>
                <Link onClick={() => history.push("/auth/register/2")}><u>Register today</u></Link> to be automatically subscribed to <u> all of our mock exams </u> 
                as well as gaining access to all of our learning material. We run 2 sets of online mock 
                exams a month and you'll be able to see exactly how your child is progressing, both for themselves and against the national cohort so they can constantly keep improving. In addition, your child will also have 
                access to unlimited practise questions to use to keep improving! <Link onClick={() => history.push("/auth/register/2")}><u>Click here</u></Link> to subscribe now and 
                use the code "BLOG20" at checkout for 20% off your first month! We also offer one of exam session purchases to suit your needs, see our available dates
                and <Link onClick={handleOpenMockExamSessionsTable}><u> sign up here.</u></Link> 
            </Typography>
        
                <div className="pricing-button-container">
                    <button
                        className="pricing-button-standard"
                        onClick={() => createCheckoutSession("lookupkey")}
                    >Get started today
                    </button>
                </div>
                <div className="pt-6">
                { hasSubmittedAnEmail ? 
                <b style={{textAlign: "center"}}>You've been added to our subscriber list! </b> : ""
                } 
                </div>        
            </div>
  
        <Modal
          open={open}
          onClose={handleClose}
        >
          <Box sx={style}>
            <form onSubmit={formik.handleSubmit}>
                <div className="relative w-full mb-3">
                    <TextField
                        fullWidth
                        id="email"
                        name="email"
                        label="Email"
                        onChange={(e) => {
                        formik.handleChange(e);
                        }}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                    />
                </div>

                <div className="text-center mt-6">
                <button
                    className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="submit"
                >
                    Get Started
                </button>
                </div>
            </form>
          </Box>
        </Modal>

        <Modal
          open={openMockExamSessionsTable}
          onClose={handleCloseMockExamSessionsTable}
          className="landing-page-upcoming-mock-exams-modal"
        >
          <Box sx={style}>
            <UpcomingMockExamsModal upcomingMockExamDates={mockExamSessionsData}/>
          </Box>
        </Modal>

        </div>
  );
}
