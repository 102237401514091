import React from 'react';
import Title from '../Title';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import { white } from 'tailwindcss/colors';

const useStyles = makeStyles({
    root: {
      minWidth: 200,
      maxWidth: 200,
      height: 100,
      textAlign: 'center',
      padding: 10,
      color: "#0284C7",
      border: '1pt solid',
      '&:hover': {
        background: "#0284C7",
        color: white,
     },
    },
  });

export default function SubTopicTile({title, onTileClick, key}) {
    const classes = useStyles();

    // Define useEffect function - will allow us to get list of questions choices for this title.

    // const title = useState(title);
    return (
        <React.Fragment>
            {/* This should be a parameter for the tile */}
            {/* <ResponsiveContainer> */}
            <ButtonBase onClick={() => onTileClick('question-choices', title)}>
              <Card variant="outlined" className={classes.root}>
                  <Title>{title}</Title>
              </Card>
            </ButtonBase>
            {/* </ResponsiveContainer> */}
        </React.Fragment>
    )
}  