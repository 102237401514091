import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import QuestionsAndUserDataService from '../../../services/QuestionsAndUserDataService'

import { Typography } from '@material-ui/core';



export default function AddQuestionToMockExamSectionForm({mockExamSectionId}) {

  const [questionImage, setQuestionImage] = useState()
  const [answerChoicesImage, setAnswerChoicesImage] = useState()
  let formData = new FormData()


  const validationSchema = yup.object({
    questionNumber: yup
      .number("Question Number")
      .required("Question Number Required"),
    questionText: yup
      .string('Enter the question'),
    multipleChoiceOptions: yup
      .string('Enter any student instructions for this section'),
    correctAnswer: yup
      .string("Enter any data for the table"),
    explanation: yup
      .string("Enter a solution for the question"),
  });


  // const WithMaterialUI = () => {
    const formik = useFormik({
      initialValues: {
        questionNumber: 1,
        questionText: '',
        multipleChoiceOptions: '',
        correctAnswer: '',
        explanation:'',
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {

        console.log("Submitting mock exam form")
        console.log("formik values")
        console.log(formik.values.questionText)
        console.log(formik.values.multipleChoiceOptions)

        formData.append("mockExamSectionId", mockExamSectionId)
        formData.append("questionNumber", formik.values.questionNumber)
        formData.append("questionText", formik.values.questionText.trim())
        formData.append("multipleChoiceOptions", formik.values.multipleChoiceOptions.trim())
        formData.append("correctAnswer", formik.values.correctAnswer.trim())
        formData.append("questionImage", questionImage)
        formData.append("answerChoices", answerChoicesImage)
        formData.append("explanation", formik.values.explanation.trim())

        QuestionsAndUserDataService.addQuestionToSection(formData).then((result) => {
          console.log(result)
          window.location.reload()
        })

      },
    });

  return (
    
    <div>
     <form
        onSubmit={formik.handleSubmit}
        >
         <label>Question Details</label>

         <div className="relative w-full mb-3">

          <TextField
              fullWidth
              id="questionNumber"
              name="questionNumber"
              label="Question Number"
              value={formik.values.questionNumber}
              onChange={formik.handleChange}
              error={formik.touched.questionNumber && Boolean(formik.errors.questionNumber)}
              helperText={formik.touched.questionNumber && formik.errors.questionNumber}
            />

          </div>

        <div className="relative w-full mb-3">
        <TextField
            fullWidth
            id="questionText"
            name="questionText"
            label="Question Text"
            value={formik.values.questionText}
            onChange={formik.handleChange}
            error={formik.touched.questionText && Boolean(formik.errors.questionText)}
            helperText={formik.touched.questionText && formik.errors.questionText}
          />
        </div>

        <div className="relative w-full mb-3">
          <Typography className="py-2">Question Image</Typography>
          <input id="file" name="file" type="file" 
              onChange={(event) => {
                event.preventDefault()
                console.log("appending question image to form")
                console.log(event.target.files[0])
                setQuestionImage(event.currentTarget.files[0]);

                // formData.append("questionImage", event.currentTarget.files[0])

              }} 
              className="form-control" />
        </div>


        <div className="relative w-full mb-3">
        <TextField
            fullWidth
            id="multipleChoiceOptions"
            name="multipleChoiceOptions"
            label="Answer Options (|| separate options), use **2** for squared subscript, put fractions in between @@ e.g @@1/2@@"
            value={formik.values.multipleChoiceOptions}
            onChange={formik.handleChange}
            error={formik.touched.multipleChoiceOptions && Boolean(formik.errors.multipleChoiceOptions)}
            helperText={formik.touched.multipleChoiceOptions && formik.errors.multipleChoiceOptions}
            InputLabelProps={{
              style: { margin: '-30px' } // add margin bottom to the InputLabel
            }}
          />
        </div>
        
        <div className="relative w-full mb-3">
          <Typography className="py-2">Multiple Choice Options Image</Typography>
          <input id="file" name="file" type="file" 
              onChange={(event) => {
                event.preventDefault()
                setAnswerChoicesImage(event.currentTarget.files[0]);

              }} 
              className="form-control" />
        </div>



        {/* Need to add image for section, any table data,  */}

        
        <div className="relative w-full mb-3">
        <TextField
            fullWidth
            id="correctAnswer"
            name="correctAnswer"
            label="Correct Answer"
            value={formik.values.correctAnswer}
            onChange={formik.handleChange}
            error={formik.touched.correctAnswer && Boolean(formik.errors.correctAnswer)}
            helperText={formik.touched.correctAnswer && formik.errors.correctAnswer}
          />
        </div>

        <div className="relative w-full mb-3">
        <Typography className="py-2">Explanation/Solution - use '\n' for new line and '•' for bullet point.</Typography>
        <TextField
            fullWidth
            id="explanation"
            name="explanation"
            label="Explanation/Solution"
            value={formik.values.explanation}
            onChange={formik.handleChange}
            error={formik.touched.explanation && Boolean(formik.errors.explanation)}
            helperText={formik.touched.explanation && formik.errors.explanation}
          />
        </div>

        <div className="text-center mt-6">
          <Button
            colour="primary"
            className="bg-blueGray-800 active:bg-blueGray-600 uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
            type="submit"
          >
            Add Question
          </Button>
        </div>
      </form>
    </div>
  );
}
