import React, { useState, useEffect } from 'react';
import Title from '../Title';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
import EnglishQuestionTopicTile from './EnglishQuestionTopicTile';
import { display } from '@mui/system';

import * as CONSTANTS from "../../StringConstants"


const useStyles = makeStyles({

    basicQuestionPadding: {
      // paddingBottom: '20px',
      paddingLeft: '20px',
    },

    root: {
      minWidth: 100,
      maxWidth: 100,
      height: 100,
      textAlign: 'center',
      padding: 10
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {

    },
  });

  // Component is currently updating state of parent component on every single key stroke input
  export default function QuestionTileEnglish({questionNumber, question, answer, updateUserAnswer, currentUserAnswer, multipleChoiceOptions, grammarQuestionType, comprehensionDescription, comprehensionPassage}) {
    // first element is the question number - second element is the selected choice
    const [selectedChoice, setSelectedChoice] = useState([])
    var currentChoice = ""
    const [hasCheckedForBoldValue, setHasCheckedForBoldValue] = useState(false)
    const [questionNumberIndex, setQuestionNumberIndex] = useState(questionNumber)
    console.log("Re rendered state")
    console.log("Current user answer: " + currentUserAnswer)
    console.log("Current selected choice: " + selectedChoice)
    console.log("Current choice: " + currentChoice)


    function checkAnswerIsInMultipleChoiceOption(answer) {
      for (var i = 0; i < multipleChoiceOptions.length; i++) {
        // console.log(i)
        // console.log("Checking: " + answer  + " and " + multipleChoiceOptions[i])
        if (multipleChoiceOptions[i] == answer) {
          // console.log("Returning true")
          return true
        }
      }
      // console.log("Returning false")
      return false
    }

    // if the selected choice isn't in the answer options 
    // check if current user answer is in answer options
    // if it is, selected = currentUserChoice - checked for bold value = true
    // otherwise nothing gets highlighted - checked for bold value = true
    // if selected choice in answer options, found our bold value - - checked for bold value = true

    // useEffect(() => {
    //   setSelectedChoice(currentUserAnswer)
    // });

    function shouldBeBold(answerOption) {
      // For each answer option, check if it equals the current selected choice
      // if not check if it equals the current user answer 
      // otherwise its not bold
  

      if (grammarQuestionType === "SPELLING" || grammarQuestionType === "PUNCTUATION") {
        // Could keep track at this level - have a list - each index is question number - 1
        // update the list on next and previous and 
        // return currentChoice == "" ? currentUserAnswer === answerOption : selectedChoice === answerOption
        if (selectedChoice[0] === questionNumber) {
          return selectedChoice[1] === answerOption
        } else {
          console.log()
          return currentUserAnswer === answerOption
        }


      } else {
        if (answerOption === selectedChoice[1]) {
          console.log("Same as selected choice")
          return true
        } 
        if (!checkAnswerIsInMultipleChoiceOption(selectedChoice[1])) {
          // console.log("Selected choice not in multiple choice options")
          if (answerOption === currentUserAnswer && answerOption !== selectedChoice[1]){
            // console.log("Same as current user answer and not selected choice")
            return true
          } else {
            // console.log("not the same")
            return false
          }
        }
      }
    }
  

    function onOptionClick(selection) {
      console.log(answer)
      console.log("selection: " + selection)
      currentChoice = selection
      console.log("Current choice: " + currentChoice)
      console.log("Updating user answer with: " + selection)
      updateUserAnswer(questionNumber, selection)
      setSelectedChoice([questionNumber, selection])
    }

   
    function getMultipleChoiceOptions() {
      console.log("Multiple choice options are: ")
      console.log(multipleChoiceOptions)
      console.log("Current selected choice is: " + selectedChoice)
      console.log("Current user answer is: " + currentUserAnswer)

      var tiles = [];

      for (var i = 0; i < multipleChoiceOptions.length; i++) {
        // console.log(i)

        let isBold = shouldBeBold(multipleChoiceOptions[i])
        console.log("isBold: " + isBold)
        tiles.push(
          <Grid item xs={12}>
            <EnglishQuestionTopicTile 
              answerOption={multipleChoiceOptions[i]} 
              optionNumber={i}
              onOptionClick={onOptionClick}
              currentAnswerSelectedByUser={selectedChoice}
              shouldBeBoldOption={isBold}
            />
          </Grid>
        )}
        return tiles
    }
    
    function formatSpellingOrPunctuationQuestion() {
      console.log("Grammar Question type is: " + grammarQuestionType)

      const questionSegments = question.split("||")
      console.log(questionSegments)
      var underLinedSegments = []


        underLinedSegments.push(
          // <Grid container spacing={2}>
          <Grid item style={{textAlign: "center", fontSize: "20px", display: "contents", justifyContent: "space-evenly"}}>
              <u className='m-4'>{questionSegments[0]}</u>
              <u className='m-4'>{questionSegments[1]}</u>
              <u className='m-4'>{questionSegments[2]}</u>
              <u className='m-4'>{questionSegments[3]}</u>
          </Grid>
        )
    

      function getSpellingAndPunctuationMultipleChoiceOptions() {
        return <Grid item xs={12} style={{textAlign: "center", fontSize: "20px", display: "flex", justifyContent: "space-evenly"}}>
            <EnglishQuestionTopicTile 
              answerOption={multipleChoiceOptions[0]} 
              optionNumber={""}
              onOptionClick={onOptionClick}
              currentAnswerSelectedByUser={selectedChoice}
              shouldBeBoldOption={shouldBeBold(multipleChoiceOptions[0])}
            />
            <EnglishQuestionTopicTile 
              answerOption={multipleChoiceOptions[1]} 
              optionNumber={""}
              onOptionClick={onOptionClick}
              currentAnswerSelectedByUser={selectedChoice}
              shouldBeBoldOption={shouldBeBold(multipleChoiceOptions[1])}
            />
            <EnglishQuestionTopicTile 
              answerOption={multipleChoiceOptions[2]} 
              optionNumber={""}
              onOptionClick={onOptionClick}
              currentAnswerSelectedByUser={selectedChoice}
              shouldBeBoldOption={shouldBeBold(multipleChoiceOptions[2])}
            />
            <EnglishQuestionTopicTile 
              answerOption={multipleChoiceOptions[3]} 
              optionNumber={""}
              onOptionClick={onOptionClick}
              currentAnswerSelectedByUser={selectedChoice}
              shouldBeBoldOption={shouldBeBold(multipleChoiceOptions[3])}
            />
            <EnglishQuestionTopicTile 
              answerOption={multipleChoiceOptions[4]} 
              optionNumber={""}
              onOptionClick={onOptionClick}
              currentAnswerSelectedByUser={selectedChoice}
              shouldBeBoldOption={shouldBeBold(multipleChoiceOptions[4])}
            />
{/*               
              <h5 className='m-4'>{multipleChoiceOptions[1]}</h5>
              <h5 className='m-4'>{multipleChoiceOptions[2]}</h5>
              <h5 className='m-4'>{multipleChoiceOptions[3]}</h5> */}
            </Grid>
      }

         return <Grid container spacing={3} >
              <Grid item xs={12}>
                <Title style={{justifyContent: "space-evenly"}}>{questionNumber + 1}) 
                  {/* <div style={{display: "flex"}}> */}
                    {underLinedSegments}
                  {/* </div>   */}
                </Title>
              </Grid>
                {getSpellingAndPunctuationMultipleChoiceOptions()}
            </Grid>
    }

    function returnRelevantQuestionInstructions() {
      console.log("Grammar Question Type")
      console.log(grammarQuestionType)
      if (grammarQuestionType == "JUMBLED_SENTENCES") {
        return CONSTANTS.JumbledSentenceQuestionInstructions
      } else if (grammarQuestionType == "SPELLING") {
        return CONSTANTS.SpellingQuestionInstructions
      } else if (grammarQuestionType == "PUNCTUATION") {
        return CONSTANTS.PunctuationQuestionInstructions
      } else if (grammarQuestionType == "MISSING_WORDS") {
        return CONSTANTS.MissingWordsQuestionInstructions
      } else if (grammarQuestionType == "SYNONYMS") {
        return CONSTANTS.SynonymsQuestionInstructions
      } else if (grammarQuestionType == "ANTONYMS") {
        return CONSTANTS.AntonymsQuestionInstructions
      } else if (grammarQuestionType == "SHUFFLED_SENTENCES") {
        return CONSTANTS.ShuffledSentencesQuestionInstructions
      } else if (grammarQuestionType == "COMPREHENSION_TEXT" || grammarQuestionType == "COMPREHENSION_QUESTION") {
        return <b>{comprehensionDescription + " " + CONSTANTS.ComprehensionQuestionInstructions}</b>
      }
      return "Question instructions for all english questions"
    }

    function formattedComprehensionPassage() {


      // Works with formatting from when comprehensions are inputted - || means new line and ||p|| is new paragraph

      var comprehensionParagraphs = comprehensionPassage.split("||")
      var formattedParaphraphs = [] 
      for (var i = 0; i < comprehensionParagraphs.length; i++) {
        if (comprehensionParagraphs[i] == "p") {
          formattedParaphraphs.push(<br></br>)
        } else {
          formattedParaphraphs.push(<div>{comprehensionParagraphs[i]}</div>)
        }
      }
      formattedParaphraphs.push(<br></br>)
      return formattedParaphraphs
    }

    return (
        <React.Fragment>
           {returnRelevantQuestionInstructions()}
          <br></br>
          <br></br>

          {formattedComprehensionPassage()}


          {grammarQuestionType == "SPELLING" || grammarQuestionType == "PUNCTUATION" ?
              formatSpellingOrPunctuationQuestion()
            :
            <Grid container spacing={3} >
            <Grid item xs={12}>
              <Title>{questionNumber + 1}) {question}</Title>
            </Grid>
              {getMultipleChoiceOptions()}
          </Grid>
          }

        </React.Fragment>
    )
        }