import React from "react";

import { Link } from "react-router-dom";

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { CardActionArea } from '@mui/material';

// images
import teacher from '../auth/images/teacher.jpg' 
import schoolChildren from '../auth/images/schoolChildren.png' 


export default function SchoolLoginDirector() {

  return (
    <Grid container style={{justifyContent: "center"}}>
           <div
            className="absolute top-0 w-full h-full bg-blueGray-800 bg-no-repeat bg-full"
            style={{
              backgroundImage:
                "url(" + require("assets/img/register_bg_2.png").default + ")",
            }}
          ></div>
      <Grid item xs={3}>

        <Link to="/auth/teacher-login">
          <Card sx={{ maxWidth: 345 }} className="pt-2" >
          <CardActionArea>
            <CardMedia
              component="img"
              height="140"
              image={teacher}
              alt="Teacher login"
            />
            <div style={{backgroundColor: "white"}}>

              <CardContent>
                <Typography gutterBottom variant="h5" component="div" textAlign={"center"}>
                  Teacher login
                </Typography>
                {/* <Typography variant="body2" color="text.secondary">
                  Lizards are a widespread group of squamate reptiles, with over 6,000
                  species, ranging across all continents except Antarctica
                </Typography> */}
              </CardContent>
            </div>

          </CardActionArea>
        </Card>
      </Link>
    </Grid>

    <Grid item xs={1}>
    </Grid>

    <Grid item xs={3}>
      <Link>
        <Card sx={{ maxWidth: 345 }}>
          <CardActionArea>
            <CardMedia
              component="img"
              height="140"
              image={schoolChildren}
              alt="Student login"
            />
          <div style={{backgroundColor: "white"}}>

              <CardContent>
                <Typography gutterBottom variant="h5" component="div" textAlign={"center"}>
                  Student Login
                </Typography>
                {/* <Typography variant="body2" color="text.secondary">
                  Lizards are a widespread group of squamate reptiles, with over 6,000
                  species, ranging across all continents except Antarctica
                </Typography> */}
              </CardContent>
            </div>

          </CardActionArea>
        </Card>
      </Link>
      </Grid>
    </Grid>

  );
}
