import React, { useState, useEffect } from 'react';
import Title from '../Title';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import ReportQuestionModal from './ReportQuestionModal';
import CheckedFlag from '../../assets/QuestionGeneratorImages/CheckedFlag.png'
import UnCheckedFlag from '../../assets/QuestionGeneratorImages/UncheckedFlag.png'

const useStyles = makeStyles({

    basicQuestionPadding: {
      // paddingBottom: '20px',
      paddingLeft: '20px',
    },

    root: {
      minWidth: 100,
      maxWidth: 100,
      height: 100,
      textAlign: 'center',
      padding: 10
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 18, 
    },
    pos: {

    },
  });

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

 

  // Component is currently updating state of parent component on every single key stroke input
export default function QuestionReviewTile({question, questionNumber, answer, userAnswer, isCorrect, solution, multipleChoiceOptions, currentQuestion}) {
    const classes = useStyles();
    const [isFlagged, setIsFlagged] = useState(false)
    const [open, setOpen] = useState(false)
    // const [hover, setHover] = useState(false);

    // const HoverData = "Click to flag question";
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    let textColor = isCorrect ? "#2bbb17" : "red"

    

    console.log("Is answer correct: " + isCorrect)
    console.log("User Answer: " + userAnswer)
    console.log("Correct Answer: " + answer)
    console.log("Solution: " + solution)

    useEffect(() => {
      setIsFlagged(false);
    }, [currentQuestion])

    function setQuestionFlagAndCloseModal() {
      setIsFlagged(true)
      setOpen(false)
    }

    function intToChar(key) {
      const code = 'A'.charCodeAt(0);
      const newLetter = String.fromCharCode(code + key);
      return newLetter
    }

    function getMultipleChoiceOptions() {
      if (multipleChoiceOptions === null || typeof multipleChoiceOptions === "undefined") {
        return
      }

      var tiles = [];

      for (var i = 0; i < multipleChoiceOptions.length; i++) {

        tiles.push(
          <Grid item xs={12}>
              {intToChar(i) + ") " + multipleChoiceOptions[i]}
          </Grid>

        )}
        return tiles
    }

    function renderQuestion() {

      if (question.includes("||")) {
        
        console.log("Grammar Question type is Spelling or Punctuation ")

        const questionSegments = question.split("||")
        console.log(questionSegments)
        var underLinedSegments = []
  
  
          return <Grid item style={{textAlign: "center", fontSize: "20px", display: "contents", justifyContent: "space-evenly"}}>
                <u className='m-4'>{questionSegments[0]}</u>
                <u className='m-4'>{questionSegments[1]}</u>
                <u className='m-4'>{questionSegments[2]}</u>
                <u className='m-4'>{questionSegments[3]}</u>
            </Grid>
        
      } else {
        return question
      }
    }

    function renderAnswer() {
      console.log(multipleChoiceOptions)
      if (isMultipleChoiceForPunctuationOrSpelling(multipleChoiceOptions)) {
      return <Grid item xs={12} style={{textAlign: "center", fontSize: "20px", display: "flex", justifyContent: "space-evenly"}}>
            <div>A</div>
            <div>B</div>
            <div>C</div>
            <div>D</div>
          </Grid>
      } else {
        return getMultipleChoiceOptions()
      }
    }

    function isMultipleChoiceForPunctuationOrSpelling(multipleChoiceOptions) {
      var multipleChoiceList = ['A','B','C','D']
      console.log("Multiple choice options when checking for punct or spelling")
      console.log(multipleChoiceOptions)
      console.log(multipleChoiceOptions === "undefined")
      console.log(typeof multipleChoiceOptions === "undefined")

      if (multipleChoiceOptions === null ||typeof multipleChoiceOptions === "undefined") {
        return false
      }
      if (multipleChoiceOptions.length != 4) {
        return false
      }
      for (var i = 0; i < multipleChoiceOptions.length; i++) {
        if (multipleChoiceOptions[i] != multipleChoiceList[i]) {
          return false
        }
      }
      return true

    }

    
    function renderSolution() {
      var tiles = [];
      console.log("Solution")
      console.log(solution)
      if (solution === null || solution === undefined) {
        console.log("No solution")
        return null
      }
      console.log("Solution type is: " + typeof solution)
      if (solution.includes("\n")) {
        console.log("Splitting up solution")
        solution = solution.split("\n")
      }

      if (typeof solution === "string") {
        return "Solution:" + solution
      }
      console.log("Rendering solution steps")
      console.log(solution)
      tiles.push(<Typography className='p-4' variant='h5'>Solution</Typography>)
      for (var i = 0; i < solution.length; i++) {
        console.log(solution[i])
        if (solution[i] === undefined) {
          continue
        }
        tiles.push(
          <Grid item xs={12}>
            {(i == 0 ? "" : (i + ") ")) + solution[i]}
          </Grid>
        )}
        return tiles
    }
   
    return (
          <>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Title><b>{questionNumber}) {renderQuestion()}</b> </Title>
              </Grid>
              {renderAnswer()}
              <b className='p-2'>{renderSolution()}</b>
              <Grid item xs={12} className="p-8">  </Grid>              
                <Grid item xs={4} style={{ color: textColor }} className={classes.title}>
                {isCorrect ? "" : "Your "}Answer: <u>{userAnswer}</u>
                </Grid>    
                <Grid item xs={2}>
                </Grid> 
                <Grid item xs={4} style={{ color: "#2bbb17", fontWeight: "bolder"}} >
                   {isCorrect ? "" : "Correct Answer: " + answer  }  
                </Grid> 
                <Grid item xs={2}>
                <img
                    alt="..."
                    src={isFlagged ? CheckedFlag : UnCheckedFlag}
                    // srcSet={mockExamsPicture}
                    // onMouseEnter={(e) => onHover(e)}
                    // onMouseLeave={(e) => onHoverOver(e)}
                    style={{width: "30%", padding: "0%"}}
                    onClick={() => setOpen(true)}
                />
                {/* {hover && <p className={hover}>{HoverData}</p>} */}
                </Grid> 
            </Grid>
              <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-add-mock-exam"
              aria-describedby="modal-able-to-add-a-set"
            >
              <Box sx={style}>
                <ReportQuestionModal
                  questionNumber={questionNumber}
                  question={question}
                  answer={answer}
                  userAnswer={userAnswer}
                  isCorrect={isCorrect}
                  solution={solution}
                  multipleChoiceOptions={multipleChoiceOptions}
                  setQuestionFlagAndCloseModal={setQuestionFlagAndCloseModal}
                />
              </Box>
            </Modal>
          </>

    )
}  