import React, { useState, useEffect } from "react";
import {useParams} from "react-router-dom";
import { useHistory } from "react-router";
import { useLocation } from 'react-router-dom';

import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios'

// Mui imports

import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Card from '@mui/material/Card';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Checkbox } from '@mui/material';
import * as CONSTANTS from "../StringConstants"
import MockExamRegistrationConfirmation from "MockExamLandingPage/MockExamRegistrationConfirmation.js";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

// Component imports 

import QuestionsAndUserDataService from 'services/QuestionsAndUserDataService';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
};

export default function RegistrationStepOne() {

  // const [privacyPolicySelection, setPrivacyPolicySelection] = useState(false)
  // const [selectedValue, setSelectedValue] = useState('1');
  const [ip, setIP] = useState('');
  const location = useLocation();

  // Use a URLSearchParams object to parse the query string
    const queryParams = new URLSearchParams(location.search);
    const fbAdId = queryParams.get('fbAdId');
    const fbAdName = queryParams.get('fbAdName');
    const fbclid = queryParams.get('fbclid');

  let history = useHistory();
  
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => history.push("/auth/individual-login");

  const getData = async () => {
    const res = await axios.get('https://geolocation-db.com/json/')
    console.log(res.data);
    var ip = res.data.IPv6
    if (!ip || ip === 'undefined' || ip === null) {
      ip = res.data.IPv4
    }
    setIP(ip)
  }

  useEffect( () => {
    //passing getData method to the lifecycle method
    getData()

  }, [])

  const validationSchema = yup.object({
    firstName: yup
      .string('Enter your name')
      .required('Field is required'),
    lastName: yup
      .string('Enter your name')
      .required('Field is required'),
    email: yup
      .string('Enter your email')
      .email('Enter a valid email')
      .required('Email is required'),
    childName: yup
      .string('Enter your name'),
    password: yup
      .string('Enter your password')
      .min(8, 'Password should be of minimum 8 characters length')
      .required('Password is required'),
    confirmPassword: yup
      .string()
      .test('passwords-match', 'Passwords must match', function(value){
        return this.parent.password === value
      }),
    acceptTerms: yup
      .bool()
      .test('acceptTerms', "Please agree to the Terms and Conditions", function(value){
        return this.parent.acceptTerms === true
     })
     .required("Please agree to the Terms and Conditions")
  });

  // function sendPartialDetails(firstName, surname, email) {
  //   console.log("Sending partial details")
  //   // QuestionsAndUserDataService.sendEmailForIncompleteUserSignUp(firstName, surname, email, "1")
  //   // QuestionsAndUserDataService.addEmailToPartialUserSignUpsSubscribersGroup(firstName, surname, email)
  //   // QuestionsAndUserDataService.sendWelcomeEmailToNewSubscriber(email, false, ["epZqyp"], firstName, surname)

  // }


  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      // password: '',
      // confirmPassword: '',
      childName: '',
      acceptTerms: false,
      customCheckLogin: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {

      console.log("Standard user registration info")
      console.log("formik values")
      console.log(formik.values.firstName)
      console.log(formik.values.lastName)
      console.log(formik.values.email)
      console.log(formik.values.childName)
      console.log(formik.values.password)
      // console.log(formik.values.confirmPassword)

      // let signUpData = []
      // signUpData["parentFirstName"] = formik.values.firstName
      // signUpData["parentLastName"] = formik.values.lastName
      // signUpData["parentEmail"] = formik.values.email
      // signUpData["childName"] = formik.values.childName
      // // signUpData["password"] = formik.values.password
      // signUpData["acceptTerms"] = formik.values.acceptTerms

     
      // sendPartialDetails(formik.values.firstName, formik.values.lastName, formik.values.email)
      const currentPage = "https://whizzdom.co.uk" + CONSTANTS.UnlimitedLearningConversionPageRoute
      QuestionsAndUserDataService.leadConversionAPIUpdate(formik.values.email, formik.values.firstName, formik.values.lastName, ip, fbclid, currentPage)
      .then((response) => {
        console.log("Response: ")
        console.log(response);
      })
      // QuestionsAndUserDataService.createCheckoutSession(signUpData, ip, "1", null)
      // console.log(selectedValue)
      if (formik.values.acceptTerms) {
        QuestionsAndUserDataService.createFreeStandardQuestionsUserAndSendLoginInstructions(formik.values.firstName, formik.values.lastName, formik.values.email, formik.values.password, formik.values.childName, fbAdId, fbAdName)
      }
      handleOpen()
      // updateUserDetails(formik.values.firstName, formik.values.lastName, formik.values.email).then(onStepChange(2))

    },
  });

  // const handleChange = (event) => {
  //   setSelectedValue(event.target.value);
  // };

  // const controlProps = (item) => ({
  //   checked: selectedValue === item,
  //   onChange: handleChange,
  //   value: item,
  //   name: 'color-radio-button-demo',
  //   inputProps: { 'aria-label': item },
  // });

  return (
    <div>

      <Card className="p-4" sx={{border: 15, borderColor: '#F6AE2D'}}>
        <Typography className="p-2" variant="h5">Create your free account below!</Typography>

          <form
            onSubmit={formik.handleSubmit}
            >
            {/* <label>Parent's details</label> */}
            <div className="relative w-full mb-3">

            <TextField
                className="data-hj-allow"
                fullWidth
                id="firstName"
                name="firstName"
                label="First Name*"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                helperText={formik.touched.firstName && formik.errors.firstName}
              />

            </div>

            <div className="relative w-full mb-3">

              <TextField
                  className="data-hj-allow"
                  fullWidth
                  id="lastName"
                  name="lastName"
                  label="Last Name*"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                  helperText={formik.touched.lastName && formik.errors.lastName}
                />

              </div>


            <div className="relative w-full mb-3">
            <TextField
              className="data-hj-allow"
              fullWidth
              id="email"
              name="email"
              label="Email*"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            </div>

            <div className="relative w-full mb-3">

            <TextField
                className="data-hj-allow"
                fullWidth
                id="childName"
                name="childName"
                label="Child Name"
                value={formik.values.childName}
                onChange={formik.handleChange}
              />

            </div>
            

          <div className="relative w-full mb-3">
            <TextField
              className="data-hj-allow"
              fullWidth
              id="password"
              name="password"
              label="Password*"
              type="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </div>

          <div className="relative w-full mb-3">
            <TextField
              className="data-hj-allow"
              fullWidth
              id="confirmPassword"
              name="confirmPassword"
              label="Confirm Password*"
              type="password"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
              helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
            />
          </div> 

            {/* <FormControl style={{textAlign: "left", marginLeft: "-30px"}}> */}
              {/* <FormLabel id="demo-radio-buttons-group-label">Product</FormLabel> */}
              {/* <RadioGroup>
                <FormControlLabel
                  control={<Radio {...controlProps("123")} 
                              sx={{
                              color: pink[800],
                              '&.Mui-checked': {
                                color: pink[600],
                              },
                }}/>} label="Book both (£35) and SAVE £15" />
                <FormControlLabel control={<Radio {...controlProps('1')}/>} label="Book 11th September (£25)" />
                <FormControlLabel control={<Radio {...controlProps('2')}/>} label="Book 18th September (£25)" />
              </RadioGroup> */}
            {/* </FormControl> */}

            <div>
            <FormControlLabel
              id="acceptTerms"
              control={<Checkbox checked={formik.values.acceptTerms} />}
              name="acceptTerms"
              label=""
              onChange={formik.handleChange}
              
            />
            I agree to the 
                <a 
                  href="https://www.11plusmock.co.uk/Whizzdom/PrivacyDocuments/WhizzdomTermsAndConditions.html"
                  target="_blank"
                > <u>
                  terms and conditions
                  </u>
                </a>
                  <div className="form-field-error" style={{color: 'red'}}>{formik.touched.acceptTerms && formik.errors.acceptTerms}</div> 

          </div>

            <div>
              <button
                // colour="primary"
                // style={{ color: "white"}}
                // className="active:bg-blueGray-600 rounded shadow hover:shadow-lg w-full ease-linear transition-all duration-150"
                // className="eleven-plus-book-now-button"
                className="eleven-plus-book-now-button"
                type="submit"
              >
                Register Now
              </button>
            </div>
          </form>
      </Card>
      <Modal
          open={open}
          onClose={handleClose}
          className="landing-page-upcoming-mock-exams-modal"
        >
          <Box sx={style}>
            <MockExamRegistrationConfirmation/>
          </Box>
        </Modal>
      </div>
  );
};
