import React from "react";
import { Redirect, Route } from "react-router-dom";
// string constants

import * as CONSTANTS from "../../StringConstants"

function ProtectedMockExamRoute({ component: Component, ...restOfProps }) {

    const user = JSON.parse(localStorage.getItem('user'));
    const isAuthenticated = user && user.accessToken && isMockExamUser(user.roles)
    console.log("this", isAuthenticated);
    console.log(user)

    function isMockExamUser(roles) {
        console.log("Roles for this use include")
        console.log(roles)
        for (var i = 0; i < roles.length; i ++) {
            if (roles[i] == "ROLE_MOCK_EXAM_STUDENT") {
                return true
            }
        }
        return false
    }

    return (
        <Route
        {...restOfProps}
        render={(props) =>
            isAuthenticated ? <Component {...props} /> : <Redirect to={CONSTANTS.IndividualLogin} />
        }
        />
    );
}

export default ProtectedMockExamRoute;
