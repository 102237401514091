import React, { useState, useEffect } from 'react';
import "../../assets/styles/index.css";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

import * as CONSTANTS from "../../StringConstants.js"

// Material UI imports

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

// System imports

import QuestionsAndUserDataService from 'services/QuestionsAndUserDataService';
import Title from "../AnalyticsComponents/Title.js"

export default function MockExamDashboard() {

  const [isLoadingMockExamSets, setIsLoadingMockExamSets] = useState(true)
  const [mockExamSessions, setMockExamSessions] = useState([])

  let history = useHistory();

  useEffect(() => {
    if (isLoadingMockExamSets) {
      getMockExamSessions()
    }
  });

  function getMockExamSets() {
    QuestionsAndUserDataService.getMockExamSets().then(result => {
      console.log(result.data)
      // setMockExamSets(result.data)
      // setIsLoadingMockExamSets(false)
    })
  }

  function getMockExamSessions() {
    QuestionsAndUserDataService.getMockExamSessions().then(result => {
      console.log(result.data)
      // setMockExamSets(result.data)
      setMockExamSessions(result.data)
      console.log("Mock Exam Sessions")
      console.log(mockExamSessions)
      setIsLoadingMockExamSets(false)
    })
  }

  function handleMockExamSessionObjectData(data) {
    return {data}
  }

//   function createDummyData(date, numberOfQuestionsInTheWorksheet, numberQuestionsCorrectlyAnswered, questionTypes, subject, topic, user, worksheetId, worksheetQuestions) {
//     return {date, numberOfQuestionsInTheWorksheet, numberQuestionsCorrectlyAnswered, questionTypes, subject, topic, user, worksheetId, worksheetQuestions}
// }


  // function showMockExamsForSet(setId) {
  //   console.log(setId)
  //   history.push(CONSTANTS.AdminMockExamPapersRoute + "/" + setId)
  // }

  function assignMockExamSetForSession(mockExamSessionId) {
    // Will need function to get sets that can be used for this session 
// Even if its set, this should still handle resetting in case we want to manually reset the mock exam for the session

// TODO: Implement check for if the mock exam session is in the past (if so then don't want to reassign)

    QuestionsAndUserDataService.assignAViableMockExamSetForMockExamSession(mockExamSessionId).then(result => {
      console.log(result.data)
    })
  }


  function sendMockExamSessionExamReports(mockExamSessionId) {
    console.log("Sending individual reports for " + mockExamSessionId)
    QuestionsAndUserDataService.generateAndSendMockExamReports(mockExamSessionId)

  }

  function sendOverallMockExamReport(mockExamSessionId) {
    QuestionsAndUserDataService.generateAndSendOverallMockExamReport(mockExamSessionId)
  }

  function showMockExamSessionDetails(mockExamSessionId) {
    history.push(CONSTANTS.AdminViewMockExamSessionUsers + "/" + mockExamSessionId)
  }


  return (
    <React.Fragment>   
      <Link to="/app/admin-whizz">Back to Admin Dashboard</Link>

      <Title>Mock Exam Sessions</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            {/* Clicking schools should take us to a school info page */}
            <TableCell>Mock Exam Session Id</TableCell> 
            <TableCell>Date</TableCell>
            <TableCell>Time</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Assigned Mock Exam Set Id</TableCell>
            <TableCell>Number of users signed up</TableCell>
            <TableCell>Exam Session Report Status</TableCell>
            <TableCell>Send Overall Report</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {mockExamSessions.map((mockExamSession) => (
            <TableRow key={mockExamSession.mockExamSessionId}>
              <TableCell><button onClick={() => showMockExamSessionDetails(mockExamSession.mockExamSessionId)}><u>{mockExamSession.mockExamSessionId}</u></button></TableCell>
              <TableCell>{mockExamSession.mockExamDate}</TableCell>
              <TableCell>{mockExamSession.mockExamTime}</TableCell>
              <TableCell>{mockExamSession.mockExamType}</TableCell>       
              <TableCell><button onClick={() => assignMockExamSetForSession(mockExamSession.mockExamSessionId)}><u>{mockExamSession.mockExamSet === null ? "Assign Set" : mockExamSession.mockExamSet.examSetName}</u></button></TableCell>
              <TableCell>{mockExamSession.mockExamUsers.length}</TableCell> 
              <TableCell>{mockExamSession.reportsSent ? "Reports Sent" : <button onClick={() => sendMockExamSessionExamReports(mockExamSession.mockExamSessionId)}><u>Click to Send Reports</u></button>}</TableCell>             
              <TableCell><button onClick={() => sendOverallMockExamReport(mockExamSession.mockExamSessionId)}>Send Overall Report</button></TableCell>
            </TableRow>
          ))}
         
        </TableBody>
      </Table>

  </React.Fragment>
  );
}
