import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';

import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { styled } from '@mui/material/styles';

import QuestionsAndUserDataService  from 'services/QuestionsAndUserDataService';
import * as CONSTANTS from "../../StringConstants"
import whiteLogo from "./../../assets/logoVariations/Primarylogomark_purple_black_white-03.png"
import ExamSideDrawerQuestionNav from './ExamSideDrawerQuestionNav';
import MockExamQuestionTile from './MockExamQuestionTile';
import CountdownTimer from './CountdownTimer';

const drawerWidth = 260;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));




// questionNumber, question, answer, updateUserAnswer, currentUserAnswer, multipleChoiceOptions, grammarQuestionType, comprehensionDescription, comprehensionPassage

  export default function MockExamPage() {
    const [open, setOpen] = useState(false);
    const [isLoadingMockExam, setIsLoadingMockExam] = useState(true)
    const [mockExamSessionTracker, setMockExamSessionTracker] = useState(null)
    const [currentMockExam, setCurrentMockExam] = useState(null)
    const [numberOfQuestions, setNumberOfQuestions] = useState(0)
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
    const [mockExamQuestionsList, setMockExamQuestionsList] = useState([])
    // A hashmap with the question number as the key and the answer as the value
    const [currentUserAnswers, setCurrentUserAnswers] = useState({})
    const [timerExpired, setTimerExpired] = useState(false);
    const [endTime, setEndTime] = useState(null)
    const [startTimeOnQuestion, setStartTimeOnQuestion] = useState(new Date());

    let history = useHistory()


// Want to store time per question - could store in state but would zero on refresh 
// Could store in cookies - would disaapear on hard refresh 
// Need a way for it to properly persist state 


    useEffect(() => {
      console.log("In use effect")
      if (isLoadingMockExam) {
        getMockExamPaper()
      }
    });

    const handleDrawerOpen = () => {
      setOpen(true);
    };
  
    const handleDrawerClose = () => {
      setOpen(false);
    };

  function handleTimeExpired() {
    setTimerExpired(true);
    QuestionsAndUserDataService.endMockExam(mockExamSessionTracker.mockExamSessionTrackerCompositeId, mockExamSessionTracker.currentMockExam.mockExamId).then(result => {
      const currentStage = result.data.currentMockExamStage
      if (currentStage === "STAGE_BREAK_TIME") {
        history.push(CONSTANTS.MockExamBreakPage)
      }
      if (currentStage === "STAGE_EXAM_SESSION_COMPLETE") {
        history.push(CONSTANTS.MockExamSessionCompletePage)
      }
      if (currentStage === "STAGE_SESSION_NOT_STARTED") {
        history.push(CONSTANTS.MockExamDashboard)
      }
    })
  }

  function updateCurrentQuestionIndex(currentQuestionNumber, newIndex) {
    // On changing the index, need to update time for that question
    // Make api call to send time and reset to fresh time 
    // updateTimeSpentOnMockExamQuestion(userId, mockExamPaperId, mockExamQuestionId, amountOfTimeSpentOnQuestion)
    let timeSpentOnQuestion = Math.abs(new Date() - startTimeOnQuestion) / 1000;

    console.log("Setting new index to: " + newIndex)
    console.log(isLoadingMockExam)
    const question = getQuestion((currentQuestionNumber-1))

    QuestionsAndUserDataService.updateTimeSpentOnMockExamQuestion(mockExamSessionTracker.mockExamSessionTrackerCompositeId.mockExamUserId, mockExamSessionTracker.currentMockExam.mockExamId, question.mockExamQuestionId, timeSpentOnQuestion)
    // .then(
    //   setStartTimeOnQuestion(new Date())
    //   setCurrentQuestionIndex(newIndex)
    //   )

    setCurrentQuestionIndex(newIndex)
    setStartTimeOnQuestion(new Date())
  }

  function getMockExamPaper() {
    QuestionsAndUserDataService.createMockExamSessionTracker().then(result => {
      console.log("Checking if currently mock exam time")
      console.log(result.data)

      

      if (result.data == "") {
        console.log("No data received, sending to starting instructions page")
        history.push(CONSTANTS.MockExamDashboard)
        return
      } else {
        // Get mock exam 
        const currentStage = result.data.currentMockExamStage
        console.log("Current stage")
        console.log(currentStage)
        if (currentStage === "STAGE_BREAK_TIME") {
          console.log("Sending to break page")
          history.push(CONSTANTS.MockExamBreakPage)
          return
        }                    
        if (currentStage === "STAGE_EXAM_SESSION_COMPLETE") {
          console.log("Sending to session complete page")
          history.push(CONSTANTS.MockExamSessionCompletePage)
          return
        }
        if (currentStage === "STAGE_SESSION_NOT_STARTED") {
          console.log("Sending back to dashboard")
          history.push(CONSTANTS.MockExamDashboard)
          return
        }
        // If student gets to this page too early before exam start somehow - then send them back and set tracker to session not started
  

        setMockExamSessionTracker(result.data)
        setCurrentMockExam(result.data.currentMockExam)
        var mockExamSections = result.data.currentMockExam.mockExamSections
        countQuestionsFromAllSections(mockExamSections)
        populateMockExamQuestionsList(mockExamSections)
        // Passes a set with one one element - that element being the set of answers for this exam if exists (would exists if the user had already started the exam and was refreshing the page)
        // populateCurrentlyAnsweredQuestions(result.data.currentMockExam.mockExamUserAnswerSets)

        console.log("Mock exam loaded:")
        console.log(isLoadingMockExam)

        var startTime = result.data.currentExamStartTime
        var endTime = result.data.currentExamEndTime

        var backEndCurrentTime = result.data.currentBackEndTime
        var frontEndCurrentTime = new Date()

        var backEndFrontEndTimeDiff = calculateBackEndFrontEndTimeDiffInMinutes(backEndCurrentTime, frontEndCurrentTime)
        console.log("Back end current time: " + backEndCurrentTime)
        console.log("Front end current time: " + frontEndCurrentTime)
        console.log("Difference between them: " + backEndFrontEndTimeDiff)

        // var difference = timeDiff(startTime, endTime)
        var timeLeftInCurrentSection = timeDiff(backEndCurrentTime, endTime)
        var valueInMilliseconds = timeLeftInCurrentSection.hours * 1000 * 60 * 60 + timeLeftInCurrentSection.minutes * 1000 * 60 + timeLeftInCurrentSection.seconds * 1000

        console.log("Time left in current section")
        console.log(timeLeftInCurrentSection)

        // console.log("Time Difference")
        // console.log(difference)

        // var startTime = result.data.currentExamStartTime.split(":")
        // var endTime = result.data.currentExamEndTime.split(":")
    
        // var newStartTime = new Date();
        // // var newEndTime = new Date();

        // newStartTime.setHours(startTime[0])
        // newStartTime.setMinutes(startTime[1])
        // newStartTime.setSeconds(startTime[2])

        // newEndTime.setHours(endTime[0])
        // newEndTime.setMinutes(endTime[1])
        // newEndTime.setSeconds(endTime[2])
        
        // var endTime = addMinutes(newStartTime, result.data.currentMockExam.examDurationInMinutes + backEndFrontEndTimeDiff)

        // console.log("Current exam start time: " + startTime)
        // console.log("Current exam end time: " + endTime)
        // console.log("Time being added to the mock exam count down counter: " + endTime - new Date())
        // setEndTime(endTime)
        setEndTime(valueInMilliseconds)
        setIsLoadingMockExam(false)

      }
      
    })
  }

  // function populateCurrentlyAnsweredQuestions(mockExamUserAnswerSets) {
  //   if ()
  // }

  function calculateBackEndFrontEndTimeDiffInMinutes(backEndCurrentTime, frontEndTime) {
    const [hours, minutes, seconds] = backEndCurrentTime.split(":");
    // const date = new Date(backendTime);
    const frontEndTimeHours = frontEndTime.getHours();
    const frontEndTimeMinutes = frontEndTime.getMinutes();
    const frontEndTimeSeconds = frontEndTime.getSeconds();
  
    const timeInMinutes = parseInt(hours) * 60 + parseInt(minutes) + parseInt(seconds) / 60;
    const frontEndTimeInMinutes = frontEndTimeHours * 60 + frontEndTimeMinutes + frontEndTimeSeconds / 60;
  
    const differenceInMinutes = frontEndTimeInMinutes - timeInMinutes;

    if (differenceInMinutes < 1) {
      return 0
    } else {
      return differenceInMinutes;
    }
  }
  

  function addMinutes(time, minutes) {

    console.log("In add minutes")
    console.log("Adding " + minutes + " to " + time)

    var mins = time.getMinutes() + minutes
    var hrs = time.getHours()

    while (mins > 60) {
      mins = mins - 60
      hrs = hrs + 1
    }

    time.setMinutes(mins)
    time.setHours(hrs)
  
    // Return the new time
    console.log("New total time: " + time)
    return time;
  }

  function timeDiff(start, end) {
    const [startHours, startMinutes, startSeconds] = start.split(':').map(Number);
    const [endHours, endMinutes, endSeconds] = end.split(':').map(Number);
  
    let diffHours = endHours - startHours;
    let diffMinutes = endMinutes - startMinutes;
    let diffSeconds = endSeconds - startSeconds;
  
    if (diffSeconds < 0) {
      diffSeconds += 60;
      diffMinutes--;
    }
  
    if (diffMinutes < 0) {
      diffMinutes += 60;
      diffHours--;
    }
  
    return { hours: diffHours, minutes: diffMinutes, seconds: diffSeconds };
  }
  
  
  function countQuestionsFromAllSections(mockExamSections) {
    var numQuestions = 0
    console.log("Sections List: ")
    console.log(mockExamSections)
    for (var i = 0; i < mockExamSections.length; i ++ ) {
      console.log(mockExamSections[i])
      console.log(mockExamSections[i].mockExamQuestions.length)
      numQuestions += mockExamSections[i].mockExamQuestions.length
    }
    setNumberOfQuestions(numQuestions)
  }
  
  function populateMockExamQuestionsList(mockExamSections) {
    console.log("Populating mock exam question list")
    var questionNumber = 1
    var currentUserAnswers = {}
    var listOfQuestions = []
    for (var i = 0; i < mockExamSections.length; i ++ ) {
      console.log(mockExamSections[i])
      console.log(mockExamSections[i].mockExamQuestions.length)
      for (var j = 0; j < mockExamSections[i].mockExamQuestions.length; j ++) {
        console.log("Question data")
        console.log(mockExamSections[i].mockExamQuestions[j])
        listOfQuestions.push(mockExamSections[i].mockExamQuestions[j])
        currentUserAnswers[questionNumber] = ""
        questionNumber += 1
      }
    }

    listOfQuestions.sort((a,b) => a.mockExamQuestionNumber - b.mockExamQuestionNumber)
    listOfQuestions = normalizeList(listOfQuestions)
    console.log("Sorted questions")
    console.log(listOfQuestions)
    setMockExamQuestionsList(listOfQuestions)
    // check if we already have an answer set
    console.log("Checking for mock exam session tracker")
    if (mockExamSessionTracker) {
      console.log("Tracker exists, checking for mock exam answer sets in current mock exam")
      if (mockExamSessionTracker.currentMockExam.mockExamUserAnswerSets) {
        var allAnswerSets = mockExamSessionTracker.currentMockExam.mockExamUserAnswerSets
        // extensive error checking to ensure this exists
        if (allAnswerSets.length == 1) {
          var answerSet = allAnswerSets[0]
          console.log("Current answer set saved in back end")
          console.log(answerSet)
          // converting to format to save in current user answers
          var currentUserAnswers = {}
          for (var i = 0; i < answerSet.mockExamAnswers.length; i++) {
            var questionNumber = getQuestionNumberFromMockExamQuestionId(answerSet.mockExamAnswers[i].mockExamQuestionsCompositeId.mockExamQuestionId)
            var userAnswer = answerSet.mockExamAnswers[i].userAnswer
            currentUserAnswers[questionNumber] = userAnswer
          }

        }
        setCurrentUserAnswers(currentUserAnswers)
      }
    }
  }

  function getQuestionNumberFromMockExamQuestionId(mockExamQuestionId) {
    for (var i = 0; i < mockExamQuestionsList.length; i++) {
      if (mockExamQuestionsList[i].mockExamQuestionId === mockExamQuestionId) {
        return mockExamQuestionsList[i].mockExamQuestionNumber
      }
    }
    return 0;
  }

  // Ensure all question numbers increase by one and we don't skip any numbers or have repeats of any question number
  function normalizeList(list) {
    let i = 0;
    while (i < list.length) {
      const current = list[i];
      const next = list[i + 1];
      if (!next) break;
      if (next.mockExamQuestionNumber - current.mockExamQuestionNumber === 1) {
        i++;
      } else if (next.mockExamQuestionNumber - current.mockExamQuestionNumber > 1) {
        const gap = next.mockExamQuestionNumber - current.mockExamQuestionNumber;
        for (let j = i + 1; j < list.length; j++) {
          list[j].mockExamQuestionNumber -= gap - 1;
        }
        continue;
      } else {
        const randomIndex = Math.floor(Math.random() * 2);
        const chosen = [current, next][randomIndex];
        const other = [current, next][1 - randomIndex];
        chosen.mockExamQuestionNumber += 1;
        for (let j = i + 1; j < list.length; j++) {
          if (list[j].mockExamQuestionNumber === chosen.mockExamQuestionNumber) {
            list[j].mockExamQuestionNumber += 1;
          }
          if (list[j].mockExamQuestionNumber > chosen.mockExamQuestionNumber) {
            list[j].mockExamQuestionNumber -= 1;
          }
        }
        i++;
      }
    }
    return list;
  }

  function getQuestion(questionIndex) {
    var targetQuestionNumber = questionIndex + 1

    for (var i = 0; i < mockExamQuestionsList.length; i ++) {
      console.log("Mock exam question number")
      console.log(mockExamQuestionsList[i].mockExamQuestionNumber)
      console.log("Target number: " + targetQuestionNumber)
      console.log(mockExamQuestionsList[i].mockExamQuestionNumber  == targetQuestionNumber)
      if (mockExamQuestionsList[i].mockExamQuestionNumber == targetQuestionNumber) {
        console.log("Returning Question")
        console.log(mockExamQuestionsList[i])
        return (mockExamQuestionsList[i])
      }
    }
    console.log("Question not found")
  }

//   function createMockExamQuestionDataStructure(question) {

//     var correctAnswer = question.correctAnswer
//     var imageMultipleChoiceOptions = question.imageMultipleChoiceOptions
//     var mockExamQuestionId = question.mockExamQuestionId
//     var mockExamQuestionImage = question.mockExamQuestionImage
//     var mockExamQuestionNumber = question.mockExamQuestionNumber
//     var mockExamQuestionText = question.mockExamQuestionText
//     var mockExamSection = question.mockExamSection
//     var textMultipleChoiceOptions = question.textMultipleChoiceOptions

//     return {correctAnswer, imageMultipleChoiceOptions, mockExamQuestionId, mockExamQuestionImage, mockExamQuestionNumber, mockExamQuestionText, mockExamSection, textMultipleChoiceOptions}
// }

  function getSection(questionIndex) {
    var targetQuestionNumber = questionIndex + 1
    var mockExamSections = currentMockExam.mockExamSections

    for (var i = 0; i < mockExamSections.length; i ++ ) {
      for (var j = 0; j < mockExamSections[i].mockExamQuestions.length; j ++) {
        if (mockExamSections[i].mockExamQuestions[j].mockExamQuestionNumber == targetQuestionNumber) {
          console.log("Returning section info")
          console.log(mockExamSections[i])
          return mockExamSections[i];
        }
      }
    }
    console.log("ERROR - Unable to find section")
  }

  function submitAndGoToNextQuestion(questionNumber, userChoice) {
    console.log("Question number: " +  questionNumber)
    console.log("Start time on question: " + startTimeOnQuestion)
    console.log("Current time: " + new Date())
    let timeSpentOnQuestion = Math.abs(new Date() - startTimeOnQuestion) / 1000;
    console.log("Time spent on question (seconds): " + timeSpentOnQuestion)

    
    // Updating answer
    const userAnswer = userChoice[1]
    var updatedUserAnswers = currentUserAnswers
    updatedUserAnswers[questionNumber] = userChoice[1]
    setCurrentUserAnswers(updatedUserAnswers)


    // Moving to next question 
    console.log("Question numbers info")
    console.log(questionNumber)
    console.log(numberOfQuestions)
    if (questionNumber < numberOfQuestions) {
      console.log("Updating question index")
      setCurrentQuestionIndex(questionNumber)
    }
    const question = getQuestion((questionNumber-1))
    QuestionsAndUserDataService.updateMockExamSessionTrackerWithUserAnswer(mockExamSessionTracker.mockExamSessionTrackerCompositeId.mockExamUserId, mockExamSessionTracker.currentMockExam.mockExamId, question.mockExamQuestionId, userAnswer, timeSpentOnQuestion).then(setStartTimeOnQuestion(new Date()))
  }

   


    return (
      <>
        <AppBar position="fixed" open={open} style={{backgroundColor: "purple"}}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>

              <img alt="11plus-Whizzdom-logo" style={{height: '60px'}} src={whiteLogo} onClick={handleDrawerClose}></img>
             
          <div className="w-full mx-autp flex justify-end md:flex-nowrap flex-wrap md:px-10 px-4" >
            <div>
              <div>
                {(!timerExpired && !isLoadingMockExam) ? (
                  <CountdownTimer 
                    targetTime={endTime} 
                    handleTimeExpired={handleTimeExpired}
                  />
                ) : (
                  <div>Loading ...</div>
                )}
              </div>
            </div>
          </div>
          </Toolbar>
        </AppBar>

        { isLoadingMockExam ? <div> Loading Mock Exam </div> : 
        
        <div>
          <ExamSideDrawerQuestionNav 
            drawerStatus={open} 
            handleDrawerClose={handleDrawerClose} 
            numberOfQuestions={numberOfQuestions}
            currentQuestionNumber={(currentQuestionIndex + 1)}
            updateCurrentQuestionIndex={updateCurrentQuestionIndex}
          />
          
          <MockExamQuestionTile
            question={getQuestion(currentQuestionIndex)}
            section={getSection(currentQuestionIndex)}
            submitAndGoToNextQuestion={submitAndGoToNextQuestion}
            currentUserAnswer={currentUserAnswers[(currentQuestionIndex + 1)]}
          />
        </div>
        
        }
      
      </>
    )
  }