import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';


import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import QuestionsAndUserDataService from '../../../services/QuestionsAndUserDataService'


// string constants


export default function EditQuestionInMockExamSectionForm({mockExamQuestionId}) {

  // let history = useHistory();
  // const [questionImage, setQuestionImage] = useState()
  // const [answerChoicesImage, setAnswerChoicesImage] = useState()
  let formData = new FormData()


  const validationSchema = yup.object({
    questionNumber: yup
      .number("Question Number")
      .required("Question Number Required"),
    questionText: yup
      .string('Enter the question'),
    multipleChoiceOptions: yup
      .string('Enter any student instructions for this section'),
    correctAnswer: yup
      .string("Enter any data for the table"),
    explanation: yup
      .string("Enter a solution for the question"),
  });


  // const WithMaterialUI = () => {
    const formik = useFormik({
      initialValues: {
        questionNumber: 1,
        questionText: '',
        multipleChoiceOptions: '',
        correctAnswer: '',
        explanation:'',
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {

        console.log("Submitting mock exam form")
        console.log("formik values")
        console.log(formik.values.questionText)
        console.log(formik.values.multipleChoiceOptions)

        formData.append("mockExamQuestionId", mockExamQuestionId)
        formData.append("questionNumber", formik.values.questionNumber)
        formData.append("questionText", formik.values.questionText)
        formData.append("multipleChoiceOptions", formik.values.multipleChoiceOptions)
        formData.append("correctAnswer", formik.values.correctAnswer)
        formData.append("Explanation", formik.values.explanation)

        
        QuestionsAndUserDataService.editQuestionInSection(
          mockExamQuestionId,
          formik.values.questionNumber, 
          formik.values.questionText, 
          formik.values.multipleChoiceOptions, 
          formik.values.correctAnswer,
          formik.values.explanation)
          .then((result) => {console.log(result)})
      },
    });

  return (
    
    <div>
     <form
        onSubmit={formik.handleSubmit}
        >
         <label>Question Details</label>

         <div className="relative w-full mb-3">

          <TextField
              fullWidth
              id="questionNumber"
              name="questionNumber"
              label="Question Number"
              value={formik.values.questionNumber}
              onChange={formik.handleChange}
              error={formik.touched.questionNumber && Boolean(formik.errors.questionNumber)}
              helperText={formik.touched.questionNumber && formik.errors.questionNumber}
            />

          </div>

        <div className="relative w-full mb-3">
        <TextField
            fullWidth
            id="questionText"
            name="questionText"
            label="Question Text"
            value={formik.values.questionText}
            onChange={formik.handleChange}
            error={formik.touched.questionText && Boolean(formik.errors.questionText)}
            helperText={formik.touched.questionText && formik.errors.questionText}
          />
        </div>


        <div className="relative w-full mb-3">
        <TextField
            fullWidth
            id="multipleChoiceOptions"
            name="multipleChoiceOptions"
            label="Answer Options (|| separate options)"
            value={formik.values.multipleChoiceOptions}
            onChange={formik.handleChange}
            error={formik.touched.multipleChoiceOptions && Boolean(formik.errors.multipleChoiceOptions)}
            helperText={formik.touched.multipleChoiceOptions && formik.errors.multipleChoiceOptions}
          />
        </div>


        {/* Need to add image for section, any table data,  */}

        
        <div className="relative w-full mb-3">
        <TextField
            fullWidth
            id="correctAnswer"
            name="correctAnswer"
            label="Correct Answer"
            value={formik.values.correctAnswer}
            onChange={formik.handleChange}
            error={formik.touched.correctAnswer && Boolean(formik.errors.correctAnswer)}
            helperText={formik.touched.correctAnswer && formik.errors.correctAnswer}
          />
        </div>

        <div className="relative w-full mb-3">
        <TextField
            fullWidth
            id="explanation"
            name="explanation"
            label="Explanation/Solution"
            value={formik.values.explanation}
            onChange={formik.handleChange}
            error={formik.touched.explanation && Boolean(formik.errors.explanation)}
            helperText={formik.touched.explanation && formik.errors.explanation}
          />
        </div>


        <div className="text-center mt-6">
          <Button
            colour="primary"
            className="bg-blueGray-800 active:bg-blueGray-600 uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
            type="submit"
          >
            Add Question
          </Button>
        </div>
      </form>
    </div>
  );
}
