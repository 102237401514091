import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";
import * as CONSTANTS from "./StringConstants.js"

// layouts

import User from "layouts/User.js";
import Auth from "layouts/Auth.js";
import Teacher from "layouts/Teacher";

// views without layouts
import Landing from "views/Landing.js";
import MockExamSignUp from "./MockExamLandingPage/MockExamSignUp"
import MockExamUser from "layouts/MockExamUser";
import StandardProduct from "./StandardProductLandingPage/StandardUserSignUp";
import TLHStaffRoutes from "theLearningHub/layouts/TLHStaffRoutes.js";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      {/* add routes with layouts */}
      {/* 11plus mock exams route should be changed but already being used in advertising */}
      <Route path={CONSTANTS.MockExamsConversionPageRoute} component={MockExamSignUp} />
      <Route path={CONSTANTS.UnlimitedLearningConversionPageRoute} component={StandardProduct} />
      <Route path="/app" component={User} />
      <Route path="/auth" component={Auth} />
      <Route path="/teacher" component={Teacher}/>
      <Route path="/mock-exams" component={MockExamUser}/>
      <Route path="/tlh" component={TLHStaffRoutes}/>
      {/* add routes without layouts */}
      <Route path="/landing" exact component={Landing} />
      {/* <Route path="/profile" exact component={Profile} /> */}
      <Route path="/" exact component={Landing} />
      {/* add redirect for first page */}
      <Redirect from="*" to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
