import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';


import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';



export default function AddTableDataToMockExamSection({mockExamSectionId}) {


  const [fileImage, setFileImage] = useState()
  const [examSubject, setExamSubject] = useState("");   

  const handleChange = (event) => {
    setExamSubject(event.target.value);
  };


  const validationSchema = yup.object({
    sectionTitle: yup
      .string('Enter your the title of the section')
      .required('A section title is required for the exam'),
    sectionInstructions: yup
      .string('Enter any student instructions for this section'),
    sectionDuration: yup
      .number('Enter the length of the exam')
      .required('A duration is required for the exam set'),
    tableData: yup
      .string("Enter any data for the table"),
    exampleInstructions: yup
      .string("Enter any instructions for the example")
  });


  // const WithMaterialUI = () => {
    const formik = useFormik({
      initialValues: {
        sectionTitle: '',
        sectionInstructions: '',
        sectionDuration: 0,
        tableData: ''
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {

        console.log("Submitting mock exam form")
        console.log("formik values")
        console.log(formik.values.sectionTitle)
        console.log(formik.values.sectionInstructions)
        console.log(formik.values.sectionDuration)
        console.log(examSubject)

    

        // var sectionImageFileStream = new FileReader()
        // sectionImageFileStream.onload = function(){alert(sectionImageFileStream.result);};
        // sectionImageFileStream.readAsBinaryString(fileImage)

        // var exampleImageStream = new FileReader()

        // exampleImageStream.readAsArrayBuffer(fileImage)
        // var streamDone = false

        console.log("Changing to stream")

        const formData = new FormData()
        formData.append('file', fileImage)
        for(var pair of formData.entries()) {
          console.log(pair[0]+', '+pair[1]);
        }
        console.log(fileImage)



        // var imageBlob = new Blob(formData.)

        // QuestionsAndUserDataService.addSectionToMockExam(mockExamId, formik.values.sectionTitle, formik.values.sectionInstructions, formik.values.sectionDuration, formik.values.tableData, formik.values.exampleInstructions, examSubject)
        // .then(res => {
        //   console.log("response")
        //   console.log(res)
        // } )


        // input.addEventListener('change', (e) => {
        //   reader.readAsArrayBuffer(e.target.files[0]);
        //   reader.onloadend = (evt) => {
        //     if (evt.target.readyState === FileReader.DONE) {
        //       const arrayBuffer = evt.target.result,
        //         array = new Uint8Array(arrayBuffer);
        //       for (const a of array) {
        //         fileByteArray.push(a);
        //       }
        //       console.log(fileByteArray)
        //       QuestionsAndUserDataService.addSectionToMockExam(mockExamId, formik.values.sectionTitle, formik.values.sectionInstructions, formik.values.sectionDuration, fileByteArray, formik.values.tableData, null, formik.values.exampleInstructions, examSubject)

        //     }
        //   }
        // })



        // exampleImageStream.onload = function(){alert(exampleImageStream.result);};
        // exampleImageStream.readAsBinaryString(exampleImage)

        // console.log(sectionImageFileStream)
        // console.log(sectionImageFileStream.result)
        // console.log(exampleImageStream)

        // var reader = new FileReader();
        // var fileByteArray = [];
        // reader.readAsArrayBuffer(fileImage);
        // reader.onloadend = function (evt) {
        //     if (evt.target.readyState == FileReader.DONE) {
        //       var arrayBuffer = evt.target.result,
        //           array = new Uint8Array(arrayBuffer);
        //       for (var i = 0; i < array.length; i++) {
        //           fileByteArray.push(array[i]);
        //         }
        //     }
        // }
        // console.log(fileImage)

        // var reader = new FileReader();
        // var binaryStringAct = null
        // reader.onload = function() {
      
        //   var arrayBuffer = this.result,
        //     array = new Uint8Array(arrayBuffer),
        //     binaryString = String.fromCharCode.apply(null, array);

          
        //   binaryStringAct = binaryString
        //   console.lo
        //   console.log(binaryString);
      
        // }
        // reader.readAsArrayBuffer(fileImage);

        // console.log("Array Buffer")
        // console.log(binaryStringAct)

        // QuestionsAndUserDataService.addSectionToMockExam(mockExamId, formik.values.sectionTitle, formik.values.sectionInstructions, formik.values.sectionDuration, fileImage, formik.values.tableData, exampleImageStream, formik.values.exampleInstructions, examSubject)

        // QuestionsAndUserDataService.addMockExamToSet(mockExamSetId, formik.values.sectionTitle, formik.values.sectionDuration)

        // QuestionsAndUserDataService.addMockExamSet(formik.values.sectionTitle, formik.values.sectionDuration, formattedTime, examType).then(result => {
        //   console.log(result)
        // })
        // updateUserDetails(formik.values.sectionTitle, formik.values.sectionDuration, formik.values.time).then(onStepChange(2))

      },
    });

  return (
    
    <div>
     <form
        onSubmit={formik.handleSubmit}
        >
         <label>Section Details</label>
        <div className="relative w-full mb-3">
        <TextField
            fullWidth
            id="sectionTitle"
            name="sectionTitle"
            label="Section title"
            value={formik.values.sectionTitle}
            onChange={formik.handleChange}
            error={formik.touched.sectionTitle && Boolean(formik.errors.sectionTitle)}
            helperText={formik.touched.sectionTitle && formik.errors.sectionTitle}
          />
        </div>

        <div className="relative w-full mb-3">
        <TextField
            fullWidth
            id="sectionInstructions"
            name="sectionInstructions"
            label="Section Instructions"
            value={formik.values.sectionInstructions}
            onChange={formik.handleChange}
            error={formik.touched.sectionInstructions && Boolean(formik.errors.sectionInstructions)}
            helperText={formik.touched.sectionInstructions && formik.errors.sectionInstructions}
          />
        </div>


        <div className="relative w-full mb-3">

          <TextField
              fullWidth
              id="sectionDuration"
              name="sectionDuration"
              label="Section Duration"
              value={formik.values.sectionDuration}
              onChange={formik.handleChange}
              error={formik.touched.sectionDuration && Boolean(formik.errors.sectionDuration)}
              helperText={formik.touched.sectionDuration && formik.errors.sectionDuration}
            />

          </div>

        {/* Need to add image for section, any table data,  */}

        {/* <div className="relative w-full mb-3">
          <Typography className="py-2">Section Image</Typography>
          <input id="file" name="file" type="file" 
              onChange={(event) => {
                setFileImage(event.currentTarget.files[0]);
              }} 
              className="form-control" />
        </div> */}

        <div className="relative w-full mb-3">
        <TextField
            fullWidth
            id="tableData"
            name="tableData"
            label="Table Data"
            value={formik.values.tableData}
            onChange={formik.handleChange}
            error={formik.touched.tableData && Boolean(formik.errors.tableData)}
            helperText={formik.touched.tableData && formik.errors.tableData}
          />
        </div>

        {/* <div className="relative w-full mb-3">
          <Typography className="py-2">Example Image</Typography>
          <input id="exampleImage" name="exampleImage" type="file" 
              onChange={(event) => {
                setExampleImage(event.currentTarget.files[1]);
              }} 
              className="form-control" />
        </div> */}
        
        


        <div className="relative w-full mb-3">
        <TextField
            fullWidth
            id="exampleInstructions"
            name="exampleInstructions"
            label="Example Instructions"
            value={formik.values.exampleInstructions}
            onChange={formik.handleChange}
            error={formik.touched.exampleInstructions && Boolean(formik.errors.exampleInstructions)}
            helperText={formik.touched.exampleInstructions && formik.errors.exampleInstructions}
          />
        </div>

        <Box style={{textAlign:"center"}}>
          <InputLabel id="examSubject">Subject</InputLabel>
          <Select
            labelId="examSubject"
            id=""
            // value={examType}
            autoWidth
            label="examSubject"
            onChange={handleChange}
            className="px-2"
          >
              <MenuItem value="MATHS">Maths</MenuItem>
              <br></br>
              <MenuItem value="ENGLISH">English</MenuItem>
              <br></br>

              <MenuItem value="VR">Verbal reasoning</MenuItem>
              <br></br>
              <MenuItem value="NVR">Non Verbal Reasoning</MenuItem>
              <br></br>
          </Select>

        </Box>

        <div className="text-center mt-6">
          <Button
            colour="primary"
            className="bg-blueGray-800 active:bg-blueGray-600 uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
            type="submit"
          >
            Add Section
          </Button>
        </div>
      </form>
    </div>
  );
}
