import React, { useState } from 'react';
import "../../assets/styles/index.css";
import { Link } from "react-router-dom";

import CreateHeadTeacherForm from 'views/admin/CreateHeadTeacherForm.js';
import CreateMockExamUserForm from 'views/admin/CreateMockExamUserForm.js';
import * as CONSTANTS from "../../StringConstants.js";
import CreateMockExamSetForm from './AdminComponents/CreateMockExamSetForm';
import CreateMockExamSessionForm from './AdminComponents/CreateMockExamSessionForm';


// Material UI imports

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { CardActionArea } from '@mui/material';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CreateAdminUserForm from './CreateAdminUserForm';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


export default function AdminMainDashboard({changeScreen}) {

  const [currentNumberOfSchools, setCurrentNumberOfSchools] = useState(0)
  const [currentNumberOfSchoolStudents, setCurrentNumberOfSchoolStudents] = useState(0)
  const [currentNumberOfIndividualUsers, setCurrentNumberOfIndividualUsers] = useState(0)


  var maxCardWidth = 345
  var minCardHeight = 200


  // Need cards for creating admins, schools/head teacher, teacher 
  // Need area to view specific data for schools - and then teachers or students 
  //Initial dashboard show - total number of schools, total number of school students, total number of individual users

//  function showSchools() {
// // will have state change approach to navigation - fewer routes to protect - adding the new users can be done via modal form approach  
//     changeScreen("school-view")
//  }


  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [mockExamOpen, setMockExamOpen] = React.useState(false);
  const handleMockExamOpen = () => setMockExamOpen(true);
  const handleMockExamClose = () => setMockExamOpen(false);
  
  const [mockExamSessionOpen, setMockExamSessionOpen] = React.useState(false);
  const handleMockExamSessionOpen = () => setMockExamSessionOpen(true);
  const handleMockExamSessionClose = () => setMockExamSessionOpen(false);

  const [createMockExamUserOpen, setCreateMockExamUserOpen] = React.useState(false);
  const handleCreateMockExamUserOpen = () => setCreateMockExamUserOpen(true);
  const handleCreateMockExamUserClose = () => setCreateMockExamUserOpen(false);

  const [createAdminUserOpen, setCreateAdminUserOpen] = React.useState(false);
  const handleCreateAdminUserOpen = () => setCreateAdminUserOpen(true);
  const handleCreateAdminUserClose = () => setCreateAdminUserOpen(false);

  return (
    
    <div>
        <Typography gutterBottom variant="h2" component="div" textAlign={"center"}>
          Welcome back
        </Typography>

        <Typography gutterBottom variant="h5" component="div" textAlign={"center"}>
            Current metrics
        </Typography>

        <Grid container style={{justifyContent: "center"}}>
          <Grid item xs={3}>
            <Card sx={{ maxWidth: maxCardWidth, minHeight:minCardHeight }} className="pt-2" >
                <CardActionArea>

                  <div style={{backgroundColor: "white"}}>
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div" textAlign={"center"}>
                        Current Number of schools: {currentNumberOfSchools}
                      </Typography>
                    </CardContent>
                  </div>

                </CardActionArea>

                <div style={{textAlign: "center"}}>
                  <Link to="/app/admin-whizz/schools-view"> View all schools</Link>
                </div>
            </Card>
          </Grid>      


          <Grid item xs={1}>
          </Grid>      

          <Grid item xs={3}>
            <Card sx={{ maxWidth: maxCardWidth, minHeight:minCardHeight }} className="pt-2" >
                <CardActionArea>

                  <div style={{backgroundColor: "white"}}>
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div" textAlign={"center"}>
                        Number of school students: {currentNumberOfSchoolStudents}
                      </Typography>
                    </CardContent>
                  </div>

                </CardActionArea>
            </Card>
          </Grid>      

          <Grid item xs={1}>
          </Grid>    

          <Grid item xs={3}>
            <Card sx={{ maxWidth: maxCardWidth, minHeight:minCardHeight }} className="pt-2" >
                <CardActionArea>

                  <div style={{backgroundColor: "white"}}>
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div" textAlign={"center"}>
                        Current number of indivual students: {currentNumberOfIndividualUsers}
                      </Typography>
                    </CardContent>
                  </div>

                </CardActionArea>
            </Card>
          </Grid>      
        </Grid>

        <br></br>

        <Typography gutterBottom variant="h5" component="div" textAlign={"center"}>
            Add new users
        </Typography>
      

        <Grid container style={{justifyContent: "center"}}>
            <Grid item xs={2}>
            <button onClick={handleCreateAdminUserOpen}>

              <Card sx={{ maxWidth: maxCardWidth, minHeight: minCardHeight}} className="pt-2" >
                  <CardActionArea>

                    <div style={{backgroundColor: "white"}}>
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div" textAlign={"center"}>
                          Create Admin
                        </Typography>
                      </CardContent>
                    </div>

                  </CardActionArea>
              </Card>
              </button>
                <Modal
                open={createAdminUserOpen}
                onClose={handleCreateAdminUserClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <CreateAdminUserForm/>
                </Box>
              </Modal>
            </Grid>      
          <Grid item xs={1}>
          </Grid>      

          <Grid item xs={2}>
            <Button onClick={handleOpen}>
              <Card sx={{ maxWidth: maxCardWidth, minHeight:minCardHeight}} className="pt-2" >
                  <CardActionArea>

                    <div style={{backgroundColor: "white"}}>
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div" textAlign={"center"}>
                          Add new Head teacher
                        </Typography>
                      </CardContent>
                    </div>

                  </CardActionArea>
              </Card>
            </Button>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <CreateHeadTeacherForm isHeadTeacher={true}/>
              </Box>
            </Modal>
          </Grid>      

          <Grid item xs={1}>
          </Grid>       

          <Grid item xs={2}>
            <Button onClick={handleCreateMockExamUserOpen}>
              <Card sx={{ maxWidth: maxCardWidth, minHeight:minCardHeight}} className="pt-2" >
                  <CardActionArea>

                    <div style={{backgroundColor: "white"}}>
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div" textAlign={"center"}>
                          Add new mock exam user
                        </Typography>
                      </CardContent>
                    </div>

                  </CardActionArea>
              </Card>
            </Button>
            <Modal
              open={createMockExamUserOpen}
              onClose={handleMockExamClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <CreateMockExamUserForm/>
              </Box>
            </Modal>
          </Grid>      

          <Grid item xs={1}>
          </Grid>

          <Grid item xs={2}>
          <Link to={CONSTANTS.AdminViewAllUsers}> 
              <Card sx={{ maxWidth: maxCardWidth, minHeight:minCardHeight}} className="pt-2" >
                  <CardActionArea>

                    <div style={{backgroundColor: "white"}}>
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div" textAlign={"center"}>
                          View all current users
                        </Typography>
                      </CardContent>
                    </div>

                  </CardActionArea>
              </Card>
            </Link>
          </Grid>   
        </Grid>
        <br></br>

        <Typography gutterBottom variant="h5" component="div" textAlign={"center"}>
            Mock exams
        </Typography>


        <Grid container style={{justifyContent: "center"}}>
          <Grid item xs={2}>
            <Card sx={{ maxWidth: maxCardWidth, minHeight: minCardHeight}} className="pt-2" >
              <Link to={CONSTANTS.AdminMockExamSessionsView}> 

                <CardActionArea>

                  <div style={{backgroundColor: "white"}}>
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div" textAlign={"center"}>
                        Manage Mock Exam Sessions
                      </Typography>
                    </CardContent>
                  </div>
                </CardActionArea>
              </Link>
            </Card>
          </Grid>      
          <Grid item xs={1}>
          </Grid>  
          
          <Grid item xs={2}>
            <Button onClick={handleMockExamSessionOpen}>

            <Card sx={{ maxWidth: maxCardWidth, minHeight: minCardHeight}} className="pt-2" >

                <CardActionArea>

                  <div style={{backgroundColor: "white"}}>
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div" textAlign={"center"}>
                        Create Mock Exam Session
                      </Typography>
                    </CardContent>
                  </div>
                </CardActionArea>
            </Card>
            </Button>

            <Modal
              open={mockExamSessionOpen}
              onClose={handleMockExamSessionClose}
              aria-labelledby="modal-add-mock-exam"
              aria-describedby="modal-able-to-add-a-set"
            >
              <Box sx={style}>
                <CreateMockExamSessionForm/>
              </Box>
            </Modal>
          </Grid>   

          <Grid item xs={1}>
          </Grid>         

          <Grid item xs={2}>
            <Card sx={{ maxWidth: maxCardWidth, minHeight: minCardHeight}} className="pt-2" >
              <Link to={CONSTANTS.AdminMockExamDashboardRoute}> 

                <CardActionArea>

                  <div style={{backgroundColor: "white"}}>
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div" textAlign={"center"}>
                        Manage Mock Exams
                      </Typography>
                    </CardContent>
                  </div>
                </CardActionArea>
              </Link>
            </Card>
          </Grid>      
          <Grid item xs={1}>
          </Grid>  


          <Grid item xs={2}>
            <Button onClick={handleMockExamOpen}>

            <Card sx={{ maxWidth: maxCardWidth, minHeight: minCardHeight}} className="pt-2" >

                <CardActionArea>

                  <div style={{backgroundColor: "white"}}>
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div" textAlign={"center"}>
                        Create Mock Exam Set
                      </Typography>
                    </CardContent>
                  </div>
                </CardActionArea>
            </Card>
            </Button>

            <Modal
              open={mockExamOpen}
              onClose={handleMockExamClose}
              aria-labelledby="modal-add-mock-exam"
              aria-describedby="modal-able-to-add-a-set"
            >
              <Box sx={style}>
                <CreateMockExamSetForm/>
              </Box>
            </Modal>
          </Grid>    

          <Grid item xs={1}>
          </Grid>         
        </Grid>           


        <Typography gutterBottom variant="h5" component="div" textAlign={"center"}>
            Add new 11plus questions
        </Typography>
      

        <Grid container style={{justifyContent: "center"}}>
          <Grid item xs={3}>
          <Link to={CONSTANTS.AdminAddEnglishQuestion}> 
            <CardActionArea>

              <Card sx={{ maxWidth: maxCardWidth, minHeight: minCardHeight}} className="pt-2" >

                    <div style={{backgroundColor: "white"}}>
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div" textAlign={"center"}>
                        Add English Questions
                        </Typography>
                      </CardContent>
                    </div>

              </Card>
               </CardActionArea>
               </Link>   
            </Grid>      
          <Grid item xs={1}>
          </Grid>          
        </Grid>    
    </div>
  );
}
