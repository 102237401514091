import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useHistory } from "react-router-dom";


import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';

import QuestionsAndUserDataService from '../../services/QuestionsAndUserDataService'


export default function ToggleAccountActivationStatusForm(user) {


  const [isAccountActive, setIsAccountActive] = useState(user.user.isAccountActive)


  const handleChange = (event) => {
    setIsAccountActive(event.target.value);
  };


  const validationSchema = yup.object({
  });

  // const WithMaterialUI = () => {
    const formik = useFormik({
      initialValues: {
        customCheckLogin: false,
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {

        if (isAccountActive === null) {
          console.log("Must have a subscriber type set")
          return 
        }
        console.log(user.user.userId)
        console.log(isAccountActive)
        QuestionsAndUserDataService.toggleAccountActivationFromDashboard(isAccountActive, user.user.userId);
      },
    });

  

  return (
    
    <div>
     <form
        onSubmit={formik.handleSubmit}
        >
         <label> Click to select the new account status</label>
         <br></br>
         <br></br>
         <div>
          <b>Current Status:</b> {isAccountActive ? "Active" : "Inactive"}
          <br></br>
         </div>
     
         <Box style={{textAlign:"center"}}>

          <InputLabel className='mb-4' id="subcriberType">Subscriber Type</InputLabel>
            <Select
              labelId="subcriberType"
              id="subcriberType"
              value={isAccountActive}
              autoWidth
              label="subcriberType"
              onChange={handleChange}
              className="px-2"
            >
                <MenuItem value="TRUE">Active</MenuItem>
                <br></br>
                <MenuItem value="FALSE">Inactive</MenuItem>
            </Select>
          </Box>

        <div className="text-center mt-6">
          <Button
            colour="primary"
            className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
            type="submit"
          >
            Update Account Status
          </Button>
        </div>
      </form>
    </div>
  );
}
