import React, { useState, useEffect } from 'react';
import "../../assets/styles/index.css";

// Material UI imports

import { styled, useTheme } from '@mui/material/styles';
import List from '@mui/material/List';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';

const drawerWidth = 260;


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // backgroundColor: 'purple',
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));


export default function ExamSideDrawerQuestionNav({drawerStatus, handleDrawerClose, numberOfQuestions, currentQuestionNumber, updateCurrentQuestionIndex}) {

  // const [isLoadingMockExams, setIsLoadingMockExams]  = useState(true)
  // const [userMockExams, setUserMockExams] = useState([])
  const theme = useTheme();
  const [open, setOpen] = React.useState(drawerStatus);
  const user = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    if (drawerStatus) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  });

  const handleDrawerOpen = () => {
    // setOpen(true);
  };

  // const handleDrawerClose = () => {
  //   handleDrawerClose
  // };

  function renderQuestionNumbers() {
    var numbers = []
    var numbersArray = []

    for (var i = 1; i <= numberOfQuestions; i ++) {
      numbersArray.push(i)
    }

    return numbersArray.map((number) => {
      const isSelected = currentQuestionNumber == number

      return (
        <ListItem 
         key={i} 
        >
          <button 
            onClick={() => updateCurrentQuestionIndex(currentQuestionNumber, (number-1))}
            className={open ? '' : ( isSelected ? 'closed-column-sidebar-numbers-nav-selected' : 'closed-column-sidebar-numbers-nav')} 
            style={open ? {} : (isSelected ? {padding: "8px", width: "150%", marginLeft: "-7px", paddingInline: "11px"} : {padding: "8px", width: "300%", marginLeft: "-5px", paddingInline: "9px"})}
          >
            {open ? "Question " + number : "Q" + number}
          </button>
        </ListItem>
      )
    })
  }

  return (
    <Drawer variant="permanent" open={open}>
    <DrawerHeader>
      <IconButton onClick={handleDrawerClose}>
        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
      </IconButton>
    </DrawerHeader>
    <Divider />
    <List style={{textAlign: "center"}}>
      {renderQuestionNumbers()}
    </List>
  </Drawer>
  );
}
