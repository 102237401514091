import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";

// Component imports

import Title from "./../AnalyticsComponents/Title.js"
import addNewClass from '../../../src/assets/img/AddClassIcon.png' 

//MUI imports 

import Typography from '@material-ui/core/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import QuestionsAndUserDataService from '../../services/QuestionsAndUserDataService'
import ClassCard from './TeacherComponents/ClassCard.js';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';

// string constants

import * as CONSTANTS from "../../StringConstants.js";

// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 400,
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   p: 4,
// };

export default function ClassesView() {

  let history = useHistory();

  const [isLoadingClassData, setisLoadingClassData] = useState(true)
  const [classData, setClassData] = useState([createDummyData("3", "Class 63B", "234", "12/20")])
//   var classData = []
  var maxCardWidth = 345
  var minCardHeight = 250

  useEffect(() => {
    if (isLoadingClassData) {
        getCurrentTeacherInfo()
    }
  });

 // The function and variable below are currently unused but can be called if dummy data is needed on the front end
 function createDummyData(classId, className, numberOfStudents, numberStudentsCompletedLastAssignment) {
    return {classId, className, numberOfStudents, numberStudentsCompletedLastAssignment}
}

function navigateToCreateNewClass() {
    history.push(CONSTANTS.CreateNewClass);
  }

  function getCurrentTeacherInfo() {
      QuestionsAndUserDataService.getAllClassesDataForTeacher().then(teacherClassesData => {
          console.log("Got current teacher info")
          console.log(teacherClassesData)
          setisLoadingClassData(false)
          setClassData(teacherClassesData.data.classes)
      })
    // QuestionsAndUserDataService.getAllTeacherDataForHeadTeacher().then(teacherData => {
    //   console.log(teacherData)
    //   setisLoadingClassData(false)
    //   setTeacherData(teacherData.data)
    //   if (teacherData.data.school) {
    //     setSchool(teacherData.data.school.schoolName)
    //   }
    // })
  }

  // function getSchool() {
  //   QuestionsAndUserDataService.getSchool()
  //     .then(schoolData => {
  //       console.log("School data")
  //       console.log(schoolData)
  //       if (schoolData) {
  //       //   setSchool(schoolData)
  //       }
  //     })
  // }


  return (
    
    <div className='p-4'>
      <Title>Your classes</Title>
      {isLoadingClassData ? "Loading your classes..." :

        
    <Grid container className='p-4'>

        {classData?.map((schoolClass) => (
            <div className='p-4'> 
                <ClassCard
                    classData={schoolClass}
                />
            </div>
        ))}
        <Grid item xs={3} className='p-4'>

            <Card sx={{ width: maxCardWidth, height:minCardHeight}} >
            {/* width:"345px", height: "200px", padding:"24px", marginTop:"-40px", marginBottom:"-48px" */}
            <CardActionArea onClick={navigateToCreateNewClass}>

            <CardMedia 
                style={{
                    width:"50%", 
                    height: "50%", 
                    marginLeft: "20%", 
                    marginRight: "20%"
                }}
                component="img"
                // height="10"
                // width="10"
                image={addNewClass}
                alt="Add New Class"
            />
            <div style={{backgroundColor: "white"}}>
            <CardContent>
                <div>
                    <Typography gutterBottom 
                        variant="h5" 
                        component="div" 
                        style={{textAlign:"center"}}>
                        Add New Class
                    </Typography>
                </div>
            </CardContent>
            </div>
            </CardActionArea>
            </Card>


          </Grid>      
    </Grid>      
    }

    </div>
  );
}
