import React from 'react';
import "../../assets/styles/index.css"




export default function MockExamQuestionTopicTile({answerOption, onOptionClick, optionNumber, currentAnswerSelectedByUser, shouldBeBoldOption, questionSegmentText}) {

  function updateUserChoice(selection) {
    if (onOptionClick) {
        console.log("selection: " + selection)
        onOptionClick(selection)
      }
    // setSelectedChoice(selection)
  }

      // Handles checks for any subscripts or fractions
  function formatAnswerOptions() {
    // first checking for subscripts
    console.log("Checking if answer option needs super script rendering for metres squared etc..")
    console.log("Answer option: " + answerOption)

    if (answerOption.includes("**")) {
      var answerOptionParts = answerOption.split("**")
      console.log(answerOptionParts)
      return (<>
                {answerOptionParts[0]}
                <sup style={{ fontSize: "small", marginTop: "-0.3em" }}>
                  {answerOptionParts[1]}
                </sup>
                {answerOptionParts[2]}
              </>)
    }
    // Now doing the check for fractions via @@, this function would need to be adjust if rendering fractions with words etc
    if (answerOption.includes("@@")) {
      var answerOptionParts = answerOption.split("@@")
      var fractionParts = answerOptionParts[1].split("/")
      var numerator = fractionParts[0]
      var denominator = fractionParts[1]

      console.log(answerOptionParts)
      console.log(fractionParts)
      return (<>
                {answerOptionParts[0]}
                {renderFraction(numerator, denominator)}
              </>)
    }
    return answerOption
  }

  function renderFraction(num, den) {
    if (num === 0) {
      return "0";
    } else if (den === 0) {
      return "undefined";
    } else {
      let whole = Math.floor(num / den);
      let remainder = num % den;
      if (whole === 0) {
        return <><sup>{num}</sup>/<sub>{den}</sub></>;
      } else if (remainder === 0) {
        return <>{whole}</>;
      } else {
        return (
          <span>
            {whole} <sup>{remainder}</sup>/<sub>{den}</sub>
          </span>
        );
      }
    }
  }


  function intToChar(key) {
    // console.log("Returning letter for: " + key)
    if (key === "") {
      return ""
    }
    const code = 'A'.charCodeAt(0);
    const newLetter = String.fromCharCode(code + key);
    // console.log("Got: " + newLetter)
    return newLetter + ") "
  }

    return (
      <div>
        <button 
          className='english-question-answer-option'
          onClick={() => updateUserChoice(answerOption)} 
          style={{
            borderStyle: "outset",
            fontWeight: shouldBeBoldOption ? "bold" : "normal",
            border: questionSegmentText ? '1px solid black' : 'none'
          }}
        >
          {/* SEGMENT BELOW IS ONLY SET FOR SPELLING AND PUNCTUATION QUESTIONS - OTHERWISE questionSegmentText IS BLANK */}
          <u className='m-4' style={{ display: 'block' }}>{questionSegmentText}</u>
          {intToChar(optionNumber)} {formatAnswerOptions()}
        </button>
      </div>  
    )
}  