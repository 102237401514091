import React, { useState } from 'react';
import "../assets/styles/index.css";

import * as SUBJECTS from '../components/EducationTiles/TileNames';
import SubjectTile from '../components/EducationTiles/SubjectTile';
import TopicTile from '../components/EducationTiles/TopicTile';
import SubTopicTile from '../components/EducationTiles/SubTopicTile';
import QuestionTile from '../components/EducationTiles/QuestionTile';
import QuestionQuantityTile from '../components/EducationTiles/QuestionQuantityTile';
import QuestionsAndUserDataService from '../services/QuestionsAndUserDataService'

// Material UI imports

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@mui/material/FormHelperText';
import NewTeacherWorksheet from './Teacher/TeacherComponents/NewTeacherWorksheet';


// Need to shift values to relative as opposed to hard coded
const useStyles = makeStyles((theme) => ({
  grid: {
    marginTop: 130,
  },

  breadCrumbs: {
    paddingBottom: 20,
  },

  root: {
    display: 'flex',
  },


  title: {
    flexGrow: 1,
  },

  TopicTiles: {
      padding: '100px',
  },

  QuestionNavButtons: {
    padding: '10px',
  }

}));

export default function Dashboard({changeSubject}) {
  const classes = useStyles();

  const [currentSubject, setCurrentSubject] = useState("Maths")
  // To render the correct level
  const [level, setLevel] = useState("topics")
  // To pass the correct number of requested questions
  const [questionRequests, setQuestionRequests] = useState([])
  // To track if amount of questions requested is valid
  const [isValidInputForQuestionAmounts, setIsValidInputForQuestionAmounts] = useState(false)
  // Current question number being shown
  const [questionNumber, setQuestionNumber] = useState(0)

  // Used for showing tiles on each level

  const [currentParentTile, setCurrentParentTile] = useState("")
  const [previousParentTile, setPreviousParentTile] = useState(null)

  // Used for breadcrumb navigation

  // const [currentSubject, setCurrentSubject] = useState("")
  const [currentTopic, setCurrentTopic] = useState("topic")
  const [currentSubTopic, setCurrentSubTopic] = useState("sub-topic")
  const [shownLevels, setShownLevels] = useState(['subjects'])

  // To store questions
  const [currentQuestions, setCurrentQuestions] = useState(null)
  // To store user answers
  const [currentUserAnswers, setCurrentUserAnswers] = useState([])


  var allRequestedQuantities = []
  var allRequestedQuestionTypes = []
  const [finalTopicsList, setFinalTopicsList] = useState([])
  const [finalTopicsQuantitiesList, setFinalTopicsQuantitiesList] = useState([])

  function populateRelevantTiles() {
    // Reset current user answers if leaving worksheet
    // setCurrentUserAnswers([])
    
    if (level === 'subject') {
      return populateSubjectTiles();
    }

    if (level === 'topics') {
      return populateTopicsTiles();
    }
    if (level === 'sub-topics') {
      return populateSubTopicTiles();
    }
    if (level === 'question-choices') {
      return getQuestionTypes();
    }
    // This component has been moved to the schools product so this level may be mostly redundant
    if (level === 'questions') {
      console.log("In the generate questions part")
      return generateQuestions();
    }
    if (level === 'teacher-mode') {
      console.log("In the teacher view for questions")
      return showTeacherWorksheet();
    }
  }

  function showTeacherWorksheet() {
    return <NewTeacherWorksheet questionsData={currentQuestions}/>; 
  }

  function getPractiseTestQuestions() {
    setQuestionNumber(0)
    let parent = "Practice Test"
    QuestionsAndUserDataService.getQuestions(parent, "not needed", ["not needed"], [5,3])
        .then(question => {
          console.log("Completing promise to get questions")
          console.log(question.data)
          setCurrentQuestions(question.data)
          setLevel('questions')
        });
    setQuestionRequests([])
  }

  // function changeToReviewMode(questionsData, questionToGoTo) {
  //   console.log("Reaching dashboard")
  //   console.log(questionsData)
  //   console.log("Question number selected in dashboard page: " + questionToGoTo)

  //   // toggleAnalyticsDashboard(true)

  //   // setQuestionsData(questionsData)
  //   // setQuestionNumber(questionToGoTo)
  //   // toggleIsReviewingWorksheet(true)
  //   // updateQuestionInfoForGoingToReview(questionsData, questionToGoTo)
  // }

  function changeTileLevel(newLevel, currentTitle) {
    // reset user answers when leaving worksheet
    setCurrentUserAnswers([])
    console.log(newLevel)
    console.log(currentTitle)
    console.log("Current level: " + level)

    if (currentTitle === "Practice Test") {
      console.log("Practice test requested")
      getPractiseTestQuestions()
    } else {
      console.log("Level being changed !!!!!!!!!!!!!!!!!")
      if(newLevel === 'topics') {
        console.log("In topics change")
        setShownLevels(['subjects'])
        setPreviousParentTile(null)
        setQuestionRequests([])
        setLevel(newLevel)
        if (currentTitle === "Maths" || currentTitle === "Verbal Reasoning") {
          changeSubject(currentTitle)
        }
        console.log("States updated")

      }
      if (newLevel === 'sub-topics') {
        setShownLevels(['subjects','topics'])
        setCurrentTopic(currentTitle)
        setCurrentSubTopic(currentTitle)
        setPreviousParentTile(null)
        setQuestionRequests([])
      }
      if (newLevel === 'question-choices') {
        setShownLevels(['subjects','topics','sub-topics'])
        setCurrentSubTopic(currentTitle)
        setPreviousParentTile(currentParentTile)
      }
      console.log("Setting new level to: " + newLevel)
      setLevel(newLevel)
      setCurrentParentTile(currentTitle)
    }
  }

  function populateSubjectTiles() {
    var subjects = ["Maths", "Verbal Reasoning"]
    console.log("In populate subject tiles")
    var tiles = []
    for (var i = 0; i < subjects.length; i++) {
      tiles.push
      (<Grid item>
        <SubjectTile
          key={i}
          title={subjects[i]}
          onTileClick={changeTileLevel}
        />
          </Grid>)
     }
    return tiles
  }

  function populateTopicsTiles() {

    var topics = [];
    if (currentSubject === "Maths") {
      topics = Object.keys(SUBJECTS.MathTopics);
    } 

    if (currentSubject === "Verbal Reasoning") {
      topics = Object.keys(SUBJECTS.VRTopics)
    }

    var tiles = [];
    for (var i = 0; i < topics.length; i++) {
        tiles.push
        (<Grid item>
          <TopicTile
            key={i}
            title={topics[i]}
            onTileClick={changeTileLevel}
          />
            </Grid>)
    }
    return tiles
  }

  function populateSubTopicTiles() {
    // Either populate sub topic tiles or the actual input text boxes
    // Can check as final layer keys would be a list of numbers as opposed to strings
    // if (currentTopic == "Maths") {
    //   topics = SUBJECTS.MathTopics[currentParentTile]
    // }
    let hasChildren = Boolean(!(Object.keys(SUBJECTS.MathTopics[currentParentTile])[0] === '0'));
    let subTopics = [];
    console.log("showing sub topics")

    if (hasChildren) {
      subTopics = Object.keys(SUBJECTS.MathTopics[currentParentTile]);
    } else {
      setLevel('question-choices');
      console.log("No children here but we have")
      console.log("Topic: " + currentTopic)
      console.log("Subtopic : " + currentSubTopic)
      return getQuestionTypes();
    }
    var tiles = [];
    for (var i = 0; i < subTopics.length; i++) {
        tiles.push(
        <Grid item>
          <SubTopicTile
            key={i}
            title={subTopics[i]}
            className={classes.TopicTiles}
            onTileClick={changeTileLevel}/>
        </Grid>)
    }
    return tiles
  }
  
  function getQuestionTypes() {
    let questionTypes = [];
    if (!previousParentTile) {
      questionTypes = Object.values(SUBJECTS.MathTopics[currentParentTile])
    } else {
      questionTypes = Object.values(SUBJECTS.MathTopics[previousParentTile][currentParentTile]);
    }
    console.log("In get question types")
    return <>
        <Grid container direction="column">
          <form noValidate autoComplete="off">
            {getQuestionTypesHelper(questionTypes)}
          </form>

            {isValidInputForQuestionAmounts ? 
              <div></div> : <div><FormHelperText error="true">*Value must be between 0 and 20</FormHelperText></div>}
        </Grid>
        <div className="w-full mx-autp flex md:flex-nowrap flex-wrap md:px-10 px-4">
          <button className="num-selection-buttons" onClick={() => changeTileLevel('topics')}>Back to Topics list</button>
          <button className="num-selection-buttons" onClick={() => getQuestions()}>Submit</button>
        </div>

        </>;
  }

  function getQuestionTypesHelper(questions) {
    console.log("In get question types helper")
    var tiles = [];
    for (var i = 0; i < questions.length; i++) {
      tiles.push(
        <Grid item xs={12}>
          <QuestionQuantityTile title={questions[i]} onValueEntered={onValueEntered}/>
        </Grid>
      )}
      return tiles
  }

  function checkQuestionInputsAreValid() {
    console.log("Checking question inputs")
    // iterate through question requests, check if at least one value is greater than zero
    const allRequestedQuantities = Object.values(questionRequests)
    if (allRequestedQuantities.length === 0) {
      console.log("Is false")
      setIsValidInputForQuestionAmounts(false);
    }

    for (let i = 0; i < allRequestedQuantities.length; i++) {
      console.log("Checking: " + allRequestedQuantities[i] )

      if (allRequestedQuantities[i] < 0) {
        setIsValidInputForQuestionAmounts(false)
        // set value here to nothing
        return
      }
    }
    setIsValidInputForQuestionAmounts(true)
    return

  }

  function getQuestions() {
    setQuestionNumber(0)
    console.log(level)
    console.log(Object.keys(questionRequests)[0])
    allRequestedQuestionTypes = Object.keys(questionRequests)
    allRequestedQuantities = Object.values(questionRequests)
    console.log("Set types and quantities:")
    console.log(allRequestedQuantities)
    console.log(allRequestedQuestionTypes)
    setFinalTopicsList(allRequestedQuestionTypes)
    setFinalTopicsQuantitiesList(allRequestedQuantities)


    for (let i = (allRequestedQuantities.length - 1); i >= 0; i--) {
      console.log("Checking: " + allRequestedQuantities[i] )

      if (allRequestedQuantities[i] <= 0 ) {
        allRequestedQuantities.splice(i, 1)
        allRequestedQuestionTypes.splice(i,1)
        console.log("Removed non positive quantity")
      }
    }

    if (allRequestedQuestionTypes.length === 0) {
      console.log("No questions with positive number of selections")
      setIsValidInputForQuestionAmounts(false);
      return
    }

    let parent = previousParentTile ? previousParentTile : "no prev parent tile";
    console.log(parent)
    console.log("Making get request for questions")
    console.log(allRequestedQuantities)
    console.log(allRequestedQuestionTypes)
    QuestionsAndUserDataService.getQuestions(currentSubject, parent, currentParentTile, allRequestedQuestionTypes, allRequestedQuantities)
        .then(question => {
          console.log("Completing promise to get questions")
          console.log(question.data)
          setCurrentQuestions(question.data)
          setLevel('teacher-mode')
        });
      console.log("Completed get request")
      console.log(currentQuestions)
     setQuestionRequests([])
    }


  // Questions already generated and stored in state by this point
  // function is rendering each question one by one.
  // questionNumbers refers to the number of the current question the user is on
  function generateQuestions() {
    //  Want option to check answer? or maybe check answers at end of worksheet.
    console.log("Question being retrieved from state")
    console.log(currentQuestions[questionNumber])

return <Grid container spacing={3}>
              <Grid item xs={12}>
                <QuestionTile
                  questionNumber={questionNumber}
                  question={currentQuestions[questionNumber].question}
                  answer={currentQuestions[questionNumber].answer}
                  updateUserAnswer={updateUserAnswers}
                  currentUserAnswer={currentUserAnswers[questionNumber]}
                />
              </Grid>
              <Grid item xs={12}>
                {getNextAndPrevButtons(currentQuestions.length)}
              </Grid>
            </Grid>;
  }

  // Store the question number in state to trigger re-render on question change (and hence show new question)
  function getNextAndPrevButtons(numberOfQuestions) {
    if ((questionNumber < (numberOfQuestions-1)) && questionNumber >= 1) {
      return <Grid container direction="row"  spacing={10}>
        <Grid className={classes.QuestionNavButtons} justify="space-between" item xs={5}>
          <Button variant="contained" onClick={() => setQuestionNumber(questionNumber-1)}>Prev</Button>
        </Grid>
        {/* <Grid item xs={6}><div></div></Grid> */}
        <Grid className={classes.QuestionNavButtons} justify="space-between" item xs={5}>
          <Button variant="contained" onClick={() => setQuestionNumber(questionNumber+1)}>Submit</Button>
        </Grid>
      </Grid>;
    }

    if (questionNumber >= 1) {
      return <Grid container direction="row"  spacing={10}>
      <Grid className={classes.QuestionNavButtons} justify="space-between" item xs={5}>
        <Button variant="contained" onClick={() => setQuestionNumber(questionNumber-1)}>Prev</Button>
      </Grid>
      <Grid className={classes.QuestionNavButtons} justify="space-between" item xs={5}>
        <Button variant="contained" onClick={() => setLevel("answers")}>View Answers</Button>
      </Grid>
    </Grid>;
    }

    if (numberOfQuestions === 1) {
      return <Grid container direction="row"  spacing={10}>
      <Grid className={classes.QuestionNavButtons} justify="space-between" item xs={5}>
        <Button variant="contained" onClick={() => setLevel("answers")}>View Answer</Button>
      </Grid>
    </Grid>;
    }

    if (questionNumber < (numberOfQuestions-1)) {
      return <Button variant="contained" onClick={() => setQuestionNumber(questionNumber+1)}>Submit</Button>;
    }
  }


  function updateUserAnswers(questionNumber, answer) {
    console.log("Updating user answer details")
    console.log(questionNumber)
    console.log(answer)
    // currentUserAnswers[questionNumber] = answer
    let copy = currentUserAnswers
    copy[questionNumber] = answer
    setCurrentUserAnswers(copy)
    console.log(currentUserAnswers)
  }

  function onValueEntered(question, amount) {
    checkQuestionInputsAreValid()
    if (amount <= 0 || amount > 20) {
      setIsValidInputForQuestionAmounts(false)
      questionRequests[question] = null
      console.log("Value must between 0 and 20")
      return
    }
    console.log("Would add " + amount + " for " + question)
    questionRequests[question] = amount
    console.log(questionRequests);
    // allRequestedQuestionTypes = Object.keys(questionRequests)
    // allRequestedQuantities = Object.values(questionRequests)
  }

  // First param indicates last part of the trail, second param is the current level
  function generateSpecificBreadCrumb(levelLabel, breadCrumbLevel) {
    if (levelLabel) {
      if (level === breadCrumbLevel) {
        console.log("On the tail")
        console.log(level)
        console.log(levelLabel)
        return <Typography color="textPrimary">{levelLabel}</Typography>
      }
      console.log(shownLevels)
      if (shownLevels.includes(breadCrumbLevel)) {
        console.log("In checking shown levels")
        return(<Link color="inherit" onClick={() => changeTileLevel(breadCrumbLevel, levelLabel)}>
                  {levelLabel}
              </Link>);
      }
    }
  }

// Get clicked elems to show when clicked
// Keep parents in the list
// Checks each level and see if it should be rendered
function generateBreadcrumbs() {
  console.log("In generate bread crumbs")
  console.log(level)
  return (<Breadcrumbs separator="›" aria-label="breadcrumb">
  {generateSpecificBreadCrumb(currentSubject, 'topics')}
  {generateSpecificBreadCrumb(currentTopic, 'sub-topics')}
  {generateSpecificBreadCrumb(currentSubTopic, 'question-choices')}
</Breadcrumbs>);
}



  return (
    <div>
      <Typography variant='h5' component={"div"} className="p-4">
        {level === "topics" || level === "subject" ? "Select a topic to start creating an assignment" : ""}
      </Typography>
      <div >
        <div className={classes.breadCrumbs}>
          {generateBreadcrumbs()}
        </div>

        <Grid container spacing={2}>
          {populateRelevantTiles()}
        </Grid>
        <div className="button-case">
          { (level === 'topics' || level === 'question-choices' || level === 'subject') ? 
          <></> : 
          <button 
            className="toTopicsList" 
            onClick={() => changeTileLevel('topics')}
          > 
            Back to Topics list
          </button>}
        </div>
      </div> 
    </div>
  );
}
