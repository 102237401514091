import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import TextField from '@material-ui/core/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Link from '@material-ui/core/Link';
import { Typography } from '@material-ui/core';
import Modal from '@mui/material/Modal';
import Divider from '@mui/material/Divider';


// import CorrectlyAnsweredQuestionsTile from '../AnalyticsComponents/CorrectlyAnsweredQuestionsTile';
import QuestionsAndUserDataService from '../../services/QuestionsAndUserDataService'
import "../../assets/styles/landingPage.css";
import * as CONSTANTS from "../../StringConstants";



// images

import CEMTitleImage from "../../assets/blogImages/CEMBlog/CEM-Title-Image.jpeg"
import CEMEnglish from "../../assets/blogImages/CEMBlog/CEM-English.jpg"
import CEMMath from "../../assets/blogImages/CEMBlog/CEM-Maths.jpg"
import CEMVR from "../../assets/blogImages/CEMBlog/CEM-VR.jpg"
import CEMNVR from "../../assets/blogImages/CEMBlog/CEM-NVR.jpg"

import CEMTitleImageWebP from "../../assets/WebPImages/BlogImages/CEMBlog/CEM-Title-Image.webp"
import CEMEnglishWebP from "../../assets/WebPImages/BlogImages/CEMBlog/CEM-English.webp"
import CEMMathWebP from "../../assets/WebPImages/BlogImages/CEMBlog/CEM-Maths.webp"
import CEMVRWebP from "../../assets/WebPImages/BlogImages/CEMBlog/CEM-VR.webp"
import CEMNVRWebP from "../../assets/WebPImages/BlogImages/CEMBlog/CEM-NVR.webp"


export default function CEMBlog() {
    let history = useHistory();

    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [hasSubmittedAnEmail, setHasSubmittedAnEmail] = useState(false)
    const location = useLocation();

    // Use a URLSearchParams object to parse the query string
    const queryParams = new URLSearchParams(location.search);
    const viewSource = queryParams.get('source');  

    useEffect(() => {
        let blogViewed = CONSTANTS.CEMBlogTitle
        QuestionsAndUserDataService.updateBlogsViewData(blogViewed, viewSource)
    });
      

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };

    const validationSchema = yup.object({
      email:yup
      .string('Email')
      .email('Enter a valid email')
    });
  
  
    const formik = useFormik({
      initialValues: {
        email: '',
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {
        if (formik.values.email === "") {
          console.log("Email value is blank")
        } else {
          console.log("Adding subscriber email to list")
          setHasSubmittedAnEmail(true)
          // sendWelcomeEmailToNewSubscriber()
          QuestionsAndUserDataService.addToSubscriptionList(formik.values.email).then(setOpen(false))
        }
      },
    });

    function createCheckoutSession(lookupKey) {
        // Handle multiple keys and products later - for now just direct to sign up with the one look up 
        history.push("/auth/register/1")
    }

    function directToGLBlog() {
        // Need to scroll to top otherwise react carries the scroll level you were at to the next page
        window.scrollTo(0, 0);
        history.push("/auth/preparing-for-the-GL-11plus-exams")
    }
        


  return (        
        <div className='eleven-plus-preparation-blog'>
            <Helmet>
                <title>Conquering the CEM 11 plus exams</title>
                <meta name="A guide about the CEM 11 plus exams and how to prepare" content="What are the CEM 11 plus exams. How to prepare for the CEM exams.
                What are the style and types of 11 plus questions. The CEM 11 plus subjects and how to prepare." />
            </Helmet>

            <div className='eleven-plus-info-blog-title-and-image'>
                <Typography className="eleven-plus-blog-title" variant="h3"><b>Conquering the CEM 11 plus Exams</b></Typography>
                <Typography variant="h6">
                    Preparing for the 11plus exams is no simple measure and so our teachers at Whizzdom have prepared this handy guide to the
                    11plus CEM papers.
                </Typography>
                <img
                className='eleven-plus-info-blog-image'
                alt="CEM-11plus-preparation-blog-info-image"
                src={CEMTitleImageWebP}
                srcSet={CEMTitleImage}
                />  
            </div>  

            <div className='eleven-plus-preparation-blog-content'>
            <Typography variant="h6">
            Only a certain number of schools choose to use the CEM format for their 11plus exams so the first thing to do is to ensure the one
            your child is applying for does so. Email us at <b>11plus@whizzdom.co.uk</b> if you are unsure and one of our team will be happy to help. The exam format 
            itself assesses 4 key areas: 
                <div className="eleven-plus-preparation-blog-bullet-point">
                    <il>
                        {'\u2022'} <Typography variant="h9"><b>English</b> - this will typically include reading and comprehension</Typography>
                    </il>
                </div>
                <div className="eleven-plus-preparation-blog-bullet-point">
                    <il>
                        {'\u2022'} <Typography variant="h9"><b>Maths</b> - consisting of numerical reasoning</Typography>
                    </il>
                </div>
                <div className="eleven-plus-preparation-blog-bullet-point">
                    <il>
                        {'\u2022'} <Typography variant="h9"><b>Verbal Reasoning</b></Typography>
                    </il>
                </div>
                <div className="eleven-plus-preparation-blog-bullet-point">
                    <il>
                        {'\u2022'} <Typography variant="h9"><b>Non-Verbal Reasoning</b></Typography>
                    </il>
                </div>
            </Typography>
            <Typography variant="h6">
            The exams consist of two papers, both 45 mins each. The papers themselves will usually assess two or more subjects with the most common 
            pairings including English and Verbal Reasoning in one and Maths and Non Verbal Reasoning in the other. A key aspect to note is that
             these papers are split into timed sections. On completing a section, students will be instructed to wait before they can progress further. 
             Once they do progress, they cannot go back to a previous section. 
            </Typography>
            
            <div className="pricing-button-container">
                <button
                    className="pricing-button-pro"
                    onClick={() => createCheckoutSession("lookupkey")}
                >Get Started Today
                </button>
            </div>

            <br></br>
            <Divider/>
            <br></br>

            <Typography className="eleven-plus-preparation-blog-subheading" variant="h4"><b>The CEM 11 Plus Subjects </b></Typography>
   
            <Grid container textAlign={"center"} alignItems="center" className='py-4'>
                <Grid xs={12} sm={5}>
                    {/* <div className='eleven-plus-preparation-blog-content'> */}

                        <Typography className="eleven-plus-preparation-blog-subheading" style={{textAlign:"left"}} variant="h6"><b>CEM English</b></Typography>
                        <Typography style={{textAlign:"left"}} variant="h6">
                            Both papers will consists of a comprehension piece with one being significantly longer than the other. Typically there are 
                            no spelling, punctuation or grammar questions in the CEM papers but schools can sometimes administer their own. These 
                            papers will specifically assess a student's ability to infer, deduct and understand vocabulary in a given context.  
                        </Typography>
                    {/* </div> */}
                </Grid>
                <Grid xs={1}/>
                <Grid xs={12} sm={5} style={{align:"center"}} className="eleven-plus-blog-image">
                    <img
                    alt="CEM-english-11plus-preparation-blog-image"
                    src={CEMEnglishWebP}
                    srcSet={CEMEnglish}
                    /> 
                </Grid>
            </Grid>
            <Grid container textAlign={"center"} alignItems="center" className='py-4'>
                <Grid xs={12} sm={5} style={{align:"center"}} className="eleven-plus-blog-image">
                    <img
                    alt="CEM-Verbal-Reasoning-11plus-preparation-blog-image"
                    src={CEMVRWebP}
                    srcSet={CEMVR}
                    /> 
                </Grid>
                <Grid xs={1}/>
                <Grid xs={12} sm={5}>
                    {/* <div className='eleven-plus-preparation-blog-content'> */}

                        <Typography className="eleven-plus-preparation-blog-subheading" style={{textAlign:"left"}} variant="h6"><b>CEM Verbal Reasoning</b></Typography>
                        <Typography style={{textAlign:"left"}} variant="h6">
                            This tends to mainly focus on <b>synonyms</b> and <b>antonyms</b> but can also include other topics such as; jumbled sentences, deductions, choose the word, 
                            complete the sentence, missing letters, odd ones out and homographs. Your child should familiarise themselves with these questions early on 
                            and can use standard VR exercises to also help prepare. 
                        </Typography>
                    {/* </div> */}
                </Grid>
            </Grid>

            <Grid container textAlign={"center"} alignItems="center" className='py-4'>
                <Grid xs={12} sm={5}>
                    {/* <div className='eleven-plus-preparation-blog-content'> */}

                        <Typography className="eleven-plus-preparation-blog-subheading" style={{textAlign:"left"}} variant="h6"><b>CEM Maths/Numerical Reasoning</b></Typography>
                        <Typography style={{textAlign:"left"}} variant="h6">
                            This is sometimes referred to as Numerical Reasoning and are designed to test problem solving/reasoning skills. The topics assessed typically will include
                            geometry, algebra, reading data (such as graph questions) and measurements. The majority of the questions however, consist of number questions using +, -, 
                            x and ÷.   
                        </Typography>
                    {/* </div> */}
                </Grid>
                <Grid xs={1}/>
                <Grid xs={12} sm={5} style={{align:"center"}} className="eleven-plus-blog-image">
                    <img
                    alt="CEM-Math-11plus-preparation-blog-image"
                    src={CEMMathWebP}
                    srcSet={CEMMath}
                    /> 
                </Grid>
            </Grid>

            <Grid container textAlign={"center"} alignItems="center" className='py-4'>
                <Grid xs={5} style={{align:"center"}} className="eleven-plus-blog-image">
                    <img
                    alt="CEM-Non-Verbal-Reasoning-11plus-preparation-blog-image"
                    src={CEMNVRWebP}
                    srcSet={CEMNVR}
                    /> 
                </Grid>
                <Grid xs={1}/>
                <Grid xs={12} sm={5}>
                    {/* <div className='eleven-plus-preparation-blog-content'> */}

                        <Typography className="eleven-plus-preparation-blog-subheading" style={{textAlign:"left"}} variant="h6"><b>CEM Non-Verbal Reasoning</b></Typography>
                        <Typography style={{textAlign:"left"}} variant="h6">
                        This differs the least from other non CEM tests and will include questions in areas such as manipulating 3D shapes and other spatial problem solving questions. 
                        The key aspect with these types of questions is familiarisation with question types that can come up. 
                        </Typography>
                    {/* </div> */}
                </Grid>
            </Grid>



            <Typography className="eleven-plus-preparation-blog-subheading" variant="h4"><b>How to Prepare</b></Typography>
            <Typography variant="h6">
                As mentioned previously, one of the key differences with the CEM exams is the student's inability to go back to a previous section. As a result, your child 
                should practise with timed constraints on a more granular scale (ie with specific worksheets or sections) to help get them more comfortable. We also recommend
                starting preparation in the summer term of year 4 or early autumn of year 5. Practising early and often will help to reduce any pressure towards the exam date.               
            <br></br>
            <br></br>
                As a result, we've provided an unlimited number of questions and mock tests for students to prepare with to help get them ready for their 11plus exams. Parents
                 can also constantly track their progress with granular answer analysis and real time feedback on their mistakes whilst our smart system will learn of their 
                 progress and highlight any areas they may need to improve. We look forward to welcoming you on the platform! Sign up to our <Link onClick={handleOpen}><u><b>our 
                    mailing list</b></u></Link>. to keep up to date with all the latest content to do with the <Link onClick={directToGLBlog}>11plus GL </Link>and CEM exams and more!

            </Typography>
        
                <div className="pricing-button-container">
                    <button
                        className="pricing-button-pro"
                        onClick={() => createCheckoutSession("lookupkey")}
                    >Start Learning Today
                    </button>
                </div>
                <div className="pt-6">
                { hasSubmittedAnEmail ? 
                <b style={{textAlign: "center"}}>You've been added to our subscriber list! </b> : ""
                } 
                </div>        
            </div>
  
        <Modal
          open={open}
          onClose={handleClose}
        >
          <Box sx={style}>
            <form onSubmit={formik.handleSubmit}>
                <div className="relative w-full mb-3">
                    <TextField
                        fullWidth
                        id="email"
                        name="email"
                        label="Email"
                        onChange={(e) => {
                        formik.handleChange(e);
                        }}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                    />
                </div>

                <div className="text-center mt-6">
                <button
                    className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="submit"
                >
                    Get Started
                </button>
                </div>
            </form>
          </Box>
        </Modal>
        </div>
  );
}
