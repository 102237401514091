import React from "react";
import { Redirect, Route } from "react-router-dom";
// string constants

import * as CONSTANTS from "../../StringConstants"

function ProtectedRoute({ component: Component, ...restOfProps }) {

    const user = JSON.parse(localStorage.getItem('user'));
    const isAuthenticated = user && user.accessToken && isStandardUser(user.roles)
    console.log("this", isAuthenticated);

    function isStandardUser(roles) {
        console.log("Roles for this use include")
        console.log(roles)
        for (var i = 0; i < roles.length; i ++) {
            if (roles[i] == "ROLE_USER" || roles[i] == "ROLE_FREE_USER") {
                return true
            }
        }
        return false
    }

    return (
        <Route
        {...restOfProps}
        render={(props) =>
            isAuthenticated ? <Component {...props} /> : <Redirect to={CONSTANTS.IndividualLogin} />
        }
        />
    );
}

export default ProtectedRoute;
