import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

// Mui imports
import TextField from '@material-ui/core/TextField';
import Box from '@mui/material/Box';
import Link from '@material-ui/core/Link';
import { Typography } from '@material-ui/core';
import Modal from '@mui/material/Modal';


// System imports
import QuestionsAndUserDataService from '../../services/QuestionsAndUserDataService'
import "../../assets/styles/landingPage.css";
import UpcomingMockExamsModal from 'components/UpcomingMockExamsModal';
import mockExamBlogImage from "../../assets/mockExamLandingPageImages/mockExamStudent.jpg"
import mockExamBlogImageWebP from "../../assets/WebPImages/BlogImages/mockExamStudent.webp"
import * as CONSTANTS from "../../StringConstants";



export default function MockExamsBlog() {
    let history = useHistory();

    const [open, setOpen] = useState(false);
    const [openMockExamSessionsTable, setMockExamSessionsTable] = useState(false);

    const [isLoadingMockExamSessionsData, setIsLoadingMockExamSessionsData] = useState(true)
    const [mockExamSessionsData, setMockExamSessionsData] = useState([])

    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const viewSource = queryParams.get('source');  

  
    useEffect(() => {
      if (isLoadingMockExamSessionsData) {
          getUpcomingMockExamSessions()
      }
      let blogViewed = CONSTANTS.TheImportanceOf11PlusMockExamsTitle
      QuestionsAndUserDataService.updateBlogsViewData(blogViewed, viewSource)
    });
  
    function getUpcomingMockExamSessions(){
      QuestionsAndUserDataService.getAllUpcomingMockExams().then(sessionsData => {
        console.log(sessionsData)
        setMockExamSessionsData(sessionsData.data)
        setIsLoadingMockExamSessionsData(false)
      })
    }

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleOpenMockExamSessionsTable = () => setMockExamSessionsTable(true);
    const handleCloseMockExamSessionsTable= () => setMockExamSessionsTable(false);

    const [hasSubmittedAnEmail, setHasSubmittedAnEmail] = useState(false)

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };

    const validationSchema = yup.object({
      email:yup
      .string('Email')
      .email('Enter a valid email')
    });
  
  
    const formik = useFormik({
      initialValues: {
        email: '',
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {
        if (formik.values.email === "") {
          console.log("Email value is blank")
        } else {
          console.log("Adding subscriber email to list")
          setHasSubmittedAnEmail(true)
          // sendWelcomeEmailToNewSubscriber()
          QuestionsAndUserDataService.addToSubscriptionList(formik.values.email).then(setOpen(false))
        }
      },
    });

    function createCheckoutSession(lookupKey) {
        // Handle multiple keys and products later - for now just direct to sign up with the one look up 
        history.push("/auth/register/2")
        }


  return (        
        <div className='eleven-plus-preparation-blog'>

            <Helmet>
                <title>5 ways mock exams will boost your child's 11 plus success</title>
                <meta name="5 ways mock exams will boost your child's 11 plus success" content="How mock exams help 11 plus preparation.
                 Familiarisation with the 11 plus Exam Format and Content. Identify areas of improvement and build confidence." />
            </Helmet>
           
            <div className='eleven-plus-info-blog-title-and-image'>
                <Typography className="eleven-plus-blog-title" variant="h3"><b>5 ways mock exams will boost your child's 11 plus success</b></Typography>
                <img
                className='eleven-plus-info-blog-image'
                alt="11plus-preparation-blog-info-image"
                src={mockExamBlogImageWebP}
                srcSet={mockExamBlogImage}
                />  
            </div>  

            <div className='eleven-plus-preparation-blog-content'>
            {/* <Typography className="eleven-plus-preparation-blog-subheading" variant="h6"><b>What is the 11plus</b></Typography> */}
            <Typography variant="h6">
                The 11 Plus exam is a crucial step in a child's academic journey, as it determines their eligibility for entry into some of the most prestigious
                 schools in the country. As such, it is essential that children are well-prepared for the exam in order to increase their chances of success. 
                 One of the most important elements of preparation is practice, and mock exams play a vital role in this process. Here are 5 ways mock exams 
                 will help your child succeed.

            </Typography>
            <Typography className="eleven-plus-preparation-blog-subheading" variant="h6"><b>Familiarisation with the Exam Format and Content</b></Typography>
            <Typography variant="h6">
                Mock exams, also known as practice tests, replicate the format and content of the 11 Plus exam as closely as possible. They give children a realistic 
                experience of the exam and help them familiarise themselves with the types of questions that will be asked, the time constraints and the overall format 
                of the 11 Plus exam. By taking mock exams, children can become more confident in their knowledge and understanding of the exam format and content.

            </Typography>
 
            <Typography className="eleven-plus-preparation-blog-subheading" variant="h6"><b>Identifying Areas of Improvement</b></Typography>
            <Typography variant="h6">
               By taking mock exams, children can identify areas where they need to focus their studies and improve their performance. This can help them target
               their studying more effectively and increase their chances of success on the actual exam. This is an important aspect of the 11 Plus preparation 
               and mock exams are an effective way to identify the areas where the child needs to work more. With Whizzdom's mock exams, as well as seeing where 
               they are, you'll also get an insight into their national ranking against the same age cohort they'll be competing against on exam day. 
            </Typography>

            <Typography className="eleven-plus-preparation-blog-subheading" variant="h6"><b>Building Confidence</b></Typography>
            <Typography variant="h6">
             Mock exams can help children build confidence by providing them with a realistic experience of the exam. They can learn how to manage their time effectively,
             stay calm under pressure and develop their test-taking strategies. This is an essential aspect of the 11 Plus preparation as it can help children overcome 
             test anxiety and perform better on the actual exam.
            </Typography>

            <Typography className="eleven-plus-preparation-blog-subheading" variant="h6"><b>Reducing Test Anxiety</b></Typography>
            <Typography variant="h6">
                Mock exams can help reduce test anxiety by providing children with an opportunity to experience the exam environment without the pressure of the actual test. 
                This can help them feel more relaxed and confident when it comes time to take the 11 Plus exam, which can lead to better performance.
            </Typography>

            <Typography className="eleven-plus-preparation-blog-subheading" variant="h6"><b>Personalised & Analytical feedback</b></Typography>
            <Typography variant="h6">
                Doing your mock exam with Whizzdom will allow your child to get a granular breakdown on their performance. The 11 Plus exams will be time pressured.
                See how long they spend on each question and section to know exactly where to speed up on when it comes to the big day. You'll also be able to 
                figure out exactly what types of questions they're struggling with. All information that can be used to address any gaps in their knowledge so they're
                fully prepared for the big day. 
                
          
            </Typography>


            <br></br>
            <br></br>
            <br></br>

            <Typography variant="h6">We understand the importance of the 11 Plus and believe that all parents should have a full understanding as well in order
             to prepare their child. For any queries in regards to the process, email <u>11plus@whizzdom.co.uk</u> and our team will address any concerns or queries 
             that you may have. We also send out free resources and 11 Plus information each week via <Link onClick={handleOpen}><u>our mailing list.</u></Link>
             
             <br></br>
             <br></br>
                <Link onClick={() => history.push("/auth/register/2")}><u>Register today</u></Link> to be automatically subscribed to <u> all of our mock exams </u> 
                as well as gaining access to all of our learning material. We run 2 sets of online mock 
                exams a month and you'll be able to see exactly how your child is progressing, both for themselves and against the national cohort so they can constantly keep improving. In addition, your child will also have 
                access to unlimited practise questions to use to keep improving! <Link onClick={() => history.push("/auth/register/2")}><u>Click here</u></Link> to subscribe now and 
                use the code "BLOG20" at checkout for 20% off your first month! We also offer one of exam session purchases to suit your needs, see our available dates
                and <Link onClick={handleOpenMockExamSessionsTable}><u> sign up here.</u></Link> 
            </Typography>
        
                <div className="pricing-button-container">
                    <button
                        className="pricing-button-standard"
                        onClick={() => createCheckoutSession("lookupkey")}
                    >Get started today
                    </button>
                </div>
                <div className="pt-6">
                { hasSubmittedAnEmail ? 
                <b style={{textAlign: "center"}}>You've been added to our subscriber list! </b> : ""
                } 
                </div>        
            </div>
  
        <Modal
          open={open}
          onClose={handleClose}
        >
          <Box sx={style}>
            <form onSubmit={formik.handleSubmit}>
                <div className="relative w-full mb-3">
                    <TextField
                        fullWidth
                        id="email"
                        name="email"
                        label="Email"
                        onChange={(e) => {
                        formik.handleChange(e);
                        }}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                    />
                </div>

                <div className="text-center mt-6">
                <button
                    className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="submit"
                >
                    Get Started
                </button>
                </div>
            </form>
          </Box>
        </Modal>

        <Modal
          open={openMockExamSessionsTable}
          onClose={handleCloseMockExamSessionsTable}
          className="landing-page-upcoming-mock-exams-modal"
        >
          <Box sx={style}>
            <UpcomingMockExamsModal upcomingMockExamDates={mockExamSessionsData}/>
          </Box>
        </Modal>

        </div>
  );
}
