import React from "react";
import LoginComponent from "../../../src/views/auth/LoginComponent.js";
import { Helmet } from 'react-helmet';


export default function IndividualLogin({loginType}) {

  console.log("In individual login")
  console.log(loginType)

  return (
    <>
       <Helmet>
        <title>Whizzdom 11 Plus Login</title>
        <meta name="Whizzdom 11 plus account login" content="Login for 11 plus users to access their learning account" />
      </Helmet>
      <LoginComponent loginType={loginType}/>
    </>
  );
}
