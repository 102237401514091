import React from 'react';

export default function Register() {
    return (
        <div className="container mx-auto px-4 h-full">
            <div className="flex content-center items-center justify-center h-full">
                <div className="w-full lg:w-6/12 px-4">
                    <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                        <div className="rounded-t mb-0 px-6 py-6">
                                Your payment was successful! You will shortly receive your welcome email with everything you need to know for your mock exam!  
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
