// Money Problems

const StandardMoneyProblems = ["Addition", "Subtraction", "Multiplication", "Division"];
const HarderMoneyProblems = ["Subtracting Multiple Values", "Multiplying Multiple Terms", "Money Problems Ratios", "Exchange Rates"];

const MoneyProblems = {
    "Standard Money Problems": StandardMoneyProblems,
    "Harder Money Problems": HarderMoneyProblems,
};


// Capacity Problems

const CapacityProblems = ["Conversion", "Multiplication", "Division"];

// Length Problems

const LengthProblems = ["Converting Units", "Addition Problems", "Subtraction Problems", "Multiplication Problems", "Division Problems"];

// Number cards - not included due to issues with question

// Complete the equation aka missing number questions

const withIntegerValues = ["Addition", "Subtraction", "Multiplication", "Division"];
const withDecimalValues = ["Addition", "Subtraction", "Multiplication", "Division"];

const CompleteTheEquation = {
    "Integer Values": withIntegerValues,
    "Decimal Values": withDecimalValues
};

// Speed Distance Time Questions

const SpeedDistanceTime = ["Speed Distance and Time"];

// Thinking of a Number Questions

const ThinkingOfANumber = ["One Operation", "Two Operations", "Three Operations", "Four Operations", "Five Operations", "Working Backwards"];

// Volume and Area Questions

const VolAndArea = ["Volume Questions", "Area Questions"];

// Fractions

const easy = ["Simple Fractions", "Word Questions"];
const standard = ["Addition", "Subtraction", "Multiplication", "Division"];
const harder = ["Addition", "Subtraction", "Multiplication", "Division"];
const averages = ["Mode", "Median", "Mean"];

const Fractions = {
    "Easy": easy,
    "Standard": standard,
    "Hard": harder,
    "Averages": averages
}

// Weight and Ratio

const WeightAndRatio = ["Standard", "Hard"];

// Word Arithmetic

const WordArithmetic = ["Addition", "Subtraction", "Multiplication", "Division", "Converting Words to Numbers"];

// Time Arithmetic (Number and word questions both ask in hrs and minutes)

const ConvertingBetweenUnits = ["Standard", "Hard"]
const NumberQuestions = ["Addition", "Subtraction", "Multiplication", "Division"];
const WordQuestions = ["Addition", "Subtraction", "Multiplication", "Division"];
const DaysAndMonthsQuestions = ["Calculating which day of the month", "Calculating Future Dates"];

const TimeArithmetic = {
    "Converting Between Units": ConvertingBetweenUnits,
    "Number Questions": NumberQuestions,
    "Word Questions": WordQuestions,
    "Days and Months": DaysAndMonthsQuestions
}

// Percentage Questions

const PercentageQuestions = ["Standard", "Word Questions", "Calculating the Proportion"];

// Decimals

const Decimals = ["Addition", "Subtraction", "Multiplication", "Division", "Rounding"];

export const MathTopics = {
    "Money Problems": MoneyProblems,
    "Capacity Problems": CapacityProblems,
    "Length Problems": LengthProblems,
    "Complete the Equation": CompleteTheEquation,
    "Speed, Distance and Time": SpeedDistanceTime,
    "Thinking of a Number": ThinkingOfANumber,
    "Volume and Area Problems": VolAndArea,
    "Fractions": Fractions,
    "Weight and Ratio": WeightAndRatio,
    "Word Arithmetic": WordArithmetic,
    "Time Arithmetic": TimeArithmetic,
    "Percentages": PercentageQuestions,
    "Decimals": Decimals,
};


export const VRTopics = {
    // "Add a letter between words": ["Add a letter between words"], - not working
    "Coding and decoding words": ["Coding and Decoding Words"],
    "Complete the Sum": ["Complete the Sum"],
    // "Group of words and codes": ["Group of words and codes"], - not working
    "Letter Connections": ["Letter Connections"],
    "Letter Series": ["Letter Series"],
    "Letters for Numbers": ["Letters for Numbers"],
    "Number Series": ["Number Series"],
    "Related numbers": ["Related Numbers"]
};

export const EnglishTopics = {
    "Jumbled Sentences": ["Jumbled Sentences"],
    "Missing Words": ["Missing Words"],
    "Synonyms": ["Synonyms"],
    "Antonyms": ["Antonyms"],
    "Shuffled Sentences": ["Shuffled Sentences"],
    "Spelling": ["Spelling"],
    "Punctuation": ["Punctuation"],
    "Comprehension": null
}
