import React, { useState } from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { useFormik } from 'formik';
import * as yup from 'yup';
import TLHEndpoints from 'services/TLHEndpoints';

export default function RescheduleAppointmentOrAssessment({rescheduleId, handleUpdatedTable, rescheduleAssessmentOrMeeting, reasonRequired}) {

  console.log("Reason required: " + reasonRequired)
  const getValidationSchema = (reasonRequired) => {
    let schemaFields = {
      // Uncomment or add other fields as necessary
      // date: yup.string().required('Date is required'),
      // time: yup.string().required('Time is required'),
    };
  
    if (reasonRequired) {
      schemaFields.reason = yup.string().required('Please provide a reason for the change');
    } else {
      schemaFields.reason = yup.string(); // Not required if reasonRequired is false
    }
  
    return yup.object(schemaFields);
  };

  const validationSchema = getValidationSchema(reasonRequired);


  const formik = useFormik({
    initialValues: {
      date: '',
      time: '',
      reason: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log("formik values")
      console.log(formik.values)
      console.log(values)
      console.log("Choosing which api")
      console.log(rescheduleAssessmentOrMeeting)
      if (rescheduleAssessmentOrMeeting == "Assessment") {
        console.log("Assessment reschedule")
        TLHEndpoints.updateBookedAssessmentTime(formik.values.date, formik.values.time, formik.values.reason, rescheduleId)
      } 
      if (rescheduleAssessmentOrMeeting == "Meeting") {
        console.log("Meeting Reschedule")
        TLHEndpoints.updateAssessmentMeetingTime(formik.values.date, formik.values.time, formik.values.reason, rescheduleId)
      }
      
      console.log("About to handle update")
      handleUpdatedTable()
    },
  });


  return (


    <form
        onSubmit={formik.handleSubmit}
    >
        <Typography variant="h6" style={{marginBottom: "20px"}}>
            Reschedule {rescheduleAssessmentOrMeeting}
        </Typography>

        {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
            id="date"
            name="date"
            label="New Appointment Date & Time"
            value={formik.values.date}
            onChange={formik.handleChange}
            renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
            />
        </LocalizationProvider> */}

        <TextField
          id="date"
          name="date"
          label="New Appointment Date"
          type="date"
          style={{paddingRight: '200px'}}
          InputLabelProps={{
            shrink: true,
          }}
          value={formik.values.date}
          onChange={formik.handleChange}
        />

        <LocalizationProvider  dateAdapter={AdapterDateFns}>
          <TimePicker
              id="time"
              name="time"
              label="New Appointment Time (12 hour clock format)"
              value={formik.values.time}
              onChange={(value) => formik.setFieldValue('time', value)}
              renderInput={(params) => <TextField style={{width: '225px'}} {...params} />}
            />
        </LocalizationProvider>

        {reasonRequired ? 
        <TextField
            label="Reason for Rescheduling"
            id="reason"
            name="reason"
            fullWidth
            multiline
            rows={4}
            value={formik.values.reason}
            onChange={formik.handleChange}
            error={formik.touched.reason && Boolean(formik.errors.reason)}
            helperText={formik.touched.reason && formik.errors.reason}
        />
          :
          <></>}

        <div style={{textAlign: "center", paddingTop: "25px"}}>
          <button 
              style={{
                padding: '10px 20px',
                fontSize: '18px',
                color: 'white',
                backgroundColor: '#0056b3',
                border: 'none',
                borderRadius: '5px',
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                cursor: 'pointer',
                transition: 'all 0.3s ease',
            }}
            onMouseOver={(e) => {
                e.target.style.backgroundColor = '#004085';
                e.target.style.boxShadow = '0 8px 16px rgba(0,0,0,0.2)';
            }}
            onMouseOut={(e) => {
                e.target.style.backgroundColor = '#0056b3';
                e.target.style.boxShadow = '0 4px 8px rgba(0,0,0,0.1)';
            }}
            type="submit"
          >
              Submit
          </button>
        </div>
    </form>


  );
}
