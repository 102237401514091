import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

// Mui imports

import TextField from '@material-ui/core/TextField';
import Box from '@mui/material/Box';
import Link from '@material-ui/core/Link';
import { Typography } from '@material-ui/core';
import Modal from '@mui/material/Modal';


// import CorrectlyAnsweredQuestionsTile from '../AnalyticsComponents/CorrectlyAnsweredQuestionsTile';
import QuestionsAndUserDataService from '../../services/QuestionsAndUserDataService'
import "../../assets/styles/landingPage.css";
import * as CONSTANTS from "../../StringConstants";



// images

import blogImage from "../../assets/blogImages/blog-kids-studying.jpg"
import blogImageWebP from "../../assets/WebPImages/BlogImages/blog-kids-studying.webp"



export default function Blog() {
    let history = useHistory();

    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [hasSubmittedAnEmail, setHasSubmittedAnEmail] = useState(false)
    const location = useLocation();

    // Use a URLSearchParams object to parse the query string
    const queryParams = new URLSearchParams(location.search);
    const viewSource = queryParams.get('source');  

    useEffect(() => {
        let blogViewed = CONSTANTS.MainBlogTitle
        QuestionsAndUserDataService.updateBlogsViewData(blogViewed, viewSource)
    });
    
  

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };

    const validationSchema = yup.object({
      email:yup
      .string('Email')
      .email('Enter a valid email')
    });
  
  
    const formik = useFormik({
      initialValues: {
        email: '',
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {
        if (formik.values.email === "") {
          console.log("Email value is blank")
        } else {
          console.log("Adding subscriber email to list")
          setHasSubmittedAnEmail(true)
          // sendWelcomeEmailToNewSubscriber()
          QuestionsAndUserDataService.addToSubscriptionList(formik.values.email).then(setOpen(false))
        }
      },
    });

    function createCheckoutSession(lookupKey) {
        // Handle multiple keys and products later - for now just direct to sign up with the one look up 
        history.push("/auth/register/1")
        }


  return (        
        <div className='eleven-plus-preparation-blog'>
             <Helmet>
                <title>Preparing for the 11 plus exams</title>
                <meta name="How to prepare for the 11 plus" content="About the 11 plus exams. What's included in the 11 plus exams. 
                How to prepare for the 11 plus exams. Subscribe for 11 plus resources." />
            </Helmet>
           
            <div className='eleven-plus-info-blog-title-and-image'>
                <Typography className="eleven-plus-blog-title" variant="h3"><b>Preparing For The 11 Plus Exams</b></Typography>
                <img
                className='eleven-plus-info-blog-image'
                alt="11plus-preparation-blog-info-image"
                src={blogImageWebP}
                srcSet={blogImage}
                />  
            </div>  

            <div className='eleven-plus-preparation-blog-content'>
            <Typography className="eleven-plus-preparation-blog-subheading" variant="h6"><b>What is the 11plus?</b></Typography>
            <Typography variant="h6">
                The 11 Plus exams are a set of academic tests used in the United Kingdom to select students for grammar and private school placement.
                 The name derives from the age group of secondary entry, 11, with the "Plus" referring to their following years in secondary school. 
                 The tests are often written by GL(Granada Learning) or CEM (Centre for Evaluation and Monitoring at Cambridge Assessment). However, 
                 both formats cover English, Maths, Verbal Reasoning, and Non-Verbal. The 11 Plus exams have been around since the 1950s and are still 
                 being used as a way to determine which students will go to which schools.

            </Typography>
            <Typography className="eleven-plus-preparation-blog-subheading" variant="h6"><b>How are the 11 Plus exams used today?</b></Typography>
            <Typography variant="h6">
                The exams are usually taken in the autumn term of Year 6, with students sitting them in their final year of primary school.
                The results of the exams are used to identify those students who would be best suited for a grammar or private school education.
                Grammar schools are state-funded schools that select their students based on academic ability whilst private schools involve 
                fees paid by the parents. 
                <br></br>
                <br></br>
                While the use of the 11 Plus exams has come under some criticism in recent years, they remain a popular
                way for schools to identify those students who are most likely to succeed in their academic studies. For parents and guardians,
                it is important to research the schools in your area to see if they still use the 11 Plus exams as part of their admissions process. 
                If they do, then you will need to start preparing your child for the exams well in advance. This assessment can then be used to place 
                students in the appropriate school for their needs.

            </Typography>
            <Typography className="eleven-plus-preparation-blog-subheading" variant="h6"><b>What is included in the 11 Plus exams?</b></Typography>
            <Typography variant="h6">
            The questions in the 11 Plus exams can vary depending on which format you are taking the test in. However, some common question types 
            that may come up include: 
                <div className="eleven-plus-preparation-blog-bullet-point">
                    <il>
                        {'\u2022'} <Typography variant="h9">Grammar and punctuation</Typography>
                    </il>
                </div>
                <div className="eleven-plus-preparation-blog-bullet-point">
                    <il>
                        {'\u2022'} <Typography variant="h9">Vocabulary</Typography>
                    </il>
                </div>
                <div className="eleven-plus-preparation-blog-bullet-point">
                    <il>
                        {'\u2022'} <Typography variant="h9">Synonyms and antonyms</Typography>
                    </il>
                </div>
                <div className="eleven-plus-preparation-blog-bullet-point">
                    <il>
                        {'\u2022'} <Typography variant="h9">Reading and comprehension</Typography>
                    </il>
                </div>
                <div className="eleven-plus-preparation-blog-bullet-point">
                    <il>
                        {'\u2022'} <Typography variant="h9">Mathematical reasoning</Typography>
                    </il>
                </div>
                <div className="eleven-plus-preparation-blog-bullet-point">
                    <il>
                        {'\u2022'} <Typography variant="h9">Spatial awareness</Typography>
                    </il>
                </div>


            Each child will have their own strengths and weaknesses. The aim of the test is to see how each child performs under pressure and to identify 
            which areas they need to work on. Both the GL and CEM exams are divided into two sections: core subjects and extended subjects. The core subjects 
            are English, Maths, and Verbal Reasoning. The extended subjects are Non-Verbal, Spatial Awareness, and Abstract Reasoning. 
            
            <br></br>
            <br></br>
            
            The CEM test has 
            been found to be more difficult in recent years, with a heavier focus on problem-solving and mental agility than the GL test. This, along with 
            a significant increase in competition has led to a decrease in the number of students being accepted into grammar schools after taking the CEM 
            test. In recent years, some of the more competitive schools have had up to 11 students applying for a single space. 

            </Typography>
            <Typography className="eleven-plus-preparation-blog-subheading" variant="h6"><b>Preparing for the 11 Plus exams</b></Typography>
            <Typography variant="h6">
            The 11 Plus exams can be daunting for both children and parents alike. However, with some proper preparation, your child can go into the test
            feeling confident and ready to do their best. With a combined total of over 60 years of tutoring experience, we've compiled some of our top tips 
            to help you prepare:

            <ul className='eleven-plus-preparation-blog-bullet-point-list'>
                <div className="eleven-plus-preparation-blog-bullet-point">
                    <il>
                        {'\u2022'} <Typography variant="h9">
                            <b>Start early:</b> It's never too early to start preparing for the 11 Plus exams. We recommend starting at least one year in 
                            advance. This will give your child plenty of time to familiarise themselves with the content and question types. Whizzdom will 
                            allow your child to cover the 11 Plus course and avoid any surprises on exam day. 
                            </Typography>
                    </il>
                </div>
                <div className="eleven-plus-preparation-blog-bullet-point">
                    <il >
                        {'\u2022'} <Typography variant="h9">
                            <b>Practice, practice, practice:</b> Take as many practice exams as you can and with Whizzdom you'll have an unlimited number. 
                            This will help them to get used to the exam format and timing. It will also give them an idea of what to expect on test day.
                            </Typography>
                    </il>
                </div>
                <div className="eleven-plus-preparation-blog-bullet-point">
                    <il >
                        {'\u2022'} <Typography variant="h9">
                            <b>Address any weaknesses:</b> The 11 Plus will require depth and breadth across all topics and subjects. Whizzdom will constantly 
                            evaluate your child's performance and highlight the topics they're weak at so they always know exactly what to do.
                            </Typography>
                    </il>
                </div>
                <div className="eleven-plus-preparation-blog-bullet-point">
                    <il >
                        {'\u2022'} <Typography variant="h9">
                            <b>Be quick:</b> The 11plus exams can be quite time pressured so be sure to focus on speed as well as accuracy.
                        </Typography>
                    </il>
                </div>
                <div className="eleven-plus-preparation-blog-bullet-point">
                    <il >
                        {'\u2022'} <Typography variant="h9">
                            <b>See their progress:</b> It's important to stay up to date with your child's 11 Plus progress as they're hitting the checkpoints 
                            towards their goal. With Whizzdom, you'll be able to check on your child's progress at any time. See how they're improving over 
                            time and use our comprehensive cover to see what's challenging them the most. </Typography>
                    </il>
                </div>
            </ul>
            </Typography>
            <Typography variant="h6">We understand the importance of the 11 Plus and believe that all parents should have a full understanding as well in order
             to prepare their child. For any queries in regards to the process, email <u>11plus@whizzdom.co.uk</u> and our team will address any concerns or queries 
             that you may have. We also send out free resources and 11 Plus information each week via <Link onClick={handleOpen}><u>our mailing list.</u></Link>
             
            <br></br>
            <br></br>
             Alternatively, sign up today and try Whizzdom for yourself. With some proper preparation, your child will feel confident and ready to take 
             on the 11 Plus exams. Good luck!
            </Typography>
        
                <div className="pricing-button-container">
                    <button
                        className="pricing-button-standard"
                        onClick={() => createCheckoutSession("lookupkey")}
                    >Get started today
                    </button>
                </div>
                <div className="pt-6">
                { hasSubmittedAnEmail ? 
                <b style={{textAlign: "center"}}>You've been added to our subscriber list! </b> : ""
                } 
                </div>        
            </div>
  
        <Modal
          open={open}
          onClose={handleClose}
        >
          <Box sx={style}>
            <form onSubmit={formik.handleSubmit}>
                <div className="relative w-full mb-3">
                    <TextField
                        fullWidth
                        id="email"
                        name="email"
                        label="Email"
                        onChange={(e) => {
                        formik.handleChange(e);
                        }}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                    />
                </div>

                <div className="text-center mt-6">
                <button
                    className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="submit"
                >
                    Get Started
                </button>
                </div>
            </form>
          </Box>
        </Modal>
        </div>
  );
}
