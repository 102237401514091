import React, { useState, useEffect } from 'react';
import "../../assets/styles/index.css";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

import * as CONSTANTS from "../../StringConstants.js"

// System imports

import QuestionsAndUserDataService from 'services/QuestionsAndUserDataService';
import Title from "../AnalyticsComponents/Title.js"
import ChangeSubscriberTypeOfUserForm from './ChangeSubscriberTypeOfUserForm';
import ResetUserPasswordForm from './ResetUserPasswordForm';
import ToggleAccountActivationStatusForm from './ToggleAccountActivationStatusForm';
// Material UI imports

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function ViewAndManageAllUsersDashboard() {

  const [isLoadingUsers, setIsLoadingUsers] = useState(true)
  const [users, setUsers] = useState([])
  const [changeSubscriberTypeOfUserFormIsOpen, setChangeSubscriberTypeOfUserFormIsOpen] = useState(false)
  const [userPasswordResetFormIsOpen, setUserPasswordResetFormIsOpen] = useState(false)
  const [toggleAccountActivationStatusFormIsOpen, setToggleAccountActivationStatusFormIsOpen] = useState(false)

  const [userBeingLookedAtForModal, setUserBeingLookedAtForModal] = useState(null)

  const handleOpen = () => setChangeSubscriberTypeOfUserFormIsOpen(true);
  const handleClose = () => setChangeSubscriberTypeOfUserFormIsOpen(false);

  const handleOpenPasswordResetForm = () => setUserPasswordResetFormIsOpen(true);
  const handleClosePasswordResetForm = () => setUserPasswordResetFormIsOpen(false);

  const handleOpenAccountActivationForm = () => setToggleAccountActivationStatusFormIsOpen(true);
  const handleCloseAccountActivationForm = () => setToggleAccountActivationStatusFormIsOpen(false);

  function openSubscriberTypeForm(user) {
    setUserBeingLookedAtForModal(user)
    setChangeSubscriberTypeOfUserFormIsOpen(true)
  }

  function openUserPasswordResetForm(user) {
    setUserBeingLookedAtForModal(user)
    setUserPasswordResetFormIsOpen(true)
  }

  function toggleAccountActivationStatus(user) {
    setUserBeingLookedAtForModal(user)
    setToggleAccountActivationStatusFormIsOpen(true)
  }

  let history = useHistory();

  useEffect(() => {
    if (isLoadingUsers) {
      getAllUsers()
    }
  });

  // function getMockExamSets() {
  //   QuestionsAndUserDataService.getMockExamSets().then(result => {
  //     console.log(result.data)
  //     // setMockExamSets(result.data)
  //     // setIsLoadingMockExamSets(false)
  //   })
  // }

  function getAllUsers() {
    QuestionsAndUserDataService.getAllUsers().then(result => {
      // console.log(result.data)
      // setMockExamSets(result.data)
      setUsers(result.data)
      console.log("Mock Exam Sessions")
      console.log(users)
      setIsLoadingUsers(false)
    })
  }

  function handleMockExamSessionObjectData(data) {
    return {data}
  }

//   function createDummyData(date, numberOfQuestionsInTheWorksheet, numberQuestionsCorrectlyAnswered, questionTypes, subject, topic, user, worksheetId, worksheetQuestions) {
//     return {date, numberOfQuestionsInTheWorksheet, numberQuestionsCorrectlyAnswered, questionTypes, subject, topic, user, worksheetId, worksheetQuestions}
// }


function getUserType(userData) {
  // console.log(userData)
  // Possible Roles
  // Mock exam subscriber 
  // Standard user 
  // Freemium User 

  let isPaidUser = userData.isPaidCustomer
  let hasMockExamRole = checkForRoles(userData.userRoles, "ROLE_MOCK_EXAM_STUDENT")
  let hasStandardUserRole = checkForRoles(userData.userRoles, "ROLE_USER")
  let hasFreemiumUserRole = checkForRoles(userData.userRoles, "ROLE_FREE_USER")
  

  if (userData.isMockExamSubscriber) {
    return "Mock Exam Subscriber"
  } 
  if (hasStandardUserRole) {
    return "Standard User"
  }
  if (hasFreemiumUserRole) {
    return "Freemium User"
  }
  if (hasMockExamRole && isPaidUser) {
    return "One Off Mock Exam User"
  }
  return "ERROR - UNABLE TO READ USER ROLES"
}

function checkForRoles(userRoles, roleToCheckFor) {
  if (!userRoles) {
    return false
  }
  for (var i = 0; i < userRoles.length; i++) {
    if (userRoles[i].name == roleToCheckFor) {
      return true
    }
  }
  return false
}


  return (
    <React.Fragment>   
      <Link to="/app/admin-whizz">Back to Admin Dashboard</Link>

      <Title>Mock Exam Users</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>User Id</TableCell> 
            <TableCell>Email</TableCell>
            <TableCell>Parent Name</TableCell>
            <TableCell>Child Name</TableCell>
            <TableCell>User type (Click to change)</TableCell>
            <TableCell>Number of user sign ins</TableCell>
            <TableCell>Number of Questions Requested</TableCell>
            <TableCell>Account Status</TableCell>
            <TableCell>Reset Password</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <TableRow key={user.userId}>
              <TableCell>{user.userId}</TableCell>
              <TableCell>{user.userEmail}</TableCell>
              <TableCell>{user.parentFirstName + " " + user.parentLastName}</TableCell>
              <TableCell>{user.childName}</TableCell>       
              <TableCell><button onClick={() => openSubscriberTypeForm(user)}>{getUserType(user)}</button></TableCell>
              <TableCell>{user.numSignIns}</TableCell> 
              <TableCell>{user.numQuestionRequested}</TableCell> 
              <TableCell><button onClick={() => toggleAccountActivationStatus(user)}>{user.isAccountActive ? "active" :"inactive"}</button></TableCell>  
              <TableCell><button onClick={() => openUserPasswordResetForm(user)}>Click to Reset</button></TableCell>           
            </TableRow>
          ))}
         
        </TableBody>
      </Table>

      <Modal
        open={changeSubscriberTypeOfUserFormIsOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ChangeSubscriberTypeOfUserForm user={userBeingLookedAtForModal}/>
        </Box>
      </Modal>

      <Modal
        open={userPasswordResetFormIsOpen}
        onClose={handleClosePasswordResetForm}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ResetUserPasswordForm user={userBeingLookedAtForModal}/>
        </Box>
      </Modal>

      <Modal
        open={toggleAccountActivationStatusFormIsOpen}
        onClose={handleCloseAccountActivationForm}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ToggleAccountActivationStatusForm user={userBeingLookedAtForModal}/>
        </Box>
      </Modal>

  </React.Fragment>
  );
}
