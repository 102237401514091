import React, { useState, useEffect } from 'react';
import "../../assets/styles/index.css";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

import * as CONSTANTS from "../../StringConstants.js"

// Material UI imports

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

// System imports

import QuestionsAndUserDataService from 'services/QuestionsAndUserDataService';
import Title from "../AnalyticsComponents/Title.js"

export default function MockExamIndividualSessionUsers() {

  const [isLoadingMockExamUsers, setIsLoadingMockExamUsers] = useState(true)
  const [mockExamUsers, setMockExamUsers] = useState([])
  const [mockExamSessionTrackers, setMockExamSessionTrackers] = useState([])
  const { mockExamSessionId } = useParams();



  useEffect(() => {
    if (isLoadingMockExamUsers) {
      getMockExamUsers()
    }
  });



  function getMockExamUsers() {
    QuestionsAndUserDataService.getUsersForMockExamSession(mockExamSessionId).then(result => {
      console.log(result.data)
      // setMockExamSets(result.data)
      setMockExamUsers(result.data.usersInSession)
      setMockExamSessionTrackers(result.data.mockExamSessionTrackers)
      console.log("Mock Exam Users")
      console.log(mockExamUsers)
      console.log("Mock Exam session trackers")
      console.log(mockExamSessionTrackers)
      setIsLoadingMockExamUsers(false)
    })
  }

  function sendIndividualReport(mockExamUserId) {
    QuestionsAndUserDataService.getMockExamReportForMockExamUser(mockExamUserId, mockExamSessionId)
  }

  function returnRelevantExamStageForUser(userId) {
    console.log(mockExamSessionTrackers.length)
    for (var i = 0; i < mockExamSessionTrackers.length; i ++) {
      console.log("Tracker: ")
      console.log(mockExamSessionTrackers[i])
      console.log("Tracker composite id:")
      console.log(mockExamSessionTrackers[i].mockExamSessionTrackerCompositeId)
      if (mockExamSessionTrackers[i].mockExamSessionTrackerCompositeId.mockExamUserId == userId) {
        return mockExamSessionTrackers[i].currentMockExamStage
      }
    }
    return "No Exam Session Tracker"
  }



  return (
    <React.Fragment>   
      <Link to="/app/admin-whizz">Back to Admin Dashboard</Link>

      {isLoadingMockExamUsers ? <></> : <div>

      <Title>Mock Exam Sessions</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            {/* Clicking schools should take us to a school info page */}
            <TableCell>Mock Exam User id</TableCell>
            <TableCell>Parent name</TableCell>
            <TableCell>Child name</TableCell>
            <TableCell>Exam Session Status</TableCell>
            <TableCell>Is Subscriber</TableCell>
            <TableCell>Get individual report</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {mockExamUsers.map((mockExamUser) => (
            <TableRow key={mockExamUser.mockExamUserId}>
              <TableCell>{mockExamUser.mockExamUserId}</TableCell>
              <TableCell>{mockExamUser.parentFirstName + " " + mockExamUser.parentLastName}</TableCell>
              <TableCell>{mockExamUser.childName}</TableCell>
              <TableCell>{returnRelevantExamStageForUser(mockExamUser.mockExamUserId)}</TableCell>
              <TableCell>{mockExamUser.subscriber ? "Yes" : "No"}</TableCell>
              <TableCell><button onClick={() => sendIndividualReport(mockExamUser.mockExamUserId)}><u>Click for report</u></button></TableCell>
            </TableRow>
          ))}
         
        </TableBody>
      </Table>
      </div>
    }

  </React.Fragment>
  );
}
