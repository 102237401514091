import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';

import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { styled } from '@mui/material/styles';

import QuestionsAndUserDataService  from 'services/QuestionsAndUserDataService';
import * as CONSTANTS from "../../StringConstants"
import whiteLogo from "./../../assets/logoVariations/Primarylogomark_purple_black_white-03.png"
import ExamSideDrawerQuestionNav from './ExamSideDrawerQuestionNav';
import MockExamQuestionTile from './MockExamQuestionTile';
import CountdownTimer from './CountdownTimer';

const drawerWidth = 260;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));


// Mock exam session tracker 
// - currentBackEndTime - NOT USED
// - currentExamEndTime - NOT USED 
// - currentExamStartTime - NOT USED
// - currentMockExamStage - NOT USED
// - currentQuestionNumber - NOT USED 
// - mockExamsCompleted - NOT USED
// - mockExamsLeft - NOT USED
// - mockExamSessionTrackerCompositeId - USED TO UPDATE ANSWERS - NOT NEEDED FOR DEMO
//    - mockExamSessionId
//    - mockExamUserId
// - currentMockExam
//    - examDurationInMinutes - NOT USED
//    - examName - NOT USED
//    - mockExamId - USED TO UPDATE ANSWERS - NOT NEEDED FOR DEMO
//    - mockExamSet - NOT USED
//    - mockExamUserAnswerSets - USED TO UPDATE ANSWERS FOR A RETURNING USER IF THEY'D BEEN DISCONNECTED ETC ... - NOT SAVING ANSWERS FOR DEMO TEST SO NOT NEEDED 
//    - mockExamSections
//      - exampleImage - SET AS NULL - WONT USE
//      - exampleText - SET AS NULL - WONT USE
//      - mockExam - Back link in db - set as null 
//      - mockExamSectionId - NOT USED
//      - sectionImage - SET AS NULL - WONT USE
//      - sectionInstructions
//      - sectionName
//      - sectionTimeInMinutes - NOT USED
//      - subject - CAN JUST DO MATHS AND WONT NEED
//      - tableData - WONT NEED
//      - mockExamQuestions
//        - correctAnswer - NOT USED
//        - explanation - NOT USED
//        - imageMultipleChoiceOptions - WONT USE
//        - mockExamQuestionId - NOT USED
//        - mockExamQuestionImage - WONT USE
//        - mockExamQuestionNumber - NEEDED
//        - mockExamQuestionText - NEEDED
//        - mockExamSection - Back link in db - set as null
//        - textMultipleChoiceOptions - NEEDED

const demoSessionTracker = {
  currentBackEndTime: null,
  currentExamEndTime: null, 
  currentExamStartTime: null, 
  currentMockExamStage: null,
  currentQuestionNumber: null, 
  mockExamsCompleted: null, 
  mockExamsLeft: null, 
  mockExamSessionTrackerCompositeId: {
    mockExamSessionId: null,
    mockExamUserId: null
  },
  currentMockExam: {
    examDurationInMinutes: null,
    mockExamSections: [{
      sectionInstructions: "Welcome to the demo test, the instructions for each section during the exam will appear here.",
      exampleText: "Click any of the options below to select it and then click \"Submit Answer\" to move on to the next question. You can come back and change your answer at any time. You can also skip between questions by clicking the number of the question you would like to go to in the margin on the left. The current question will appear in purple.",
      sectionName: "sectionName",
      subject: "Maths",
      mockExamQuestions: [
        {
          mockExamQuestionNumber: 1,
          mockExamQuestionText: "What is 15 + 15?",
          textMultipleChoiceOptions: "10||20||30||40||50"
        },
        {
          mockExamQuestionNumber: 2,
          mockExamQuestionText: "Which of the following is a synonym for the word success?",
          textMultipleChoiceOptions: "Fortune||Loss||Agreed||Determine||Apple"
        },
        {
          mockExamQuestionNumber: 3,
          mockExamQuestionText: "What is the biggest square number less than 150?",
          textMultipleChoiceOptions: "12||220||121||144||49"
        }
      ]
    }]
  }

}


// questionNumber, question, answer, updateUserAnswer, currentUserAnswer, multipleChoiceOptions, grammarQuestionType, comprehensionDescription, comprehensionPassage

  export default function MockExamDemoPage() {
    const [open, setOpen] = useState(false);
    const [isLoadingMockExam, setIsLoadingMockExam] = useState(true)
    const [mockExamSessionTracker, setMockExamSessionTracker] = useState(demoSessionTracker)
    const [currentMockExam, setCurrentMockExam] = useState(demoSessionTracker.currentMockExam)
    const [numberOfQuestions, setNumberOfQuestions] = useState(demoSessionTracker.currentMockExam.mockExamSections[0].mockExamQuestions.length)
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
    const [mockExamQuestionsList, setMockExamQuestionsList] = useState(demoSessionTracker.currentMockExam.mockExamSections[0].mockExamQuestions)
    const [currentUserAnswers, setCurrentUserAnswers] = useState({})
    const [timerExpired, setTimerExpired] = useState(false);


    let history = useHistory()

//   function createMockExamQuestionDataStructure(question) {

//     var correctAnswer = question.correctAnswer
//     var imageMultipleChoiceOptions = question.imageMultipleChoiceOptions
//     var mockExamQuestionId = question.mockExamQuestionId
//     var mockExamQuestionImage = question.mockExamQuestionImage
//     var mockExamQuestionNumber = question.mockExamQuestionNumber
//     var mockExamQuestionText = question.mockExamQuestionText
//     var mockExamSection = question.mockExamSection
//     var textMultipleChoiceOptions = question.textMultipleChoiceOptions

//     return {correctAnswer, imageMultipleChoiceOptions, mockExamQuestionId, mockExamQuestionImage, mockExamQuestionNumber, mockExamQuestionText, mockExamSection, textMultipleChoiceOptions}
// }

    // useEffect(() => {
    //   console.log("In use effect")
    //   if (isLoadingMockExam) {
    //     getMockExamPaper()
    //   }
    // });

    const handleDrawerOpen = () => {
      setOpen(true);
    };
  
    const handleDrawerClose = () => {
      setOpen(false);
    };


  function updateCurrentQuestionIndex(newIndex) {
    console.log("Setting new index to: " + newIndex)
    console.log(isLoadingMockExam)
    setCurrentQuestionIndex(newIndex)
  }


  function getQuestion(questionIndex) {
    var targetQuestionNumber = questionIndex + 1

    for (var i = 0; i < mockExamQuestionsList.length; i ++) {
      console.log("Mock exam question number")
      console.log(mockExamQuestionsList[i].mockExamQuestionNumber)
      console.log("Target number: " + targetQuestionNumber)
      console.log(mockExamQuestionsList[i].mockExamQuestionNumber  == targetQuestionNumber)
      if (mockExamQuestionsList[i].mockExamQuestionNumber == targetQuestionNumber) {
        console.log("Returning Question")
        console.log(mockExamQuestionsList[i])
        return (mockExamQuestionsList[i])
      }
    }
    console.log("Question not found")
  }



  function getSection(questionIndex) {
    var targetQuestionNumber = questionIndex + 1
    var mockExamSections = currentMockExam.mockExamSections
    console.log("Getting section")
    console.log(mockExamSections)
    console.log(mockExamSections.length)
    for (var i = 0; i < mockExamSections.length; i ++ ) {
      console.log("In loop start")
      for (var j = 0; j < mockExamSections[i].mockExamQuestions.length; j ++) {
        console.log("Now for questions")
        if (mockExamSections[i].mockExamQuestions[j].mockExamQuestionNumber == targetQuestionNumber) {
          console.log("Returning section info")
          console.log(mockExamSections[i])
          return mockExamSections[i];
        }
      }
    }
    console.log("ERROR - Unable to find section")
  }

  function submitAndGoToNextQuestion(questionNumber, userChoice) {
    console.log("Question number: " +  questionNumber)
    
    // Updating answer
    const userAnswer = userChoice[1]
    var updatedUserAnswers = currentUserAnswers
    updatedUserAnswers[questionNumber] = userChoice[1]
    setCurrentUserAnswers(updatedUserAnswers)


    // Moving to next question 
    console.log("Question numbers info")
    console.log(questionNumber)
    console.log(numberOfQuestions)
    if (questionNumber < numberOfQuestions) {
      console.log("Updating question index")
      setCurrentQuestionIndex(questionNumber)
    }
    const question = getQuestion((questionNumber-1))
  }

  function handleTimeExpired() {
    setTimerExpired(true);
  }

  function addMinutes(time, minutes) {

    console.log("In add minutes")
    console.log("Adding " + minutes + " to " + time)

    var mins = time.getMinutes() + minutes
    var hrs = time.getHours()

    while (mins > 60) {
      mins = mins - 60
      hrs = hrs + 1
    }

    time.setMinutes(mins)
    time.setHours(hrs)
  
    // Return the new time
    console.log("New total time: " + time)
    return time;
  }



    return (
      <>
        <AppBar position="fixed" open={open} style={{backgroundColor: "purple"}}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>

              <img alt="11plus-Whizzdom-logo" style={{height: '60px'}} src={whiteLogo} onClick={handleDrawerClose}></img>
             
          <div className="w-full mx-autp flex justify-end md:flex-nowrap flex-wrap md:px-10 px-4" >
            <div>
            <div>
                {(!timerExpired) ? (
                  <CountdownTimer 
                    targetTime={(addMinutes(new Date(), 5))} 
                    handleTimeExpired={handleTimeExpired}
                  />
                ) : (
                  <div>Your time is up.</div>
                )}
              </div>
            </div>
          </div>
          </Toolbar>
        </AppBar>

        
        <div>
          <ExamSideDrawerQuestionNav 
            drawerStatus={open} 
            handleDrawerClose={handleDrawerClose} 
            numberOfQuestions={numberOfQuestions}
            currentQuestionNumber={(currentQuestionIndex + 1)}
            updateCurrentQuestionIndex={updateCurrentQuestionIndex}
          />
          
          <MockExamQuestionTile
            question={getQuestion(currentQuestionIndex)}
            section={getSection(currentQuestionIndex)}
            submitAndGoToNextQuestion={submitAndGoToNextQuestion}
            currentUserAnswer={currentUserAnswers[(currentQuestionIndex + 1)]}
          />
        </div>
        
        <div style={{textAlign: "center", marginLeft: "5pc"}}>
          {currentQuestionIndex === 2 ? 
          <div>
            <button
              className="start-mock-exam-button"
              type="button"
              onClick={() => history.push(CONSTANTS.MockExamDemoCompletePage)}
            >
            End Demo Test
            </button>
          </div> 
          : <div></div>}
        </div>
      </>
    )
  }