import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useHistory } from "react-router-dom";


import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';




export default function UpcomingMockExamsModal({upcomingMockExamDates}) {

  let history = useHistory();
  console.log(upcomingMockExamDates)
  console.log(upcomingMockExamDates == "")
  const[sortedDates, setSortedDates] = useState([])

  useEffect(() => {
    console.log(upcomingMockExamDates)
      for (var i = upcomingMockExamDates.length - 1; i >= 0; i-- ) {
        if (upcomingMockExamDates[i].catchUpSession) {
            upcomingMockExamDates.splice(i, 1)
        }
      }
    upcomingMockExamDates.sort((a,b) => new Date(a.mockExamDate) - new Date(b.mockExamDate))
    setSortedDates(upcomingMockExamDates)
  });

  function formatDate(date) {
    console.log(date)
    var dateComponents = date.split("-")
    console.log(dateComponents)
    var dateString = dateComponents[2]
    console.log("Date string")
    console.log(dateString)
    console.log(typeof(dateString))
    var wordingForNumber = ""

    if (dateString[0] == 1) {
      wordingForNumber = "th"
    }
    else if(dateString[1] === "1") {
      wordingForNumber = "st"
    }
    else if (dateString[1] === "2") {
      wordingForNumber = "nd"
    }
    else if (dateString[1] === "3") {
      wordingForNumber = "rd"
      
    }
    else {
      console.log("Giving th for " + dateString)
      console.log(dateString[0])
      console.log(dateString[1])
      wordingForNumber = "th"
    }
    dateString += wordingForNumber
    console.log(dateString)

    var month = convertMonthNumberToCorrspondingWords(dateComponents[1])
    var year = dateComponents[0]

    return dateString + " " + month + " " + year
  }

  function formatTime(time) {
    var timeComponents = time.split(":")
    console.log(timeComponents)
    return timeComponents[0] + ":" + timeComponents[1] + " " + (timeComponents[0] < 12 ? "am" : "pm")

  }

  function convertMonthNumberToCorrspondingWords(monthNumber) {
    switch(monthNumber) {
      case "01":
        return "January"
      case "02":
        return "February"
      case "03":
        return "March"
      case "04":
        return "April"
      case "05":
        return "May"
      case "06":
        return "June"
      case "07":
        return "July"
      case "08":
        return "August"
      case "09":
        return "September"
      case "10":
        return "October"
      case "11":
        return "November"
      case "12":
        return "December"
    }
  }

  function getLinkTarget(blogType) {
    if (blogType == "GL") {
      return "https://whizzdom.co.uk/auth/preparing-for-the-GL-11plus-exams"
    } else {
      return "https://whizzdom.co.uk/auth/preparing-for-the-cem-11plus-exams"
    }
  }

  function registerForSession(mockExamSessionId) {
    history.push("/auth/register/3/" + mockExamSessionId)
  }


  return (
    <>
    {upcomingMockExamDates == "" ?
    <div className="table-container" style={{textAlign: "center", fontWeight: "bold", fontSize: "25px"}}>
      We currently have no upcoming mock exams.
    </div>
    :
    <div className="table-container">
      <table className="mock-exam-registration-table">
        <thead className="table-header">
          <tr>
            <th>Type</th>
            <th>Date</th>
            <th>Start Time</th>
            <th>Duration</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
        {upcomingMockExamDates.map((mockExamSession) => (
            <tr key={mockExamSession.mockExamSessionId} className="table-row">
              <td><u><a href={getLinkTarget(mockExamSession.mockExamType)} target="_blank">{mockExamSession.mockExamType}</a></u></td>
              <td>{formatDate(mockExamSession.mockExamDate)}</td>
              <td>{formatTime(mockExamSession.mockExamTime)}</td>
              <td>2 papers - 50 minutes each</td>
              <td><button className="register-for-mock-exam-button" onClick={() => registerForSession(mockExamSession.mockExamSessionId)}>Register</button></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>}
  </>
  );
}
