import React from "react";
import { Redirect, Route } from "react-router-dom";
// string constants

import * as CONSTANTS from "../../StringConstants"

function ProtectedAdminRoute({ component: Component, ...restOfProps }) {

    const user = JSON.parse(localStorage.getItem('user'));
    const isAuthenticated = user && user.accessToken && isAdmin(user.roles)
    console.log("this", isAuthenticated);

    function isAdmin(roles) {
        for (var i = 0; i < roles.length; i ++) {
            if (roles[i] == "ROLE_ADMIN") {
                return true
            }
        }
        return false
    }

    return (
        <Route
        {...restOfProps}
        render={(props) =>
            isAuthenticated ? <Component {...props} /> : <Redirect to={CONSTANTS.IndividualLogin} />
        }
        />
    );
}

export default ProtectedAdminRoute;
