import React, { useState, useEffect } from 'react';
import { Typography, TextField, Checkbox, FormControl, InputLabel, Select, MenuItem, ListItemText, Chip, Box } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { useFormik } from 'formik';
import * as yup from 'yup';
import * as CONSTANTS from "../../../StringConstants.js";
import TLHClassesAPICalls from 'services/TLHClassesAPICalls.js';
import TLHEndpoints from 'services/TLHEndpoints.js';

export default function ChangeEnquiryStatusModel({ handleClose, enquiry }) {

  const [tlhLessons, setTlhLessons] = useState();
  const [isLoadingLessons, setIsLoadingLessons] = useState(true);

  useEffect(() => {
    if (isLoadingLessons) {
      getAllTLHLessons();
    }
  }, [isLoadingLessons]);

  function getAllTLHLessons() {
    TLHClassesAPICalls.getAllTLHLessons().then(result => {
      setTlhLessons(result.data);
      setIsLoadingLessons(false);
      console.log(result.data)
    });
  }

  const formik = useFormik({
    initialValues: {
      newEnquiryStatus: enquiry.conversionStatus,
      children: enquiry.childrenBooked.reduce((acc, child) => {
        acc[child.assessmentStudentId] = {
          lessonId: [],
          date: '',
          time: '',
          reason: ''
        };
        return acc;
      }, {})
    },
    validationSchema: yup.object({}),
    onSubmit: (values) => {
      TLHEndpoints.updateChildConversionStatus(
        formik.values.newEnquiryStatus, 
        formik.values.children, 
        enquiry.enquiryAndAssessmentBookingId
      );
      handleClose(false);
    },
  });

  function formatStringFromEnumForm(status) {
    let formatConversionStatus = status.toLowerCase();
    formatConversionStatus = formatConversionStatus.replace(/_/g, " ");
    formatConversionStatus = formatConversionStatus.charAt(0).toUpperCase() + formatConversionStatus.slice(1);
    return formatConversionStatus;
  }

  return (
    <div style={{ textAlign: "center", alignContent: "center" }}>
      <form onSubmit={formik.handleSubmit}>
        <Typography variant="h6" style={{ marginBottom: "40px" }}>
          Current Enquiry Status: {enquiry.conversionStatus}
        </Typography>

        <div className="relative w-full" style={{ paddingBottom: '20px', display: 'flex', justifyContent: 'center' }}>
          <FormControl fullWidth style={{ maxWidth: '250px', paddingBottom: '20px' }}>
            <InputLabel id="newEnquiryStatus">Change Enquiry Status</InputLabel>
            <Select
              labelId="newEnquiryStatus"
              id="newEnquiryStatus"
              name="newEnquiryStatus"
              value={formik.values.newEnquiryStatus}
              onChange={formik.handleChange}
              fullWidth
            >
              {CONSTANTS.TLHEnquiryStatus.map((status, index) => (
                <MenuItem key={index} value={status}>
                  {formatStringFromEnumForm(status)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        {(formik.values.newEnquiryStatus === "ASSESSMENT_MEETING_SCHEDULED" 
        || formik.values.newEnquiryStatus === "INTRODUCTORY_MEETING_SCHEDULED"
        || formik.values.newEnquiryStatus === "ASSESSMENT_BOOKED"
        || formik.values.newEnquiryStatus === "REQUIRES_FOLLOW_UP")
         &&
          enquiry.childrenBooked.map((child) => (
            <div key={child.assessmentStudentId}>
              <Typography variant="h6" style={{ marginBottom: '20px' }}>
                Schedule for {child.studentFirstName}
              </Typography>
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
                <div style={{ marginRight: '20px' }}>
                  <TextField
                    id={`date-${child.assessmentStudentId}`}
                    name={`children.${child.assessmentStudentId}.date`}
                    label="New Appointment Date"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={formik.values.children[child.assessmentStudentId].date}
                    onChange={formik.handleChange}
                  />
                </div>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <TimePicker
                    id={`time-${child.assessmentStudentId}`}
                    name={`children.${child.assessmentStudentId}.time`}
                    label="New Appointment Time (12 hour clock format)"
                    value={formik.values.children[child.assessmentStudentId].time}
                    onChange={(value) => formik.setFieldValue(`children.${child.assessmentStudentId}.time`, value)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>
              <TextField
                style={{ marginTop: "30px" }}
                label="Reason for Rescheduling"
                id={`reason-${child.assessmentStudentId}`}
                name={`children.${child.assessmentStudentId}.reason`}
                fullWidth
                multiline
                rows={4}
                value={formik.values.children[child.assessmentStudentId].reason}
                onChange={formik.handleChange}
              />
            </div>
          ))
        }

        {formik.values.newEnquiryStatus === "CONVERTED" && (isLoadingLessons ? <div>Loading Tlh Lessons</div> :
          <>
            <div>
              For multiple children, if a child is not signing up leave their class blank
            </div>
            {enquiry.childrenBooked.map((child) => (
              <div key={child.assessmentStudentId} className="child-selection">
                <div className="relative w-full" style={{ justifyContent: 'center' }}>
                  <Typography variant="h6" style={{ marginBottom: '20px' }}>
                    Which class will {child.studentFirstName} be joining?
                  </Typography>
                </div>
                <div className="relative w-full" style={{ paddingBottom: '10px', display: 'flex', justifyContent: 'center' }}>
                  <FormControl fullWidth style={{ maxWidth: '250px', paddingBottom: '10px' }}>
                    <InputLabel id={`selectedLessonId-${child.assessmentStudentId}`}>Lesson</InputLabel>
                    <Select
                      labelId={`selectedLessonId-${child.assessmentStudentId}`}
                      id={`selectedLessonId-${child.assessmentStudentId}`}
                      name={`children.${child.assessmentStudentId}.lessonId`}
                      value={formik.values.children[child.assessmentStudentId].lessonId}
                      onChange={formik.handleChange}
                      multiple
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected.map((value) => (
                            <Chip key={value} label={tlhLessons.find(lesson => lesson.classId === value)?.className} />
                          ))}
                        </Box>
                      )}
                    >
                      {tlhLessons.map((lesson, index) => (
                        <MenuItem key={index} value={lesson.classId}>
                          <Checkbox checked={formik.values.children[child.assessmentStudentId].lessonId.includes(lesson.classId)} />
                          <ListItemText primary={`${lesson.className} - ${formatStringFromEnumForm(lesson.classDay)} - ${formatStringFromEnumForm(lesson.roomNumber)} - ${lesson.startTime}`} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            ))}
          </>)
        }

        <div style={{ textAlign: "center", paddingTop: "25px" }}>
          <button
            style={{
              padding: '10px 20px',
              fontSize: '18px',
              color: 'white',
              backgroundColor: '#0056b3',
              border: 'none',
              borderRadius: '5px',
              boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
              cursor: 'pointer',
              transition: 'all 0.3s ease',
            }}
            onMouseOver={(e) => {
              e.target.style.backgroundColor = '#004085';
              e.target.style.boxShadow = '0 8px 16px rgba(0,0,0,0.2)';
            }}
            onMouseOut={(e) => {
              e.target.style.backgroundColor = '#0056b3';
              e.target.style.boxShadow = '0 4px 8px rgba(0,0,0,0.1)';
            }}
            type="submit"
          >
            Change Enquiry Status
          </button>
        </div>
      </form>
    </div>
  );
}
