import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';

import { makeStyles } from '@material-ui/core/styles';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import * as CONSTANTS from "../../StringConstants"

import MockExamQuestionTopicTile from './MockExamQuestionTopicTile';
import ReportQuestionModal from 'components/EducationTiles/ReportQuestionModal';
import CheckedFlag from '../../assets/QuestionGeneratorImages/CheckedFlag.png'
import UnCheckedFlag from '../../assets/QuestionGeneratorImages/UncheckedFlag.png'
import { Typography } from '@material-ui/core';

const useStyles = makeStyles({

    basicQuestionPadding: {
      // paddingBottom: '20px',
      paddingLeft: '20px',
    },

    root: {
      minWidth: 100,
      maxWidth: 100,
      height: 100,
      textAlign: 'center',
      padding: 10
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 18, 
    },
    pos: {

    },
  });

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

 
export default function MockExamReviewTile({mockExamQuestionText, correctAnswer, explanation, textMultipleChoiceOptions, currentQuestionNumber, userAnswer, section, questionImage}) {

    console.log("Question details")

    console.log(userAnswer)


    const classes = useStyles();
    const [isFlagged, setIsFlagged] = useState(false)
    const [open, setOpen] = useState(false)

    const isCorrect = correctAnswer == userAnswer
    const answer = ""
    const solution = explanation
    var multipleChoiceOptions = textMultipleChoiceOptions
    const questionNumber = currentQuestionNumber
    const [imageData, setImageData] = useState({});
    const [sectionImageData, setSectionImageData] = useState({})

    // const currentQuestion = question 


    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    let textColor = isCorrect ? "#2bbb17" : "red"
    

    console.log("Is answer correct: " + isCorrect)
    console.log("User Answer: " + userAnswer)
    console.log("Correct Answer: " + answer)
    console.log("Solution: " + solution)

    useEffect(() => {
      setIsFlagged(false);
      setImageData(questionImage)
      setSectionImageData(section.sectionImage)

    })

    function setQuestionFlagAndCloseModal() {
      setIsFlagged(true)
      setOpen(false)
    }

    function intToChar(key) {
      const code = 'A'.charCodeAt(0);
      const newLetter = String.fromCharCode(code + key);
      return newLetter
    }

    function getMultipleChoiceOptions() {
      var multipleChoiceOptions = textMultipleChoiceOptions.split("||")
      console.log("Mock Exam multiple choice options text: " + multipleChoiceOptions )

      var tiles = [];

      for (var i = 0; i < multipleChoiceOptions.length; i++) {

        // if (section.subject == "ENGLISH") {
        //     multipleChoiceAnswerOption = multipleChoiceAnswerOption[i].charAt(0).toUpperCase() + multipleChoiceAnswerOption[i].slice(1);
        //   }


        tiles.push(
          <Grid item xs={12}>
              {intToChar(i) + ") "}   {formatFractionsAndSubscripts(multipleChoiceOptions[i])}
          </Grid>

        )}
        return tiles
    }


    // function getMultipleChoiceOptions() {
    //   var multipleChoiceOptions = textMultipleChoiceOptions.split("||")
    //   console.log("Multiple choice options are: ")
    //   console.log(multipleChoiceOptions)
    //   // console.log("Current selected choice is: " + selectedChoice)
    //   console.log("Current user answer is: " + userAnswer)

    //   var tiles = [];

    //   for (var i = 0; i < multipleChoiceOptions.length; i++) {
    //     // console.log(i)

    //     var multipleChoiceAnswerOption = multipleChoiceOptions[i]
    //     console.log("Subject")
    //     console.log(section.subject)
    //     if (section.subject == "ENGLISH") {
    //       multipleChoiceAnswerOption = multipleChoiceAnswerOption.charAt(0).toUpperCase() + multipleChoiceAnswerOption.slice(1);
    //     }

    //     let isBold = shouldBeBold(multipleChoiceAnswerOption)
    //     console.log("isBold: " + isBold)
    //     tiles.push(
    //       <Grid item xs={12}>
    //         <MockExamQuestionTopicTile 
    //           answerOption={multipleChoiceAnswerOption} 
    //           optionNumber={i}
    //           onOptionClick={onOptionClick}
    //           currentAnswerSelectedByUser={selectedChoice}
    //           shouldBeBoldOption={isBold}
    //         />
    //       </Grid>
    //     )}
    //     return tiles
    // }

    // function renderQuestion() {

    //   if (mockExamQuestionText.includes("||")) {
        
    //     console.log("Grammar Question type is Spelling or Punctuation ")

    //     const questionSegments = mockExamQuestionText.split("||")
    //     console.log(questionSegments)
    //     var underLinedSegments = []
  
  
    //       return <Grid item style={{textAlign: "center", fontSize: "20px", display: "contents", justifyContent: "space-evenly"}}>
    //             <u className='m-4'>{questionSegments[0]}</u>
    //             <u className='m-4'>{questionSegments[1]}</u>
    //             <u className='m-4'>{questionSegments[2]}</u>
    //             <u className='m-4'>{questionSegments[3]}</u>
    //         </Grid>
        
    //   } else {
    //     return mockExamQuestionText
    //   }
    // }


        // Handles checks for any subscripts or fractions - ASSUMES NO QUESTION HAS BOTH
    function formatFractionsAndSubscripts(mockExamText) {

      var questionText = mockExamText
      var questionSegments = []

      console.log("Checking if question needs super script rendering for metres squared etc..")
      console.log("Question text: " + questionText)

      // Checking for subscripts
      if (questionText.includes("**")) {
        console.log("Looks like question needs it")
        var answerOptionParts = questionText.split("**")
        // subscripts will be the odd indices
        for (var i = 0; i < answerOptionParts.length; i ++) {
          if (i % 2 == 0) {
            questionSegments.push(<b>{answerOptionParts[i]}</b>)
          } else {
            questionSegments.push(
            <b><sup>
              {answerOptionParts[i]}
            </sup></b>
          )}
        }
        return questionSegments
      }


      // Checking for fractions 
      if (questionText.includes("@@")) {
        var fractionParts = questionText.split("@@")
        console.log("fraction needing to be formatted")
        // Fractions will be the odd indexes within the array 
        for (var i = 0; i < fractionParts.length; i ++) {
          if (i % 2 == 0) {
            questionSegments.push(<b>{fractionParts[i]}</b>)
          } else {
            var numeratorAndDenominator = fractionParts[i].split("/")
            var numerator = numeratorAndDenominator[0]
            var denominator = numeratorAndDenominator[1]
            questionSegments.push(<b>{renderFraction(numerator, denominator)}</b>)
          }
        }
        return questionSegments
      }

      return <>{mockExamText}</>
    }

    function renderFraction(num, den) {
      if (num === 0) {
        return "0";
      } else if (den === 0) {
        return "undefined";
      } else {
        let whole = Math.floor(num / den);
        let remainder = num % den;
        if (whole === 0) {
          return <><sup>{num}</sup>/<sub>{den}</sub></>;
        } else if (remainder === 0) {
          return <>{whole}</>;
        } else {
          return (
            <span>
              {whole} <sup>{remainder}</sup>/<sub>{den}</sub>
            </span>
          );
        }
      }
    }

    // Use this instead of the renderMockExamQuestion for spelling or punctuation 
    function formatSpellingOrPunctuationQuestion() {
      console.log("Grammar Question type is: " + section.sectionName)

      const questionSegments = mockExamQuestionText.split("||")
      console.log(questionSegments)
      var underLinedSegments = []


        underLinedSegments.push(
          // <Grid container spacing={2}>
          <Grid item style={{textAlign: "center", fontSize: "20px", display: "contents", justifyContent: "space-evenly"}}>
              {/* <u className='m-4'>{questionSegments[0]}</u>
              <u className='m-4'>{questionSegments[1]}</u>
              <u className='m-4'>{questionSegments[2]}</u>
              <u className='m-4'>{questionSegments[3]}</u> */}
              {questionSegments[0] + " " + questionSegments[1] + " " + questionSegments[2] + " " + questionSegments[3]}
          </Grid>
        )
    

         return <Grid container spacing={3} >
              <Grid item xs={12}>
                <Typography variant='h6' style={{justifyContent: "space-evenly"}}>
                  {/* <div style={{display: "flex"}}> */}
                  {questionNumber}{") "} {underLinedSegments}
                  {/* </div>   */}
                </Typography>
              </Grid>
                {getSpellingAndPunctuationMultipleChoiceOptions(questionSegments)}
            </Grid>
    }


    function getSpellingAndPunctuationMultipleChoiceOptions(questionSegments) {

      multipleChoiceOptions = textMultipleChoiceOptions.split("||")

      return <Grid item xs={12} style={{textAlign: "center", fontSize: "20px", display: "flex", justifyContent: "space-evenly"}}>
        <div>
          
          <MockExamQuestionTopicTile 
            answerOption={multipleChoiceOptions[0]} 
            optionNumber={""}
            onOptionClick={null}
            currentAnswerSelectedByUser={null}
            shouldBeBoldOption={false}
            style={{ display: 'block' }}
            questionSegmentText={questionSegments[0]}
          />
        </div>
        <div>
          {/* <u className='m-4' style={{ display: 'block' }}>{questionSegments[1]}</u> */}
          <MockExamQuestionTopicTile 
            answerOption={multipleChoiceOptions[1]} 
            optionNumber={""}
            onOptionClick={null}
            currentAnswerSelectedByUser={null}
            shouldBeBoldOption={false}
            style={{ display: 'block' }}
            questionSegmentText={questionSegments[1]}
          />
        </div>
        <div>
          {/* <u className='m-4' style={{ display: 'block' }}>{questionSegments[2]}</u> */}
          <MockExamQuestionTopicTile 
            answerOption={multipleChoiceOptions[2]} 
            optionNumber={""}
            onOptionClick={null}
            currentAnswerSelectedByUser={null}
            shouldBeBoldOption={false}
            questionSegmentText={questionSegments[2]}
          />
         </div> 
         <div>
          {/* <u className='m-4' style={{ display: 'block' }}>{questionSegments[3]}</u> */}
          <MockExamQuestionTopicTile 
            answerOption={multipleChoiceOptions[3]} 
            optionNumber={""}
            onOptionClick={null}
            currentAnswerSelectedByUser={null}
            shouldBeBoldOption={false}
            questionSegmentText={questionSegments[3]}
          />
          </div>
          <div>
            {/* <b className='m-4' style={{ display: 'block' }}>{"."}</b> */}
            <MockExamQuestionTopicTile 
              answerOption={multipleChoiceOptions[4]} 
              optionNumber={""}
              onOptionClick={null}
              currentAnswerSelectedByUser={null}
              shouldBeBoldOption={false}
              questionSegmentText={questionSegments[4]}
            />
          </div>
          </Grid>
    }


    // function isMultipleChoiceForPunctuationOrSpelling(multipleChoiceOptions) {
    //   var multipleChoiceList = ['A','B','C','D']
    //   console.log("Multiple choice options when checking for punct or spelling")
    //   console.log(multipleChoiceOptions)
    //   console.log(multipleChoiceOptions === "undefined")
    //   console.log(typeof multipleChoiceOptions === "undefined")

    //   if (multipleChoiceOptions === null ||typeof multipleChoiceOptions === "undefined") {
    //     return false
    //   }
    //   if (multipleChoiceOptions.length != 4) {
    //     return false
    //   }
    //   for (var i = 0; i < multipleChoiceOptions.length; i++) {
    //     if (multipleChoiceOptions[i] != multipleChoiceList[i]) {
    //       return false
    //     }
    //   }
    //   return true

    // }

    function returnRelevantQuestionInstructions() {
      console.log(section)
      if(!section) {
        console.log("Null section passed, question details as below:")
        console.log("Question Number: " + currentQuestionNumber)
        console.log("Question text: " + mockExamQuestionText)
        console.log("Correct answer: " + correctAnswer)
        console.log("User answer: " + userAnswer)
        console.log("Explanation: " + explanation)
        console.log("Multiple choice options: " + textMultipleChoiceOptions)
        // mockExamQuestionText, correctAnswer, explanation, textMultipleChoiceOptions, currentQuestionNumber, userAnswer, section, questionImage
        return null
      }
      var grammarQuestionType = section.sectionName
      console.log("Current grammar question type")
      console.log(grammarQuestionType)

      if (grammarQuestionType == "Jumbled Sentences") {
        return CONSTANTS.JumbledSentenceQuestionInstructions
      } else if (grammarQuestionType == "Spelling") {
        return CONSTANTS.SpellingQuestionInstructions
      } else if (grammarQuestionType == "Punctuation") {
        return CONSTANTS.PunctuationQuestionInstructions
      } else if (grammarQuestionType == "Missing Words") {
        return CONSTANTS.MissingWordsQuestionInstructions
      } else if (grammarQuestionType == "Synonyms") {
        return CONSTANTS.SynonymsQuestionInstructions
      } else if (grammarQuestionType == "Antonyms") {
        return CONSTANTS.AntonymsQuestionInstructions
      } else if (grammarQuestionType == "Shuffled Sentences") {
        return CONSTANTS.ShuffledSentencesQuestionInstructions
      } else if (grammarQuestionType == "Comprehension") {
        return <b>{ CONSTANTS.ComprehensionQuestionInstructions} </b>
      }
      return section.sectionInstructions
    }


    function formattedComprehensionPassage() {

      // Works with formatting from when comprehensions are inputted - || means new line and ||p|| is new paragraph

      var comprehensionParagraphs = section.tableData.split("||")
      var formattedParaphraphs = [] 
      formattedParaphraphs.push(<br></br>)
      for (var i = 0; i < comprehensionParagraphs.length; i++) {
        if (comprehensionParagraphs[i] == "p") {
          formattedParaphraphs.push(<br></br>)
        } else {
          formattedParaphraphs.push(<div>{comprehensionParagraphs[i]}</div>)
        }
      }
      formattedParaphraphs.push(<br></br>)
      return formattedParaphraphs
    }

    
    function renderSolution() {

      if (!explanation) {
        return 
      }

      let lines = explanation.split("\\n");
      let formattedLines = [];
    
      for (let i = 0; i < lines.length; i++) {
        let line = lines[i];
    
        if (line.includes("•")) {
          let bulletPoints = line.split("•");
          formattedLines.push(<div style={{ marginBottom: '10px' }}>{formatFractionsAndSubscripts(bulletPoints[0])}</div>);
    
          for (let j = 1; j < bulletPoints.length; j++) {
            var lineToPush = bulletPoints[j].trim()
            formattedLines.push(<li style={{ marginBottom: '10px' }}>{formatFractionsAndSubscripts(lineToPush)}</li>);
          }
        } else {
          let bulletPointIndex = line.indexOf("•");
          while (bulletPointIndex !== -1) {
            var lineToPush = line.substring(0, bulletPointIndex).trim()
            formattedLines.push(<div style={{ marginBottom: '10px' }}>{formatFractionsAndSubscripts(lineToPush)}</div>);
            line = "• " + line.substring(bulletPointIndex + 1).trim();
            bulletPointIndex = line.indexOf("•");
          }
          formattedLines.push(<div style={{ marginBottom: '10px' }}>{formatFractionsAndSubscripts(line)}</div>);
        }
      }
      return <div style={{paddingLeft: "10px"}}>{formattedLines.map((line, index) => <p key={index}>{line}</p>)}</div>;

    }


    function renderSectionExampleText(sectionExampleText) {

      if (!sectionExampleText) {
        return 
      }

      let lines = sectionExampleText.split("\\n");
      let formattedLines = [];
    
      for (let i = 0; i < lines.length; i++) {
        let line = lines[i];
    
        if (line.includes("•")) {
          let bulletPoints = line.split("•");
          formattedLines.push(<div style={{ marginBottom: '10px' }}>{(bulletPoints[0])}</div>);
    
          for (let j = 1; j < bulletPoints.length; j++) {
            var lineToPush = bulletPoints[j].trim()
            formattedLines.push(<li style={{ marginBottom: '10px' }}>{(lineToPush)}</li>);
          }
        } else {
          let bulletPointIndex = line.indexOf("•");
          while (bulletPointIndex !== -1) {
            var lineToPush = line.substring(0, bulletPointIndex).trim()
            formattedLines.push(<div style={{ marginBottom: '10px' }}>{(lineToPush)}</div>);
            line = "• " + line.substring(bulletPointIndex + 1).trim();
            bulletPointIndex = line.indexOf("•");
          }
          formattedLines.push(<div style={{ marginBottom: '10px' }}>{(line)}</div>);
        }
      }
      return <div style={{paddingLeft: "10px"}}>{formattedLines.map((line, index) => <p key={index}>{line}</p>)}</div>;

    }

   
    return (
          <>
            <Grid container spacing={3} style={{padding: "15px"}}>
              <Grid item xs={12}>
              <div/>
                {returnRelevantQuestionInstructions()}  
              <div/>
              {/* <div style={{fontSize: "18px", marginBottom: "5px"}}>
                <b>{section.sectionInstructions}</b>
              </div> */}
              {/* {section.sectionImage} */}

              {/* <div style={{marginBottom: "10px"}}>
                <b>{section.exampleText}</b>
              </div> */}

              {sectionImageData? 
                <div style={{textAlign: "center", width: "100%"}}>
                  <img src={`data:image/png;base64,${sectionImageData}`} alt="question-image-loading..." style={{ display: 'inline-block' }} />
                </div>
                :
                <div></div>
                }

          <div style={{padding: "5px", textAlign: "center", fontSize: "20px"}}>
          <b>{renderSectionExampleText(section.exampleText)}</b>
          </div>

              
              {section.exampleImage}
              {section.sectionName == "Comprehension" ? formattedComprehensionPassage() : ""}


              {section.sectionName == "Spelling" || section.sectionName == "Punctuation" ? (<></>) : 
                  (<div style={{marginTop: "0px", marginBottom: "20px", paddingBlock: "5px", fontSize: "23px"}}>
                    <b>{currentQuestionNumber + ") "}</b> {formatFractionsAndSubscripts(mockExamQuestionText)}
                  </div>)
                }             
             </Grid>

             {questionImage ? 
                <div style={{textAlign: "center", width: "100%"}}>
                  <img src={`data:image/png;base64,${imageData}`} alt="question-image-loading..." style={{ display: 'inline-block' }} />
                </div>
                :
                <div></div>
                }



              {/* {renderAnswerOptions()} */}
              <div style={{fontSize: "20px"}}>
                {section.sectionName == "Spelling" || section.sectionName == "Punctuation" ?
                    formatSpellingOrPunctuationQuestion()
                  :
                  <Grid container spacing={3} >
                  {/* <Grid item xs={12}>
                    <Title>{questionNumber + 1}) {question}</Title>
                  </Grid> */}
                    {getMultipleChoiceOptions()}
                </Grid>
                }
              </div>

              
              <Grid item xs={12} className="p-8">  </Grid>              
                <Grid item xs={4} style={{ color: textColor }} className={classes.title}>
                    Your Answer: {(userAnswer == "" || userAnswer == null) ? "Unanswered" : formatFractionsAndSubscripts(userAnswer)}
                </Grid>    
                <Grid item xs={2}>
                </Grid> 
                <Grid item xs={4} style={{ color: "#2bbb17", fontWeight: "bolder"}} >
                   {isCorrect ? "" : "Correct Answer: " }  {isCorrect ? "" : formatFractionsAndSubscripts(correctAnswer)}  
                </Grid> 
                <Grid item xs={2}>
                <img
                    alt="..."
                    src={isFlagged ? CheckedFlag : UnCheckedFlag}
                    style={{width: "30%", padding: "0%"}}
                    onClick={() => setOpen(true)}
                />
                </Grid> 
                <div>
                  <Typography className='p-4' variant='h5'>Solution</Typography>
                  <div style={{ display: 'block' }}>
                    <b>{renderSolution()}</b>
                  </div>
                </div>
            </Grid>
              <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-add-mock-exam"
              aria-describedby="modal-able-to-add-a-set"
            >
              <Box sx={style}>
                <ReportQuestionModal
                  questionNumber={questionNumber}
                  question={mockExamQuestionText}
                  answer={answer}
                  userAnswer={userAnswer}
                  isCorrect={isCorrect}
                  solution={solution}
                  multipleChoiceOptions={section.sectionName == "Spelling" || section.sectionName == "Punctuation" ? multipleChoiceOptions : multipleChoiceOptions.split()}
                  setQuestionFlagAndClos  Modal={setQuestionFlagAndCloseModal}
                />
              </Box>
            </Modal>
          </>

    )
}  