import React, { useState } from 'react';
import { useHistory } from 'react-router';

import FooterAdmin from "components/Footers/FooterAdmin.js";
import whiteLogo from "./../../assets/logoVariations/Primarylogomark_purple_black_white-03.png"

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar'; //Only used in mock exams (and auth navbar )
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

import * as CONSTANTS from "../../StringConstants"
import SideDrawer from './SideDrawer';
import MockExamAccountSettingsButton from './MockExamAccountSettingsButton';
import QuestionsAndUserDataService from 'services/QuestionsAndUserDataService';


const drawerWidth = 260;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));


export default function MockExamUserPractiseExam() {
  const [open, setOpen] = React.useState(true);
  const [isLoadingPastExams, setIsLoadingPastExams] = useState(true)
  const [hasPastExamSets, setHasPastExamSets] = useState(false)
  let history = useHistory()


  let dummyData = [
    createDummyData("2022-05-21", 0.6), // 1
    createDummyData("2022-05-21", 0.4), // 1
    createDummyData("2022-05-23", 0.7), // 2
    createDummyData("2022-05-23", 0.83), // 3
    createDummyData("2022-05-24", 0.73), // 4
    createDummyData("2022-05-25", 0.91), // 5
  ]



  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };


 // The function and variable below are currently unused but can be called if dummy data is needed on the front end
 function createDummyData(time, amount) {
  return {time, amount}
}



  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} style={{backgroundColor: "purple"}}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          {/* <Typography variant="h6" noWrap component="div">
            Mini variant drawer
          </Typography> */}
            <img alt="11plus-Whizzdom-logo" style={{height: '60px'}} src={whiteLogo}></img>
            <div className="w-full mx-autp flex justify-end md:flex-nowrap flex-wrap md:px-10 px-4" >
              <div>
                <MockExamAccountSettingsButton/>
              </div>
            </div>
        </Toolbar>
      </AppBar>

      <SideDrawer drawerStatus={open} handleDrawerClose={handleDrawerClose}/>

      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", margin: "10% auto 0" }}>
        <Box sx={{width: "100%", maxWidth: 500}}>
          <Typography variant="h4" style={{textAlign: "center", justifyContent: "center", display: "flex", alignItems: "center", height: "70%", marginTop: "20%"}}>
              We recommend taking the short practice exam below to allow yourself to gain a familiarity with the mock exam platform and layout.
          </Typography>     
          <div style={{display: "flex", justifyContent: "center", marginTop: "5px"}}>
            <button
              className="start-mock-exam-button"
              type="button"
              onClick={() => history.push(CONSTANTS.MockExamDemoTest)}
            >
            Start Practice Exam
            </button>
          </div>  
        </Box>
      </div>

{/* 
      <div>
        <Box sx={{width: "100%", maxWidth: 500}}>
          <Typography variant="h4" style={{textAlign: "center", justifyContent: "center", display: "flex", alignItems: "center", height: "100%", position: "fixed", left: "35%", width: "35%"}}>
              We recommend taking the short practice test below to allow yourself to gain a familiarity with the mock exam platform and layout.
          </Typography>       
        </Box>
      </div>
      <div>
        <button
          className="start-mock-exam-button"
          type="button"
          onClick={null}
        >
         Start Mock Exam
        </button>
      </div> */}

      <Box component="main" sx={{ flexGrow: 1, left: "50%", position: "fixed", bottom: 0}}>
        <FooterAdmin/>
      </Box>
    </Box>
  );
}