import React, { useState, useEffect } from 'react';
import {useParams} from "react-router-dom";

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Chart from '../AnalyticsComponents/Chart';
import Box from '@mui/material/Box';

import PastWorksheets from '../AnalyticsComponents/PastWorksheets';
import QuestionsAndUserDataService from '../../services/QuestionsAndUserDataService'
import { Typography } from '@material-ui/core';

const columns = [
    { field: 'order', headerName: '', width: 20, sortable: false,},
    // {
    //   field: 'lastName',
    //   headerName: 'Last name',
    //   width: 150,
    //   editable: true,
    // },
    {
      field: 'fullName',
      headerName: 'Strongest Subjects',
      description: 'The student\'s top five subjects',
      sortable: false,
      width: 170,
      valueGetter: (params) =>
        `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    },
    {
        field: 'accuracy',
        headerName: 'Accuracy',
        description: 'The number of questions they\'ve gotten right out of the total number attempted',
        width: 79,
        editable: true,
      },
  ];

  const rows = [
    { id: 1, lastName: 'Snow', firstName: 'Jon', viewDetails: 'View', order: 1 },
    { id: 2, lastName: 'Lannister', firstName: 'Cersei', viewDetails: 'View', order: 2 },
    { id: 3, lastName: 'Lannister', firstName: 'Jaime', viewDetails: 'View', order: 3 },
    { id: 4, lastName: 'Stark', firstName: 'Arya', viewDetails: 'View', order: 4 },
    { id: 5, lastName: 'Targaryen with a long name', firstName: 'Daenerys', viewDetails: 'View', order: 5 },
    { id: 6, lastName: 'Melisandre', firstName: null, viewDetails: 'View', order: 6 },
    { id: 7, lastName: 'Clifford', firstName: 'Ferrara', viewDetails: 'View', order: 7 },
    { id: 8, lastName: 'Frances', firstName: 'Rossini', viewDetails: 'View', order: 8 },
    { id: 9, lastName: 'Roxie', firstName: 'Harvey', viewDetails: 'View', order: 9 },
  ];

export default function ViewStudent() {
    const [worksheetData, setWorksheetData] = useState([])
    const [chartData, setChartData] = useState([])
    const [isNoContent, setIsNoContent] = useState(false)
    const [studentData, setStudentData] = useState([])
    const { studentId } = useParams();

    const [isLoadingStudentData, setIsLoadingStudentData] = useState(true)
    const [isWorksheetDataLoading, setIsWorksheetDataLoading] = useState(true)

    const blockHeight = 120
    const paddingUsed = 20

    useEffect(() => {
        console.log("Arrived at student individual page")
        if (isLoadingStudentData) {
            QuestionsAndUserDataService.getStudentData(studentId).then(studentData => {
                console.log(studentData)
                setStudentData(studentData.data)
                setIsLoadingStudentData(false)
            })
        }

      });  

    // The function and variable below are currently unused but can be called if dummy data is needed on the front end
    function createDummyData(date, numberOfQuestionsInTheWorksheet, numberQuestionsCorrectlyAnswered, questionTypes, subject, topic, user, worksheetId, worksheetQuestions) {
        return {date, numberOfQuestionsInTheWorksheet, numberQuestionsCorrectlyAnswered, questionTypes, subject, topic, user, worksheetId, worksheetQuestions}
    }

    let dummyData = [
        createDummyData("2022-05-21", 10, 6, "Subtraction", "Math", "Fractions - Standard", null, 1, null), // 1
        createDummyData("2022-05-21", 10, 4, "Multiplication", "Math", "Decimals", null, 2, null), // 1
        createDummyData("2022-05-23", 15, 11, "Area Questions", "Math", "Volume and Area Problems", null, 3, null), // 2
        createDummyData("2022-05-23", 10, 9, "Multiplication", "Math", "Capacity Problems", null, 4, null), // 3
        createDummyData("2022-05-24", 12, 9, "Addition", "Math", "Length Problems", null, 5, null), // 4
        createDummyData("2022-05-25", 20, 19, "Multiplication", "Math", "Fractions - Standard", null, 6, null), // 5
    ]


    function convertToChartData(allWorkSheetData) {
        let chartData =[]
        for (let i = 0; i < allWorkSheetData.length; i++) {
            let percentageScore = Math.round((allWorkSheetData[i].numberQuestionsCorrectlyAnswered / allWorkSheetData[i].numberOfQuestionsInTheWorksheet)*100)
            // Since data is ordered with latest first we need to flip this to show the score progression correctly 
            let flippedIndex = allWorkSheetData.length - i - 1
            chartData[flippedIndex] = createData(allWorkSheetData[i].date, percentageScore)
        }
        setChartData(chartData)
    }

    function createData(time, amount) {
        return { time, amount };
    }


  return (        
            <div className='p-4'>
                <Typography className='py-4' variant="h5">{isLoadingStudentData ?  "Student" : studentData.firstName + "'s"} progress</Typography>
                <Grid container spacing={3}>
               {/* Chart */}
               <Grid item xs={8}>
                    <Paper
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        height: blockHeight*3 + paddingUsed,
                    }}
                    >
                    <Chart chartData={chartData}/>
                    </Paper>
                </Grid>

                <Grid item xs={4}>
                    
                    <div style={{paddingBottom: paddingUsed + 'px'}}>
                        <Box sx={{ height: 1.5*blockHeight, width: '100%' }}>
                            {/* <DataGrid
                                rows={rows}
                                columns={columns}
                                // autoPageSize
                                disableSelectionOnClick
                                disableColumnFilter
                                disableColumnMenu
                                disableColumnSelector
                                // disableExtendRowFullWidth
                                loading={false}
                                density="compact"
                                showColumnRightBorder={false}
                                showCellRightBorder={false}
                                hideFooter={true}
                            /> */}
                        </Box>    
                    </div>

                    <div>
                        <Box sx={{ height: 1.5*blockHeight, width: '100%' }}>
                            {/* <DataGrid
                                rows={rows}
                                columns={columns}
                                // autoPageSize
                                disableSelectionOnClick
                                disableColumnFilter
                                disableColumnMenu
                                disableColumnSelector
                                // disableExtendRowFullWidth
                                loading={false}
                                density="compact"
                                showColumnRightBorder={false}
                                showCellRightBorder={false}
                                hideFooter={true}
                            /> */}
                        </Box>    
                    </div>
                    </Grid>
                
                {/* Recent PastWorksheets */}
                <Grid item xs={12}>
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                        {/* Add check and mention no past worksheets if required */}
                        { isWorksheetDataLoading ?
                            <div>Loading data ... </div> : 
                            ((isNoContent) ? 
                                <div>No worksheets assigned yet </div> :
                                <PastWorksheets 
                                    data={worksheetData}
                                />)
                        }
                    </Paper>
                </Grid>

               

                {/* <Grid item xs={3}>
                   
                </Grid> */}

                 
                </Grid>
            </div>
  );
}
