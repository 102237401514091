export const ProDetails = [
    "Aligned with the Year 5 + 6 core curriculum.", 
    "Analytics on your child's performance.",
    "Unlimited Questions.",
    "Easy to view answers.",
    "Review portal for past worksheets.",
    "Measure your child's improvement over time.",
];

export const mockExamDetails = [
    "All the learning included in our standard package.",
    "Two premium mock exams per set to simulate the 11plus.",
    "Two monthly online sets (4 mock exams).",
    "National rankings for each exam",
    // "Two premium standard mock exams sets each month to measure your child's progress.",
    "Standardised scoring.",
    "Clear Solutions.",
    "Detailed performance feedback reports.",
    "Clear analytics to track your child's progress.",
    "Charged on a monthly basis (2 exam sets)."
]

export const oneOffMockExamDetails = [
    "Two mock exams in the set to simulate the intensity of entrance exams.",
    "Our premium product features for one exam",
    "Standardised scoring.",
    "Access to corrections and analytics for one month after the session.",
    "Analytical breakdown of your child's performance."
]