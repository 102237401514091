import React from 'react';

// Material UI imports

import Typography from '@material-ui/core/Typography';
import Grid from '@mui/material/Grid';



export default function IndividualQuestionInTeacherWorksheet({questionNumber, question, answer}) {

  return (
    <div>
      <Grid container spacing={3} >
        <Grid item xs={8}>
          <Typography>{questionNumber + 1}) {question}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography>Answer: {answer}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography> Change Question Button </Typography>
        </Grid>
      </Grid>
    </div>
  );
}
