import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';
import { Grid } from '@material-ui/core';

import QuestionsAndUserDataService  from 'services/QuestionsAndUserDataService';
import * as CONSTANTS from "../../StringConstants"
import whiteLogo from "./../../assets/logoVariations/Primarylogomark_purple_black_white-03.png"

const drawerWidth = 260;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));


  export default function MockExamSessionCompletePage() {
    const [open, setOpen] = useState(false);
    const [isConfirmingSessionHasEnded, setIsConfirmingSessionHasEnded] = useState(true)


    let history = useHistory()

    useEffect(() => {
      console.log("In use effect")
      if (isConfirmingSessionHasEnded) {
        confirmSessionIsEndedAndUpdateBackEnd()
      }
    });



  function confirmSessionIsEndedAndUpdateBackEnd() {
    QuestionsAndUserDataService.endMockExamSession().then(result => {
      console.log("Checking if mock exam has ended")
      console.log(result.data)

      if (result.data == "") {
        history.push(CONSTANTS.MockExamDashboard)
      } 
      // Store mock exam session tracker
      // setMockExamSessionTracker(result.data)


      const currentStage = result.data.currentMockExamStage

      if (currentStage === "STAGE_EXAM_IN_PROGRESS") {
        history.push(CONSTANTS.MockExamPage)
        return
      }
      if (currentStage === "STAGE_BREAK_TIME") {
        history.push(CONSTANTS.MockExamBreakPage)
        return
      }
      if (currentStage === "STAGE_SESSION_NOT_STARTED") {
        history.push(CONSTANTS.MockExamDashboard)
        return
      }

      setIsConfirmingSessionHasEnded(false)

    })
  }
     
    return (
      <>
        <AppBar position="fixed" open={open} style={{backgroundColor: "purple"}}>
          <Toolbar>
         
              <img alt="11plus-Whizzdom-logo" style={{height: '60px'}} src={whiteLogo}></img>
             
          </Toolbar>
        </AppBar>

        <Grid container>
          <Grid item xs={3}></Grid>

          <Grid item xs={6}>

          <Box sx={{width: "100%", maxWidth: 700, display: "flex", flexDirection: "column",  marginTop: "30%", alignItems: "center", height: "100vh"}}>
            <Typography 
                variant="h4" 
                style={{textAlign: "center", justifyContent: "center", display: "flex", alignItems: "center"}}>

              Congratulations! 
              <br></br>
              You have completed your 11 Plus mock exam session. You will receive and email with your results by 7pm today.
            </Typography> 

            <div style={{ textAlign: "center", width: "100%", marginBottom: "50px" }}>
                {/* <Link to="/auth/register/1"> */}
                    <button
                      className={"start-mock-exam-button"}
                      type="button"
                      onClick={() => history.push(CONSTANTS.MockExamDashboard)}
                    >
                    Return to Dashboard
                  </button>
              </div>

          </Box>
          </Grid>
            <Grid item xs={3}></Grid>
        </Grid>
      
      </>
    )
  }