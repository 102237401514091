import axios from 'axios'
import authHeader from "./auth-header";


const LOCAL_HOST = 'https://localhost:8443'
const PRODUCTION_PORT = 'https://whizzdom.co.uk:8443'
const LOCAL_OR_PRODUCTION = PRODUCTION_PORT;
const senderEmailApiToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiZjMwY2UwZWI2MGNlOGYwODQyYzkyMTZjZGQwYjcwMGQ2ZDNmOWYwOTk3ZDlmN2QzZDQ4NzA0ODAwMmJiZGQxZWRlNjcxOTJkOTE5Y2VlNmUiLCJpYXQiOjE2NTc1Mjk2NjkuMDM4NjEsIm5iZiI6MTY1NzUyOTY2OS4wMzg2MTksImV4cCI6NDgxMTEzMzI2OS4wMzU5NDEsInN1YiI6Ijc0MTI0MiIsInNjb3BlcyI6W119.dVuzJsyZsWGgiDDx_WNT2z25We4-I63ePYrpB6-Hf515XvLYqG2Iy1GsfgIv2Er8qS-k0lZbynrEpyKVzrNsUg"

const POSTMAN_CONVERSION_API = "https://graph.facebook.com/v15.0/631827471693892/events?access_token=EAARb48tiFHcBACXkoNBrZB7CRZCNDJT4jze0ZBRfaj7CaocBuYQ7UKyljNCuuGxroU8efLUguJ0O7K7nJxt1nZAO7bDySmZCb3O2F4ARo4RU1tC39ZA8AGjp3ctXRokhWW56IXjgZAmsoZCza9G7SbSfj7hxX9F1xyTMzDM0YISbH5pMrdgduTIRPRK1IeKReoYZD"
const FB_CONVERSION_API_VERSION = "v15.0"
const FB_PIXEL_ID = "631827471693892"
const FB_ACCESS_KEY_TOKEN = "EAARb48tiFHcBACXkoNBrZB7CRZCNDJT4jze0ZBRfaj7CaocBuYQ7UKyljNCuuGxroU8efLUguJ0O7K7nJxt1nZAO7bDySmZCb3O2F4ARo4RU1tC39ZA8AGjp3ctXRokhWW56IXjgZAmsoZCza9G7SbSfj7hxX9F1xyTMzDM0YISbH5pMrdgduTIRPRK1IeKReoYZD"
const FB_CONVERSION_API_ENDPOINT = "https://graph.facebook.com/" + FB_CONVERSION_API_VERSION + "/" + FB_PIXEL_ID + "/events?access_token=" + FB_ACCESS_KEY_TOKEN

const ROOT_TLH_ENDPOINT = "/auth"

async function encodeInSHA256(message) {
    // encode as UTF-8
    const msgBuffer = new TextEncoder().encode(message);                    

    // hash the message
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

    // convert ArrayBuffer to Array
    const hashArray = Array.from(new Uint8Array(hashBuffer));

    // convert bytes to hex string                  
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
}

class TLHEndpoints {

      //creating function to load ip address from the API

    saveNewAssessmentEnquiry(parentInfo, childrenInfo) {
        // Throws null as no user in local storage
        // const user = JSON.parse(localStorage.getItem('user'));
        // let email = user.email;
        return axios.post(LOCAL_OR_PRODUCTION + ROOT_TLH_ENDPOINT + `/saveNewAssessmentEnquiry`,{
            // email: email,
            parentInfo: parentInfo,
            childrenInfo: childrenInfo
        })
    }

    updateDetailsForNewAssessmentEnquiry(parentInfo, childrenInfo, enquiryId) {
        // Throws null as no user in local storage
        // const user = JSON.parse(localStorage.getItem('user'));
        // let email = user.email;
        return axios.post(LOCAL_OR_PRODUCTION + ROOT_TLH_ENDPOINT + `/updateDetailsForNewAssessmentEnquiry`,{
            // email: email,
            parentInfo: parentInfo,
            childrenInfo: childrenInfo,
            enquiryId: enquiryId
        })
    }

    addNewAssessmentType(assessmentInfo) {

        return axios.post(LOCAL_OR_PRODUCTION + ROOT_TLH_ENDPOINT + `/addNewAssessmentType`,{
            assessmentInfo: assessmentInfo
        })
    }

    getAllAssessmentTypesInfo() {
        return axios.post(LOCAL_OR_PRODUCTION + ROOT_TLH_ENDPOINT + `/getAllAssessmentTypesInfo`)
    }

    getAllEnquiriesInfo() {
        return axios.post(LOCAL_OR_PRODUCTION + ROOT_TLH_ENDPOINT + `/getAllEnquiriesInfo`)
    }

    // These are currently returing all assessments as opposed to going back one month 
    getAllBookedAssessmentsGoingBack1MonthToTheFuture() {
        return axios.post(LOCAL_OR_PRODUCTION + `/auth/getAllBookedAssessmentsGoingBack1MonthToTheFuture`)
    }

    getAllBookedAssessmentsForSpecificDate(date) {
        return axios.post(LOCAL_OR_PRODUCTION + ROOT_TLH_ENDPOINT + `/getAllBookedAssessmentsForSpecificDate`,{
            date: date,
        })
    }

    getAllBookedAssessmentMeetingsForSpecificDate(date) {
        return axios.post(LOCAL_OR_PRODUCTION + ROOT_TLH_ENDPOINT + `/getAllBookedAssessmentMeetingsForSpecificDate`,{
            date: date,
        })
    }

    addNoteToAssessmentBooking(assesmentBookingId, note) {
        return axios.post(LOCAL_OR_PRODUCTION + ROOT_TLH_ENDPOINT + `/addNoteToAssessmentBooking`,{
            assessmentBookingId: assesmentBookingId,
            note: note
        })
    }

    getAllCommunicationNotesForSpecificStudent(assesmentBookingId) {
        return axios.post(LOCAL_OR_PRODUCTION + ROOT_TLH_ENDPOINT + `/getAllCommunicationNotesForSpecificStudent`,{
            assessmentBookingId: assesmentBookingId,
        })
    }

    toggleChildAsAttendedAssessmentStatusOnBackend(assessmentBookingId, checked) {
        return axios.post(LOCAL_OR_PRODUCTION + ROOT_TLH_ENDPOINT + `/toggleChildAsAttendedAssessmentStatusOnBackend`,{
            assessmentBookingId: assessmentBookingId,
            checked: checked
        })
    }

    toggleChildAsConfirmedForAssessmentStatusOnBackend(assessmentBookingId, checked) {
        return axios.post(LOCAL_OR_PRODUCTION + ROOT_TLH_ENDPOINT + `/toggleChildAsConfirmedForAssessmentStatusOnBackend`,{
            assessmentBookingId: assessmentBookingId,
            checked: checked
        })
    }

    toggleAssessmentMeetingAttendedStatusOnBackend(assessmentBookingId, checked) {
        return axios.post(LOCAL_OR_PRODUCTION + ROOT_TLH_ENDPOINT + `/toggleAssessmentMeetingAttendedStatusOnBackend`,{
            assessmentBookingId: assessmentBookingId,
            checked: checked
        })
    }

    toggleConfirmedForAssessmentMeetingStatusOnBackend(assessmentBookingId, checked) {
        return axios.post(LOCAL_OR_PRODUCTION + ROOT_TLH_ENDPOINT + `/toggleConfirmedForAssessmentMeetingStatusOnBackend`,{
            assessmentBookingId: assessmentBookingId,
            checked: checked
        })
    }

    updateBookedAssessmentTime(date, time, reason, id) {
        return axios.post(LOCAL_OR_PRODUCTION + ROOT_TLH_ENDPOINT + `/updateBookedAssessmentTime`,{
            date: date,
            time: time,
            reason: reason,
            id: id,
        })
    }

    
    updateAssessmentMeetingTime(date, time, reason, id) {
        return axios.post(LOCAL_OR_PRODUCTION + ROOT_TLH_ENDPOINT + `/updateAssessmentMeetingTime`,{
            date: date,
            time: time,
            reason: reason,
            id: id,
        })
    }

    updateChildConversionStatus(newStatus, childrenInfo, parentAssessmentBookingId, newDate, newTime, reasonForChange) {
        return axios.post(LOCAL_OR_PRODUCTION + ROOT_TLH_ENDPOINT + `/updateChildConversionStatus`,{
            newStatus: newStatus,
            childrenInfo: childrenInfo,
            parentAssessmentBookingId: parentAssessmentBookingId,
            newDate: newDate, 
            newTime: newTime, 
            reasonForChange: reasonForChange
        })
    }

    createProspectiveParentsEvening(prospectiveParentsEveningsDate, prospectiveParentsEveningsTime) {
        return axios.post(LOCAL_OR_PRODUCTION + ROOT_TLH_ENDPOINT + `/createProspectiveParentsEvening`,{
            prospectiveParentsEveningsDate: prospectiveParentsEveningsDate,
            prospectiveParentsEveningsTime: prospectiveParentsEveningsTime,
        })
    }

    getAllProspectiveParentsEvenings() {
        return axios.post(LOCAL_OR_PRODUCTION + ROOT_TLH_ENDPOINT + `/getAllProspectiveParentsEvenings`)
    }

    getProspectiveParentsEveningInfo(parentsEveningId) {
        return axios.post(LOCAL_OR_PRODUCTION + ROOT_TLH_ENDPOINT + `/getProspectiveParentsEveningInfo`,{
            parentsEveningId: parentsEveningId,
        })
    }

    AddNewProspectiveParentsDataFormData(parentsEveningValues, parentsEveningId) {
        console.log("Firing")
        return axios.post(LOCAL_OR_PRODUCTION + ROOT_TLH_ENDPOINT + `/AddNewProspectiveParentsDataFormData`,{
            parentsEveningValues: parentsEveningValues,
            parentsEveningId: parentsEveningId,
        })
    }


    printLessonPlans(modalContent, numTeacherCopiesRequested, numStudentsCopiesRequested) {
        return axios.post(LOCAL_OR_PRODUCTION + ROOT_TLH_ENDPOINT + `/getTLHFiles`,{
            modalContent: modalContent,
            numTeacherCopiesRequested: numTeacherCopiesRequested,
            numStudentsCopiesRequested: numStudentsCopiesRequested
        })
    }


    // checkIfCurrentMockExamSessionTakingPlace() {
    //     const user = JSON.parse(localStorage.getItem('user'));
    //     let email = user.email;
    //     return axios.post(LOCAL_OR_PRODUCTION + `/getCurrentMockExamSessionIfTakingPlace`,{
    //         email: email,
    //     }, 
    //         {headers: authHeader()})
    // }


    // addMockExamSet(examSetName, examSetDate, examSetTime, examType) {
    //     return axios.post(LOCAL_OR_PRODUCTION + `/createMockExamSet`,{
    //         examSetName: examSetName,
    //         examSetDate: examSetDate,
    //         examSetTime: examSetTime,
    //         examType: examType
    //     }, 
    //         {headers: authHeader()})
    //     .then((response) => {
    //         console.log("Mock exam set added")
    //         return true
    //     }, (error) => {
    //         console.log("error adding mock exam set")
    //         console.log(error);
    //         return false
    //     })
    // }

  
    

}
export default new TLHEndpoints();
