import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
// import QuestionReviewTile from './QuestionReviewTile';
import Link from '@material-ui/core/Link';
import {useParams} from "react-router-dom";

import * as CONSTANTS from "../../StringConstants"
import FooterAdmin from "components/Footers/FooterAdmin.js";
import whiteLogo from "./../../assets/logoVariations/Primarylogomark_purple_black_white-03.png"
import MockExamAccountSettingsButton from './MockExamAccountSettingsButton';
import SideDrawer from './SideDrawer';
import QuestionsAndUserDataService from 'services/QuestionsAndUserDataService';

import CssBaseline from '@mui/material/CssBaseline';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import MuiAppBar from '@mui/material/AppBar'; //Only used in mock exams (and auth navbar )
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MockExamReviewTile from './MockExamReviewTile';



const drawerWidth = 260;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));


export default function ReviewMockExamPaper() {

    const { paperId } = useParams();
    // const [currentQuestion, setCurrentQuestion] = useState(0)
    const [currentQuestion, setCurrentQuestion] = useState(() => {
      // Initialize the count from local storage, or 0 if it's not set
      const storedCurrentQuestionNumber = localStorage.getItem('currentQuestion');
      if (paperId != localStorage.getItem('currentPaperId')) {
        return 0
      }
      return storedCurrentQuestionNumber ? parseInt(storedCurrentQuestionNumber, 10) : 0;
    });

    const [navBarMultiplier, setNavBarMultiplier] = useState(0)
    const [isLoadingMockExamPaper, setIsLoadingMockExamPaper] = useState(true)
    const [userAnswers, setUserAnswers] = useState([])
    const [mockExamPaper, setMockExamPaper] = useState([])

    var isComprehension = false
    const [questions, setQuestions] = useState([])
    const [open, setOpen] = React.useState(false);





    const handleDrawerOpen = () => {
      setOpen(true);
    };
  
    const handleDrawerClose = () => {
      setOpen(false);
    };

    useEffect(() => {
      if (isLoadingMockExamPaper) {
        getMockExamPaperWithUserAnswerSet()
      }
      localStorage.setItem('currentQuestion', currentQuestion);
      localStorage.setItem('currentPaperId', paperId);
    }, [currentQuestion]);

    function getMockExamPaperWithUserAnswerSet() {
      console.log("Getting mockExam paper with user answer set")
      QuestionsAndUserDataService.getMockExamPaperWithUserAnswerSet(paperId).then(result => {
        console.log("Result from getting mock exam paper with user answer set")
        console.log(result)
        setMockExamPaper(result.data.MockExamPaper)
        populateMockExamQuestionsList(result.data.MockExamPaper.mockExamSections)
        setIsLoadingMockExamPaper(false)
        setUserAnswers(result.data.MockExamAnswerSet.mockExamAnswers)
        // setSessionsData(result.data)
      });
    }

    function populateMockExamQuestionsList(mockExamSections) {
      console.log("Populating mock exam question list")
      var questionNumber = 1
      var currentUserAnswers = {}
      var listOfQuestions = []
      for (var i = 0; i < mockExamSections.length; i ++ ) {
        console.log(mockExamSections[i])
        console.log(mockExamSections[i].mockExamQuestions.length)
        for (var j = 0; j < mockExamSections[i].mockExamQuestions.length; j ++) {
          console.log("Question data")
          console.log(mockExamSections[i].mockExamQuestions[j])
          listOfQuestions.push(mockExamSections[i].mockExamQuestions[j])
          currentUserAnswers[questionNumber] = ""
          questionNumber += 1
        }
      }

      console.log("Mock exam currently:")
      console.log(mockExamPaper)
      
      console.log("Unsorted Questions")
      console.log(listOfQuestions)
      listOfQuestions = listOfQuestions.sort((a,b) => a.mockExamQuestionNumber - b.mockExamQuestionNumber)
      console.log("Sorted questions before normalising")
      console.log(listOfQuestions)
      listOfQuestions = normalizeList(listOfQuestions)
      console.log("Sorted questions")
      console.log(listOfQuestions)
      setQuestions(listOfQuestions)
    
    }

      // Ensure all question numbers increase by one and we don't skip any numbers or have repeats of any question number
  function normalizeList(list) {
    let i = 0;
    while (i < list.length) {
      const current = list[i];
      const next = list[i + 1];
      if (!next) break;
      if (next.mockExamQuestionNumber - current.mockExamQuestionNumber === 1) {
        i++;
      } else if (next.mockExamQuestionNumber - current.mockExamQuestionNumber > 1) {
        const gap = next.mockExamQuestionNumber - current.mockExamQuestionNumber;
        for (let j = i + 1; j < list.length; j++) {
          list[j].mockExamQuestionNumber -= gap - 1;
        }
        continue;
      } else {
        const randomIndex = Math.floor(Math.random() * 2);
        const chosen = [current, next][randomIndex];
        const other = [current, next][1 - randomIndex];
        chosen.mockExamQuestionNumber += 1;
        for (let j = i + 1; j < list.length; j++) {
          if (list[j].mockExamQuestionNumber === chosen.mockExamQuestionNumber) {
            list[j].mockExamQuestionNumber += 1;
          }
          if (list[j].mockExamQuestionNumber > chosen.mockExamQuestionNumber) {
            list[j].mockExamQuestionNumber -= 1;
          }
        }
        i++;
      }
    }
    return list;
  }
  


    // useEffect(() => {
    //     console.log("Firing use effect")
    //     if (questionsData.comprehensionDescription !== "" && !areQuestionsSorted) {
    //         const comprehensionQuestions = questions
    //         const sortedComprehensionQuestions = comprehensionQuestions.sort((a,b) => a.questionNumber > b.questionNumber ? 1 : -1)
    //         setQuestions(sortedComprehensionQuestions)
    //     }
    //     setAreQuestionsSorted(true)
    //     console.log("Questions info")
    //     console.log(questions)
    //   });


    function showNumberNavigationBelt() {
        
        console.log("In navigation belt")
        let navigationSteps = []
        navigationSteps.push(
        <Grid item xs={1} justifyContent='center'>
            <Link underline="always" onClick={() => setNavBarMultiplier(navBarMultiplier-1)}>{navBarMultiplier > 0 ? "Prev" : null}</Link>
        </Grid>)

        let maxNumberOfQuestionsToShowInNav = 10
        let bottomLimit = maxNumberOfQuestionsToShowInNav*navBarMultiplier
        let topLimit = maxNumberOfQuestionsToShowInNav + (maxNumberOfQuestionsToShowInNav*navBarMultiplier)
        console.log("About to render buttons")
        console.log("Iterating: " + Math.min(topLimit, questions.length) + " times")
        console.log(topLimit)
        console.log(questions.length)
        console.log(questions)
        for (let i = bottomLimit + 1; i <= Math.min(topLimit, questions.length); i++) {
          console.log("Rendering each button")
          console.log("Number of list items: " + navigationSteps.length)
            navigationSteps.push(
                <Grid item xs={1} justifyContent='center'>
                    <div className={(i-1) === currentQuestion ? "mock-exam-review-navbar-selected" : "mock-exam-review-navbar"}>
                        <Link color="inherit" underline={ (i-1) === currentQuestion ? "always" : "hover"} onClick={() => setCurrentQuestion(i-1)}>
                                Q{i}
                        </Link>
                    </div>
                </Grid>
            )
        }
        navigationSteps.push(
            <Grid item xs={1} justifyContent='center'>
                <Link underline="always" onClick={() => setNavBarMultiplier(navBarMultiplier+1)}>{ topLimit >= questions.length ? "" : "Next"}</Link>
            </Grid>)
        console.log("Ending and returning navigation belt")
        return navigationSteps
    }

    function showRelevantQuestionReviewTile(questionToGoTo) {
        let indexOfquestionToGoTo = questionToGoTo 
        console.log("Questions info in mock exam review")
        console.log(questions)
        console.log(indexOfquestionToGoTo)
        console.log("User answers")
        console.log(userAnswers)

        var userAnswerValue = ""

        for (var i = 0; i < userAnswers.length; i++) {
          if (userAnswers[i].mockExamQuestionsCompositeId.mockExamQuestionId == questions[indexOfquestionToGoTo].mockExamQuestionId) {
            userAnswerValue = userAnswers[i].userAnswer
          }
        }

        let answerOptions = questions[indexOfquestionToGoTo].multipleChoiceOptions
        let multipleChoiceAnswerOptions = questions[indexOfquestionToGoTo].multipleChoiceOptions

        // Will already be in a list form if user has just completed the sheet. 
        if (typeof answerOptions === 'string' || answerOptions instanceof String) {
            multipleChoiceAnswerOptions = questions[indexOfquestionToGoTo].multipleChoiceOptions.split("||")
        } 
        // questionToGoTo refers to the actual number not the index value of the question, hence need to subtract one
        return (
                <MockExamReviewTile
                  mockExamQuestionText={questions[indexOfquestionToGoTo].mockExamQuestionText}
                  correctAnswer={questions[indexOfquestionToGoTo].correctAnswer}
                  explanation={questions[indexOfquestionToGoTo].explanation}
                  textMultipleChoiceOptions={questions[indexOfquestionToGoTo].textMultipleChoiceOptions}
                  currentQuestionNumber={questions[indexOfquestionToGoTo].mockExamQuestionNumber}
                  userAnswer={userAnswerValue}
                  section={getSection(questionToGoTo)}
                  questionImage={questions[indexOfquestionToGoTo].mockExamQuestionImage}
                />
        )
    }

    function getSection(questionIndex) {
      var targetQuestionNumber = questionIndex + 1
      var mockExamSections = mockExamPaper.mockExamSections
      console.log("All sections include")
      console.log(mockExamSections)
      console.log("Getting section")
      console.log("Looking for section of question: " + questionIndex)
  
      for (var i = 0; i < mockExamSections.length; i ++ ) {
        console.log("Searching in first loop")
        for (var j = 0; j < mockExamSections[i].mockExamQuestions.length; j ++) {
          console.log("Searching in second loop")
          if (mockExamSections[i].mockExamQuestions[j].mockExamQuestionNumber == targetQuestionNumber) {
            console.log("Returning section info")
            console.log(mockExamSections[i])
            return mockExamSections[i];
          }
        }
      }
      console.log("ERROR - Unable to find section")
    }

    // function returnRelevantQuestionInstructions() {
    //     // console.log("Grammar Question Type")
    //     // console.log(grammarQuestionType)
    //     var grammarQuestionType = getSectionName()
    //     console.log("Current grammar question type")
    //     console.log(grammarQuestionType)

    //     if (grammarQuestionType == "Jumbled Sentences") {
    //       return CONSTANTS.JumbledSentenceQuestionInstructions
    //     } else if (grammarQuestionType == "Spelling") {
    //       return CONSTANTS.SpellingQuestionInstructions
    //     } else if (grammarQuestionType == "Punctuation") {
    //       return CONSTANTS.PunctuationQuestionInstructions
    //     } else if (grammarQuestionType == "Missing Words") {
    //       return CONSTANTS.MissingWordsQuestionInstructions
    //     } else if (grammarQuestionType == "Synonyms") {
    //       return CONSTANTS.SynonymsQuestionInstructions
    //     } else if (grammarQuestionType == "Antonyms") {
    //       return CONSTANTS.AntonymsQuestionInstructions
    //     } else if (grammarQuestionType == "Shuffled Sentences") {
    //       return CONSTANTS.ShuffledSentencesQuestionInstructions
    //     } else if (grammarQuestionType == "English Comprehension") {
    //       return <b>{questionsData.comprehensionDescription + " " + CONSTANTS.ComprehensionQuestionInstructions}</b>
    //     }
    //     return ""
    //   }

      function getSectionName() {
        var targetQuestionNumber = currentQuestion
        var mockExamSections = mockExamPaper.mockExamSections
    
        for (var i = 0; i < mockExamSections.length; i ++ ) {
          for (var j = 0; j < mockExamSections[i].mockExamQuestions.length; j ++) {
            if (mockExamSections[i].mockExamQuestions[j].mockExamQuestionNumber == targetQuestionNumber) {
              console.log("Returning section info")
              console.log(mockExamSections[i])
              return mockExamSections[i].sectionName;
            }
          }
        }
        console.log("ERROR - Unable to find section")
      }

    // function formattedComprehensionPassage() {

    //     // Works with formatting from when comprehensions are inputted - || means new line and ||p|| is new paragraph
  
    //     var comprehensionParagraphs = questionsData.comprehensionText.split("||")
    //     var formattedParaphraphs = [] 
    //     formattedParaphraphs.push(<br></br>)
    //     for (var i = 0; i < comprehensionParagraphs.length; i++) {
    //       if (comprehensionParagraphs[i] == "p") {
    //         formattedParaphraphs.push(<br></br>)
    //       } else {
    //         formattedParaphraphs.push(<div>{comprehensionParagraphs[i]}</div>)
    //       }
    //     }
    //     formattedParaphraphs.push(<br></br>)
    //     return formattedParaphraphs
    //   }

    return (
        // <div>
          <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open={open} style={{backgroundColor: "purple"}}>
              <Toolbar>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  sx={{
                    marginRight: 5,
                    ...(open && { display: 'none' }),
                  }}
                >
                  <MenuIcon />
                </IconButton>

                  <img alt="11plus-Whizzdom-logo" style={{height: '60px'}} src={whiteLogo}></img>
                  <div className="w-full mx-autp flex justify-end md:flex-nowrap flex-wrap md:px-10 px-4" >
                    <div>
                      <MockExamAccountSettingsButton/>
                    </div>
                  </div>
              </Toolbar>
            </AppBar>

            <SideDrawer drawerStatus={open} handleDrawerClose={handleDrawerClose}/>
          
          {isLoadingMockExamPaper ? <div>Loading Paper</div> : <>

          <Grid container direction="row" justifyContent='center'>

              <Grid item xs={12} style={{marginTop: "80px"}}>
              </Grid>
              

              <Grid container className='p-4' direction="row" justifyContent='center' >
                {showNumberNavigationBelt()} 
              </Grid>
                
              {/* <br/>
                {returnRelevantQuestionInstructions()}  
              <br/> */}

              <Grid item xs={12}>
                {showRelevantQuestionReviewTile(currentQuestion)}
              </Grid>

            </Grid>
            </>}
         </Box>

    )
}
