import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import QuestionsAndUserDataService from '../../../services/QuestionsAndUserDataService'


// string constants



export default function AddMockExamToSetForm({mockExamSetId}) {

  const validationSchema = yup.object({
    name: yup
      .string('Enter your name')
      .required('A name is required for the exam set'),
    examLength: yup
      .number('Enter the length of the exam')
      .required('A duration is required for the exam set'),
  });


  // const WithMaterialUI = () => {
    const formik = useFormik({
      initialValues: {
        name: '',
        examLength: 0,
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {

        console.log("Submitting mock exam form")
        console.log("formik values")
        console.log(formik.values.name)
        console.log(formik.values.examLength)

        QuestionsAndUserDataService.addMockExamToSet(mockExamSetId, formik.values.name, formik.values.examLength)

        // QuestionsAndUserDataService.addMockExamSet(formik.values.name, formik.values.examLength, formattedTime, examType).then(result => {
        //   console.log(result)
        // })
        // updateUserDetails(formik.values.name, formik.values.examLength, formik.values.time).then(onStepChange(2))

      },
    });

  return (
    
    <div>
     <form
        onSubmit={formik.handleSubmit}
        >
         <label> Exam Details</label>
        <div className="relative w-full mb-3">

        <TextField
            fullWidth
            id="name"
            name="name"
            label="Exam name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />

        </div>


        <div className="relative w-full mb-3">

          <TextField
              fullWidth
              id="examLength"
              name="examLength"
              label="Exam Duration"
              value={formik.values.examLength}
              onChange={formik.handleChange}
              error={formik.touched.examLength && Boolean(formik.errors.examLength)}
              helperText={formik.touched.examLength && formik.errors.examLength}
            />

          </div>


        <div className="text-center mt-6">
          <Button
            colour="primary"
            className="bg-blueGray-800 active:bg-blueGray-600 uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
            type="submit"
          >
            Add Exam To Set
          </Button>
        </div>
      </form>
    </div>
  );
}
