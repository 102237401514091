import React from 'react';
// import "../../assets/styles/index.css";

// Material UI imports

import Typography from '@material-ui/core/Typography';
import Card from '@mui/material/Card';
import { CardActionArea } from '@mui/material';
import Box from '@mui/material/Box';

import AssignmentIcon from '../../../../src/assets/img/AssignmentIcon.png';

// String constants

// import * as CONSTANTS from "./../../../StringConstants"
import * as CONSTANTS from "../../../StringConstants.js";
import { useHistory } from "react-router-dom";


// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   textAlign: "-moz-center",
//   textAlign: '-webkit-center',
//   width: 400,
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   p: 4,
  
// };


export default function AddNewAssignment({classData}) {

  // var cardWidth = 315
  // var cardHeight = 240
  const blockHeight = 75
  let history = useHistory()

  function directToHomeworkPage() {
    console.log("Setting hw")
    history.push(CONSTANTS.AddAssignmentView)
  }

  return (
    <div style={{textAlign: "-moz-center",
    textAlign: '-webkit-center'}}>

      <Card onClick={directToHomeworkPage}>
        <CardActionArea>
        <Box
          component="img"
          sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'row',
                height: blockHeight,
            }}
          alt="Add assignment icon"
          src={AssignmentIcon}
          >
          </Box>
          
        <Typography gutterBottom 
            variant="h6" 
            component="div" 
            style={{textAlign:"center", paddingTop:0}}>
            <b>Assign Homework</b>
        </Typography>
        </CardActionArea>
      </Card>

    </div>
  );
}